<div class="complaint create-container">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{'complaint.create.Title'|translate}}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <app-complaint-form [scenario]="formScenario"></app-complaint-form>
        </mat-card-content>
    </mat-card>
</div>
