/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ToasterService} from 'angular2-toaster';
import {
    FLYER_LOGO_MAX_FILE_SIZE,
    ITEM_STATUS_ID_OBJ_LIST, ITEM_STATUS_OBJ_LIST,
    FORM_SCENARIO_CREATE,
    FORM_SCENARIO_EDIT
} from '../../../constants';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseForm} from '../../../classes/base-form';
import {environment} from '../../../../environments/environment';
import {AdvertiserService} from '../../../services/advertiser.service';
import {IAdvertiser} from '../../../interfaces/advertiser.interface';
import {IImageFileRestriction} from '../../../interfaces/image-file-restriction';
import {IFlyerCategory} from '../../../interfaces/flyer-category.interface';
import {FLYER_EXTRA_WITH_EMPTY_DATA, IExtraItem, IExtraItemEditInput} from '../../../interfaces/extra-item.interface';
import {ExtraItemService} from '../../../services/extra-item.service';


@Component({
    selector: 'app-extra-item-form',
    templateUrl: 'form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExtraItemFormComponent extends BaseForm<IExtraItem, IExtraItemEditInput> implements OnInit {
    @Input() scenario: string;
    @Output() dataSaved: EventEmitter<IExtraItem> = new EventEmitter();

    item: IExtraItem = {...FLYER_EXTRA_WITH_EMPTY_DATA};
    backLink = '/extra-item/index';
    error = '';

    scenarioCreate = FORM_SCENARIO_CREATE; // Need for component HTML usage
    scenarioEdit = FORM_SCENARIO_EDIT; // Need for component HTML usage

    formGroup: FormGroup = null;

    statusList = [
        {id: ITEM_STATUS_ID_OBJ_LIST.DELETED, name: ITEM_STATUS_OBJ_LIST.DELETED},
        {id: ITEM_STATUS_ID_OBJ_LIST.ACTIVE, name: ITEM_STATUS_OBJ_LIST.ACTIVE},
        {id: ITEM_STATUS_ID_OBJ_LIST.PAUSED, name: ITEM_STATUS_OBJ_LIST.PAUSED},
        {id: ITEM_STATUS_ID_OBJ_LIST.DISABLED, name: ITEM_STATUS_OBJ_LIST.DISABLED},
        {id: ITEM_STATUS_ID_OBJ_LIST.HIDDEN, name: ITEM_STATUS_OBJ_LIST.HIDDEN},
        {id: ITEM_STATUS_ID_OBJ_LIST.MODERATED, name: ITEM_STATUS_OBJ_LIST.MODERATED},
    ];

    advertiserList: Array<IAdvertiser> = [];
    categoryList: Array<IFlyerCategory> = [];

    uploadType = {
        image1: 'image1',
        image2: 'image2'
    };

    imageRestriction: IImageFileRestriction = {
        acceptedFiles: ['image/png', 'image/jpeg'],
        maxFileSize: FLYER_LOGO_MAX_FILE_SIZE
    };

    selectedCategoryList: Array<number> = [];

    private image1File: File = null;
    private image1FileChanged = false;
    private image2File: File = null;
    private image2FileChanged = false;

    constructor(
        protected translate: TranslateService,
        protected itemService: ExtraItemService,
        protected toasterService: ToasterService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected changeDetectorRef: ChangeDetectorRef,
        private formBuilder: FormBuilder,
        private advertiserService: AdvertiserService
    ) {
        super(translate, toasterService, route, router, changeDetectorRef, itemService);

        this.buildFormGroup();
    }

    ngOnInit() {
        this.handleItemFromRequestOrHistory('extraItem');
    }

    fillFormData() {
        this.updateAdvertiserList();

        const formData = {
            status_id: this.item.status_id,
            title: this.item.title,
            description_short: this.item.description_short,
            description_long: this.item.description_long,
            code: this.item.code,
            frequency: this.item.frequency,
            advertiser_id: this.item.advertiser_id,
            image1: this.item.image1,
            image2: this.item.image2,
            preselect: this.item.preselect,
            step: this.item.step,
            opt_in: this.item.opt_in
        };

        this.formGroup.reset(formData);
    }

    getFormErrorMessage(): string {
        return ''; //TODO: add handler
    }

    submitForm(data: any): void {
        this.error = '';

        const input = this.getInputFromFormData(data);

        this.itemService.storeItem(input).subscribe((item) => {
            this.item = item;
            this.onSave();
        }, result => this.handleRequestError(result, 'ExtraItem'), () => {
            this.goBack();
        });
    }

    getInputFromFormData(data: any): IExtraItemEditInput {
        return {
            data: {
                id: this.item.id,
                status_id: data.status_id,
                advertiser_id: data.advertiser_id,
                code: data.code,
                description_short: data.description_short,
                description_long: data.description_long,
                frequency: data.frequency,
                title: data.title,
                opt_in: true == data.opt_in,
                step: data.step,
                preselect: data.preselect,
                condition: data.condition
            },
            image1: this.image1FileChanged ? this.image1File : null,
            image2: this.image2FileChanged ? this.image2File : null,
        };
    }

    onUploadError(event: any, uploadType: string): void {
        switch (uploadType) {
            case this.uploadType.image1:
                this.image1File = null;
                break;
            default: //this.uploadType.image2
                this.image2File = null;
        }

        if (environment.production) {
            console.warn('Image upload failed!');
        } else {
            console.warn('Image upload failed!', event);
        }
    }

    onUploadSuccess(file: File, uploadType: string): void {
        switch (uploadType) {
            case this.uploadType.image1:
                this.image1File = file;
                this.image1FileChanged = true;
                this.formGroup.patchValue({image1: file.name});
                break;
            default: //this.uploadType.image2
                this.image2File = file;
                this.image2FileChanged = true;
        }
    }

    onUploadCancel(uploadType: string): void {
        switch (uploadType) {
            case this.uploadType.image1:
                this.image1File = null;
                this.formGroup.patchValue({image1: null});
                break;
            default: //this.uploadType.image2
                this.image2File = null;
        }
    }

    getImage1Link(): string {
        return !!this.item.image1 && this.item.image1.length ? this.item.image1 : '';
    }

    getImage2Link(): string {
        return !!this.item.image2 && this.item.image2.length ? this.item.image2 : '';
    }

    private buildFormGroup() {
        this.formGroup = this.formBuilder.group({
            status_id: ['', Validators.compose([
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(255)
            ])],
            title: ['', Validators.compose([
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(255)
            ])],
            description_short: ['', Validators.compose([
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(255)
            ])],
            description_long: ['', Validators.compose([
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(255)
            ])],
            frequency: ['', Validators.compose([
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(255)
            ])],
            code: ['', Validators.compose([
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(255)
            ])],
            advertiser_id: ['', Validators.compose([
                Validators.required,
                Validators.maxLength(255)
            ])],
            opt_in: [''],
            preselect: [''],
            step: [''],
            image1: ['', Validators.compose([
                Validators.required
            ])],
            image2: ['']
        });
    }

    private updateAdvertiserList(): void {
        this.advertiserService.getList({
            sort: 'company',
            order: 'asc',
            page: 0,
            filter: {}
        }).subscribe((list) => {
            this.advertiserList = list.rows;

            this.changeDetectorRef.detectChanges();
        });
    }
}
