/**
 * 2020 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2020 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ToasterService} from 'angular2-toaster';
import {TranslateService} from '@ngx-translate/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {matchPasswordValidator} from '../../../functions';
import {LoginService} from '../../../services/login.service';
import {BaseForm} from '../../../classes/base-form';
import {Helper} from '../../../classes/helper';

@Component({
    selector: 'app-reset-password-form',
    templateUrl: './form.component.html'
})

export class ResetPasswordFormComponent implements OnInit {
    @Input() accessToken: string;
    @Output() public passwordChanged: EventEmitter<boolean> = new EventEmitter();
    @Output() public invalidHash: EventEmitter<boolean> = new EventEmitter();

    error = '';
    formGroup: FormGroup = null;

    constructor(
        public loginService: LoginService,
        protected translate: TranslateService,
        private toasterService: ToasterService,
        private formBuilder: FormBuilder
    ) {
    }

    public ngOnInit() {
        this.buildFormGroup();
    }

    getErrorMessage(control: AbstractControl): string {
        return Helper.getErrorMessage(control, this.translate);
    }

    getFormErrorMessage(): string {
        if (this.formGroup.hasError('matchPassword')) {
            return 'validators.passwords mismatch';
        }
    }

    reset(data: any): void {
        this.error = '';

        this.loginService.resetPassword(this.accessToken, data.password).subscribe(() => {
            this.onSave();
        }, (response: any) => {
            if (null === response) {
                console.warn('Error. Can\'t reset password');
                this.onFail();
            } else {
                if (undefined === response.message) {
                    return;
                }

                this.error = `reset-password.form.errors.${response.message}`;
                this.onFail();
            }
        });
    }

    onSave() {
        this.passwordChanged.emit(true);
        this.toasterService.pop('success', this.translate.instant('pop.Success'), this.translate.instant('reset-password.Password has changed successfully'));
    }

    onFail() {
        this.toasterService.pop('error', this.translate.instant('pop.Failed'), this.translate.instant('reset-password.Failed to change password'));
    }

    private buildFormGroup() {
        this.formGroup = this.formBuilder.group({
            password: ['', Validators.compose([
                Validators.required,
                Validators.minLength(5),
                Validators.maxLength(255)
            ])],
            repeatPassword: ['', Validators.compose([
                Validators.required,
                Validators.minLength(5),
                Validators.maxLength(255)
            ])]
        });

        this.formGroup.setValidators(matchPasswordValidator);
    }
}
