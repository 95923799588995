/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ToasterService} from 'angular2-toaster';
import {
    FORM_SCENARIO_CREATE,
    FORM_SCENARIO_EDIT,
    STATUS_OPTION_LIST
} from '@app/constants';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseForm} from '@classes/base-form';
import {IFlyer} from '@interfaces/flyer.interface';
import {FlyerService} from '@services/flyer.service';
import {ISurvey, ISurveyEditInput, SURVEY_WITH_EMPTY_DATA} from '@interfaces/survey.interface';
import {SurveyService} from '@services/survey.service';


@Component({
    selector: 'app-survey-form',
    templateUrl: 'form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SurveyFormComponent extends BaseForm<ISurvey, ISurveyEditInput> implements OnInit {
    @Input() scenario: string;
    @Output() dataSaved: EventEmitter<ISurvey> = new EventEmitter();

    item: ISurvey = {...SURVEY_WITH_EMPTY_DATA};
    backLink = '/survey/index';
    error = '';

    scenarioCreate = FORM_SCENARIO_CREATE; // Need for component HTML usage
    scenarioEdit = FORM_SCENARIO_EDIT; // Need for component HTML usage

    formGroup: FormGroup = null;

    statusList = STATUS_OPTION_LIST;

    flyerList: Array<IFlyer> = [];

    startDate = new Date();

    constructor(
        protected translate: TranslateService,
        protected itemService: SurveyService,
        protected toasterService: ToasterService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected changeDetectorRef: ChangeDetectorRef,
        private formBuilder: FormBuilder,
        private flyerService: FlyerService
    ) {
        super(translate, toasterService, route, router, changeDetectorRef, itemService);

        this.buildFormGroup();
    }

    ngOnInit() {
        this.handleItemFromRequestOrHistory('survey');
    }

    fillFormData() {
        this.updateFlyerList();

        const formData = {
            status_id: this.item.status_id,
            description: this.item.description,
            flyer_id: this.item.flyer_id,
            start: this.item.start,
            end: this.item.end
        };

        this.formGroup.reset(formData);
    }

    getFormErrorMessage(): string {
        return ''; //TODO: add handler
    }

    submitForm(data: any): void {
        this.error = '';

        const input = this.getInputFromFormData(data);

        this.itemService.storeItem(input).subscribe((item) => {
            this.item = item;
            this.onSave();
        }, result => this.handleRequestError(result, 'Survey'), () => {
            this.goBack();
        });
    }

    getInputFromFormData(data: any): ISurveyEditInput {
        return {
            id: this.item.id,
            status_id: data.status_id,
            flyer_id: data.flyer_id,
            description: data.description,
            start: data.start,
            end: data.end
        };
    }

    private buildFormGroup() {
        this.formGroup = this.formBuilder.group({
            status_id: ['', Validators.compose([
                Validators.required,
                Validators.maxLength(255)
            ])],
            description: ['', Validators.compose([
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(255)
            ])],
            flyer_id: ['', Validators.compose([
                Validators.required,
                Validators.maxLength(255)
            ])],
            start: ['', Validators.compose([
                Validators.minLength(1),
                Validators.maxLength(255)
            ])],
            end: ['', Validators.compose([
                Validators.minLength(1),
                Validators.maxLength(255)
            ])],
        });
    }

    private updateFlyerList(): void {
        this.flyerService.getList({
            sort: 'title',
            order: 'asc',
            page: 0,
            filter: {}
        }).subscribe((list) => {
            this.flyerList = list.rows;

            this.changeDetectorRef.detectChanges();
        });
    }
}
