/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {
    COMPLAINT_TYPE_ID_OBJ_LIST,
    COMPLAINT_TYPE_OBJ_LIST,
    USER_POLICY_OBJ_LIST
} from '@app/constants';
import {LoginService} from '@services/login.service';
import {TranslateService} from '@ngx-translate/core';
import {BaseViewComponent} from '@classes/base-view';
import {COMPLAINT_WITH_EMPTY_DATA, IComplaint, IComplaintEditInput} from '@interfaces/complaint.interface';
import {ComplaintService} from '@services/complaint.service';

@Component({
    selector: 'app-complaint-view',
    templateUrl: 'view.component.html'
})
export class ComplaintViewComponent extends BaseViewComponent<IComplaint, IComplaintEditInput> implements OnInit {
    item: IComplaint = {...COMPLAINT_WITH_EMPTY_DATA};
    baseRoute = '/complaint';
    itemHistoryKey = 'complaint';

    constructor(
        protected itemService: ComplaintService,
        protected translate: TranslateService,
        protected deleteDialog: MatDialog,
        protected router: Router,
        protected route: ActivatedRoute,
        private loginService: LoginService
    ) {
        super(itemService, translate, deleteDialog, router, route);
    }

    ngOnInit() {
        this.handleItemFromRequestOrHistory();
    }

    canIndex(): boolean {
        return this.loginService.isGranted(USER_POLICY_OBJ_LIST.POLICY_ADMIN_COMPLAINT_EDIT);
    }

    canDelete(): boolean {
        return this.loginService.isGranted(USER_POLICY_OBJ_LIST.POLICY_ADMIN_COMPLAINT_EDIT);
    }

    getTypeTranslationKey(typeId: number): string {
        switch (typeId) {
            case COMPLAINT_TYPE_ID_OBJ_LIST.LATE_DELIVERY:
                return COMPLAINT_TYPE_OBJ_LIST.LATE_DELIVERY;
            case COMPLAINT_TYPE_ID_OBJ_LIST.TYPE_DELIVERED_OLD:
                return COMPLAINT_TYPE_OBJ_LIST.DELIVERED_OLD;
            case COMPLAINT_TYPE_ID_OBJ_LIST.NOT_DELIVERED:
                return COMPLAINT_TYPE_OBJ_LIST.NOT_DELIVERED;
            default: // COMPLAINT_TYPE_ID_OBJ_LIST.WRONG_ADDRESS:
                return COMPLAINT_TYPE_OBJ_LIST.WRONG_ADDRESS;
        }
    }
}
