/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {
    USER_POLICY_OBJ_LIST,
    USER_RECEIVE_FLYER_ID_OBJ_LIST,
    USER_RECEIVE_FLYER_OBJ_LIST,
    YES_NO_ID_OBJ_LIST,
    YES_NO_OBJ_LIST,
    USER_SEX_ID_OBJ_LIST,
    USER_SEX_OBJ_LIST,
    USER_SOURCE_ID_OBJ_LIST,
    USER_SOURCE_OBJ_LIST,
    USER_STATUS_ID_OBJ_LIST,
    USER_STATUS_OBJ_LIST
} from '@app/constants';
import {LoginService} from '@services/login.service';
import {UserService} from '@services/user.service';
import {USER_WITH_EMPTY_DATA, IUser, IUserEditInput} from '@interfaces/user.interface';
import {TranslateService} from '@ngx-translate/core';
import {BaseViewComponent} from '@classes/base-view';

@Component({
    selector: 'app-user-view',
    templateUrl: './view.component.html'
})
export class UserViewComponent extends BaseViewComponent<IUser, IUserEditInput> implements OnInit {
    item: IUser = {...USER_WITH_EMPTY_DATA};
    baseRoute = '/user';
    itemHistoryKey = 'user';

    constructor(
        protected itemService: UserService,
        protected translate: TranslateService,
        protected deleteDialog: MatDialog,
        protected router: Router,
        protected route: ActivatedRoute,
        private loginService: LoginService
    ) {
        super(itemService, translate, deleteDialog, router, route);
    }

    ngOnInit() {
        this.handleItemFromRequestOrHistory();
    }

    canIndex(): boolean {
        return this.loginService.isGranted(USER_POLICY_OBJ_LIST.POLICY_INDEX_USER);
    }

    canDelete(): boolean {
        return this.loginService.isGranted(USER_POLICY_OBJ_LIST.POLICY_DELETE_USER) && this.item.status_id != USER_STATUS_ID_OBJ_LIST.DELETED;
    }

    getUserStatusClass(status_id: number): string {
        switch (status_id) {
            case USER_STATUS_ID_OBJ_LIST.DISABLED:
                return 'yellow';
            case USER_STATUS_ID_OBJ_LIST.PAUSED:
                return 'yellow';
            case USER_STATUS_ID_OBJ_LIST.DELETED:
                return 'red';
            case USER_STATUS_ID_OBJ_LIST.HIDDEN:
                return 'blue';
            case USER_STATUS_ID_OBJ_LIST.MODERATED:
                return 'blue';
            default: // USER_STATUS_ID_OBJ_LIST.ACTIVE
                return 'green';
        }
    }

    getUserStatusName(status_id: number): string {
        switch (status_id) {
            case USER_STATUS_ID_OBJ_LIST.DISABLED:
                return USER_STATUS_OBJ_LIST.DISABLED;
            case USER_STATUS_ID_OBJ_LIST.PAUSED:
                return USER_STATUS_OBJ_LIST.PAUSED;
            case USER_STATUS_ID_OBJ_LIST.DELETED:
                return USER_STATUS_OBJ_LIST.DELETED;
            case USER_STATUS_ID_OBJ_LIST.HIDDEN:
                return USER_STATUS_OBJ_LIST.HIDDEN;
            case USER_STATUS_ID_OBJ_LIST.MODERATED:
                return USER_STATUS_OBJ_LIST.MODERATED;
            default: // USER_STATUS_ID_OBJ_LIST.ACTIVE
                return USER_STATUS_OBJ_LIST.ACTIVE;
        }
    }

    userHasLogo(user: IUser): boolean {
        return !!user.avatar_img && user.avatar_img.length > 0;
    }

    getLogoLink(user: IUser): string {
        return this.userHasLogo(user) ? user.avatar_img : '';
    }

    getSex(value: string): string {
        switch (value) {
            case USER_SEX_ID_OBJ_LIST.MALE:
                return USER_SEX_OBJ_LIST.MALE;
            case USER_SEX_ID_OBJ_LIST.FEMALE:
                return USER_SEX_OBJ_LIST.FEMALE;
            case USER_SEX_ID_OBJ_LIST.FAM:
                return USER_SEX_OBJ_LIST.FAM;
            default:
                return '';
        }
    }

    getNewsletter(value: number): string {
        switch (value) {
            case YES_NO_ID_OBJ_LIST.NO:
                return YES_NO_OBJ_LIST.NO;
            case YES_NO_ID_OBJ_LIST.YES:
                return YES_NO_OBJ_LIST.YES;
            default:
                return '';
        }
    }

    getSendSticker(value: number): string {
        switch (value) {
            case YES_NO_ID_OBJ_LIST.NO:
                return YES_NO_OBJ_LIST.NO;
            case YES_NO_ID_OBJ_LIST.YES:
                return YES_NO_OBJ_LIST.YES;
            default:
                return '';
        }
    }

    getReceiveFlyers(value: number): string {
        switch (value) {
            case USER_RECEIVE_FLYER_ID_OBJ_LIST.YES:
                return USER_RECEIVE_FLYER_OBJ_LIST.YES;
            case USER_RECEIVE_FLYER_ID_OBJ_LIST.TO_MUCH:
                return USER_RECEIVE_FLYER_OBJ_LIST.TO_MUCH;
            case USER_RECEIVE_FLYER_ID_OBJ_LIST.STICKER:
                return USER_RECEIVE_FLYER_OBJ_LIST.STICKER;
            case USER_RECEIVE_FLYER_ID_OBJ_LIST.POOR_DELIVERY:
                return USER_RECEIVE_FLYER_OBJ_LIST.POOR_DELIVERY;
            default:
                return '';
        }
    }

    getSource(value: number): string {
        switch (value) {
            case USER_SOURCE_ID_OBJ_LIST.WEBSITE:
                return USER_SOURCE_OBJ_LIST.WEBSITE;
            case USER_SOURCE_ID_OBJ_LIST.IMPORT:
                return USER_SOURCE_OBJ_LIST.IMPORT;
            case USER_SOURCE_ID_OBJ_LIST.MOBILE_APP:
                return USER_SOURCE_OBJ_LIST.MOBILE_APP;
            case USER_SOURCE_ID_OBJ_LIST.PHONE:
                return USER_SOURCE_OBJ_LIST.PHONE;
            case USER_SOURCE_ID_OBJ_LIST.WRITTEN:
                return USER_SOURCE_OBJ_LIST.WRITTEN;
            default:
                return '';
        }
    }
}
