/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ToasterService} from 'angular2-toaster';
import {
    FORM_SCENARIO_CREATE,
    FORM_SCENARIO_EDIT, USER_POLICY_OBJ_LIST
} from '../../../constants';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseForm} from '../../../classes/base-form';
import {ROLE_WITH_EMPTY_DATA, IRoleEditInput, IRole} from '../../../interfaces/role.interface';
import {RoleService} from '../../../services/role.service';

@Component({
    selector: 'app-role-form',
    templateUrl: 'form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoleFormComponent extends BaseForm<IRole, IRoleEditInput> implements OnInit {
    item: IRole = {...ROLE_WITH_EMPTY_DATA};
    backLink = '/role/index';
    error = '';

    scenarioCreate = FORM_SCENARIO_CREATE; // Need for component HTML usage
    scenarioEdit = FORM_SCENARIO_EDIT; // Need for component HTML usage

    formGroup: FormGroup = null;

    policiesList: Array<string> = Object.keys(USER_POLICY_OBJ_LIST);

    selectedPoliciesList: Array<string> = [];

    constructor(
        protected translate: TranslateService,
        protected itemService: RoleService,
        protected toasterService: ToasterService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected changeDetectorRef: ChangeDetectorRef,
        private formBuilder: FormBuilder
    ) {
        super(translate, toasterService, route, router, changeDetectorRef, itemService);

        this.buildFormGroup();
    }

    ngOnInit() {
        this.handleItemFromRequestOrHistory('role');
    }

    fillFormData() {
        const formData = {
            role: this.item.role
        };

        this.formGroup.reset(formData);

        this.selectedPoliciesList = this.item.policiesList;
    }

    getFormErrorMessage(): string {
        return ''; //TODO: add handler
    }

    submitForm(data: any): void {
        this.error = '';

        const input = this.getInputFromFormData(data);

        this.itemService.storeItem(input).subscribe((item) => {
            this.item = item;
            this.onSave();
        }, result => this.handleRequestError(result, 'Role'), () => {
            this.goBack();
        });
    }

    getInputFromFormData(data: any): IRoleEditInput {
        return {
            id: this.item.id,
            role: data.role,
            policiesList: this.selectedPoliciesList
        };
    }

    togglePolicy(policy: string): void {
        const index = this.item.policiesList.indexOf(policy);
        if (-1 === index) {
            this.selectedPoliciesList.push(policy);
        } else {
            this.selectedPoliciesList.splice(index, 1);
        }
    }

    private buildFormGroup() {
        this.formGroup = this.formBuilder.group({
            role: ['', Validators.compose([
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(255)
            ])]
        });
    }
}
