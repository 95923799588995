<div class="column-filter-container">
    <p class="info">{{'column-filter.hint'|translate}}</p>

    <div cdkDropListGroup>
        <div class="list-container">
            <h2>{{'column-filter.active-columns'|translate}}</h2>

            <div
                #activeList
                cdkDropList
                [cdkDropListData]="active"
                class="column-filter-list"
                (cdkDropListDropped)="drop($event)">
                <div class="column-box" *ngFor="let item of active" cdkDrag>
                    <div class="column-custom-placeholder" *cdkDragPlaceholder></div>
                    {{item.title|translate}}
                </div>
            </div>
        </div>

        <div class="list-container">
            <h2>{{'column-filter.available-columns'|translate}}</h2>

            <div
                #availableList
                cdkDropList
                [cdkDropListData]="available"
                class="column-filter-list"
                (cdkDropListDropped)="drop($event)">
                <div class="column-box" *ngFor="let item of available" cdkDrag>
                    <div class="column-custom-placeholder" *cdkDragPlaceholder></div>
                    {{item.title|translate}}
                </div>
            </div>
        </div>
    </div>
</div>

<hr/>
