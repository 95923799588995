<div class="user view-container">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{'user.view.Title'|translate}}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <div class="row">
                <div class="col-4">
                    <h3>{{'user.fields.status_id'|translate}}</h3>
                    <div class="status" [ngClass]="getUserStatusClass(item.status_id)">{{'status.' + getUserStatusName(item.status_id)|translate}}</div>
                </div>

                <div class="col-4">
                    <h3>{{'user.fields.role_id'|translate}}</h3>
                    <div>{{item.Role.role}}</div>
                </div>

                <div class="col-4">
                    <h3>{{'item.fields.id'|translate}}</h3>
                    <div>{{item.id}}</div>
                </div>
            </div>
            <hr>

            <div class="row">
                <div class="col-6">
                    <h3>{{'user.fields.avatar_img'|translate}}</h3>
                    <div>{{item.avatar_img}}</div>
                </div>
            </div>
            <hr>

            <div class="row">
                <div class="col-4">
                    <h3>{{'user.fields.first_name'|translate}}</h3>
                    <div>{{item.first_name}}</div>
                </div>

                <div class="col-4">
                    <h3>{{'user.fields.middle_name'|translate}}</h3>
                    <div>{{item.middle_name}}</div>
                </div>

                <div class="col-4">
                    <h3>{{'user.fields.last_name'|translate}}</h3>
                    <div>{{item.last_name}}</div>
                </div>
            </div>
            <hr>

            <div class="row">
                <div class="col-4">
                    <h3>{{'user.fields.sex'|translate}}</h3>
                    <div>{{getSex(item.sex)}}</div>
                </div>

                <div class="col-4">
                    <h3>{{'user.fields.mail'|translate}}</h3>
                    <div>{{item.mail}}</div>
                </div>

                <div class="col-4">
                    <h3>{{'user.fields.phone'|translate}}</h3>
                    <div>{{item.phone}}</div>
                </div>
            </div>
            <hr>

            <div class="row">
                <div class="col-3">
                    <h3>{{'user.fields.street_address'|translate}}</h3>
                    <div>{{item.street_address}}</div>
                </div>

                <div class="col-3">
                    <h3>{{'user.fields.zip_code'|translate}}</h3>
                    <div>{{item.zip_code}}</div>
                </div>

                <div class="col-3">
                    <h3>{{'user.fields.building_number'|translate}}</h3>
                    <div>{{item.building_number}}</div>
                </div>

                <div class="col-3">
                    <h3>{{'user.fields.building_number_extra'|translate}}</h3>
                    <div>{{item.building_number_extra}}</div>
                </div>
            </div>
            <hr>

            <div class="row">
                <div class="col-3">
                    <h3>{{'user.fields.receives_flyers'|translate}}</h3>
                    <div>{{getReceiveFlyers(item.receives_flyers)}}</div>
                </div>

                <div class="col-3">
                    <h3>{{'user.fields.newsletter'|translate}}</h3>
                    <div>{{getNewsletter(item.newsletter)}}</div>
                </div>

                <div class="col-3">
                    <h3>{{'user.fields.mail_confirmed'|translate}}</h3>
                    <div>{{item.mail_confirmed}}</div>
                </div>

                <div class="col-3">
                    <h3>{{'user.fields.choice_help'|translate}}</h3>
                    <div>{{item.choice_help}}</div>
                </div>
            </div>
            <hr>

            <div class="row">
                <div class="col-6">
                    <h3>{{'user.fields.send_sticker'|translate}}</h3>
                    <div>{{getSendSticker(item.send_sticker)}}</div>
                </div>

                <div class="col-6">
                    <h3>{{'user.fields.why'|translate}}</h3>
                    <div>{{item.why}}</div>
                </div>
            </div>
            <hr>

            <div class="row">
                <div class="col-6">
                    <h3>{{'user.fields.source_id'|translate}}</h3>
                    <div>{{getSource(item.source_id)}}</div>
                </div>

                <div class="col-6">
                    <h3>{{'user.fields.delete_reason'|translate}}</h3>
                    <div>{{item.delete_reason}}</div>
                </div>
            </div>
            <hr>

            <div class="row">
                <div class="col-6">
                    <h3>{{'user.fields.comments'|translate}}</h3>
                    <div>{{item.comments}}</div>
                </div>

                <div class="col-6">
                    <h3>{{'user.fields.admin_note'|translate}}</h3>
                    <div>{{item.admin_note}}</div>
                </div>
            </div>
            <hr>

            <div *ngIf="canIndex()" class="row">
                <div class="col-6">
                    <h3>{{'user.fields.created'|translate}}</h3>
                    <div>{{item.created | date : dateTimeLongFormat : timeZone}}</div>
                </div>

                <div class="col-6">
                    <h3>{{'user.fields.updated'|translate}}</h3>
                    <div>{{item.updated | date : dateTimeLongFormat : timeZone}}</div>
                </div>
            </div>
            <hr>

            <div class="button-row d-flex justify-content-end mt-3">
                <button
                    mat-raised-button
                    color="primary"
                    (click)="updateSelected(item)">{{'actions.update'|translate}}</button>

                <button *ngIf="canDelete()"
                    mat-raised-button
                    color="warn"
                    (click)="deleteSelected(item)">{{'actions.delete'|translate}}</button>

                <button *ngIf="canIndex()"
                    mat-raised-button
                    (click)="backToIndex()">{{'actions.Back to list'|translate}}</button>
            </div>


        </mat-card-content>
    </mat-card>
</div>



