/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ToasterService} from 'angular2-toaster';
import {FormFieldErrorListInterface, RequestErrorInterface} from '@interfaces/error.interface';
import {Helper} from '@classes/helper';
import {UserService} from '@services/user.service';

@Component({
    selector: 'app-user-import-form',
    templateUrl: 'form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserImportFormComponent implements OnInit {
    error = '';
    formGroup: FormGroup = null;
    importFile: File = null;
    processLog = '';

    constructor(
        protected translate: TranslateService,
        private userService: UserService,
        private toasterService: ToasterService,
        private formBuilder: FormBuilder,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        this.buildFormGroup();
    }

    ngOnInit() {
    }

    getErrorMessage(control: AbstractControl): string {
        return Helper.getErrorMessage(control, this.translate);
    }

    onFileChange(event) {
        if (event.target.files.length > 0) {
            this.importFile = event.target.files[0];
        }
    }

    getFormErrorMessage(): string {
        return ''; //TODO: add handler
    }

    submitForm(): void {
        this.error = '';
        this.processLog = '';

        this.userService.import(this.importFile).subscribe((data) => {
            this.processLog = data;
            this.changeDetectorRef.detectChanges();
        }, (result: any) => {
            if (null === result) {
                console.warn('Error. Can\'t process file');
                this.onFail();
            } else {
                this.applyErrorsFromRequest(this.changeDetectorRef, this.formGroup, result.error);
                this.onFail();
            }
        });
    }

    onFail() {
        this.toasterService.pop('error', this.translate.instant('pop.Failed'), this.translate.instant('user.import.form.File processing failed'));
    }

    private buildFormGroup() {
        this.formGroup = this.formBuilder.group({
            file: [null, Validators.required]
        });
    }

    protected applyErrorsFromRequest(changeDetectorRef: ChangeDetectorRef, formGroup: FormGroup, requestError: RequestErrorInterface) {
        const fields: FormFieldErrorListInterface = !!requestError.fields ? requestError.fields : {};
        Object.keys(fields).forEach((field: string) => {
            let control: AbstractControl = null;

            if (this.formGroup.contains(field)) {
                control = formGroup.controls[field];
            }

            if (null !== control) {
                let errors = control.errors;
                errors = null === errors ? {} : errors;
                errors['api_invalid'] = this.translate.instant('user.errors.' + fields[field].message);
                control.setErrors(errors);
                changeDetectorRef.detectChanges();
            }
        });
    }
}
