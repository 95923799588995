import {
    DATE_TIME_LONG_FORMAT
} from '../constants';
import {getCurrentTimeZone} from '../functions';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {DeleteDialog} from '@components/dialogs/delete/delete.component';
import {IItemService} from '@interfaces/item-service.interface';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort, MatSortHeader, Sort} from '@angular/material/sort';
import {MatTable} from '@angular/material/table';
import {EMPTY_PAGE_INPUT, IPageInput} from '@interfaces/page-input.interface';
import {merge, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {IItemList} from '@interfaces/item-list.interface';
import {Helper} from './helper';
import {EventEmitter} from '@angular/core';
import {IFilterValue} from '@interfaces/filter.interface';
import {UrlService} from '@services/url.service';
import _ from 'lodash';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {StoreService} from '@services/store.service';
import {IColumn} from '@interfaces/column-filter.interface';

/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

export class BaseIndexComponent<TItem, TEditInput> {
    filterChanged: EventEmitter<IFilterValue> = new EventEmitter();

    paginator: MatPaginator = null;
    sort: MatSort = null;
    table: MatTable<any> = null;

    itemList: Array<TItem> = [];
    selectedItem: TItem = null;
    editorItem: TItem = null;
    resultsLength: number = 0;

    dateTimeLongFormat = DATE_TIME_LONG_FORMAT;
    timeZone = getCurrentTimeZone();

    baseRoute = '/';
    itemHistoryKey = 'item';

    input: IPageInput = {...EMPTY_PAGE_INPUT};

    tableName = 'baseTable';
    tableColumns: Array<IColumn> = [];
    activeColumns: Array<IColumn> = [];
    availableColumns: Array<IColumn> = [];
    displayedColumns: Array<string> = [];

    displayFilter = false;
    displayColumnFilter = false;

    constructor(
        protected itemService: IItemService<TItem, TEditInput>,
        protected deleteDialog: MatDialog,
        protected router: Router,
        protected urlService: UrlService,
        protected storeServ: StoreService
    ) {}

    viewSelected(item: TItem): void {
        console.log('index item', item);
        this.router.navigateByUrl(
            `${this.baseRoute}/${item['id']}`,
            this.getHistoryData(item)
        );
    }

    updateSelected(item: TItem): void {
        this.router.navigateByUrl(
            `${this.baseRoute}/update/${item['id']}`,
            this.getHistoryData(item)
        );
    }

    deleteSelected(item: TItem): void {
        const dialogRef = this.deleteDialog.open(DeleteDialog);

        dialogRef.afterClosed().subscribe(result => {
            if (true === result) {
                this.itemService.deleteItem(item).subscribe(() => {
                    this.paginator.pageIndex = 0;
                    this.updateList();
                });
            }
        });
    }

    getStatusClass(statusId: number): string {
        return Helper.getItemStatusClass(statusId);
    }

    getStatusTranslationKey(statusId: number): string {
        return Helper.getItemStatusName(statusId);
    }

    toggleFilter(): void {
        this.displayFilter = !this.displayFilter;
    }

    toggleColumnFilter(): void {
        this.displayColumnFilter = !this.displayColumnFilter;
    }

    onFilterChanged(filter: IFilterValue): void {
        this.input.filter = filter;

        this.filterChanged.emit(filter);
    }

    onColumnFilterChanged(columns: Array<IColumn>): void {
        this.storeServ.storeValue(this.tableName, JSON.stringify(this.activeColumns.map(item => item.name)));
        this.displayedColumns = this.activeColumns.map(col => col.name);
    }

    protected getHistoryData(item: TItem): any {
        const data = {
            state: {}
        };

        data.state[this.itemHistoryKey] = item;

        return data;
    }

    protected subscribeEvents(): void {
        this.sort.sortChange.subscribe((event: Sort) => {
            this.paginator.pageIndex = 0;
            this.updateSort(event);
        });

        this.paginator.page.subscribe((event: PageEvent) => {
            this.selectedItem = null;
            this.editorItem = null;
            this.updatePage(event);
        });

        this.filterChanged.subscribe((filter: IFilterValue) => {
            this.updateFilter(filter);
        });
    }

    protected updatePage(page: PageEvent) {
        this.urlService.updateQueryPage(page.pageIndex).then(() => {
            // TODO: Add handler
        });
    }

    protected updateSort(event: Sort) {
        this.urlService.updateQuerySort(event.active, event.direction).then(() => this.paginator.firstPage());
    }

    protected updateFilter(filter: IFilterValue): void {
        this.urlService.updateQueryFilter(filter).then(() => {
            this.input.filter = filter;
            this.paginator.firstPage();
        });
    }

    protected async handleUrlQuery(): Promise<void> {
        const tableQuery = await this.urlService.handleUrlQuery();

        this.handleUrlQuerySort(tableQuery);
        this.handleUrlQueryFilter(tableQuery);

        this.paginator.pageIndex = tableQuery.page;

        this.subscribeEvents();
        this.updateList();
    }

    protected getSortableField(sortField: string): MatSortHeader | undefined {
        return this.sort.sortables.get(sortField) as MatSortHeader | undefined;
    }

    protected handleUrlQuerySort(tableQuery: any): void {
        const sortableField = this.getSortableField(tableQuery.sort);

        if (!_.isUndefined(sortableField) && !_.isUndefined(this.sort)) {
            this.sort.sort({
                id: tableQuery.sort,
                start: tableQuery.order === 'asc' ? 'asc' : 'desc',
                disableClear: true
            });
            sortableField._setAnimationTransitionState({ toState: 'active' });
        }
    }

    protected handleUrlQueryFilter(tableQuery: any): void {
        if (typeof tableQuery.filter !== 'string') {
            this.input.filter = {...this.input.filter, ...tableQuery.filter};
        }
    }

    protected updateList(): void {
        merge(this.sort.sortChange, this.paginator.page, this.filterChanged)
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.input.sort = this.sort.active;
                    this.input.order = this.sort.direction;
                    this.input.page = this.paginator.pageIndex;

                    return this.itemService.getList(this.input);
                }),
                map((data: IItemList<TItem>) => {
                    this.resultsLength = data.count;

                    return data.rows;
                }),
                catchError(err => {
                    console.error(err);
                    return observableOf([]);
                })
            ).subscribe(
            (data: Array<TItem>) => {
                return this.itemList = data;
            });
    }

    protected handleTableColumns(): void {
        const val = this.storeServ.value(this.tableName);
        let storedList = JSON.parse(val);
        storedList = _.isArray(storedList) ? storedList : [];
        storedList = storedList.filter(item => -1 < this.tableColumns.map(col => col.name).indexOf(item));

        if (!storedList.length) {
            this.activeColumns = this.tableColumns;
        } else {
            for (const name of storedList) {
                const col = this.tableColumns.find(item => item.name === name);
                if (col) {
                    this.activeColumns.push(col);
                }
            }
        }

        this.availableColumns = _.difference(this.tableColumns, this.activeColumns);
        this.displayedColumns = this.activeColumns.map(col => col.name);
    }
}
