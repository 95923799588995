<div
    *ngIf="displayLayout"
    class="wrap main-layout"
>
    <mat-toolbar class="main-toolbar-container">
        <app-navbar class="main-navbar-container"></app-navbar>
    </mat-toolbar>

    <mat-sidenav-container class="main-sidenav-container">
        <mat-sidenav mode="side" opened>
            <app-sidebar></app-sidebar>
        </mat-sidenav>

        <mat-sidenav-content>
            <router-outlet *ngIf="displayLayout"></router-outlet>

            <div *ngIf="showMainOverlay" class="overlay"></div>

            <toaster-container [toasterconfig]="toasterConfig"></toaster-container>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>

<div *ngIf="!displayLayout"
    class="wrap no-layout">
    <toaster-container [toasterconfig]="toasterConfig"></toaster-container>
    <router-outlet></router-outlet>
</div>
