<form
    [formGroup]="formGroup"
    novalidate autocomplete="off"
    class="complaint form-container"
    (ngSubmit)="submitForm(formGroup.value)">

    <mat-error *ngIf="formGroup.invalid">{{getFormErrorMessage()|translate}}</mat-error>
    <mat-error *ngIf="error.length">{{error|translate}}</mat-error>

    <div *ngIf="scenario === scenarioEdit" class="row mb-4">
        <div class="col-12">
            <div class="item-id">{{'item.fields.id'|translate}}: {{item.id}}</div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-md-6">
            <mat-form-field>
                <mat-label>{{'complaint.fields.type_id'|translate}}</mat-label>
                <mat-select
                    formControlName="type_id"
                    required>
                    <mat-option *ngFor="let item of typeList" [value]="item.id">{{('complaint_type.' + item.name)|translate}}</mat-option>
                </mat-select>
                <mat-error *ngIf="formGroup.controls['type_id'].invalid">
                    {{getErrorMessage(formGroup.controls['type_id'])}}
                </mat-error>
            </mat-form-field>
        </div>

        <div class="col-12 col-md-6">
            <mat-form-field>
                <mat-label>{{'complaint.fields.mail'|translate}}</mat-label>
                <input
                    matInput
                    [placeholder]="'complaint.fields.mail'|translate"
                    formControlName="mail"
                    required>
                <mat-error *ngIf="formGroup.controls['mail'].invalid">
                    {{getErrorMessage(formGroup.controls['mail'])}}
                </mat-error>
            </mat-form-field>
        </div>

        <div class="col-12 col-md-6">
            <mat-form-field>
                <mat-label>{{'complaint.fields.name'|translate}}</mat-label>
                <input
                    matInput
                    [placeholder]="'complaint.fields.name'|translate"
                    formControlName="name"
                    required>
                <mat-error *ngIf="formGroup.controls['name'].invalid">
                    {{getErrorMessage(formGroup.controls['name'])}}
                </mat-error>
            </mat-form-field>
        </div>

        <div class="col-12 col-md-6">
            <mat-form-field>
                <mat-label>{{'complaint.fields.zip_code'|translate}}</mat-label>
                <input
                    matInput
                    [placeholder]="'complaint.fields.zip_code'|translate"
                    formControlName="zip_code"
                    required>
                <mat-error *ngIf="formGroup.controls['zip_code'].invalid">
                    {{getErrorMessage(formGroup.controls['zip_code'])}}
                </mat-error>
            </mat-form-field>
        </div>

        <div class="col-12 col-md-6">
            <mat-form-field>
                <mat-label>{{'complaint.fields.building_number'|translate}}</mat-label>
                <input
                    matInput
                    [placeholder]="'complaint.fields.building_number'|translate"
                    formControlName="building_number"
                    required>
                <mat-error *ngIf="formGroup.controls['building_number'].invalid">
                    {{getErrorMessage(formGroup.controls['building_number'])}}
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <mat-form-field>
                <mat-label>{{'complaint.fields.text'|translate}}</mat-label>
                <textarea
                    matInput
                    [placeholder]="'complaint.fields.text'|translate"
                    formControlName="text"
                    rows="5"
                    required></textarea>
                <mat-error *ngIf="formGroup.controls['text'].invalid">
                    {{getErrorMessage(formGroup.controls['text'])}}
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="button-row d-flex justify-content-end">
        <button
            mat-raised-button
            color="primary"
            type="submit"
            [disabled]="!formGroup.valid">{{'form.Save'|translate}}</button>

        <button
            mat-raised-button
            (click)="goBack()">{{'form.Cancel'|translate}}</button>
    </div>
</form>
