import {
    DATE_TIME_LONG_FORMAT
} from '../constants';
import {getCurrentTimeZone} from '../functions';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {DeleteDialog} from '@components/dialogs/delete/delete.component';
import {IItemService} from '@interfaces/item-service.interface';
import {Helper} from './helper';

/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

export class BaseViewComponent<TItem, TEditInput> {
    item: TItem = null;
    dateTimeLongFormat = DATE_TIME_LONG_FORMAT;
    timeZone = getCurrentTimeZone();
    baseRoute = '/';
    itemHistoryKey = 'item';

    constructor(
        protected itemService: IItemService<TItem, TEditInput>,
        protected translate: TranslateService,
        protected deleteDialog: MatDialog,
        protected router: Router,
        protected route: ActivatedRoute
    ) {}

    handleItemFromRequestOrHistory() {
        this.route.paramMap.subscribe(params => {
            const id = Number(params.get('id'));
            if (!id) {
                this.router.navigate(['/404']);

                return;
            }

            // Get company from route data history
            if (undefined !== history.state[this.itemHistoryKey] && id === history.state[this.itemHistoryKey].id) {
                this.item = history.state[this.itemHistoryKey];

                return;
            }

            // Get company data from API
            this.itemService.getItem(id).subscribe((item) => {
                this.item = item;
            });
        }, err => {
            console.error(err);
        });
    }

    updateSelected(item: TItem): void {
        const data = {
            state: {
                backLink: `${this.baseRoute}/${item['id']}`
            }
        };

        data.state[this.itemHistoryKey] = item;

        this.router.navigateByUrl(
            `${this.baseRoute}/update/${item['id']}`,
            data
        );
    }

    deleteSelected(item: TItem): void {
        const dialogRef = this.deleteDialog.open(DeleteDialog);

        dialogRef.afterClosed().subscribe(result => {
            if (true === result) {
                this.itemService.deleteItem(item).subscribe(() => {
                    this.backToIndex();
                });
            }
        });
    }

    backToIndex(): void {
        this.router.navigate([`${this.baseRoute}/index`]);
    }

    getItemStatusClass(status_id: number): string {
        return Helper.getItemStatusClass(status_id);
    }

    getItemStatusName(status_id: number): string {
        return Helper.getItemStatusName(status_id);
    }
}
