/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {
    MASK_ZIP_AREA,
    USER_POLICY_OBJ_LIST
} from '../../../constants';
import {Router} from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTable} from '@angular/material/table';
import {LoginService} from '../../../services/login.service';
import {BaseIndexComponent} from '../../../classes/base-index';
import {IPageInput} from '../../../interfaces/page-input.interface';
import {ValueDialog} from '../../dialogs/value/value.component';
import _ from 'lodash';
import {IExtraItem} from '../../../interfaces/extra-item.interface';
import {ExtraItemZipAreaService} from '../../../services/extra-item-zip-area.service';
import {IExtraItemZipArea, IExtraItemZipAreaEditInput} from '../../../interfaces/extra-item-zip-area.interface';
import {UrlService} from '@services/url.service';
import * as url from 'url';
import {StoreService} from '@services/store.service';

@Component({
    selector: 'app-extra-item-zip-area-index',
    templateUrl: 'index.component.html'
})
export class ExtraItemZipAreaIndexComponent extends BaseIndexComponent<IExtraItemZipArea, IExtraItemZipAreaEditInput> implements OnInit, AfterViewInit, OnChanges {
    @Input('item') item: IExtraItem;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatTable, {static: true}) table: MatTable<any>;

    displayedColumns: Array<string> = ['id', /*'item_id', */'zip_area', 'action'];

    input: IPageInput = {
        sort: '',
        order: '',
        page: 0,
        filter: {}
    };

    constructor(
        protected itemService: ExtraItemZipAreaService,
        protected deleteDialog: MatDialog,
        protected valueDialog: MatDialog,
        protected router: Router,
        protected urlService: UrlService,
        private loginService: LoginService,
        protected storeServ: StoreService
    ) {
        super(itemService, deleteDialog, router, urlService, storeServ);
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.handleUrlQuery();
    }

    ngOnChanges(changes: SimpleChanges) {
        const item = changes.item.currentValue;
        if (item.id) {
            this.input.filter.item_id = this.item.id.toString();
            this.updateList();
        }
    }

    canEdit(): boolean {
        return this.loginService.isGranted(USER_POLICY_OBJ_LIST.POLICY_ADMIN_EXTRA_ITEM_ZIP_AREA_EDIT);
    }

    create(): void {
        this.editItemValue(0, {
            data: {
                title: 'extra-item-zip-area.create.Title',
                mask: MASK_ZIP_AREA
            }
        });
    }

    updateSelected(item: IExtraItemZipArea): void {
        this.editItemValue(item.id, {
            data: {
                title: 'extra-item-zip-area.update.Title',
                mask: MASK_ZIP_AREA,
                value: item.zip_area
            }
        });
    }

    private editItemValue(itemId: number, dialogData: any): void {
        const dialogRef = this.valueDialog.open(ValueDialog, dialogData);

        dialogRef.afterClosed().subscribe(area => {
            if (!_.isNil(area)) {
                this.itemService.storeItem({
                    id: itemId,
                    item_id: this.item.id,
                    zip_area: area.toLowerCase()
                }).subscribe(() => {
                    this.paginator.pageIndex = 0;
                    this.updateList();
                });
            }
        });
    }
}
