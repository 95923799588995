/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ToasterService} from 'angular2-toaster';
import {
    FLYER_LOGO_MAX_FILE_SIZE,
    FORM_SCENARIO_CREATE,
    FORM_SCENARIO_EDIT, SERVER_TIME_ZONE, SERVER_TIME_ZONE_DAYLIGHT_SAVING_TIME, TIME_KEEP_BEFORE_CAN_EDIT_SEND
} from '@app/constants';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseForm} from '@classes/base-form';
import {environment} from '../../../../environments/environment';
import {IImageFileRestriction} from '@interfaces/image-file-restriction';
import {MessageService} from '@services/message.service';
import {IMessage, IMessageEditInput, MESSAGE_WITH_EMPTY_DATA} from '@interfaces/message.interface';
import {FlyerService} from '@services/flyer.service';
import {IFlyer} from '@interfaces/flyer.interface';
import moment, {now} from 'moment';
import {isDaylightSavingTime} from '@app/functions';


@Component({
    selector: 'app-message-form',
    templateUrl: 'form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageFormComponent extends BaseForm<IMessage, IMessageEditInput> implements OnInit {
    item: IMessage = {...MESSAGE_WITH_EMPTY_DATA};
    backLink = '/message/index';
    error = '';

    scenarioCreate = FORM_SCENARIO_CREATE; // Need for component HTML usage
    scenarioEdit = FORM_SCENARIO_EDIT; // Need for component HTML usage

    formGroup: FormGroup = null;

    imageRestriction: IImageFileRestriction = {
        acceptedFiles: ['image/png', 'image/jpeg'],
        maxFileSize: FLYER_LOGO_MAX_FILE_SIZE
    };

    flyerList: Array<IFlyer> = [];

    minDate = new Date(Date.now() + TIME_KEEP_BEFORE_CAN_EDIT_SEND * 1000 - 3600000); // Remove one hour after testing

    private imageFile: File = null;
    private imageFileChanged = false;

    private userFile: File = null;

    private timeZone = isDaylightSavingTime() ? SERVER_TIME_ZONE_DAYLIGHT_SAVING_TIME : SERVER_TIME_ZONE;

    constructor(
        protected translate: TranslateService,
        protected itemService: MessageService,
        protected toasterService: ToasterService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected changeDetectorRef: ChangeDetectorRef,
        private formBuilder: FormBuilder,
        private flyerService: FlyerService
    ) {
        super(translate, toasterService, route, router, changeDetectorRef, itemService);

        this.buildFormGroup();
    }

    ngOnInit() {
        this.updateFlyerList();
        this.handleItemFromRequestOrHistory('interest');
    }

    fillFormData() {
        const sendDate = this.messageIsNew()
            ? moment().add(TIME_KEEP_BEFORE_CAN_EDIT_SEND * 1000)
            : moment(this.item.send_date);

        const formData = {
            title: this.item.title,
            description: this.item.description,
            send_date: sendDate.utcOffset(this.timeZone * 60).format('YYYY-MM-DD HH:mm'),
            link: this.item.link,
            link_url: this.item.link_url,
            image: this.item.image,
            flyer_id: this.item.flyer_id
        };

        this.formGroup.reset(formData);

        const controller = this.formGroup.get('userFile');
        if (this.messageIsNew()) {
            controller.setValidators([Validators.required]);
        } else {
            controller.clearValidators();
        }
        controller.updateValueAndValidity();
    }

    getFormErrorMessage(): string {
        return ''; //TODO: add handler
    }

    submitForm(data: any): void {
        this.error = '';

        data.send_date = moment(data.send_date).format('YYYY-MM-DD HH:mm:ss') + ` GMT+0${this.timeZone}00`;

        const input = this.getInputFromFormData(data);

        this.itemService.storeItem(input).subscribe((item) => {
            this.item = item;
            this.onSave();
        }, result => this.handleRequestError(result, 'Interest'), () => {
            this.goBack();
        });
    }

    getInputFromFormData(data: any): IMessageEditInput {
        return {
            data: {
                id: this.item.id,
                link: data.link,
                link_url: data.link_url,
                description: data.description,
                flyer_id: data.flyer_id,
                title: data.title,
                send_date: data.send_date
            },
            image: this.imageFileChanged ? this.imageFile : null,
            users: this.userFile ? this.userFile : null,
        };
    }

    onUploadError(event: any): void {
        this.imageFile = null;

        if (environment.production) {
            console.warn('Image upload failed!');
        } else {
            console.warn('Image upload failed!', event);
        }
    }

    onUploadSuccess(file: File): void {
        this.imageFile = file;
        this.imageFileChanged = true;
        this.formGroup.patchValue({image: file.name});
    }

    onUploadCancel(): void {
        this.imageFile = null;
        this.formGroup.patchValue({image: null});
    }

    getImageLink(): string {
        return !!this.item.image && this.item.image.length ? this.item.image : '';
    }

    onUserFileChange(event) {
        if (event.target.files.length > 0) {
            this.userFile = event.target.files[0];
        }
    }

    canChangeSendDate(): boolean {
        return this.messageIsNew()
            || (new Date(this.item.send_date).getTime()) > (now() + TIME_KEEP_BEFORE_CAN_EDIT_SEND);
    }

    messageIsNew(): boolean {
        return !this.item.created.length;
    }

    private buildFormGroup() {
        this.formGroup = this.formBuilder.group({
            flyer_id: ['', Validators.compose([
                Validators.minLength(1),
                Validators.maxLength(255)
            ])],
            title: ['', Validators.compose([
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(255)
            ])],
            description: ['', Validators.compose([
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(255)
            ])],
            link: ['', Validators.compose([
                Validators.minLength(1),
                Validators.maxLength(255)
            ])],
            link_url: ['', Validators.compose([
                Validators.minLength(1),
                Validators.maxLength(255)
            ])],
            image: [''],
            send_date: ['', Validators.required],
            userFile: [null, Validators.required]
        });
    }

    private updateFlyerList(): void {
        this.flyerService.getList({
            sort: 'name',
            order: 'asc',
            page: 0,
            filter: {}
        }).subscribe((list) => {
            this.flyerList = list.rows;

            this.changeDetectorRef.detectChanges();
        });
    }
}
