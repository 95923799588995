<form class="forgot-password-form">
    <mat-error *ngIf="error.length">{{error|translate}}</mat-error>

    <mat-form-field>
        <mat-label>{{'forgot-password.form.email'|translate}}</mat-label>
        <input matInput [placeholder]="'forgot-password.form.email'|translate" [formControl]="email" required>
        <mat-error *ngIf="email.invalid">{{getErrorMessage(email)}}</mat-error>
    </mat-form-field>

    <div class="form-row button-row">
        <button
            mat-raised-button
            color="primary"
            (click)="recover()"
            [disabled]="email.invalid">{{'forgot-password.form.Send'|translate}}</button>
    </div>
</form>
