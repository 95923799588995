<div class="extra-item update-container">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{'extra-item.update.Title'|translate}}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <app-extra-item-form [scenario]="formScenario"></app-extra-item-form>
        </mat-card-content>
    </mat-card>
</div>
