<div class="survey-question view-container">
    <mat-card class="mb-4">
        <mat-card-header>
            <mat-card-title>{{'survey-question.view.Title'|translate}}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <div class="row">
                <div class="col-4">
                    <h3>{{'survey-question.fields.type_id'|translate}}</h3>
                    <div>{{('survey_question_type.' + getTypeTranslationKey(item.type_id))|translate}}</div>
                </div>

                <div class="col-4">
                    <h3>{{'survey-question.fields.survey_id'|translate}}</h3>
                    <div>{{item.Survey ? item.Survey.description : ''}}</div>
                </div>

                <div class="col-4">
                    <h3>{{'item.fields.id'|translate}}</h3>
                    <div>{{item.id}}</div>
                </div>
            </div>
            <hr>

            <div class="row">
                <div class="col-4">
                    <h3>{{'survey-question.fields.question'|translate}}</h3>
                    <div>{{item.question}}</div>
                </div>

                <div class="col-4">
                    <h3>{{'survey-question.fields.answer'|translate}}</h3>
                    <div>{{item.answer}}</div>
                </div>
            </div>
            <hr>

            <div class="button-row d-flex justify-content-end mt-3">
                <button
                    mat-raised-button
                    color="primary"
                    (click)="updateSelected(item)">{{'actions.update'|translate}}</button>

                <button *ngIf="canDelete()"
                    mat-raised-button
                    color="warn"
                    (click)="deleteSelected(item)">{{'actions.delete'|translate}}</button>

                <button *ngIf="canIndex()"
                    mat-raised-button
                    (click)="backToIndex()">{{'actions.Back to list'|translate}}</button>
            </div>
        </mat-card-content>
    </mat-card>
</div>



