<form
    [formGroup]="formGroup"
    novalidate autocomplete="off"
    class="advertiser form-container"
    (ngSubmit)="submitForm(formGroup.value)">

    <mat-error *ngIf="formGroup.invalid">{{getFormErrorMessage()|translate}}</mat-error>
    <mat-error *ngIf="error.length">{{error|translate}}</mat-error>

    <div *ngIf="scenario === scenarioEdit" class="row mb-4">
        <div class="col-12">
            <div class="item-id">{{'item.fields.id'|translate}}: {{item.id}}</div>
        </div>
    </div>

    <div class="row">
        <div class="col-6">
            <div class="row">
                <div class="col-12">
                    <mat-form-field>
                        <mat-label>{{'advertiser.fields.status_id'|translate}}</mat-label>
                        <mat-select
                            formControlName="status_id"
                            required>
                            <mat-option *ngFor="let item of statusList" [value]="item.id">{{item.name}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="formGroup.controls['status_id'].invalid">
                            {{getErrorMessage(formGroup.controls['status_id'])}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <mat-form-field>
                        <mat-label>{{'advertiser.fields.company'|translate}}</mat-label>
                        <input
                            matInput
                            [placeholder]="'advertiser.fields.company'|translate"
                            formControlName="company"
                            required>
                        <mat-error *ngIf="formGroup.controls['company'].invalid">
                            {{getErrorMessage(formGroup.controls['company'])}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <mat-form-field>
                        <mat-label>{{'advertiser.fields.contact_name'|translate}}</mat-label>
                        <input
                            matInput
                            [placeholder]="'advertiser.fields.contact_name'|translate"
                            formControlName="contact_name"
                            required>
                        <mat-error *ngIf="formGroup.controls['contact_name'].invalid">
                            {{getErrorMessage(formGroup.controls['contact_name'])}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <mat-form-field>
                        <mat-label>{{'advertiser.fields.zip_code'|translate}}</mat-label>
                        <input
                            matInput
                            [placeholder]="'advertiser.fields.zip_code'|translate"
                            formControlName="zip_code"
                            required>
                        <mat-error *ngIf="formGroup.controls['zip_code'].invalid">
                            {{getErrorMessage(formGroup.controls['zip_code'])}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <mat-form-field>
                        <mat-label>{{'advertiser.fields.street_address'|translate}}</mat-label>
                        <input
                            matInput
                            [placeholder]="'advertiser.fields.street_address'|translate"
                            formControlName="street_address"
                            required>
                        <mat-error *ngIf="formGroup.controls['street_address'].invalid">
                            {{getErrorMessage(formGroup.controls['street_address'])}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

        </div>

        <div class="col-6">
            <div class="row">
                <div class="col-12">
                    <mat-form-field>
                        <mat-label>{{'advertiser.fields.city'|translate}}</mat-label>
                        <input
                            matInput
                            [placeholder]="'advertiser.fields.city'|translate"
                            formControlName="city"
                            required>
                        <mat-error *ngIf="formGroup.controls['city'].invalid">
                            {{getErrorMessage(formGroup.controls['city'])}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <mat-form-field>
                        <mat-label>{{'advertiser.fields.state'|translate}}</mat-label>
                        <input
                            matInput
                            [placeholder]="'advertiser.fields.state'|translate"
                            formControlName="state"
                            required>
                        <mat-error *ngIf="formGroup.controls['state'].invalid">
                            {{getErrorMessage(formGroup.controls['state'])}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <mat-form-field>
                        <mat-label>{{'advertiser.fields.country'|translate}}</mat-label>
                        <input
                            matInput
                            [placeholder]="'advertiser.fields.country'|translate"
                            formControlName="country"
                            required>
                        <mat-error *ngIf="formGroup.controls['country'].invalid">
                            {{getErrorMessage(formGroup.controls['country'])}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>

    <div class="button-row d-flex justify-content-end">
        <button
            mat-raised-button
            color="primary"
            type="submit"
            [disabled]="!formGroup.valid">{{'form.Save'|translate}}</button>

        <button
            mat-raised-button
            (click)="goBack()">{{'form.Cancel'|translate}}</button>
    </div>
</form>
