/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {PAGINATOR_PAGE_SIZE} from '../constants';
import {IPageInput} from '@interfaces/page-input.interface';
import {IItemList} from '@interfaces/item-list.interface';
import {ISurveyQuestion, ISurveyQuestionEditInput} from '@interfaces/survey.interface';

@Injectable({
    providedIn: 'root',
})

export class SurveyQuestionService {
    constructor(private httpClient: HttpClient) {}

    getItem(id: number): Observable<ISurveyQuestion> {
        return this.httpClient.get<ISurveyQuestion>(
            `/admin/survey-question/${id}`
        );
    }

    getList(input: IPageInput): Observable<IItemList<ISurveyQuestion>> {
        const offset = input.page * PAGINATOR_PAGE_SIZE;

        return this.httpClient.get<IItemList<ISurveyQuestion>>(
            `/admin/survey-question?sort=${input.sort}&order=${input.order}&limit=${PAGINATOR_PAGE_SIZE}&offset=${offset}&filter=${input.filter ? JSON.stringify(input.filter) : ''}`
        );
    }

    storeItem(input: ISurveyQuestionEditInput): Observable<ISurveyQuestion> {
        if (input.id) {
            return this.httpClient.put<ISurveyQuestion>(
                `/admin/survey-question/${input.id}`,
                input,
            );
        }

        return this.httpClient.post<ISurveyQuestion>(
            '/admin/survey-question',
            input,
        );
    }

    deleteItem(item: ISurveyQuestion): Observable<ISurveyQuestion> {
        return this.httpClient.delete<ISurveyQuestion>(
            `/admin/survey-question/${item.id}`
        );
    }

    exportToCsv(input: IPageInput): Observable<Blob> {
        return this.httpClient.get(
            `/admin/export/csv/survey-question?sort=${input.sort}&order=${input.order}&filter=${input.filter ? JSON.stringify(input.filter) : ''}`,
            { responseType: 'blob' }
        );
    }
}
