/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'app-delete-dialog',
    templateUrl: 'delete.component.html'
})

export class DeleteDialog {
    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    }
}
