/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, Injectable, NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HomeComponent} from '@components/home/home.component';
import {PageNotFoundComponent} from '@components/page-not-found/page-not-found.component';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {HttpLoaderFactory} from './functions';
import {StoreService} from '@services/store.service';
import {DATEPICKER_FORMATS, DATETIMEPICKER_FORMATS, DEFAULT_LANGUAGE, LOCALE_NL} from './constants';
import {AccessService} from '@services/access.service';
import {TokenService} from '@services/token.service';
import {LoginService} from '@services/login.service';
import {EventService} from '@services/event.service';
import {SpinnerService} from '@services/spinner.service';
import {ToasterModule, ToasterService} from 'angular2-toaster';
import {HttpConfigInterceptor} from './interceptors/httpconfig.interceptor';
import {AppRoutingModule} from './app-routing.module';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatInputModule} from '@angular/material/input';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {RouterModule} from '@angular/router';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SidebarComponent} from '@components/layout/sidebar/sidebar.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {NavbarComponent} from '@components/layout/navbar/navbar.component';
import {MatMenuModule} from '@angular/material/menu';
import {FlexLayoutModule} from '@angular/flex-layout';
import {LoginComponent} from '@components/login/login.component';
import {LoginFormComponent} from '@components/login/form/form.component';
import {MatCardModule} from '@angular/material/card';
import {DashBoardComponent} from '@components/dashboard/dashboard.component';
import {LogoutComponent} from '@components/logout/logout.component';
import {ForgotPasswordComponent} from '@components/forgot-password/forgot-password.component';
import {ForgotPasswordFormComponent} from '@components/forgot-password/form/form.component';
import {ResetPasswordComponent} from '@components/reset-password/reset-password.component';
import {ResetPasswordFormComponent} from '@components/reset-password/form/form.component';
import {MatDividerModule} from '@angular/material/divider';
import {MatListModule} from '@angular/material/list';
import {MatGridListModule} from '@angular/material/grid-list';
import {UserService} from '@services/user.service';
import {UserIndexComponent} from '@components/user/index/index.component';
import {UserCreateComponent} from '@components/user/create/create.component';
import {UserUpdateComponent} from '@components/user/update/update.component';
import {UserFormComponent} from '@components/user/form/form.component';
import {UserViewComponent} from '@components/user/view/view.component';
import {ImageUploadComponent} from '@components/image-upload/image-upload.component';
import {windowProviders} from '@services/window-providers';
import {DomainService} from '@services/domain.service';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {EmbedFileUploaderComponent} from '@components/embed-file-uploader/embed-file-uploader.component';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import {NgSelectModule} from '@ng-select/ng-select';
import {OnlyNumbersDirective} from '@classes/directives/numbers-only';
import {RoleFormComponent} from '@components/role/form/form.component';
import {RoleCreateComponent} from '@components/role/create/create.component';
import {RoleUpdateComponent} from '@components/role/update/update.component';
import {RoleIndexComponent} from '@components/role/index/index.component';
import {RoleViewComponent} from '@components/role/view/view.component';
import {RoleService} from '@services/role.service';
import {SwaggerComponent} from '@components/swagger/swagger.component';
import {FlyerAcceptLinkFormComponent} from '@components/flyer/accept-link/form.component';
import {FlyerService} from '@services/flyer.service';
import {FlyerIndexComponent} from '@components/flyer/index/index.component';
import {FlyerViewComponent} from '@components/flyer/view/view.component';
import {FlyerCategoryIndexComponent} from '@components/flyer-category/index/index.component';
import {FlyerCategoryViewComponent} from '@components/flyer-category/view/view.component';
import {FlyerCategoryFormComponent} from '@components/flyer-category/form/form.component';
import {FlyerCategoryCreateComponent} from '@components/flyer-category/create/create.component';
import {FlyerCategoryUpdateComponent} from '@components/flyer-category/update/update.component';
import {FlyerFormComponent} from '@components/flyer/form/form.component';
import {FlyerCreateComponent} from '@components/flyer/create/create.component';
import {FlyerUpdateComponent} from '@components/flyer/update/update.component';
import {DeleteDialog} from '@components/dialogs/delete/delete.component';
import {ExtraItemFormComponent} from '@components/extra-item/form/form.component';
import {ExtraItemCreateComponent} from '@components/extra-item/create/create.component';
import {ExtraItemUpdateComponent} from '@components/extra-item/update/update.component';
import {ExtraItemIndexComponent} from '@components/extra-item/index/index.component';
import {ExtraItemViewComponent} from '@components/extra-item/view/view.component';
import {FlyerCategoryService} from '@services/flyer-category.service';
import {ExtraItemService} from '@services/extra-item.service';
import {InterestFormComponent} from '@components/interest/form/form.component';
import {InterestIndexComponent} from '@components/interest/index/index.component';
import {InterestViewComponent} from '@components/interest/view/view.component';
import {InterestCreateComponent} from '@components/interest/create/create.component';
import {InterestUpdateComponent} from '@components/interest/update/update.component';
import {InterestService} from '@services/interest.service';
import {FlyerZipAreaIndexComponent} from '@components/flyer/zip-area-index/index.component';
import {ValueDialog} from '@components/dialogs/value/value.component';
import {TextMaskModule} from 'angular2-text-mask';
import {FlyerZipAreaService} from '@services/flyer-zip-area.service';
import {ExtraItemZipAreaService} from '@services/extra-item-zip-area.service';
import {ExtraItemZipAreaIndexComponent} from '@components/extra-item/zip-area-index/index.component';
import {TableFilterComponent} from '@components/common/table-filter/filter.component';

import { MomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MessageIndexComponent} from '@components/message/index/index.component';
import {MessageCreateComponent} from '@components/message/create/create.component';
import {MessageUpdateComponent} from '@components/message/update/update.component';
import {MessageFormComponent} from '@components/message/form/form.component';
import {MessageViewComponent} from '@components/message/view/view.component';

import {
    NGX_MAT_DATE_FORMATS, NgxMatDateAdapter,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';
import {
    NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS,
    NgxMatMomentAdapter,
    NgxMatMomentModule
} from '@angular-material-components/moment-adapter';
import {EditionFormComponent} from '@components/edition/form/form.component';
import {EditionCreateComponent} from '@components/edition/create/create.component';
import {EditionUpdateComponent} from '@components/edition/update/update.component';
import {EditionIndexComponent} from '@components/edition/index/index.component';
import {EditionViewComponent} from '@components/edition/view/view.component';
import {ComplaintFormComponent} from '@components/complaint/form/form.component';
import {ComplaintCreateComponent} from '@components/complaint/create/create.component';
import {ComplaintUpdateComponent} from '@components/complaint/update/update.component';
import {ComplaintIndexComponent} from '@components/complaint/index/index.component';
import {ComplaintViewComponent} from '@components/complaint/view/view.component';
import {SurveyFormComponent} from '@components/survey/form/form.component';
import {SurveyCreateComponent} from '@components/survey/create/create.component';
import {SurveyUpdateComponent} from '@components/survey/update/update.component';
import {SurveyIndexComponent} from '@components/survey/index/index.component';
import {SurveyViewComponent} from '@components/survey/view/view.component';
import {MessageService} from '@services/message.service';
import {ComplaintService} from '@services/complaint.service';
import {EditionService} from '@services/edition.service';
import {SurveyService} from '@services/survey.service';
import {SurveyQuestionService} from '@services/survey-question.service';
import {SurveyAnswerService} from '@services/survey-answer.service';
import {SurveyQuestionFormComponent} from '@components/survey/question/form/form.component';
import {SurveyQuestionCreateComponent} from '@components/survey/question/create/create.component';
import {SurveyQuestionUpdateComponent} from '@components/survey/question/update/update.component';
import {SurveyQuestionIndexComponent} from '@components/survey/question/index/index.component';
import {SurveyQuestionViewComponent} from '@components/survey/question/view/view.component';
import {SurveyAnswerIndexComponent} from '@components/survey/answer/index/index.component';
import {UserImportFormComponent} from '@components/user/import/form.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {TableColumnFilterComponent} from '@components/common/column-filter/filter.component';
import {AdvertiserFormComponent} from '@components/advertiser/form/form.component';
import {AdvertiserIndexComponent} from '@components/advertiser/index/index.component';
import {AdvertiserCreateComponent} from '@components/advertiser/create/create.component';
import {AdvertiserUpdateComponent} from '@components/advertiser/update/update.component';
import {AdvertiserViewComponent} from '@components/advertiser/view/view.component';
import {AdvertiserService} from '@services/advertiser.service';
import {FlyerEmailOptinFormComponent} from '@components/flyer-email-optin/form/form.component';
import {FlyerEmailOptinIndexComponent} from '@components/flyer-email-optin/index/index.component';
import {FlyerEmailOptinUpdateComponent} from '@components/flyer-email-optin/update/update.component';
import {FlyerEmailOptinService} from '@services/extra-email-optin.service';


@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        PageNotFoundComponent,
        SidebarComponent,
        NavbarComponent,
        DashBoardComponent,
        LoginComponent,
        LoginFormComponent,
        LogoutComponent,
        ForgotPasswordComponent,
        ForgotPasswordFormComponent,
        ResetPasswordComponent,
        ResetPasswordFormComponent,
        DeleteDialog,
        ValueDialog,
        UserIndexComponent,
        UserViewComponent,
        UserCreateComponent,
        UserUpdateComponent,
        UserFormComponent,
        ImageUploadComponent,
        EmbedFileUploaderComponent,
        OnlyNumbersDirective,
        RoleFormComponent,
        RoleCreateComponent,
        RoleUpdateComponent,
        RoleIndexComponent,
        RoleViewComponent,
        SwaggerComponent,
        FlyerAcceptLinkFormComponent,
        FlyerIndexComponent,
        FlyerViewComponent,
        FlyerFormComponent,
        FlyerCreateComponent,
        FlyerUpdateComponent,
        FlyerCategoryIndexComponent,
        FlyerCategoryViewComponent,
        FlyerCategoryFormComponent,
        FlyerCategoryCreateComponent,
        FlyerCategoryUpdateComponent,
        ExtraItemFormComponent,
        ExtraItemCreateComponent,
        ExtraItemUpdateComponent,
        ExtraItemIndexComponent,
        ExtraItemViewComponent,
        InterestFormComponent,
        InterestIndexComponent,
        InterestViewComponent,
        InterestCreateComponent,
        InterestUpdateComponent,
        FlyerZipAreaIndexComponent,
        ExtraItemZipAreaIndexComponent,
        FlyerEmailOptinFormComponent,
        FlyerEmailOptinIndexComponent,
        FlyerEmailOptinUpdateComponent,
        TableFilterComponent,
        MessageCreateComponent,
        MessageIndexComponent,
        MessageUpdateComponent,
        MessageFormComponent,
        MessageViewComponent,
        EditionFormComponent,
        EditionCreateComponent,
        EditionUpdateComponent,
        EditionIndexComponent,
        EditionViewComponent,
        ComplaintFormComponent,
        ComplaintCreateComponent,
        ComplaintUpdateComponent,
        ComplaintIndexComponent,
        ComplaintViewComponent,
        SurveyFormComponent,
        SurveyCreateComponent,
        SurveyUpdateComponent,
        SurveyIndexComponent,
        SurveyViewComponent,
        SurveyQuestionFormComponent,
        SurveyQuestionCreateComponent,
        SurveyQuestionUpdateComponent,
        SurveyQuestionIndexComponent,
        SurveyQuestionViewComponent,
        SurveyAnswerIndexComponent,
        UserImportFormComponent,
        TableColumnFilterComponent,
        AdvertiserFormComponent,
        AdvertiserIndexComponent,
        AdvertiserCreateComponent,
        AdvertiserCreateComponent,
        AdvertiserUpdateComponent,
        AdvertiserViewComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        NgbModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            isolate: false
        }),
        ToasterModule,
        HttpClientModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        MatIconModule,
        MatCheckboxModule,
        MatRadioModule,
        MatDialogModule,
        MatTableModule,
        MatTabsModule,
        MatSortModule,
        MatPaginatorModule,
        MatToolbarModule,
        MatMenuModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatInputModule,
        MatFormFieldModule,
        MatSelectModule,
        MatButtonModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatCardModule,
        MatDividerModule,
        MatListModule,
        MatGridListModule,
        MatProgressBarModule,
        MaterialFileInputModule,
        NgSelectModule,
        RouterModule,
        CdkTableModule,
        CdkTreeModule,
        FlexLayoutModule,
        TextMaskModule,
        MomentDateModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxMatMomentModule,
        DragDropModule
    ],
    providers: [
        SpinnerService,
        AccessService,
        TokenService,
        AdvertiserService,
        FlyerService,
        FlyerCategoryService,
        ExtraItemService,
        InterestService,
        FlyerZipAreaService,
        ExtraItemZipAreaService,
        FlyerEmailOptinService,
        MessageService,
        ComplaintService,
        EditionService,
        SurveyService,
        SurveyQuestionService,
        SurveyAnswerService,
        RoleService,
        UserService,
        LoginService,
        {provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true},
        StoreService,
        EventService,
        ToasterService,
        {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {
            appearance: 'standard',
            // appearance: 'legacy',
            floatLabel: 'always'
        }},
        windowProviders,
        DomainService,
        {provide: MAT_DATE_LOCALE, useValue: LOCALE_NL},
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: DATEPICKER_FORMATS },
        // { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        { provide: NGX_MAT_DATE_FORMATS, useValue: DATETIMEPICKER_FORMATS },
        // { provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
        {
            provide: NgxMatDateAdapter,
            useClass: NgxMatMomentAdapter,
            deps: [MAT_DATE_LOCALE, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        }
    ],
    bootstrap: [AppComponent],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    entryComponents: [
        DeleteDialog,
        ValueDialog
    ]
})
export class AppModule {
    constructor(
        translate: TranslateService,
        storeService: StoreService
    ) {
        let lang = storeService.value('lang');
        if (null === lang || !lang.length) {
            lang = DEFAULT_LANGUAGE;
            storeService.storeValue('lang', lang);
        }

        translate.setDefaultLang(lang);
        translate.use(lang);
    }
}
