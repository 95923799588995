import {ITEM_STATUS_ID_OBJ_LIST} from '@app/constants';

/**
 * 2022 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2022 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

export interface IAdvertiser {
    id: number;
    company: string;
    street_address: string;
    zip_code: string;
    city: string;
    country: string;
    state: string;
    contact_name: string;
    status_id: number;
}

export const ADVERTISER_WITH_EMPTY_DATA: IAdvertiser = {
    id: 0,
    status_id: ITEM_STATUS_ID_OBJ_LIST.ACTIVE,
    company: '',
    street_address: '',
    zip_code: '',
    city: '',
    country: 'Netherlands',
    state: '',
    contact_name: ''
};

export interface IAdvertiserEditInput {
    id: number;
    company: string;
    street_address: string;
    zip_code: string;
    city: string;
    country: string;
    state: string;
    contact_name: string;
    status_id: number;
}
