/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {
    ITEM_STATUS_ID_OBJ_LIST
} from '../constants';

export interface IInterest {
    id: number;
    status_id: number;
    title: string;
    description_short: string;
    description_long: string;
    image1: string;
    image2?: string;
    code: string;
    created: string;
    updated: string;
}

export const INTEREST_WITH_EMPTY_DATA: IInterest = {
    id: 0,
    status_id: ITEM_STATUS_ID_OBJ_LIST.DISABLED,
    title: '',
    description_short: '',
    description_long: '',
    code: '',
    image1: '',
    image2: '',
    created: '',
    updated: ''
};

export interface IInterestEditInput {
    data: {
        id: number;
        status_id: string;
        title: string;
        description_short: string;
        description_long?: string;
        code: string;
    };
    image1: File|null;
    image2: File|null;
}

