/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {LoginService} from '@services/login.service';
import {ToasterService} from 'angular2-toaster';
import {
    FORM_SCENARIO_CREATE,
    FORM_SCENARIO_EDIT,
    USER_RECEIVE_FLYER_ID_OBJ_LIST,
    USER_RECEIVE_FLYER_OBJ_LIST,
    USER_SEX_ID_OBJ_LIST,
    USER_SEX_OBJ_LIST,
    USER_SOURCE_ID_OBJ_LIST,
    USER_SOURCE_OBJ_LIST,
    USER_STATUS_ID_OBJ_LIST,
    USER_STATUS_OBJ_LIST, YES_NO_OPTION_LIST
} from '@app/constants';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseForm} from '@classes/base-form';
import {UserService} from '@services/user.service';
import {USER_WITH_EMPTY_DATA, IUserEditInput, IUser} from '@interfaces/user.interface';
import {IRole} from '@interfaces/role.interface';
import {RoleService} from '@services/role.service';
import {IPageInput} from '@interfaces/page-input.interface';


@Component({
    selector: 'app-user-form',
    templateUrl: 'form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserFormComponent extends BaseForm<IUser, IUserEditInput> implements OnInit {
    item: IUser = {...USER_WITH_EMPTY_DATA};
    backLink = '/user/index';
    error = '';

    scenarioCreate = FORM_SCENARIO_CREATE; // Need for component HTML usage
    scenarioEdit = FORM_SCENARIO_EDIT; // Need for component HTML usage

    formGroup: FormGroup = null;

    statusList = [
        {id: USER_STATUS_ID_OBJ_LIST.DELETED, name: USER_STATUS_OBJ_LIST.DELETED},
        {id: USER_STATUS_ID_OBJ_LIST.ACTIVE, name: USER_STATUS_OBJ_LIST.ACTIVE},
        {id: USER_STATUS_ID_OBJ_LIST.PAUSED, name: USER_STATUS_OBJ_LIST.PAUSED},
        {id: USER_STATUS_ID_OBJ_LIST.DISABLED, name: USER_STATUS_OBJ_LIST.DISABLED},
        {id: USER_STATUS_ID_OBJ_LIST.HIDDEN, name: USER_STATUS_OBJ_LIST.HIDDEN},
        {id: USER_STATUS_ID_OBJ_LIST.MODERATED, name: USER_STATUS_OBJ_LIST.MODERATED},
    ];

    roleList: Array<IRole> = [];

    sexList = [
        {id: USER_SEX_ID_OBJ_LIST.MALE, name: USER_SEX_OBJ_LIST.MALE},
        {id: USER_SEX_ID_OBJ_LIST.FEMALE, name: USER_SEX_OBJ_LIST.FEMALE},
        {id: USER_SEX_ID_OBJ_LIST.FAM, name: USER_SEX_OBJ_LIST.FAM}
    ];

    sendStickerList = YES_NO_OPTION_LIST;

    newsLetterList = YES_NO_OPTION_LIST;

     receiveFlyerList = [
        {id: USER_RECEIVE_FLYER_ID_OBJ_LIST.STICKER, name: USER_RECEIVE_FLYER_OBJ_LIST.STICKER},
        {id: USER_RECEIVE_FLYER_ID_OBJ_LIST.POOR_DELIVERY, name: USER_RECEIVE_FLYER_OBJ_LIST.POOR_DELIVERY},
        {id: USER_RECEIVE_FLYER_ID_OBJ_LIST.TO_MUCH, name: USER_RECEIVE_FLYER_OBJ_LIST.TO_MUCH},
        {id: USER_RECEIVE_FLYER_ID_OBJ_LIST.YES, name: USER_RECEIVE_FLYER_OBJ_LIST.YES}
    ];

    // TODO: Replace to a handler for getting advertiser list from API
    advertiserList = [];

    sourceList = [
        {id: USER_SOURCE_ID_OBJ_LIST.WEBSITE, name: USER_SOURCE_OBJ_LIST.WEBSITE},
        {id: USER_SOURCE_ID_OBJ_LIST.IMPORT, name: USER_SOURCE_OBJ_LIST.IMPORT},
        {id: USER_SOURCE_ID_OBJ_LIST.PHONE, name: USER_SOURCE_OBJ_LIST.PHONE},
        {id: USER_SOURCE_ID_OBJ_LIST.WRITTEN, name: USER_SOURCE_OBJ_LIST.WRITTEN},
        {id: USER_SOURCE_ID_OBJ_LIST.MOBILE_APP, name: USER_SOURCE_OBJ_LIST.MOBILE_APP},
    ];

    constructor(
        protected translate: TranslateService,
        protected itemService: UserService,
        protected toasterService: ToasterService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected changeDetectorRef: ChangeDetectorRef,
        private formBuilder: FormBuilder,
        private loginService: LoginService,
        private roleService: RoleService
    ) {
        super(translate, toasterService, route, router, changeDetectorRef, itemService);

        this.buildFormGroup();
    }

    ngOnInit() {
        this.handleItemFromRequestOrHistory('user');
    }

    fillFormData() {
        this.updateRoleList();

        const formData = {
            status_id: this.item.status_id,
            first_name: this.item.first_name,
            middle_name: this.item.middle_name,
            last_name: this.item.last_name,
            sex: this.item.sex,
            mail: this.item.mail,
            // phone: this.item.phone,
            street_address: this.item.street_address,
            zip_code: this.item.zip_code,
            building_number: this.item.building_number,
            building_number_extra: this.item.building_number_extra,
            receives_flyers: this.item.receives_flyers,
            newsletter: this.item.newsletter,
            // send_sticker: this.item.send_sticker,
            // why: this.item.why,
            advertiser_id: this.item.advertiser_id,
            // delete_reason: this.item.delete_reason,
            source_id: this.item.source_id,
            // birthday: this.item.birthday,
            // comments: this.item.comments,
            admin_note: this.item.admin_note,
            role_id: this.item.role_id
        };

        this.formGroup.reset(formData);
    }

    getFormErrorMessage(): string {
        return ''; //TODO: add handler
    }

    submitForm(data: any): void {
        this.error = '';

        const input = this.getInputFromFormData(data);

        this.itemService.storeItem(input).subscribe((item) => {
            this.item = item;
            this.onSave();
        }, result => this.handleRequestError(result, 'User'), () => {
            this.goBack();
        });
    }

    getInputFromFormData(data: any): IUserEditInput {
        return {
            id: this.item.id,
            status_id: data.status_id,
            first_name: data.first_name,
            middle_name: data.middle_name,
            last_name: data.last_name,
            mail: data.mail,
            sex: data.sex,
            // phone: data.phone,
            street_address: data.street_address,
            zip_code: data.zip_code.replace(' ', '').toUpperCase(),
            building_number: data.building_number,
            building_number_extra: data.building_number_extra && data.building_number_extra.length ? data.building_number_extra: null,
            receives_flyers: data.receives_flyers,
            newsletter: data.newsletter,
            // send_sticker: data.send_sticker,
            // why: data.why,
            advertiser_id: data.advertiser_id,
            // delete_reason: data.delete_reason,
            source_id: data.source_id,
            // birthday: data.birthday,
            // comments: data.comments,
            admin_note: data.admin_note,
            role_id: data.role_id
        };
    }

    private buildFormGroup() {
        this.formGroup = this.formBuilder.group({
            status_id: ['', Validators.compose([
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(255)
            ])],
            first_name: ['', Validators.compose([
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(255)
            ])],
            middle_name: ['', Validators.compose([
                Validators.minLength(1),
                Validators.maxLength(255)
            ])],
            last_name: ['', Validators.compose([
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(255)
            ])],
            mail: ['', Validators.compose([
                Validators.required,
                Validators.minLength(2),
                Validators.maxLength(255)
            ])],
            sex: ['', Validators.compose([
                Validators.minLength(1),
                Validators.maxLength(1)
            ])],
            zip_code: ['', Validators.compose([
                Validators.required,
                Validators.minLength(2),
                Validators.maxLength(255)
            ])],
            street_address: ['', Validators.compose([
                Validators.minLength(2),
                Validators.maxLength(255)
            ])],
            building_number: ['', Validators.compose([
                Validators.required,
                // CustomValidators.integer(),
                Validators.maxLength(255)
            ])],
            building_number_extra: ['', Validators.compose([
                Validators.maxLength(255)
            ])],
            /*phone: ['', Validators.compose([
                Validators.required,
                Validators.minLength(2),
                Validators.maxLength(255)
            ])],*/
            receives_flyers: ['', Validators.compose([
                // CustomValidators.integer(),
                Validators.minLength(1),
                Validators.maxLength(1)
            ])],
            newsletter: ['', Validators.compose([
                // CustomValidators.integer(),
                // Validators.minLength(1),
                Validators.maxLength(1)
            ])],
            /*send_sticker: ['', Validators.compose([
                // CustomValidators.integer(),
                Validators.minLength(1),
                Validators.maxLength(1)
            ])],
            why: ['', Validators.compose([
                Validators.minLength(2),
                Validators.maxLength(255)
            ])],*/
            advertiser_id: ['', Validators.compose([
                // Validators.minLength(1),
                Validators.maxLength(255)
            ])],
            /*delete_reason: ['', Validators.compose([
                Validators.minLength(2),
                Validators.maxLength(255)
            ])],*/
            source_id: ['', Validators.compose([
                Validators.required,
                // CustomValidators.integer(),
                Validators.minLength(1),
                Validators.maxLength(255)
            ])],
            /*birthday: ['', Validators.compose([
                Validators.minLength(2),
                Validators.maxLength(255)
            ])],
            comments: ['', Validators.compose([
                Validators.minLength(2),
                Validators.maxLength(255)
            ])],*/
            admin_note: ['', Validators.compose([
                // Validators.minLength(2),
                Validators.maxLength(255)
            ])],
            role_id: ['', Validators.compose([
                Validators.required,
                // CustomValidators.integer(),
                Validators.minLength(1),
                Validators.maxLength(255)
            ])],

        });
    }

    private updateRoleList(): void {
        const input: IPageInput = {
            sort: 'name',
            order: 'asc',
            page: 0,
            filter: {}
        }
        this.roleService.getList(input).subscribe((list) => {
            this.roleList = list.rows;

            this.changeDetectorRef.detectChanges();
        });
    }
}
