/**
 * 2020 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2020 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {LoginService} from '../../services/login.service';

@Component({
    template: '<div></div>'
})

export class LogoutComponent {

    constructor(loginService: LoginService, route: ActivatedRoute) {
        route.params.subscribe(() => {
            loginService.logout();
        }, err => {
            console.error(err);
        });
    }
}
