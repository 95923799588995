/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {
    ITEM_STATUS_ID_OBJ_LIST, SURVEY_QUESTION_TYPE_ID_OBJ_LIST
} from '../constants';
import {IFlyer} from '@interfaces/flyer.interface';
import {IUser} from '@interfaces/user.interface';

export interface ISurvey {
    id: number;
    status_id: number;
    flyer_id: number;
    description: string;
    start: string;
    end: string;
    created: string;
    updated: string|null;
    Flyer?: IFlyer;
}

export const SURVEY_WITH_EMPTY_DATA: ISurvey = {
    id: 0,
    status_id: ITEM_STATUS_ID_OBJ_LIST.ACTIVE,
    flyer_id: 0,
    description: '',
    start: '',
    end: '',
    created: '',
    updated: null
};

export interface ISurveyEditInput {
    id: number;
    status_id: number;
    flyer_id: number;
    description: string;
    start: string;
    end: string;
}

export interface ISurveyQuestion {
    id: number;
    survey_id: number;
    type_id: number;
    question: string;
    answer: string|null;
    Survey?: ISurvey;
}

export const SURVEY_QUESTION_WITH_EMPTY_DATA: ISurveyQuestion = {
    id: 0,
    type_id: SURVEY_QUESTION_TYPE_ID_OBJ_LIST.OPEN,
    survey_id: 0,
    question: '',
    answer: null
};

export interface ISurveyQuestionEditInput {
    id: number;
    survey_id: number;
    type_id: number;
    question: string;
    answer: string
}

export interface ISurveyAnswer {
    id: number;
    user_id: number;
    question_id: number;
    answer: string;
    created: string;
    User?: IUser;
    SurveyQuestion?: ISurveyQuestion;
}

export const SURVEY_ANSWER_WITH_EMPTY_DATA: ISurveyAnswer = {
    id: 0,
    question_id: 0,
    user_id: 0,
    answer: '',
    created: ''
};

export interface ISurveyAnswerEditInput {
    id: number;
    user_id: number;
    question_id: number;
    answer: string;
}



