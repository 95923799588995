<div class="edition view-container">
    <mat-card class="mb-4">
        <mat-card-header>
            <mat-card-title>{{'edition.view.Title'|translate}}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <div class="row">
                <div class="col-4">
                    <h3>{{'edition.fields.status_id'|translate}}</h3>
                    <div class="status" [ngClass]="getStatusClass(item.status_id)">{{'status.' + getStatusName(item.status_id)|translate}}</div>
                </div>

                <div class="col-4">
                    <h3>{{'edition.fields.flyer_id'|translate}}</h3>
                    <div>{{item.Flyer ? item.Flyer.title : ''}}</div>
                </div>

                <div class="col-4">
                    <h3>{{'item.fields.id'|translate}}</h3>
                    <div>{{item.id}}</div>
                </div>
            </div>
            <hr>

            <div class="row">
                <div class="col-4">
                    <h3>{{'edition.fields.title'|translate}}</h3>
                    <div>{{item.title}}</div>
                </div>

                <div class="col-4">
                    <h3>{{'edition.fields.size'|translate}}</h3>
                    <div>{{item.size}}</div>
                </div>

                <div class="col-4">
                    <h3>{{'edition.fields.pages'|translate}}</h3>
                    <div>{{item.pages}}</div>
                </div>
            </div>
            <hr>

            <div class="row">
                <div class="col-4">
                    <h3>{{'edition.fields.weight'|translate}}</h3>
                    <div>{{item.weight}}</div>
                </div>

                <div class="col-4">
                    <h3>{{'edition.fields.pdf'|translate}}</h3>
                    <div>{{item.pdf}}</div>
                </div>

                <div class="col-4">
                    <h3>{{'edition.fields.pricing'|translate}}</h3>
                    <div>{{item.pricing}}</div>
                </div>
            </div>
            <hr>

            <div class="row">
                <div class="col-6">
                    <h3>{{'edition.fields.date_start'|translate}}</h3>
                    <div>{{item.date_start | date : dateTimeLongFormat : timeZone}}</div>
                </div>

                <div class="col-6">
                    <h3>{{'edition.fields.date_end'|translate}}</h3>
                    <div>{{item.date_end | date : dateTimeLongFormat : timeZone}}</div>
                </div>
            </div>
            <hr>

            <div class="button-row d-flex justify-content-end mt-3">
                <button
                    mat-raised-button
                    color="primary"
                    (click)="updateSelected(item)">{{'actions.update'|translate}}</button>

                <button *ngIf="canDelete()"
                    mat-raised-button
                    color="warn"
                    (click)="deleteSelected(item)">{{'actions.delete'|translate}}</button>

                <button *ngIf="canIndex()"
                    mat-raised-button
                    (click)="backToIndex()">{{'actions.Back to list'|translate}}</button>
            </div>


        </mat-card-content>
    </mat-card>
</div>



