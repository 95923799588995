/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {
    FILTER_BLOCK_TYPE_DATE_TIME_RANGE,
    FILTER_BLOCK_TYPE_INPUT,
    ITEM_STATUS_ID_OBJ_LIST,
    USER_POLICY_OBJ_LIST
} from '@app/constants';
import {Router} from '@angular/router';
import {LoginService} from '@services/login.service';
import {IInterest, IInterestEditInput} from '@interfaces/interest.interface';
import {InterestService} from '@services/interest.service';
import {BaseIndexComponent} from '@classes/base-index';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTable} from '@angular/material/table';
import {IFilterConfig, IFilterValue} from '@interfaces/filter.interface';
import {UrlService} from '@services/url.service';
import {IPageInput} from '@interfaces/page-input.interface';
import {Helper} from '@classes/helper';
import {StoreService} from '@services/store.service';
import {IColumn} from '@interfaces/column-filter.interface';

@Component({
    selector: 'app-interest-index',
    templateUrl: 'index.component.html'
})
export class InterestIndexComponent extends BaseIndexComponent<IInterest, IInterestEditInput> implements OnInit, AfterViewInit {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatTable) table: MatTable<any>;

    tableName = 'interestList';

    tableColumns: Array<IColumn> = [
        {
            name: 'id',
            title: 'item.fields.id'
        },
        {
            name: 'code',
            title: 'interest.fields.code'
        },
        {
            name: 'title',
            title: 'interest.fields.title'
        },
        {
            name: 'description_short',
            title: 'interest.fields.description_short'
        },
        {
            name: 'status_id',
            title: 'interest.fields.status_id'
        },
        {
            name: 'created',
            title: 'interest.fields.created'
        },
        {
            name: 'updated',
            title: 'interest.fields.updated'
        },
        {
            name: 'action',
            title: 'item.fields.action'
        }
    ];

    statusDeleted = ITEM_STATUS_ID_OBJ_LIST.DELETED;

    baseRoute = '/interest';
    itemHistoryKey = 'interest';

    filterConfig: IFilterConfig = {
        title: 'common.Filter',
        blocks: [
            {
                title: 'interest.fields.id',
                type: FILTER_BLOCK_TYPE_INPUT,
                propertyName: 'id',
            },
            {
                title: 'interest.fields.title',
                type: FILTER_BLOCK_TYPE_INPUT,
                propertyName: 'title',
            },
            {
                title: 'interest.fields.description_short',
                type: FILTER_BLOCK_TYPE_INPUT,
                propertyName: 'description_short'
            },
            {
                title: 'interest.fields.code',
                type: FILTER_BLOCK_TYPE_INPUT,
                propertyName: 'code'
            },
            {
                placeholderFrom: 'interest.fields.createdFrom',
                placeholderTo: 'interest.fields.createdTo',
                type: FILTER_BLOCK_TYPE_DATE_TIME_RANGE,
                propertyName: 'created'
            }
        ]
    }

    constructor(
        protected itemService: InterestService,
        protected deleteDialog: MatDialog,
        protected router: Router,
        protected urlService: UrlService,
        private loginService: LoginService,
        protected storeServ: StoreService
    ) {
        super(itemService, deleteDialog, router, urlService, storeServ);
    }

    ngOnInit() {
        this.handleTableColumns();
    }

    ngAfterViewInit() {
        this.handleUrlQuery();
    }

    canDelete(item: IInterest): boolean {
        return this.loginService.isGranted(USER_POLICY_OBJ_LIST.POLICY_ADMIN_EXTRA_ITEM_EDIT) && item.status_id != ITEM_STATUS_ID_OBJ_LIST.DELETED;
    }

    toggleFilter(): void {
        this.displayFilter = !this.displayFilter;
    }

    onFilterChanged(filter: IFilterValue): void {
        this.input.filter = filter;

        this.filterChanged.emit(filter);
    }

    exportToCsv(): void {
        Helper.exportToCsv(this.input, this.itemService, 'interest_export.csv');
    }
}
