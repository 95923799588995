/**
 * 2022 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2022 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Component} from '@angular/core';
import {
    FORM_SCENARIO_EDIT
} from '@app/constants';


@Component({
    selector: 'app-advertiser-update',
    templateUrl: 'update.component.html'
})
export class AdvertiserUpdateComponent {
    formScenario = FORM_SCENARIO_EDIT;
}
