/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @author    Alex Kolesnichenko <kolexk@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {PAGINATOR_PAGE_SIZE} from '../constants';
import {IUserEditInput, IUser} from '../interfaces/user.interface';
import {IPageInput} from '../interfaces/page-input.interface';
import {IItemList} from '../interfaces/item-list.interface';

@Injectable({
    providedIn: 'root',
})

export class UserService {
    constructor(private httpClient: HttpClient) {}

    getItem(id: number): Observable<IUser> {
        return this.httpClient.get<IUser>(
            '/user/' + id
        );
    }

    getList(input: IPageInput): Observable<IItemList<IUser>> {
        const offset = input.page * PAGINATOR_PAGE_SIZE;

        return this.httpClient.get<IItemList<IUser>>(
            `/user/list?sort=${input.sort}&order=${input.order}&limit=${PAGINATOR_PAGE_SIZE}&offset=${offset}&filter=${input.filter ? JSON.stringify(input.filter) : ''}`
        );
    }

    storeItem(input: IUserEditInput): Observable<IUser> {
        if (input.id) {
            return this.httpClient.patch<IUser>(
                `/user/${input.id}`,
                input,
            );
        }

        return this.httpClient.post<IUser>(
            '/user',
            input,
        );
    }

    deleteItem(user: IUser): Observable<IUser> {
        return this.httpClient.delete<IUser>(
            `/user/${user.id}`
        );
    }

    import(file: File): Observable<any> {
        const formData = new FormData();
        formData.append('file', file, file.name);

        return this.httpClient.post('/admin/import-user', formData, {responseType: 'text'});
    }
}
