<div class="complaint view-container">
    <mat-card class="mb-4">
        <mat-card-header>
            <mat-card-title>{{'complaint.view.Title'|translate}}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <div class="row">
                <div class="col-4">
                    <h3>{{'complaint.fields.type_id'|translate}}</h3>
                    <div>{{('complaint_type.' + getTypeTranslationKey(item.type_id))|translate}}</div>
                </div>

                <div class="col-4">
                    <h3>{{'complaint.fields.mail'|translate}}</h3>
                    <div>{{item.mail}}</div>
                </div>

                <div class="col-4">
                    <h3>{{'item.fields.id'|translate}}</h3>
                    <div>{{item.id}}</div>
                </div>
            </div>
            <hr>

            <div class="row">
                <div class="col-4">
                    <h3>{{'complaint.fields.name'|translate}}</h3>
                    <div>{{item.name}}</div>
                </div>

                <div class="col-4">
                    <h3>{{'complaint.fields.zip_code'|translate}}</h3>
                    <div>{{item.zip_code}}</div>
                </div>

                <div class="col-4">
                    <h3>{{'complaint.fields.building_number'|translate}}</h3>
                    <div>{{item.building_number}}</div>
                </div>
            </div>
            <hr>

            <div class="row">
                <div class="col-12">
                    <h3>{{'complaint.fields.text'|translate}}</h3>
                    <div>{{item.text}}</div>
                </div>
            </div>
            <hr>

            <div class="row">
                <div class="col-6">
                    <h3>{{'complaint.fields.created'|translate}}</h3>
                    <div>{{item.created | date : dateTimeLongFormat : timeZone}}</div>
                </div>
            </div>
            <hr>

            <div class="button-row d-flex justify-content-end mt-3">
                <button
                    mat-raised-button
                    color="primary"
                    (click)="updateSelected(item)">{{'actions.update'|translate}}</button>

                <button *ngIf="canDelete()"
                    mat-raised-button
                    color="warn"
                    (click)="deleteSelected(item)">{{'actions.delete'|translate}}</button>

                <button *ngIf="canIndex()"
                    mat-raised-button
                    (click)="backToIndex()">{{'actions.Back to list'|translate}}</button>
            </div>


        </mat-card-content>
    </mat-card>
</div>



