/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Component, OnInit, Input} from '@angular/core';
import {StoreService} from '../../../services/store.service';
import {TranslateService} from '@ngx-translate/core';
import {LoginService} from '../../../services/login.service';
import {AccountInterface} from '../../../interfaces/account.interface';
import {FormControl, Validators} from '@angular/forms';


@Component({
    selector: 'app-login-form',
    templateUrl: 'form.component.html'
})

export class LoginFormComponent implements OnInit {
    @Input() logoUrl: string;

    error = '';
    message = '';
    user: { name: string, password: string } = {
        name: '',
        password: ''
    };

    email = new FormControl('', [Validators.required, Validators.email]);
    password = new FormControl('', [Validators.required, Validators.minLength(5)]);

    constructor(
        public loginService: LoginService,
        public storeServ: StoreService,
        public translate: TranslateService,
    ) {}

    ngOnInit(): void {
        if (this.storeServ.value('logout') === '1') {
            this.storeServ.remove('logout');

            this.message = this.translate.instant('login-form.Logged out');
        }
    }

    getEmailErrorMessage() {
        if (this.email.hasError('required')) {
            return 'validators.required';
        }

        return this.email.hasError('email') ? 'validators.email' : '';
    }

    getPasswordErrorMessage() {
        if (this.password.hasError('required')) {
            return 'validators.required';
        }

        return this.password.hasError('minLength') ? 'validators.password min' : '';
    }

    login(): void {
        this.error = '';
        this.message = '';

        const mail = this.email.value.toString().toLowerCase();
        const password = this.password.value.toString();

        this.loginService.login(mail, password).subscribe((res: AccountInterface|string) => {
            if (typeof res === 'string') {
                this.loginFailed(res);
            } else {
                this.loginSuccess();
            }
        });
    }

    loginSuccess(): void {
        this.loginService.goDashboard();
    }

    loginFailed(error: string): void {
        this.error = error;
    }
}
