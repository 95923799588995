/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {
    SURVEY_QUESTION_TYPE_ID_OBJ_LIST,
    SURVEY_QUESTION_TYPE_OBJ_LIST,
    USER_POLICY_OBJ_LIST
} from '@app/constants';
import {Router} from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTable} from '@angular/material/table';
import {LoginService} from '@services/login.service';
import {BaseIndexComponent} from '@classes/base-index';
import {UrlService} from '@services/url.service';
import {ISurvey, ISurveyQuestion, ISurveyQuestionEditInput} from '@interfaces/survey.interface';
import {SurveyQuestionService} from '@services/survey-question.service';
import {merge, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {IItemList} from '@interfaces/item-list.interface';
import {StoreService} from '@services/store.service';
import {IColumn} from '@interfaces/column-filter.interface';

@Component({
    selector: 'app-survey-question-index',
    templateUrl: 'index.component.html'
})
export class SurveyQuestionIndexComponent extends BaseIndexComponent<ISurveyQuestion, ISurveyQuestionEditInput> implements OnInit, AfterViewInit {
    @Input() survey: ISurvey;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatTable, {static: true}) table: MatTable<any>;

    tableName = 'surveyQuestionList';

    tableColumns: Array<IColumn> = [
        {
            name: 'id',
            title: 'item.fields.id'
        },
        {
            name: 'type_id',
            title: 'survey-question.fields.type_id'
        },
        {
            name: 'question',
            title: 'survey-question.fields.question'
        },
        {
            name: 'answer',
            title: 'survey-question.fields.answer'
        },
        {
            name: 'action',
            title: 'item.fields.action'
        }
    ];

    itemList: Array<ISurveyQuestion> = [];

    baseRoute = '/survey-question';
    itemHistoryKey = 'surveyQuestion';

    constructor(
        protected itemService: SurveyQuestionService,
        protected deleteDialog: MatDialog,
        protected router: Router,
        protected urlService: UrlService,
        protected loginService: LoginService,
        protected storeServ: StoreService
    ) {
        super(itemService, deleteDialog, router, urlService, storeServ);
    }

    ngOnInit() {
        this.handleTableColumns();
    }

    ngAfterViewInit() {
        this.input.filter = {
            survey_id: this.survey.id.toString()
        }

        this.handleUrlQuery();
    }

    canDelete(item: ISurveyQuestion): boolean {
        return this.loginService.isGranted(USER_POLICY_OBJ_LIST.POLICY_ADMIN_SURVEY_EDIT);
    }

    getTypeTranslationKey(typeId: number) {
        switch (typeId) {
            case SURVEY_QUESTION_TYPE_ID_OBJ_LIST.OPEN:
                return SURVEY_QUESTION_TYPE_OBJ_LIST.OPEN;
            case SURVEY_QUESTION_TYPE_ID_OBJ_LIST.SINGLE:
                return SURVEY_QUESTION_TYPE_OBJ_LIST.SINGLE;
            default: // SURVEY_QUESTION_TYPE_ID_OBJ_LIST.MULTIPLE
                return SURVEY_QUESTION_TYPE_OBJ_LIST.MULTIPLE;
        }
    }

    getCreateLink(): string {
        return `/survey-question/create/${this.survey.id}`;
    }

    // protected updateList(): void {
    //     merge(this.sort.sortChange, this.paginator.page, this.filterChanged)
    //         .pipe(
    //             startWith({}),
    //             switchMap(() => {
    //                 this.input.sort = this.sort.active;
    //                 this.input.order = this.sort.direction;
    //                 this.input.page = this.paginator.pageIndex;
    //                 this.input.filter = {
    //                     survey_id: this.survey.id.toString()
    //                 }
    //
    //                 return this.itemService.getList(this.input);
    //             }),
    //             map((data: IItemList<ISurveyQuestion>) => {
    //                 this.resultsLength = data.count;
    //
    //                 return data.rows;
    //             }),
    //             catchError(err => {
    //                 console.error(err);
    //                 return observableOf([]);
    //             })
    //         ).subscribe(
    //         (data: Array<ISurveyQuestion>) => {
    //             return this.itemList = data;
    //         });
    // }
}
