<form
    [formGroup]="formGroup"
    novalidate autocomplete="off"
    class="flyer-category form-container"
    (ngSubmit)="submitForm(formGroup.value)">

    <mat-error *ngIf="formGroup.invalid">{{getFormErrorMessage()|translate}}</mat-error>
    <mat-error *ngIf="error.length">{{error|translate}}</mat-error>

    <div *ngIf="scenario === scenarioEdit" class="row mb-4">
        <div class="col-12">
            <div class="user-id">{{'item.fields.id'|translate}}: {{item.id}}</div>
        </div>
    </div>

    <div class="row">
        <div class="col-6">
            <mat-form-field>
                <mat-label>{{'flyer-category.fields.name'|translate}}</mat-label>
                <input
                    matInput
                    [placeholder]="'flyer-category.fields.name'|translate"
                    formControlName="name"
                    required>
                <mat-error *ngIf="formGroup.controls['name'].invalid">
                    {{getErrorMessage(formGroup.controls['name'])}}
                </mat-error>
            </mat-form-field>
        </div>

        <div class="col-6">
            <mat-form-field>
                <mat-label>{{'flyer-category.fields.icon'|translate}}</mat-label>
                <input
                    matInput
                    [placeholder]="'flyer-category.fields.icon'|translate"
                    formControlName="icon"
                    required>
                <mat-hint>{{'flyer-category.fields.iconHint'|translate}}</mat-hint>
                <mat-error *ngIf="formGroup.controls['icon'].invalid">
                    {{getErrorMessage(formGroup.controls['icon'])}}
                </mat-error>
            </mat-form-field>
        </div>

        <div class="col-12">
            <mat-form-field>
                <mat-label>{{'flyer-category.fields.description'|translate}}</mat-label>
                <input
                    matInput
                    [placeholder]="'flyer-category.fields.description'|translate"
                    formControlName="description">
                <mat-error *ngIf="formGroup.controls['description'].invalid">
                    {{getErrorMessage(formGroup.controls['description'])}}
                </mat-error>
            </mat-form-field>
        </div>

    </div>

    <div class="button-row d-flex justify-content-end">
        <button
            mat-raised-button
            color="primary"
            type="submit"
            [disabled]="!formGroup.valid">{{'form.Save'|translate}}</button>

        <button
            mat-raised-button
            (click)="goBack()">{{'form.Cancel'|translate}}</button>
    </div>
</form>
