<div class="dashboard-container content-block">
    <div class="platform_dashboard-item-text">

    </div>

    <div class="row">
        <div class="col-12">
            <div
                class="head">
                <h1>{{'dashboard.cards.welcome.Welcome'|translate}} {{userName? userName : 'user'}}</h1>
            </div>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-6">TBA</div>

        <!--<div class="col-6">
            <mat-card class="w-100">
                <mat-card-header>
                    <mat-card-title>{{'dashboard.cards.downloads.Downloads'|translate}}</mat-card-title>
                </mat-card-header>

                <mat-card-content fxLayout="row">
                    <app-dashboard-file class="w-100"></app-dashboard-file>
                </mat-card-content>
            </mat-card>
        </div>-->

        <!--<div class="col-6">
            <mat-card class="w-100">
                <mat-card-header>
                    <mat-card-title>{{'dashboard.cards.tickets.Support Tickets'|translate}}</mat-card-title>
                </mat-card-header>

                <mat-card-content fxLayout="row">
                    <app-dashboard-ticket class="w-100"></app-dashboard-ticket>
                </mat-card-content>
            </mat-card>
        </div>-->
    </div>

    <div class="row mt-4">
        <!--<div class="col-12">
            <mat-card class="w-100">
                <mat-card-header>
                    <mat-card-title>{{'dashboard.cards.licenses.My Active Licenses'|translate}}</mat-card-title>
                </mat-card-header>

                <mat-card-content fxLayout="row">
                    <app-dashboard-product class="w-100"></app-dashboard-product>
                </mat-card-content>
            </mat-card>
        </div>-->
    </div>

</div>


