<div class="user update-container">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{'user.update.Title'|translate}}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <app-user-form [scenario]="formScenario"></app-user-form>
        </mat-card-content>
    </mat-card>
</div>
