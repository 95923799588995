<div
    class="api-test-container"
    #apiTestContainer>

    <div class="content-block">
        <h1 translate>swagger.Test & Authorisation</h1>

        <div class="general-statistics-wrapper">
            <div class="about-container">
                <h3 class="mt-10" translate>swagger.How to authorize in test tool</h3>

                <p class="api-description" translate>swagger.For starting to run requests you should Authorize at first. You need to make a few steps
                    for it.</p>
                <ol class="api-description">
                    <li translate>swagger.Click on /auth/basic path section bellow</li>
                    <li translate>swagger.Click on Try it out button</li>
                    <li translate>swagger.Enter correct username and password and click on Execute button</li>
                    <li translate>swagger.Copy to a clipboard "token" JSON parameter from request response subsection</li>
                    <li translate>swagger.Click on Authorize button from a top tool section</li>
                    <li translate>swagger.Paste copied token to value input and click on Authorize button</li>
                    <li translate>swagger.Close Authorize dialog</li>
                </ol>
                <p class="api-description" translate>swagger.After you authorised all requests will contain authorization information and you could make request.</p>
            </div>
            <div class="auth-container-button-wrapper">
                <div class="swagger-ui">
                    <div class="wrapper">
                        <section class="block col-12 block-desktop col-12-desktop">
                            <div id="authorize-button-container"></div>
                        </section>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-20">
            <div id="swagger-ui"></div>
        </div>
    </div>

    <div class="mt-20 swagger-endpoint-card card-bottom-shadow">
        <div class="swagger-ui">
            <div class="wrapper">
                <section class="block col-12 block-desktop col-12-desktop">
                    <div id="schemas-block"></div>
                </section>
            </div>
        </div>
    </div>
</div>
