/**
 * 2020 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2020 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

export const COUNTRY_LIST = [
    {
        'iso2': 'AF',
        'name': 'Afghanistan'
    },
    {
        'iso2': 'AX',
        'name': 'Aland Islands'
    },
    {
        'iso2': 'AL',
        'name': 'Albania'
    },
    {
        'iso2': 'DZ',
        'name': 'Algeria'
    },
    {
        'iso2': 'AS',
        'name': 'American Samoa'
    },
    {
        'iso2': 'AD',
        'name': 'Andorra'
    },
    {
        'iso2': 'AO',
        'name': 'Angola'
    },
    {
        'iso2': 'AI',
        'name': 'Anguilla'
    },
    {
        'iso2': 'AQ',
        'name': 'Antarctica'
    },
    {
        'iso2': 'AG',
        'name': 'Antigua and Barbuda'
    },
    {
        'iso2': 'AR',
        'name': 'Argentina'
    },
    {
        'iso2': 'AM',
        'name': 'Armenia'
    },
    {
        'iso2': 'AW',
        'name': 'Aruba'
    },
    {
        'iso2': 'AU',
        'name': 'Australia'
    },
    {
        'iso2': 'AT',
        'name': 'Austria'
    },
    {
        'iso2': 'AZ',
        'name': 'Azerbaijan'
    },
    {
        'iso2': 'BS',
        'name': 'Bahamas'
    },
    {
        'iso2': 'BH',
        'name': 'Bahrain'
    },
    {
        'iso2': 'BD',
        'name': 'Bangladesh'
    },
    {
        'iso2': 'BB',
        'name': 'Barbados'
    },
    {
        'iso2': 'BY',
        'name': 'Belarus'
    },
    {
        'iso2': 'BE',
        'name': 'Belgium'
    },
    {
        'iso2': 'BZ',
        'name': 'Belize'
    },
    {
        'iso2': 'BJ',
        'name': 'Benin'
    },
    {
        'iso2': 'BM',
        'name': 'Bermuda'
    },
    {
        'iso2': 'BT',
        'name': 'Bhutan'
    },
    {
        'iso2': 'BO',
        'name': 'Bolivia'
    },
    {
        'iso2': 'BQ',
        'name': 'Bonaire, Saint Eustatius and Saba '
    },
    {
        'iso2': 'BA',
        'name': 'Bosnia and Herzegovina'
    },
    {
        'iso2': 'BW',
        'name': 'Botswana'
    },
    {
        'iso2': 'BV',
        'name': 'Bouvet Island'
    },
    {
        'iso2': 'BR',
        'name': 'Brazil'
    },
    {
        'iso2': 'IO',
        'name': 'British Indian Ocean Territory'
    },
    {
        'iso2': 'VG',
        'name': 'British Virgin Islands'
    },
    {
        'iso2': 'BN',
        'name': 'Brunei'
    },
    {
        'iso2': 'BG',
        'name': 'Bulgaria'
    },
    {
        'iso2': 'BF',
        'name': 'Burkina Faso'
    },
    {
        'iso2': 'BI',
        'name': 'Burundi'
    },
    {
        'iso2': 'KH',
        'name': 'Cambodia'
    },
    {
        'iso2': 'CM',
        'name': 'Cameroon'
    },
    {
        'iso2': 'CA',
        'name': 'Canada'
    },
    {
        'iso2': 'CV',
        'name': 'Cape Verde'
    },
    {
        'iso2': 'KY',
        'name': 'Cayman Islands'
    },
    {
        'iso2': 'CF',
        'name': 'Central African Republic'
    },
    {
        'iso2': 'TD',
        'name': 'Chad'
    },
    {
        'iso2': 'CL',
        'name': 'Chile'
    },
    {
        'iso2': 'CN',
        'name': 'China'
    },
    {
        'iso2': 'CX',
        'name': 'Christmas Island'
    },
    {
        'iso2': 'CC',
        'name': 'Cocos Islands'
    },
    {
        'iso2': 'CO',
        'name': 'Colombia'
    },
    {
        'iso2': 'KM',
        'name': 'Comoros'
    },
    {
        'iso2': 'CK',
        'name': 'Cook Islands'
    },
    {
        'iso2': 'CR',
        'name': 'Costa Rica'
    },
    {
        'iso2': 'HR',
        'name': 'Croatia'
    },
    {
        'iso2': 'CU',
        'name': 'Cuba'
    },
    {
        'iso2': 'CW',
        'name': 'Curacao'
    },
    {
        'iso2': 'CY',
        'name': 'Cyprus'
    },
    {
        'iso2': 'CZ',
        'name': 'Czech Republic'
    },
    {
        'iso2': 'CD',
        'name': 'Democratic Republic of the Congo'
    },
    {
        'iso2': 'DK',
        'name': 'Denmark'
    },
    {
        'iso2': 'DJ',
        'name': 'Djibouti'
    },
    {
        'iso2': 'DM',
        'name': 'Dominica'
    },
    {
        'iso2': 'DO',
        'name': 'Dominican Republic'
    },
    {
        'iso2': 'TL',
        'name': 'East Timor'
    },
    {
        'iso2': 'EC',
        'name': 'Ecuador'
    },
    {
        'iso2': 'EG',
        'name': 'Egypt'
    },
    {
        'iso2': 'SV',
        'name': 'El Salvador'
    },
    {
        'iso2': 'GQ',
        'name': 'Equatorial Guinea'
    },
    {
        'iso2': 'ER',
        'name': 'Eritrea'
    },
    {
        'iso2': 'EE',
        'name': 'Estonia'
    },
    {
        'iso2': 'ET',
        'name': 'Ethiopia'
    },
    {
        'iso2': 'FK',
        'name': 'Falkland Islands'
    },
    {
        'iso2': 'FO',
        'name': 'Faroe Islands'
    },
    {
        'iso2': 'FJ',
        'name': 'Fiji'
    },
    {
        'iso2': 'FI',
        'name': 'Finland'
    },
    {
        'iso2': 'FR',
        'name': 'France'
    },
    {
        'iso2': 'GF',
        'name': 'French Guiana'
    },
    {
        'iso2': 'PF',
        'name': 'French Polynesia'
    },
    {
        'iso2': 'TF',
        'name': 'French Southern Territories'
    },
    {
        'iso2': 'GA',
        'name': 'Gabon'
    },
    {
        'iso2': 'GM',
        'name': 'Gambia'
    },
    {
        'iso2': 'GE',
        'name': 'Georgia'
    },
    {
        'iso2': 'DE',
        'name': 'Germany'
    },
    {
        'iso2': 'GH',
        'name': 'Ghana'
    },
    {
        'iso2': 'GI',
        'name': 'Gibraltar'
    },
    {
        'iso2': 'GR',
        'name': 'Greece'
    },
    {
        'iso2': 'GL',
        'name': 'Greenland'
    },
    {
        'iso2': 'GD',
        'name': 'Grenada'
    },
    {
        'iso2': 'GP',
        'name': 'Guadeloupe'
    },
    {
        'iso2': 'GU',
        'name': 'Guam'
    },
    {
        'iso2': 'GT',
        'name': 'Guatemala'
    },
    {
        'iso2': 'GG',
        'name': 'Guernsey'
    },
    {
        'iso2': 'GN',
        'name': 'Guinea'
    },
    {
        'iso2': 'GW',
        'name': 'Guinea-Bissau'
    },
    {
        'iso2': 'GY',
        'name': 'Guyana'
    },
    {
        'iso2': 'HT',
        'name': 'Haiti'
    },
    {
        'iso2': 'HM',
        'name': 'Heard Island and McDonald Islands'
    },
    {
        'iso2': 'HN',
        'name': 'Honduras'
    },
    {
        'iso2': 'HK',
        'name': 'Hong Kong'
    },
    {
        'iso2': 'HU',
        'name': 'Hungary'
    },
    {
        'iso2': 'IS',
        'name': 'Iceland'
    },
    {
        'iso2': 'IN',
        'name': 'India'
    },
    {
        'iso2': 'ID',
        'name': 'Indonesia'
    },
    {
        'iso2': 'IR',
        'name': 'Iran'
    },
    {
        'iso2': 'IQ',
        'name': 'Iraq'
    },
    {
        'iso2': 'IE',
        'name': 'Ireland'
    },
    {
        'iso2': 'IM',
        'name': 'Isle of Man'
    },
    {
        'iso2': 'IL',
        'name': 'Israel'
    },
    {
        'iso2': 'IT',
        'name': 'Italy'
    },
    {
        'iso2': 'CI',
        'name': 'Ivory Coast'
    },
    {
        'iso2': 'JM',
        'name': 'Jamaica'
    },
    {
        'iso2': 'JP',
        'name': 'Japan'
    },
    {
        'iso2': 'JE',
        'name': 'Jersey'
    },
    {
        'iso2': 'JO',
        'name': 'Jordan'
    },
    {
        'iso2': 'KZ',
        'name': 'Kazakhstan'
    },
    {
        'iso2': 'KE',
        'name': 'Kenya'
    },
    {
        'iso2': 'KI',
        'name': 'Kiribati'
    },
    {
        'iso2': 'XK',
        'name': 'Kosovo'
    },
    {
        'iso2': 'KW',
        'name': 'Kuwait'
    },
    {
        'iso2': 'KG',
        'name': 'Kyrgyzstan'
    },
    {
        'iso2': 'LA',
        'name': 'Laos'
    },
    {
        'iso2': 'LV',
        'name': 'Latvia'
    },
    {
        'iso2': 'LB',
        'name': 'Lebanon'
    },
    {
        'iso2': 'LS',
        'name': 'Lesotho'
    },
    {
        'iso2': 'LR',
        'name': 'Liberia'
    },
    {
        'iso2': 'LY',
        'name': 'Libya'
    },
    {
        'iso2': 'LI',
        'name': 'Liechtenstein'
    },
    {
        'iso2': 'LT',
        'name': 'Lithuania'
    },
    {
        'iso2': 'LU',
        'name': 'Luxembourg'
    },
    {
        'iso2': 'MO',
        'name': 'Macao'
    },
    {
        'iso2': 'MK',
        'name': 'Macedonia'
    },
    {
        'iso2': 'MG',
        'name': 'Madagascar'
    },
    {
        'iso2': 'MW',
        'name': 'Malawi'
    },
    {
        'iso2': 'MY',
        'name': 'Malaysia'
    },
    {
        'iso2': 'MV',
        'name': 'Maldives'
    },
    {
        'iso2': 'ML',
        'name': 'Mali'
    },
    {
        'iso2': 'MT',
        'name': 'Malta'
    },
    {
        'iso2': 'MH',
        'name': 'Marshall Islands'
    },
    {
        'iso2': 'MQ',
        'name': 'Martinique'
    },
    {
        'iso2': 'MR',
        'name': 'Mauritania'
    },
    {
        'iso2': 'MU',
        'name': 'Mauritius'
    },
    {
        'iso2': 'YT',
        'name': 'Mayotte'
    },
    {
        'iso2': 'MX',
        'name': 'Mexico'
    },
    {
        'iso2': 'FM',
        'name': 'Micronesia'
    },
    {
        'iso2': 'MD',
        'name': 'Moldova'
    },
    {
        'iso2': 'MC',
        'name': 'Monaco'
    },
    {
        'iso2': 'MN',
        'name': 'Mongolia'
    },
    {
        'iso2': 'ME',
        'name': 'Montenegro'
    },
    {
        'iso2': 'MS',
        'name': 'Montserrat'
    },
    {
        'iso2': 'MA',
        'name': 'Morocco'
    },
    {
        'iso2': 'MZ',
        'name': 'Mozambique'
    },
    {
        'iso2': 'MM',
        'name': 'Myanmar'
    },
    {
        'iso2': 'NA',
        'name': 'Namibia'
    },
    {
        'iso2': 'NR',
        'name': 'Nauru'
    },
    {
        'iso2': 'NP',
        'name': 'Nepal'
    },
    {
        'iso2': 'NL',
        'name': 'Netherlands'
    },
    {
        'iso2': 'NC',
        'name': 'New Caledonia'
    },
    {
        'iso2': 'NZ',
        'name': 'New Zealand'
    },
    {
        'iso2': 'NI',
        'name': 'Nicaragua'
    },
    {
        'iso2': 'NE',
        'name': 'Niger'
    },
    {
        'iso2': 'NG',
        'name': 'Nigeria'
    },
    {
        'iso2': 'NU',
        'name': 'Niue'
    },
    {
        'iso2': 'NF',
        'name': 'Norfolk Island'
    },
    {
        'iso2': 'KP',
        'name': 'North Korea'
    },
    {
        'iso2': 'MP',
        'name': 'Northern Mariana Islands'
    },
    {
        'iso2': 'NO',
        'name': 'Norway'
    },
    {
        'iso2': 'OM',
        'name': 'Oman'
    },
    {
        'iso2': 'PK',
        'name': 'Pakistan'
    },
    {
        'iso2': 'PW',
        'name': 'Palau'
    },
    {
        'iso2': 'PS',
        'name': 'Palestinian Territory'
    },
    {
        'iso2': 'PA',
        'name': 'Panama'
    },
    {
        'iso2': 'PG',
        'name': 'Papua New Guinea'
    },
    {
        'iso2': 'PY',
        'name': 'Paraguay'
    },
    {
        'iso2': 'PE',
        'name': 'Peru'
    },
    {
        'iso2': 'PH',
        'name': 'Philippines'
    },
    {
        'iso2': 'PN',
        'name': 'Pitcairn'
    },
    {
        'iso2': 'PL',
        'name': 'Poland'
    },
    {
        'iso2': 'PT',
        'name': 'Portugal'
    },
    {
        'iso2': 'PR',
        'name': 'Puerto Rico'
    },
    {
        'iso2': 'QA',
        'name': 'Qatar'
    },
    {
        'iso2': 'CG',
        'name': 'Republic of the Congo'
    },
    {
        'iso2': 'RE',
        'name': 'Reunion'
    },
    {
        'iso2': 'RO',
        'name': 'Romania'
    },
    {
        'iso2': 'RU',
        'name': 'Russia'
    },
    {
        'iso2': 'RW',
        'name': 'Rwanda'
    },
    {
        'iso2': 'BL',
        'name': 'Saint Barthelemy'
    },
    {
        'iso2': 'SH',
        'name': 'Saint Helena'
    },
    {
        'iso2': 'KN',
        'name': 'Saint Kitts and Nevis'
    },
    {
        'iso2': 'LC',
        'name': 'Saint Lucia'
    },
    {
        'iso2': 'MF',
        'name': 'Saint Martin'
    },
    {
        'iso2': 'PM',
        'name': 'Saint Pierre and Miquelon'
    },
    {
        'iso2': 'VC',
        'name': 'Saint Vincent and the Grenadines'
    },
    {
        'iso2': 'WS',
        'name': 'Samoa'
    },
    {
        'iso2': 'SM',
        'name': 'San Marino'
    },
    {
        'iso2': 'ST',
        'name': 'Sao Tome and Principe'
    },
    {
        'iso2': 'SA',
        'name': 'Saudi Arabia'
    },
    {
        'iso2': 'SN',
        'name': 'Senegal'
    },
    {
        'iso2': 'RS',
        'name': 'Serbia'
    },
    {
        'iso2': 'SC',
        'name': 'Seychelles'
    },
    {
        'iso2': 'SL',
        'name': 'Sierra Leone'
    },
    {
        'iso2': 'SG',
        'name': 'Singapore'
    },
    {
        'iso2': 'SX',
        'name': 'Sint Maarten'
    },
    {
        'iso2': 'SK',
        'name': 'Slovakia'
    },
    {
        'iso2': 'SI',
        'name': 'Slovenia'
    },
    {
        'iso2': 'SB',
        'name': 'Solomon Islands'
    },
    {
        'iso2': 'SO',
        'name': 'Somalia'
    },
    {
        'iso2': 'ZA',
        'name': 'South Africa'
    },
    {
        'iso2': 'GS',
        'name': 'South Georgia and the South Sandwich Islands'
    },
    {
        'iso2': 'KR',
        'name': 'South Korea'
    },
    {
        'iso2': 'SS',
        'name': 'South Sudan'
    },
    {
        'iso2': 'ES',
        'name': 'Spain'
    },
    {
        'iso2': 'LK',
        'name': 'Sri Lanka'
    },
    {
        'iso2': 'SD',
        'name': 'Sudan'
    },
    {
        'iso2': 'SR',
        'name': 'Suriname'
    },
    {
        'iso2': 'SJ',
        'name': 'Svalbard and Jan Mayen'
    },
    {
        'iso2': 'SZ',
        'name': 'Swaziland'
    },
    {
        'iso2': 'SE',
        'name': 'Sweden'
    },
    {
        'iso2': 'CH',
        'name': 'Switzerland'
    },
    {
        'iso2': 'SY',
        'name': 'Syria'
    },
    {
        'iso2': 'TW',
        'name': 'Taiwan'
    },
    {
        'iso2': 'TJ',
        'name': 'Tajikistan'
    },
    {
        'iso2': 'TZ',
        'name': 'Tanzania'
    },
    {
        'iso2': 'TH',
        'name': 'Thailand'
    },
    {
        'iso2': 'TG',
        'name': 'Togo'
    },
    {
        'iso2': 'TK',
        'name': 'Tokelau'
    },
    {
        'iso2': 'TO',
        'name': 'Tonga'
    },
    {
        'iso2': 'TT',
        'name': 'Trinidad and Tobago'
    },
    {
        'iso2': 'TN',
        'name': 'Tunisia'
    },
    {
        'iso2': 'TR',
        'name': 'Turkey'
    },
    {
        'iso2': 'TM',
        'name': 'Turkmenistan'
    },
    {
        'iso2': 'TC',
        'name': 'Turks and Caicos Islands'
    },
    {
        'iso2': 'TV',
        'name': 'Tuvalu'
    },
    {
        'iso2': 'VI',
        'name': 'U.S. Virgin Islands'
    },
    {
        'iso2': 'UG',
        'name': 'Uganda'
    },
    {
        'iso2': 'UA',
        'name': 'Ukraine'
    },
    {
        'iso2': 'AE',
        'name': 'United Arab Emirates'
    },
    {
        'iso2': 'UK',
        'name': 'United Kingdom'
    },
    {
        'iso2': 'US',
        'name': 'United States'
    },
    {
        'iso2': 'UM',
        'name': 'United States Minor Outlying Islands'
    },
    {
        'iso2': 'UY',
        'name': 'Uruguay'
    },
    {
        'iso2': 'UZ',
        'name': 'Uzbekistan'
    },
    {
        'iso2': 'VU',
        'name': 'Vanuatu'
    },
    {
        'iso2': 'VA',
        'name': 'Vatican'
    },
    {
        'iso2': 'VE',
        'name': 'Venezuela'
    },
    {
        'iso2': 'VN',
        'name': 'Vietnam'
    },
    {
        'iso2': 'WF',
        'name': 'Wallis and Futuna'
    },
    {
        'iso2': 'EH',
        'name': 'Western Sahara'
    },
    {
        'iso2': 'YE',
        'name': 'Yemen'
    },
    {
        'iso2': 'ZM',
        'name': 'Zambia'
    },
    {
        'iso2': 'ZW',
        'name': 'Zimbabwe'
    }
];

export const COUNTRY_LIST_NL = [
    {'iso2': 'AF', 'name': 'Afghanistan'},
    {'iso2': 'AL', 'name': 'Albanië'},
    {'iso2': 'DZ', 'name': 'Algerije'},
    {'iso2': 'AS', 'name': 'Amerikaans-Samoa'},
    {'iso2': 'VI', 'name': 'Amerikaanse Maagdeneilanden'},
    {'iso2': 'UM', 'name': 'Amerikaanse ondergeschikte afgelegen eilanden'},
    {'iso2': 'AD', 'name': 'Andorra'},
    {'iso2': 'AO', 'name': 'Angola'},
    {'iso2': 'AI', 'name': 'Anguilla'},
    {'iso2': 'AQ', 'name': 'Antarctica'},
    {'iso2': 'AG', 'name': 'Antigua en Barbuda'},
    {'iso2': 'AR', 'name': 'Argentinië'},
    {'iso2': 'AM', 'name': 'Armenië'},
    {'iso2': 'AW', 'name': 'Aruba'},
    {'iso2': 'AU', 'name': 'Australië'},
    {'iso2': 'AZ', 'name': 'Azerbeidzjan'},
    {'iso2': 'BS', 'name': 'Bahama\'s'},
    {'iso2': 'BH', 'name': 'Bahrein'},
    {'iso2': 'BD', 'name': 'Bangladesh'},
    {'iso2': 'BB', 'name': 'Barbados'},
    {'iso2': 'BY', 'name': 'Wit-Rusland'},
    {'iso2': 'BE', 'name': 'België'},
    {'iso2': 'BZ', 'name': 'Belize'},
    {'iso2': 'BJ', 'name': 'Benin'},
    {'iso2': 'BM', 'name': 'Bermuda'},
    {'iso2': 'BT', 'name': 'Bhutan'},
    {'iso2': 'BO', 'name': 'Bolivia'},
    {'iso2': 'BA', 'name': 'Bosnië en Herzegovina'},
    {'iso2': 'BW', 'name': 'Botswana'},
    {'iso2': 'BV', 'name': 'Bouveteiland'},
    {'iso2': 'BR', 'name': 'Brazilië'},
    {'iso2': 'IO', 'name': 'Brits Territorium in de Indische Oceaan'},
    {'iso2': 'VG', 'name': 'Britse Maagdeneilanden'},
    {'iso2': 'BN', 'name': 'Brunei'},
    {'iso2': 'BG', 'name': 'Bulgarije'},
    {'iso2': 'BF', 'name': 'Burkina Faso'},
    {'iso2': 'BI', 'name': 'Burundi'},
    {'iso2': 'KH', 'name': 'Cambodja'},
    {'iso2': 'CA', 'name': 'Canada'},
    {'iso2': 'KY', 'name': 'Caymaneilanden'},
    {'iso2': 'CF', 'name': 'Centraal-Afrikaanse Republiek'},
    {'iso2': 'CL', 'name': 'Chili'},
    {'iso2': 'CN', 'name': 'China'},
    {'iso2': 'CX', 'name': 'Christmaseiland'},
    {'iso2': 'CC', 'name': 'Cocoseilanden'},
    {'iso2': 'CO', 'name': 'Colombia'},
    {'iso2': 'KM', 'name': 'Comoren'},
    {'iso2': 'CG', 'name': 'Congo'},
    {'iso2': 'CK', 'name': 'Cookeilanden'},
    {'iso2': 'CR', 'name': 'Costa Rica'},
    {'iso2': 'CU', 'name': 'Cuba'},
    {'iso2': 'CY', 'name': 'Cyprus'},
    {'iso2': 'CD', 'name': 'Democratische Republiek Congo'},
    {'iso2': 'DK', 'name': 'Denemarken'},
    {'iso2': 'DJ', 'name': 'Djibouti'},
    {'iso2': 'DM', 'name': 'Dominica'},
    {'iso2': 'DO', 'name': 'Dominicaanse Republiek'},
    {'iso2': 'DE', 'name': 'Duitsland'},
    {'iso2': 'EC', 'name': 'Ecuador'},
    {'iso2': 'EG', 'name': 'Egypte'},
    {'iso2': 'SV', 'name': 'El Salvador'},
    {'iso2': 'GQ', 'name': 'Equatoriaal-Guinea'},
    {'iso2': 'ER', 'name': 'Eritrea'},
    {'iso2': 'EE', 'name': 'Estland'},
    {'iso2': 'ET', 'name': 'Ethiopië'},
    {'iso2': 'FO', 'name': 'Faeröer'},
    {'iso2': 'FK', 'name': 'Falklandeilanden'},
    {'iso2': 'FJ', 'name': 'Fiji'},
    {'iso2': 'PH', 'name': 'Filipijnen'},
    {'iso2': 'FI', 'name': 'Finland'},
    {'iso2': 'FR', 'name': 'Frankrijk'},
    {'iso2': 'GF', 'name': 'Frans-Guyana'},
    {'iso2': 'PF', 'name': 'Frans-Polynesië'},
    {'iso2': 'TF', 'name': 'Franse Gebieden in de zuidelijke Indische Oceaan'},
    {'iso2': 'GA', 'name': 'Gabon'},
    {'iso2': 'GM', 'name': 'Gambia'},
    {'iso2': 'GE', 'name': 'Georgië'},
    {'iso2': 'GH', 'name': 'Ghana'},
    {'iso2': 'GI', 'name': 'Gibraltar'},
    {'iso2': 'GD', 'name': 'Grenada'},
    {'iso2': 'GR', 'name': 'Griekenland'},
    {'iso2': 'GL', 'name': 'Groenland'},
    {'iso2': 'GP', 'name': 'Guadeloupe'},
    {'iso2': 'GU', 'name': 'Guam'},
    {'iso2': 'GT', 'name': 'Guatemala'},
    {'iso2': 'GN', 'name': 'Guinee'},
    {'iso2': 'GW', 'name': 'Guinee-Bissau'},
    {'iso2': 'GY', 'name': 'Guyana'},
    {'iso2': 'HT', 'name': 'Haïti'},
    {'iso2': 'HM', 'name': 'Heard- en McDonaldeilanden'},
    {'iso2': 'HN', 'name': 'Honduras'},
    {'iso2': 'HU', 'name': 'Hongarije'},
    {'iso2': 'HK', 'name': 'Hongkong'},
    {'iso2': 'IS', 'name': 'IJsland'},
    {'iso2': 'IE', 'name': 'Ierland'},
    {'iso2': 'IN', 'name': 'India'},
    {'iso2': 'ID', 'name': 'Indonesië'},
    {'iso2': 'IQ', 'name': 'Irak'},
    {'iso2': 'IR', 'name': 'Iran'},
    {'iso2': 'IL', 'name': 'Israël'},
    {'iso2': 'IT', 'name': 'Italië'},
    {'iso2': 'CI', 'name': 'Ivoorkust'},
    {'iso2': 'JM', 'name': 'Jamaica'},
    {'iso2': 'JP', 'name': 'Japan'},
    {'iso2': 'YE', 'name': 'Jemen'},
    {'iso2': 'YU', 'name': 'Joegoslavië'},
    {'iso2': 'JO', 'name': 'Jordanië'},
    {'iso2': 'CV', 'name': 'Kaapverdië'},
    {'iso2': 'CM', 'name': 'Kameroen'},
    {'iso2': 'KZ', 'name': 'Kazachstan'},
    {'iso2': 'KE', 'name': 'Kenia'},
    {'iso2': 'KG', 'name': 'Kirgizië'},
    {'iso2': 'KI', 'name': 'Kiribati'},
    {'iso2': 'KW', 'name': 'Koeweit'},
    {'iso2': 'HR', 'name': 'Kroatië'},
    {'iso2': 'LA', 'name': 'Laos'},
    {'iso2': 'LS', 'name': 'Lesotho'},
    {'iso2': 'LV', 'name': 'Letland'},
    {'iso2': 'LB', 'name': 'Libanon'},
    {'iso2': 'LR', 'name': 'Liberia'},
    {'iso2': 'LY', 'name': 'Libië'},
    {'iso2': 'LI', 'name': 'Liechtenstein'},
    {'iso2': 'LT', 'name': 'Litouwen'},
    {'iso2': 'LU', 'name': 'Luxemburg'},
    {'iso2': 'MO', 'name': 'Macau'},
    {'iso2': 'MG', 'name': 'Madagaskar'},
    {'iso2': 'MW', 'name': 'Malawi'},
    {'iso2': 'MV', 'name': 'Maldiven'},
    {'iso2': 'MY', 'name': 'Maleisië'},
    {'iso2': 'ML', 'name': 'Mali'},
    {'iso2': 'MT', 'name': 'Malta'},
    {'iso2': 'MA', 'name': 'Marokko'},
    {'iso2': 'MH', 'name': 'Marshalleilanden'},
    {'iso2': 'MQ', 'name': 'Martinique'},
    {'iso2': 'MR', 'name': 'Mauritanië'},
    {'iso2': 'MU', 'name': 'Mauritius'},
    {'iso2': 'YT', 'name': 'Mayotte'},
    {'iso2': 'MX', 'name': 'Mexico'},
    {'iso2': 'FM', 'name': 'Micronesia'},
    {'iso2': 'MD', 'name': 'Moldavië'},
    {'iso2': 'MC', 'name': 'Monaco'},
    {'iso2': 'MN', 'name': 'Mongolië'},
    {'iso2': 'MS', 'name': 'Montserrat'},
    {'iso2': 'MZ', 'name': 'Mozambique'},
    {'iso2': 'MM', 'name': 'Myanmar'},
    {'iso2': 'NA', 'name': 'Namibië'},
    {'iso2': 'NR', 'name': 'Nauru'},
    {'iso2': 'NL', 'name': 'Nederland'},
    {'iso2': 'AN', 'name': 'Nederlandse Antillen'},
    {'iso2': 'NP', 'name': 'Nepal'},
    {'iso2': 'NI', 'name': 'Nicaragua'},
    {'iso2': 'NC', 'name': 'Nieuw-Caledonië'},
    {'iso2': 'NZ', 'name': 'Nieuw-Zeeland'},
    {'iso2': 'NE', 'name': 'Niger'},
    {'iso2': 'NG', 'name': 'Nigeria'},
    {'iso2': 'NU', 'name': 'Niue'},
    {'iso2': 'KP', 'name': 'Noord-Korea'},
    {'iso2': 'MP', 'name': 'Noordelijke Marianen'},
    {'iso2': 'NO', 'name': 'Noorwegen'},
    {'iso2': 'NF', 'name': 'Norfolkeiland'},
    {'iso2': 'UA', 'name': 'Oekraïne'},
    {'iso2': 'UZ', 'name': 'Oezbekistan'},
    {'iso2': 'OM', 'name': 'Oman'},
    {'iso2': 'TL', 'name': 'Oost-Timor'},
    {'iso2': 'AT', 'name': 'Oostenrijk'},
    {'iso2': 'PK', 'name': 'Pakistan'},
    {'iso2': 'PW', 'name': 'Palau'},
    {'iso2': 'PA', 'name': 'Panama'},
    {'iso2': 'PG', 'name': 'Papoea-Nieuw-Guinea'},
    {'iso2': 'PY', 'name': 'Paraguay'},
    {'iso2': 'PE', 'name': 'Peru'},
    {'iso2': 'PN', 'name': 'Pitcairneilanden'},
    {'iso2': 'PL', 'name': 'Polen'},
    {'iso2': 'PT', 'name': 'Portugal'},
    {'iso2': 'PR', 'name': 'Porto Rico'},
    {'iso2': 'QA', 'name': 'Qatar'},
    {'iso2': 'RE', 'name': 'Réunion'},
    {'iso2': 'RO', 'name': 'Roemenië'},
    {'iso2': 'RU', 'name': 'Rusland'},
    {'iso2': 'RW', 'name': 'Rwanda'},
    {'iso2': 'KN', 'name': 'Saint Kitts en Nevis'},
    {'iso2': 'LC', 'name': 'Saint Lucia'},
    {'iso2': 'VC', 'name': 'Saint Vincent en de Grenadines'},
    {'iso2': 'PM', 'name': 'Saint-Pierre en Miquelon'},
    {'iso2': 'SB', 'name': 'Salomonseilanden'},
    {'iso2': 'WS', 'name': 'Samoa'},
    {'iso2': 'SM', 'name': 'San Marino'},
    {'iso2': 'ST', 'name': 'Sao Tomé en Principe'},
    {'iso2': 'SA', 'name': 'Saoedi-Arabië'},
    {'iso2': 'SN', 'name': 'Senegal'},
    {'iso2': 'SC', 'name': 'Seychellen'},
    {'iso2': 'SL', 'name': 'Sierra Leone'},
    {'iso2': 'SG', 'name': 'Singapore'},
    {'iso2': 'SH', 'name': 'Sint-Helena'},
    {'iso2': 'SI', 'name': 'Slovenië'},
    {'iso2': 'SK', 'name': 'Slowakije'},
    {'iso2': 'SO', 'name': 'Somalië'},
    {'iso2': 'ES', 'name': 'Spanje'},
    {'iso2': 'LK', 'name': 'Sri Lanka'},
    {'iso2': 'SD', 'name': 'Soedan'},
    {'iso2': 'SR', 'name': 'Suriname'},
    {'iso2': 'SJ', 'name': 'Svalbard en Jan Mayen'},
    {'iso2': 'SZ', 'name': 'Swaziland'},
    {'iso2': 'SY', 'name': 'Syrië'},
    {'iso2': 'TJ', 'name': 'Tadzjikistan'},
    {'iso2': 'TW', 'name': 'Taiwan'},
    {'iso2': 'TZ', 'name': 'Tanzania'},
    {'iso2': 'TH', 'name': 'Thailand'},
    {'iso2': 'TG', 'name': 'Togo'},
    {'iso2': 'TK', 'name': 'Tokelau-eilanden'},
    {'iso2': 'TO', 'name': 'Tonga'},
    {'iso2': 'TT', 'name': 'Trinidad en Tobago'},
    {'iso2': 'TD', 'name': 'Tsjaad'},
    {'iso2': 'CZ', 'name': 'Tsjechië'},
    {'iso2': 'TN', 'name': 'Tunesië'},
    {'iso2': 'TR', 'name': 'Turkije'},
    {'iso2': 'TM', 'name': 'Turkmenistan'},
    {'iso2': 'TC', 'name': 'Turks- en Caicoseilanden'},
    {'iso2': 'TV', 'name': 'Tuvalu'},
    {'iso2': 'UG', 'name': 'Oeganda'},
    {'iso2': 'UY', 'name': 'Uruguay'},
    {'iso2': 'VU', 'name': 'Vanuatu'},
    {'iso2': 'VA', 'name': 'Vaticaanstad'},
    {'iso2': 'VE', 'name': 'Venezuela'},
    {'iso2': 'GB', 'name': 'Verenigd Koninkrijk'},
    {'iso2': 'AE', 'name': 'Verenigde Arabische Emiraten'},
    {'iso2': 'US', 'name': 'Verenigde Staten'},
    {'iso2': 'VN', 'name': 'Vietnam'},
    {'iso2': 'MK', 'name': 'Voormalige Joegoslavische Republiek Macedonië'},
    {'iso2': 'WF', 'name': 'Wallis en Futuna'},
    {'iso2': 'EH', 'name': 'Westelijke Sahara'},
    {'iso2': 'ZM', 'name': 'Zambia'},
    {'iso2': 'ZW', 'name': 'Zimbabwe'},
    {'iso2': 'ZA', 'name': 'Zuid-Afrika'},
    {'iso2': 'GS', 'name': 'Zuid-Georgië en Zuidelijke Sandwicheilanden'},
    {'iso2': 'KR', 'name': 'Zuid-Korea'},
    {'iso2': 'SE', 'name': 'Zweden'},
    {'iso2': 'CH', 'name': 'Zwitserland'}
];
