<form
    [formGroup]="formGroup"
    novalidate autocomplete="off"
    class="flyer-email-optin form-container"
    (ngSubmit)="submitForm(formGroup.value)">

    <mat-error *ngIf="formGroup.invalid">{{getFormErrorMessage()|translate}}</mat-error>
    <mat-error *ngIf="error.length">{{error|translate}}</mat-error>

    <div *ngIf="scenario === scenarioEdit" class="row mb-4">
        <div class="col-12">
            <div class="item-id">{{'item.fields.id'|translate}}: {{item.id}}</div>
        </div>
    </div>

    <div class="row">
        <div class="col-6">
            <div class="row">
                <div class="col-12">
                    <mat-form-field>
                        <mat-label>{{'flyer-email-optin.fields.user_id'|translate}}</mat-label>
                        <input
                            matInput
                            [placeholder]="'flyer-email-optin.fields.user_id'|translate"
                            formControlName="user_id"
                            required>
                        <mat-error *ngIf="formGroup.controls['user_id'].invalid">
                            {{getErrorMessage(formGroup.controls['user_id'])}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <mat-form-field>
                        <mat-label>{{'flyer-email-optin.fields.flyer_id'|translate}}</mat-label>
                        <input
                            matInput
                            [placeholder]="'flyer-email-optin.fields.flyer_id'|translate"
                            formControlName="flyer_id"
                            required>
                        <mat-error *ngIf="formGroup.controls['flyer_id'].invalid">
                            {{getErrorMessage(formGroup.controls['flyer_id'])}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>

    <div class="button-row d-flex justify-content-end">
        <button
            mat-raised-button
            color="primary"
            type="submit"
            [disabled]="!formGroup.valid">{{'form.Save'|translate}}</button>

        <button
            mat-raised-button
            (click)="goBack()">{{'form.Cancel'|translate}}</button>
    </div>
</form>
