import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as _ from 'lodash';
import {Moment} from 'moment';
import {
    FILTER_BLOCK_TYPE_DATE_TIME,
    FILTER_BLOCK_TYPE_DATE_TIME_RANGE,
    FILTER_BLOCK_TYPE_INPUT,
    FILTER_BLOCK_TYPE_RANGE,
    FILTER_BLOCK_TYPE_SEARCH_SELECT,
    FILTER_BLOCK_TYPE_SELECT
} from '@app/constants';
import {
    IBaseFilterBlock,
    IFilterBlockInput,
    IFilterBlockSearchSelect,
    IFilterConfig,
    IFilterValue, ISelectOption
} from '@interfaces/filter.interface';
import {TextMaskConfig} from 'angular2-text-mask';
import {getCurrentTimeZone} from '@app/functions';

@Component({
    selector: 'app-table-filter',
    templateUrl: 'filter.component.html'
})

export class TableFilterComponent implements OnInit {
    @Input() config: IFilterConfig;
    @Input() filter: IFilterValue;
    @Output() filterChanged: EventEmitter<IFilterValue> = new EventEmitter();
    @Output() filterClosed: EventEmitter<void> = new EventEmitter();

    timeZone = getCurrentTimeZone();

    blockTypeInput = FILTER_BLOCK_TYPE_INPUT;
    blockTypeSelect = FILTER_BLOCK_TYPE_SELECT;
    blockTypeSearchSelect = FILTER_BLOCK_TYPE_SEARCH_SELECT;
    blockTypeRange = FILTER_BLOCK_TYPE_RANGE;
    blockTypeDateTime = FILTER_BLOCK_TYPE_DATE_TIME;
    blockTypeDateTimeRange = FILTER_BLOCK_TYPE_DATE_TIME_RANGE;

    filterDateList: {
        [key: string]: string | Date | {
            from: string | Date;
            to: string | Date;
        }
    } = {};

    constructor() {
    }

    ngOnInit() {
        this.processFilterValues();
    }

    getBlockClass(block: IBaseFilterBlock): string {
        return ['filter-block', block.type, block.customClass || ''].join(' ');
    }

    getBlockMask(block: IFilterBlockInput): null | TextMaskConfig {
        return {
            mask: block.mask ?? false,
            guide: false
        };
    }

    getFilterValue(propertyName): string {
        const value = _.get(this.filter, propertyName);

        return _.isString(value) ? value : '';
    }

    getRangeValue(propertyName: string, minMax: string): string {
        const value = _.get(this.filter, `${propertyName}.${minMax}`);

        return _.isString(value) ? value : '';
    }

    getSearchFilterItems(block: IFilterBlockSearchSelect): Array<ISelectOption> {
        if (block.items.length) {
            return block.items;
        }

        const value: string = _.isString(this.filter[block.propertyName])
            ? (this.filter[block.propertyName] as string)
            : '';

        return value.length ? [{id: value, name: value}] : [];
    }

    onClose(): void {
        this.filterClosed.emit();
    }

    onFilterChanged(propertyName: string, value: string) {
        this.filter[propertyName] = value;
    }

    onFilterCleared(propertyName: string) {
        this.filter[propertyName] = '';
    }

    onFilterDateChanged(propertyName: string, value: Moment) {
        _.isEmpty(value) ? this.onFilterCleared(propertyName) : this.onFilterChanged(propertyName, value.toISOString());
    }

    onFilterDateRangeChanged(propertyName: string, fromTo: string, value: Moment) {
        if (_.isNil(this.filter[propertyName])) {
            this.filter[propertyName] = {
                from: '',
                to: ''
            };
        }
        this.filter[propertyName][fromTo] = _.isEmpty(value) ? '' : value.toISOString();
    }

    getFilterDateRangeValue(propertyName: string, fromTo: string): string {
        return _.get(this.filterDateList[propertyName], fromTo, '');
    }

    updateBlockSearchItemList(block: IFilterBlockSearchSelect, event: any) {
        const value = event.target.value || '';
        if ( value.length) {
            block.getList(value).subscribe((items) => (block.items = items));
        }
    }

    onApply(): void {
        this.filterChanged.emit(this.filter);
    }

    onClear(): void {
        Object.keys(this.filter).forEach((key) => {
            if (_.isString(this.filter[key]) || _.isNumber(this.filter[key])) {
                this.filter[key] = '';
                return;
            }

            if (!_.isUndefined(this.filter[key]['min'])) {
                this.filter[key] = {
                    min: '',
                    max: ''
                };
                return;
            }

            if (!_.isUndefined(this.filter[key]['from'])) {
                this.filter[key] = {
                    from: '',
                    to: ''
                };
            }
        });
        this.filterChanged.emit(this.filter);
        this.processFilterValues();
    }

    private processFilterValues(): void {
        this.filterDateList = {};

        this.config.blocks.forEach((block) => {
            const filterValue = this.filter[block.propertyName];

            switch (block.type) {
                case FILTER_BLOCK_TYPE_DATE_TIME:
                    this.filterDateList[block.propertyName] = _.isString(filterValue) && filterValue.length ?
                         this.getStartDayDate(filterValue)
                        : '';
                    break;

                case FILTER_BLOCK_TYPE_DATE_TIME_RANGE:
                    const from = _.get(filterValue, 'from', '');
                    const to = _.get(filterValue, 'to', '');
                    this.filterDateList[block.propertyName] = {
                        from: from.length ? this.getStartDayDate(from) : '',
                        to: to.length ? this.getEndDayDate(to) : ''
                    };
                    break;

                case FILTER_BLOCK_TYPE_SEARCH_SELECT:
                    (block as IFilterBlockSearchSelect).items = this.getSearchFilterItems(block as IFilterBlockSearchSelect);
                    break;
                default:
                    break;
            }
        });
    }

    private getProperDate(date: string): Date {
        return date.length ? new Date(date) : new Date();
    }

    private getStartDayDate(date: string): Date {
        const dateObj = this.getProperDate(date);
        dateObj.setUTCHours(0, 0, 0, 0)

        return dateObj;
    }

    private getEndDayDate(date: string): Date {
        const dateObj = this.getProperDate(date);
        dateObj.setUTCHours(23, 59, 59, 0);

        return dateObj;
    }
}
