import {IFilterValue} from '@interfaces/filter.interface';
import {SortDirection} from '@angular/material/sort';

/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

export interface IPageInput {
    sort: string;
    order: SortDirection;
    page: number;
    limit?: number;
    filter: IFilterValue;
}

export const EMPTY_PAGE_INPUT: IPageInput = {
    sort: '',
    order: 'asc',
    page: 0,
    filter: {}
}
