/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {PAGINATOR_PAGE_SIZE} from '../constants';
import {IPageInput} from '@interfaces/page-input.interface';
import {IItemList} from '@interfaces/item-list.interface';
import {ISurvey, ISurveyEditInput} from '@interfaces/survey.interface';

@Injectable({
    providedIn: 'root',
})

export class SurveyService {
    constructor(private httpClient: HttpClient) {}

    getItem(id: number): Observable<ISurvey> {
        return this.httpClient.get<ISurvey>(
            `/admin/survey/${id}`
        );
    }

    getList(input: IPageInput): Observable<IItemList<ISurvey>> {
        const offset = input.page * PAGINATOR_PAGE_SIZE;

        return this.httpClient.get<IItemList<ISurvey>>(
            `/admin/survey?sort=${input.sort}&order=${input.order}&limit=${PAGINATOR_PAGE_SIZE}&offset=${offset}&filter=${input.filter ? JSON.stringify(input.filter) : ''}`
        );
    }

    storeItem(input: ISurveyEditInput): Observable<ISurvey> {
        if (input.id) {
            return this.httpClient.put<ISurvey>(
                `/admin/survey/${input.id}`,
                input,
            );
        }

        return this.httpClient.post<ISurvey>(
            '/admin/survey',
            input,
        );
    }

    deleteItem(item: ISurvey): Observable<ISurvey> {
        return this.httpClient.delete<ISurvey>(
            `/admin/survey/${item.id}`
        );
    }

    exportToCsv(input: IPageInput): Observable<Blob> {
        return this.httpClient.get(
            `/admin/export/csv/survey?sort=${input.sort}&order=${input.order}&filter=${input.filter ? JSON.stringify(input.filter) : ''}`,
            { responseType: 'blob' }
        );
    }
}
