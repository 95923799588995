/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Component, EventEmitter, Output} from '@angular/core';
import {ToasterService} from 'angular2-toaster';
import {TranslateService} from '@ngx-translate/core';
import {AbstractControl, FormControl, Validators} from '@angular/forms';
import {LoginService} from '../../../services/login.service';
import {BaseForm} from '../../../classes/base-form';
import {Helper} from '../../../classes/helper';

@Component({
    selector: 'app-forgot-password-form',
    templateUrl: 'form.component.html'
})

export class ForgotPasswordFormComponent {
    @Output() public dataSent: EventEmitter<boolean> = new EventEmitter();

    error = '';
    email = new FormControl('', [Validators.required, Validators.email]);

    constructor(
        public loginService: LoginService,
        protected translate: TranslateService,
        private toasterService: ToasterService,
    ) {}

    recover() {
        this.error = '';

        this.loginService.forgotPassword(this.email.value.toLowerCase()).subscribe(() => {
            this.onSave();
        }, (response: any) => {
            if (null === response) {
                console.warn('Error. Can\'t send forgot password message');
                this.onFail();
            } else {
                if (undefined === response.error) {
                    return;
                }

                if (!!response.error.invalid) {
                    this.error = `forgot-password.form.errors.User with current email not exists`;
                }

                this.onFail();
            }
        });
    }

    onSave() {
        this.dataSent.emit(true);
    }

    onFail() {
        this.toasterService.pop('error', this.translate.instant('pop.Failed'), this.translate.instant('forgot-password.Failed to send email'));
    }

    getErrorMessage(control: AbstractControl): string {
        return Helper.getErrorMessage(control, this.translate);
    }
}
