<form
    [formGroup]="formGroup"
    novalidate autocomplete="off"
    class="flyer-accept-link-form-container"
    (ngSubmit)="submitForm(formGroup.value)">

    <mat-error *ngIf="formGroup.invalid">{{getFormErrorMessage()|translate}}</mat-error>
    <mat-error *ngIf="error.length">{{error|translate}}</mat-error>

    <div class="row">
        <div class="col-12">
            <p translate>flyer.accept-link.form.Please upload XLS, XLSX file with columns user_id, flyer_id. After file processed you will receive new file with flyer accept links.</p>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="mat-input">
                <label>{{'flyer.accept-link.form.file'|translate}}</label>
                <input
                    formControlName="file"
                    type="file"
                    (change)="onFileChange($event)"/>
                <div class="mat-error" *ngIf="formGroup.controls['file'].invalid">
                    {{getErrorMessage(formGroup.controls['file'])}}
                </div>
            </div>
        </div>
    </div>

    <div class="button-row d-flex justify-content-end">
        <button
            mat-raised-button
            color="primary"
            type="submit"
            [disabled]="!formGroup.valid">{{'flyer.accept-link.form.Generate'|translate}}</button>
    </div>
</form>
