/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */
import {NgxMatDateFormats} from '@angular-material-components/datetime-picker';

export const APP_LOGO = '/assets/images/logo.png';
export const APP_LANG_EN_IMAGE_PATH = '/assets/images/ln_en.svg';
export const APP_LANG_NL_IMAGE_PATH = '/assets/images/ln_nl.svg';

export const ERROR_API_INTERNAL_MESSAGE = 'API Internal error! Please contact to support if error will occur again.';

export const QUERY_ACCESS_TOKEN_KEY = 'access_token';

export const SESSION_LIFETIME = 1800; // 30 min session

// Language constants
export const LANG_EN = 'en';
export const LANG_NL = 'nl';
export const DEFAULT_LANGUAGE = LANG_EN;
export const LOCALE = 'en-US';
export const LOCALE_NL = 'nl';
export const AVAILABLE_LANGUAGES = [LANG_EN, LANG_NL];
export const LANGUAGE_LIST = [
    {
        id: LANG_EN,
        name: 'English'
    },
    {
        id: LANG_NL,
        name: 'Dutch'
    }
];
export const NETHERLANDS_CODE = 'NL';

export const DATE_FORMAT = 'dd.MM.yyyy';
export const DATE_FORMAT_SLASH_VERSION = 'dd/MM/yyyy';
export const DATE_FORMAT_MONTH_YEAR = 'MMM yyyy';
export const DATE_TIME_LONG_FORMAT = 'dd.MM.yyyy hh:mm:ss a';
export const DATE_TIME_SHORT_FORMAT = 'dd.MM.yyyy hh:mm a';
export const DATE_TIME_LONG_FORMAT_SLASH_VERSION = 'dd/MM/yyyy hh:mm a';
export const SERVER_TIME_ZONE = 1; // CET +1
export const SERVER_TIME_ZONE_DAYLIGHT_SAVING_TIME = 2; // CET +2 Summer time zone
export const MOMENT_DATE_FORMATS = {
    parse: {
        dateInput: 'DD.MM.YYYY',
    },
    display: {
        dateInput: 'DD.MM.YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'DD.MM.YYYY',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};
export const MOMENT_MONTH_DATE_FORMATS = {
    parse: {
        dateInput: 'MM/YYYY',
    },
    display: {
        dateInput: 'MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

export const SECONDS_IN_DAY = 86400;
export const SECONDS_IN_WEEK = 604800;

export const INTERCEPTOR_SKIP_HEADER = 'X-Skip-Interceptor';
export const INTERCEPTOR_SKIP_TRANSLATION_URL = '/assets/translations/';

export const USER_POLICY_OBJ_LIST = {
    'POLICY_INDEX_USER': 'POLICY_INDEX_USER',
    'POLICY_CREATE_USER': 'POLICY_CREATE_USER',
    'POLICY_VIEW_USER': 'POLICY_VIEW_USER',
    'POLICY_OWN_VIEW_USER': 'POLICY_OWN_VIEW_USER',
    'POLICY_EDIT_USER': 'POLICY_EDIT_USER',
    'POLICY_OWN_EDIT_USER': 'POLICY_OWN_EDIT_USER',
    'POLICY_DELETE_USER': 'POLICY_DELETE_USER',
    'POLICY_DASHBOARD': 'POLICY_DASHBOARD',
    'POLICY_SUBSCRIBE_FLYERS': 'POLICY_SUBSCRIBE_FLYERS',
    'POLICY_ROLE_VIEW_OWN': 'POLICY_ROLE_VIEW_OWN',

    // Admin policies
    'POLICY_ADMIN_POLICY_LIST': 'POLICY_ADMIN_POLICY_LIST',
    'POLICY_ADMIN_ROLE_VIEW': 'POLICY_ADMIN_ROLE_VIEW',
    'POLICY_ADMIN_ROLE_EDIT': 'POLICY_ADMIN_ROLE_EDIT',
    'POLICY_SWAGGER': 'POLICY_SWAGGER',
    'POLICY_FLYER_ACCEPT_LINK': 'POLICY_FLYER_ACCEPT_LINK',
    'POLICY_ADMIN_FLYER_EDIT': 'POLICY_ADMIN_FLYER_EDIT',
    'POLICY_ADMIN_INTEREST_EDIT': 'POLICY_ADMIN_INTEREST_EDIT',
    'POLICY_ADMIN_EXTRA_ITEM_EDIT': 'POLICY_ADMIN_EXTRA_ITEM_EDIT',
    'POLICY_ADMIN_FLYER_CATEGORY_EDIT': 'POLICY_ADMIN_FLYER_CATEGORY_EDIT',
    'POLICY_ADMIN_ADVERTISER_VIEW': 'POLICY_ADMIN_ADVERTISER_VIEW',
    'POLICY_ADMIN_ADVERTISER_EDIT': 'POLICY_ADMIN_ADVERTISER_EDIT',
    'POLICY_ADMIN_EXTRA_ITEM_ZIP_AREA_VIEW': 'POLICY_ADMIN_EXTRA_ITEM_ZIP_AREA_VIEW',
    'POLICY_ADMIN_EXTRA_ITEM_ZIP_AREA_EDIT': 'POLICY_ADMIN_EXTRA_ITEM_ZIP_AREA_EDIT',
    'POLICY_ADMIN_FLYER_ZIP_AREA_EDIT': 'POLICY_ADMIN_FLYER_ZIP_AREA_EDIT',
    'POLICY_ADMIN_FLYER_ZIP_AREA_VIEW': 'POLICY_ADMIN_FLYER_ZIP_AREA_VIEW',
    'POLICY_ADMIN_SURVEY_VIEW': 'POLICY_ADMIN_SURVEY_VIEW',
    'POLICY_ADMIN_SURVEY_EDIT': 'POLICY_ADMIN_SURVEY_EDIT',
    'POLICY_ADMIN_EDITION_EDIT': 'POLICY_ADMIN_EDITION_EDIT',
    'POLICY_ADMIN_EDITION_VIEW': 'POLICY_ADMIN_EDITION_VIEW',
    'POLICY_ADMIN_COMPLAINT_VIEW': 'POLICY_ADMIN_COMPLAINT_VIEW',
    'POLICY_ADMIN_COMPLAINT_EDIT': 'POLICY_ADMIN_COMPLAINT_EDIT',
    'POLICY_ADMIN_FLYER_EMAIL_OPTIN_EDIT': 'POLICY_ADMIN_FLYER_EMAIL_OPTIN_EDIT',
    'POLICY_ADMIN_FLYER_EMAIL_OPTIN_VIEW': 'POLICY_ADMIN_FLYER_EMAIL_OPTIN_VIEW',
    'POLICY_ADMIN_MESSAGE_EDIT': 'POLICY_ADMIN_MESSAGE_EDIT',
    'POLICY_ADMIN_IMPORT_USER': 'POLICY_ADMIN_IMPORT_USER',
    'POLICY_EXPORT_USER_FLYER_EXTRA': 'POLICY_EXPORT_USER_FLYER_EXTRA'
};

export const USER_ROLE_ID_LIST = {
    'GUEST': 0,
    'USER': 1,
    'MANAGER': 2,
    'ADMIN': 3,
    'ADVERTISER': 4
};

export const ROLE_GUEST = 'GUEST';

export const EVENT_SIDEBAR_TOGGLE = 'sidebar toggle';
export const EVENT_LEAD_URL_UPDATED = 'lead url updated';
export const EVENT_TITLE_UPDATED = 'navbar title updated';

export const PAGINATOR_PAGE_SIZE = 30;
export const PAGINATOR_PAGE_NUMBER_WITHOUT_PAGINATION = -1;

export const EXCLUDED_LOGIN_ROUTE_LIST = [
    'register',
    'password-recovery'
];

export const FORM_SCENARIO_EDIT = 'edit';
export const FORM_SCENARIO_CREATE = 'create';


export const THOUSAND = 1000;
export const MILLION = 1000000;

export const DAYS_OF_WEEK = {
    '1': 'Monday',
    '2': 'Tuesday',
    '3': 'Wednesday',
    '4': 'Thursday',
    '5': 'Friday',
    '6': 'Saturday',
    '7': 'Sunday'
};

export const NO_LAYOUT_ROUTE_LIST = [
    '/login',
    '/forgot-password',
    '/reset-password',
    '/404'
];

export const USER_STATUS_OBJ_LIST = {
    'DELETED': 'DELETED',
    'ACTIVE': 'ACTIVE',
    'PAUSED': 'PAUSED',
    'DISABLED': 'DISABLED',
    'HIDDEN': 'HIDDEN',
    'MODERATED': 'MODERATED'
};

export const USER_STATUS_ID_OBJ_LIST = {
    'DELETED': 0,
    'ACTIVE': 1,
    'PAUSED': 2,
    'DISABLED': 4,
    'HIDDEN': 5,
    'MODERATED': 6
};

export const ITEM_STATUS_ID_OBJ_LIST = {
    'DELETED': 0,
    'ACTIVE': 1,
    'PAUSED': 2,
    'DISABLED': 4,
    'HIDDEN': 5,
    'MODERATED': 6
};

export const ITEM_STATUS_OBJ_LIST = {
    'DELETED': 'DELETED',
    'ACTIVE': 'ACTIVE',
    'PAUSED': 'PAUSED',
    'DISABLED': 'DISABLED',
    'HIDDEN': 'HIDDEN',
    'MODERATED': 'MODERATED'
};

export const STATUS_OPTION_LIST = [
    {id: ITEM_STATUS_ID_OBJ_LIST.DELETED, name: ITEM_STATUS_OBJ_LIST.DELETED},
    {id: ITEM_STATUS_ID_OBJ_LIST.ACTIVE, name: ITEM_STATUS_OBJ_LIST.ACTIVE},
    {id: ITEM_STATUS_ID_OBJ_LIST.PAUSED, name: ITEM_STATUS_OBJ_LIST.PAUSED},
    {id: ITEM_STATUS_ID_OBJ_LIST.DISABLED, name: ITEM_STATUS_OBJ_LIST.DISABLED},
    {id: ITEM_STATUS_ID_OBJ_LIST.HIDDEN, name: ITEM_STATUS_OBJ_LIST.HIDDEN},
    {id: ITEM_STATUS_ID_OBJ_LIST.MODERATED, name: ITEM_STATUS_OBJ_LIST.MODERATED}
];

export const ITEM_FREQUENCY_ID_OBJ_LIST = {
    'WEEK': 7,
    'TWO_WEEKS': 14,
    'MONTH': 30,
    'THREE_MONTH': 99
};

export const ITEM_FREQUENCY_OBJ_LIST = {
    'WEEK': 'WEEK',
    'TWO_WEEKS': 'TWO_WEEKS',
    'MONTH': 'MONTH',
    'THREE_MONTH': 'THREE_MONTH'
};

export const ITEM_FREQUENCY_OPTION_LIST = [
    {id: ITEM_FREQUENCY_ID_OBJ_LIST.WEEK, name: ITEM_FREQUENCY_OBJ_LIST.WEEK},
    {id: ITEM_FREQUENCY_ID_OBJ_LIST.TWO_WEEKS, name: ITEM_FREQUENCY_OBJ_LIST.TWO_WEEKS},
    {id: ITEM_FREQUENCY_ID_OBJ_LIST.MONTH, name: ITEM_FREQUENCY_OBJ_LIST.MONTH},
    {id: ITEM_FREQUENCY_ID_OBJ_LIST.THREE_MONTH, name: ITEM_FREQUENCY_OBJ_LIST.THREE_MONTH}
];

export const ROOT_COMPANY_ID = 1; // Company with this ID couldn't be deleted

export const TEXT_FILE_EXTENSIONS: string[] = ['txt', 'xls', 'xlsx', 'doc', 'docx', 'pdf', 'json'];
export const IMAGE_FILE_EXTENSIONS: string[] = ['png', 'jpg', 'jpeg', 'svg'];
export const ARCHIVE_FILE_EXTENSIONS: string[] = ['zip', 'rar', 'csv'];
export const DATA_FILE_EXTENSIONS: string[] = ['r', 'py', 'asc', 'sql'];

export const EMBED_FILE_UPLOADER_MAX_FILES = 100;
export const EMBED_FILE_UPLOADER_MAX_FILE_SIZE = 256576000;

export const USER_SEX_ID_OBJ_LIST = {
    MALE: 'm',
    FEMALE: 'f',
    FAM: 'o'
};

export const USER_SEX_OBJ_LIST = {
    MALE: 'Male',
    FEMALE: 'Female',
    FAM: 'Fam'
};

export const YES_NO_ID_OBJ_LIST = {
    NO: 0,
    YES: 1
};

export const YES_NO_OBJ_LIST = {
    NO: 'No',
    YES: 'Yes'
};

export const YES_NO_OPTION_LIST = [
    {id: YES_NO_ID_OBJ_LIST.NO, name: YES_NO_OBJ_LIST.NO},
    {id: YES_NO_ID_OBJ_LIST.YES, name: YES_NO_OBJ_LIST.YES}
];

export const USER_RECEIVE_FLYER_ID_OBJ_LIST = {
    STICKER: 1,
    POOR_DELIVERY: 2,
    TO_MUCH: 3,
    YES: 4
};

export const USER_RECEIVE_FLYER_OBJ_LIST = {
    STICKER: 'No, i have a “sticker”',
    POOR_DELIVERY: 'No, delivery is poor',
    TO_MUCH: 'Yes, but to much',
    YES: 'Yes'
};

export const USER_RECEIVE_FLYER_OPTION_LIST = [
    {id: USER_RECEIVE_FLYER_ID_OBJ_LIST.STICKER, name: USER_RECEIVE_FLYER_OBJ_LIST.STICKER},
    {id: USER_RECEIVE_FLYER_ID_OBJ_LIST.POOR_DELIVERY, name: USER_RECEIVE_FLYER_OBJ_LIST.POOR_DELIVERY},
    {id: USER_RECEIVE_FLYER_ID_OBJ_LIST.TO_MUCH, name: USER_RECEIVE_FLYER_OBJ_LIST.TO_MUCH},
    {id: USER_RECEIVE_FLYER_ID_OBJ_LIST.YES, name: USER_RECEIVE_FLYER_OBJ_LIST.YES}
];

export const USER_SOURCE_ID_OBJ_LIST = {
    WEBSITE: 1,
    IMPORT: 2,
    PHONE: 3,
    WRITTEN: 4,
    MOBILE_APP: 5
}

export const USER_SOURCE_OBJ_LIST = {
    WEBSITE: 'Website',
    IMPORT: 'Import',
    PHONE: 'Phone',
    WRITTEN: 'Written',
    MOBILE_APP: 'Mobile app'
}

export const USER_SOURCE_OPTION_LIST = [
    {id: USER_SOURCE_ID_OBJ_LIST.WEBSITE, name: USER_SOURCE_OBJ_LIST.WEBSITE},
    {id: USER_SOURCE_ID_OBJ_LIST.IMPORT, name: USER_SOURCE_OBJ_LIST.IMPORT},
    {id: USER_SOURCE_ID_OBJ_LIST.PHONE, name: USER_SOURCE_OBJ_LIST.PHONE},
    {id: USER_SOURCE_ID_OBJ_LIST.WRITTEN, name: USER_SOURCE_OBJ_LIST.WRITTEN},
    {id: USER_SOURCE_ID_OBJ_LIST.MOBILE_APP, name: USER_SOURCE_OBJ_LIST.MOBILE_APP}
];

export const COMPLAINT_TYPE_ID_OBJ_LIST = {
    LATE_DELIVERY: 1,
    NOT_DELIVERED: 2,
    TYPE_DELIVERED_OLD: 3,
    TYPE_WRONG_ADDRESS: 4
};

export const COMPLAINT_TYPE_OBJ_LIST = {
    LATE_DELIVERY: 'LATE_DELIVERY',
    NOT_DELIVERED: 'NOT_DELIVERED',
    DELIVERED_OLD: 'DELIVERED_OLD',
    WRONG_ADDRESS: 'WRONG_ADDRESS'
};

export const COMPLAINT_TYPE_OPTION_LIST = [
    {id: COMPLAINT_TYPE_ID_OBJ_LIST.LATE_DELIVERY, name: COMPLAINT_TYPE_OBJ_LIST.LATE_DELIVERY},
    {id: COMPLAINT_TYPE_ID_OBJ_LIST.NOT_DELIVERED, name: COMPLAINT_TYPE_OBJ_LIST.NOT_DELIVERED},
    {id: COMPLAINT_TYPE_ID_OBJ_LIST.TYPE_DELIVERED_OLD, name: COMPLAINT_TYPE_OBJ_LIST.DELIVERED_OLD},
    {id: COMPLAINT_TYPE_ID_OBJ_LIST.TYPE_WRONG_ADDRESS, name: COMPLAINT_TYPE_OBJ_LIST.WRONG_ADDRESS},
];

export const SURVEY_QUESTION_TYPE_ID_OBJ_LIST = {
    OPEN: 1,
    SINGLE: 2,
    MULTIPLE: 3
};

export const SURVEY_QUESTION_TYPE_OBJ_LIST = {
    OPEN: 'OPEN',
    SINGLE: 'SINGLE',
    MULTIPLE: 'MULTIPLE'
};

export const SURVEY_QUESTION_TYPE_OPTION_LIST = [
    {id: SURVEY_QUESTION_TYPE_ID_OBJ_LIST.OPEN, name: SURVEY_QUESTION_TYPE_OBJ_LIST.OPEN},
    {id: SURVEY_QUESTION_TYPE_ID_OBJ_LIST.SINGLE, name: SURVEY_QUESTION_TYPE_OBJ_LIST.SINGLE},
    {id: SURVEY_QUESTION_TYPE_ID_OBJ_LIST.MULTIPLE, name: SURVEY_QUESTION_TYPE_OBJ_LIST.MULTIPLE}
];

export const SWAGGER_SPEC_FILE_URL_PATH = '/admin/swagger';

export const FLYER_LOGO_MAX_FILE_SIZE = 2097152; //2 Mib

export const MASK_POSTAL_CODE_UPPER_CASE = [/[1-9]/, /\d/, /\d/, /\d/, /[A-Z]/, /[A-Z]/];
export const MASK_POSTAL_CODE = [/[1-9]/, /\d/, /\d/, /\d/, /[a-zA-Z]/, /[a-zA-Z]/];
export const MASK_ZIP_AREA = [/[1-9]/, /\d/, /\d/, /\d/];

export const FILTER_BLOCK_TYPE_SELECT = 'select';
export const FILTER_BLOCK_TYPE_SEARCH_SELECT = 'search-select';
export const FILTER_BLOCK_TYPE_INPUT = 'input';
export const FILTER_BLOCK_TYPE_RANGE = 'range';
export const FILTER_BLOCK_TYPE_DATE_TIME = 'date-time';
export const FILTER_BLOCK_TYPE_DATE_TIME_RANGE = 'date-time-range';

export const DATEPICKER_FORMATS = {
    parse: {
        dateInput: 'DD.MM.YYYY'
    },
    display: {
        dateInput: 'DD.MM.YYYY',
        monthYearLabel: 'YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY'
    }
};

export const DATETIMEPICKER_FORMATS: NgxMatDateFormats = {
    parse: {
        dateInput: 'DD.MM.YYYY HH:mm'
    },
    display: {
        dateInput: 'DD.MM.YYYY HH:mm',
        monthYearLabel: "YYYY",
        dateA11yLabel: "LL",
        monthYearA11yLabel: "YYYY"
    }
};

export const TIMEZONE_TIME_SHIFT = 3600; // 1 hour
export const TIME_KEEP_BEFORE_CAN_SEND = 60; // 1 min
export const TIME_KEEP_BEFORE_CAN_EDIT_SEND = 240; // 4 min
