/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {
    FILTER_BLOCK_TYPE_DATE_TIME_RANGE,
    FILTER_BLOCK_TYPE_INPUT,
    FILTER_BLOCK_TYPE_SEARCH_SELECT,
    FILTER_BLOCK_TYPE_SELECT,
    ITEM_FREQUENCY_OPTION_LIST,
    STATUS_OPTION_LIST,
    USER_POLICY_OBJ_LIST, USER_RECEIVE_FLYER_OPTION_LIST, USER_SOURCE_OPTION_LIST,
    USER_STATUS_ID_OBJ_LIST,
    YES_NO_OPTION_LIST
} from '../../../constants';
import {Router} from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTable} from '@angular/material/table';
import {UserService} from '../../../services/user.service';
import {LoginService} from '../../../services/login.service';
import {IUser, IUserEditInput} from '../../../interfaces/user.interface';
import {BaseIndexComponent} from '../../../classes/base-index';
import {Helper} from '../../../classes/helper';
import {UrlService} from '@services/url.service';
import {IFilterConfig, IFilterValue} from '@interfaces/filter.interface';
import {StoreService} from '@services/store.service';
import {IColumn} from '@interfaces/column-filter.interface';

@Component({
    selector: 'app-user-index',
    templateUrl: 'index.component.html'
})
export class UserIndexComponent extends BaseIndexComponent<IUser, IUserEditInput> implements OnInit, AfterViewInit {
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatTable, {static: true}) table: MatTable<any>;

    tableName = 'userList';

    tableColumns: Array<IColumn> = [
        {
            name: 'id',
            title: 'item.fields.id'
        },
        {
            name: 'status_id',
            title: 'user.fields.status_id'
        },
        {
            name: 'first_name',
            title: 'user.fields.first_name'
        },
        {
            name: 'mail',
            title: 'user.fields.mail'
        },
        {
            name: 'phone',
            title: 'user.fields.phone'
        },
        {
            name: 'role_id',
            title: 'user.fields.role_id'
        },
        {
            name: 'created',
            title: 'user.fields.created'
        },
        {
            name: 'updated',
            title: 'user.fields.updated'
        },
        {
            name: 'action',
            title: 'item.fields.action'
        }
    ];

    statusDeleted = USER_STATUS_ID_OBJ_LIST.DELETED;

    baseRoute = '/user';
    itemHistoryKey = 'user';

    filterConfig: IFilterConfig = {
        title: 'common.Filter',
        blocks: [
            {
                title: 'user.fields.id',
                type: FILTER_BLOCK_TYPE_INPUT,
                propertyName: 'id'
            },
            {
                title: 'user.fields.first_name',
                type: FILTER_BLOCK_TYPE_INPUT,
                propertyName: 'first_name'
            },
            {
                title: 'user.fields.last_name',
                type: FILTER_BLOCK_TYPE_INPUT,
                propertyName: 'last_name'
            },
            {
                title: 'user.fields.mail',
                type: FILTER_BLOCK_TYPE_INPUT,
                propertyName: 'mail'
            },
            {
                title: 'user.fields.zip_code',
                type: FILTER_BLOCK_TYPE_INPUT,
                propertyName: 'zip_code'
            },
            /*{
                title: 'user.fields.city',
                type: FILTER_BLOCK_TYPE_INPUT,
                propertyName: 'city'
            },*/
            {
                title: 'user.fields.street_address',
                type: FILTER_BLOCK_TYPE_INPUT,
                propertyName: 'street_address'
            },
            {
                title: 'user.fields.building_number',
                type: FILTER_BLOCK_TYPE_INPUT,
                propertyName: 'building_number'
            },
            {
                title: 'user.fields.building_number_extra',
                type: FILTER_BLOCK_TYPE_INPUT,
                propertyName: 'building_number_extra'
            },
            {
                title: 'user.fields.receives_flyers',
                type: FILTER_BLOCK_TYPE_SELECT,
                propertyName: 'receives_flyers',
                items: USER_RECEIVE_FLYER_OPTION_LIST
            },
            {
                title: 'user.fields.send_sticker',
                type: FILTER_BLOCK_TYPE_SELECT,
                propertyName: 'send_sticker',
                items: YES_NO_OPTION_LIST
            },
            {
                title: 'user.fields.source_id',
                type: FILTER_BLOCK_TYPE_SELECT,
                propertyName: 'source_id',
                items: USER_SOURCE_OPTION_LIST
            },
            {
                title: 'user.fields.status_id',
                type: FILTER_BLOCK_TYPE_SELECT,
                propertyName: 'status_id',
                items: STATUS_OPTION_LIST
            },
            {
                placeholderFrom: 'user.fields.createdFrom',
                placeholderTo: 'user.fields.createdTo',
                type: FILTER_BLOCK_TYPE_DATE_TIME_RANGE,
                propertyName: 'created'
            },
            {
                placeholderFrom: 'user.fields.updatedFrom',
                placeholderTo: 'user.fields.updatedTo',
                type: FILTER_BLOCK_TYPE_DATE_TIME_RANGE,
                propertyName: 'updated'
            }
        ]
    };

    constructor(
        protected itemService: UserService,
        protected deleteDialog: MatDialog,
        protected router: Router,
        protected urlService: UrlService,
        private loginService: LoginService,
        protected storeServ: StoreService
    ) {
        super(itemService, deleteDialog, router, urlService, storeServ);
    }

    ngOnInit() {
        this.handleTableColumns();
    }

    ngAfterViewInit() {
        this.handleUrlQuery();
    }

    canDelete(user: IUser): boolean {
        return this.loginService.isGranted(USER_POLICY_OBJ_LIST.POLICY_DELETE_USER) && user.status_id != USER_STATUS_ID_OBJ_LIST.DELETED;
    }

    getStatusClass(statusId: number): string {
        return Helper.getUserStatusClass(statusId);
    }

    getStatusTranslationKey(statusId: number): string {
        return Helper.getUserStatusName(statusId);
    }
}
