<div class="fullscreen-overlay forgot-password-container" fxLayout="row" fxLayoutAlign="center center">
    <mat-card class="center-card">
        <mat-card-header>
            <mat-card-title>{{'forgot-password.Forgot password'|translate}}</mat-card-title>
        </mat-card-header>

        <mat-card-content fxLayout="row" fxLayoutAlign="space-between start">
            <div class="left-side" fxFlex="45%" fxLayout="column" fxLayoutAlign="center start">
                <img class="logo" src="/assets/images/logo.png" alt="Kiesjefolders"/>
                <p *ngIf="showStep === 1"
                    class="info"
                    translate>
                    forgot-password.Enter your E-mail address to receive a recovery link</p>

                <p *ngIf="showStep === 1"
                   class="info">
                    <a
                        routerLink="/login"
                        translate>forgot-password.Back to login</a>
                    </p>
            </div>

            <div class="right-side" fxFlex="45%" fxLayout="column" fxLayoutAlign="center center">
                <app-forgot-password-form
                    *ngIf="showStep === 1"
                    class="w-100"
                    (dataSent)="onDataSent()"></app-forgot-password-form>

                <p *ngIf="showStep === 2"
                   class="info mt-4"
                   translate>forgot-password.We have sent you an e-mail with which you can reset your password. Please check your spam box if necessary.</p>
            </div>
        </mat-card-content>
    </mat-card>
</div>
