import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {IColumn} from '@interfaces/column-filter.interface';

@Component({
    selector: 'app-table-column-filter',
    templateUrl: 'filter.component.html'
})

export class TableColumnFilterComponent {
    @Input() active: Array<IColumn>;
    @Input() available: Array<IColumn>;
    @Output() changed: EventEmitter<Array<IColumn>> = new EventEmitter();
    @ViewChild( CdkDropList, {static: true}) activeList: CdkDropList;
    @ViewChild( CdkDropList, {static: true}) availableList: CdkDropList;

    drop(event: CdkDragDrop<string[]>) {
        console.log(event);

        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex,
            );
        }

        this.changed.emit(this.activeList.data);
    }
}
