/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Component} from '@angular/core';
import {
    FORM_SCENARIO_EDIT
} from '../../../constants';
import {FlyerCategoryCreateComponent} from '../create/create.component';


@Component({
    selector: 'app-flyer-category-update',
    templateUrl: 'update.component.html'
})
export class FlyerCategoryUpdateComponent {
    formScenario = FORM_SCENARIO_EDIT;
}
