/**
 * 2019 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2019 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';
import {getFilesSize} from '../../functions';
import {
    ARCHIVE_FILE_EXTENSIONS,
    DATA_FILE_EXTENSIONS,
    EMBED_FILE_UPLOADER_MAX_FILE_SIZE,
    EMBED_FILE_UPLOADER_MAX_FILES,
    IMAGE_FILE_EXTENSIONS,
    TEXT_FILE_EXTENSIONS
} from '../../constants';


@Component({
    selector: 'app-embed-file-uploader',
    templateUrl: './embed-file-uploader.component.html',
    styleUrls: ['./embed-file-uploader.component.scss']
})
export class EmbedFileUploaderComponent implements OnInit, AfterViewInit {
    @Input() showedAsPopup: boolean;
    @Input() adminUserId: string;
    @Input() allowedFileExtensions: string[] = _.concat(
        TEXT_FILE_EXTENSIONS,
        IMAGE_FILE_EXTENSIONS,
        ARCHIVE_FILE_EXTENSIONS,
        DATA_FILE_EXTENSIONS
    );
    @Input() fileList: Array<File> = [];
    @Input() maxFilesNumber = EMBED_FILE_UPLOADER_MAX_FILES;
    @Input() maxSingleFileSize = EMBED_FILE_UPLOADER_MAX_FILE_SIZE;
    @Output() attachSuccess: EventEmitter<File> = new EventEmitter();
    @Output() fileListChange: EventEmitter<Array<File>> = new EventEmitter();
    @Output() attachError: EventEmitter<Array<string>> = new EventEmitter();
    @ViewChild('fileInput', {static: true}) fileInput: ElementRef;

    errorList: Array<string> = [];

    private allowedExtensionsForErrors: any;

    constructor(
        private translateService: TranslateService,
    ) {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.allowedExtensionsForErrors = _.map(this.allowedFileExtensions).join(', .');
    }

    onFileSelected(event) {
        if (_.isEmpty(event.target.files)) {
            return;
        }

        this.handleFile(<File>event.target.files[0]);
        this.fileInput.nativeElement.value = '';
    }

    onDragOver(evt) {
        evt.preventDefault();
        evt.stopPropagation();
    }

    onDragLeave(evt) {
        evt.preventDefault();
        evt.stopPropagation();
    }

    onDrop(evt) {
        evt.preventDefault();
        evt.stopPropagation();

        const files = evt.dataTransfer.files;
        if (files.length > 0) {
            this.handleFile(files[0]);
        }
    }

    removeAttachedFile(file: File): void {
        const newList: Array<File> = [];
        this.fileList.forEach((item: File) => {
            if (file !== item) {
                newList.push(item);
            }
        });

        this.fileList = newList;
        this.fileListChange.emit(this.fileList);
    }

    getFilesSize(size: number): string {
        return getFilesSize(size);
    }

    private handleFile(file: File) {
        if (null === file) {
            return;
        }

        this.clearErrorsList();

        if (this.checkFileForErrors(file) && this.checkForTheFileLimit() && this.checkReportForFileDuplicates(file)) {
            this.fileList.push(file);
            this.onAttachSuccess(file);
        } else {
            this.onAttachError(this.errorList);
        }
    }

    private onAttachSuccess(file: File): void {
        this.attachSuccess.emit(file);
        this.fileListChange.emit(this.fileList);
    }

    private onAttachError(error: Array<string>): void {
        this.attachError.emit(error);
    }

    private validateFile(file: File): any {
        if (_.isNull(file)) {
            return null;
        }

        if (!_.isUndefined(this.allowedFileExtensions) && this.allowedFileExtensions.indexOf(file.type) === -1) {
            return {
                imageFile: {
                    error: 'acceptedFiles',
                    value: this.allowedFileExtensions
                }
            };
        }

        if (!_.isUndefined(this.maxSingleFileSize) && this.maxSingleFileSize < file.size) {
            return {
                imageFile: {
                    error: 'maxFileSize',
                    value: this.translateService.instant('embed-file-uploader.Max file size exceed')
                }
            };
        }

        return null;
    }

    private clearErrorsList(): void {
        this.errorList.length = 0;
    }

    private checkFileForErrors(file): boolean {
        let flag = true;
        const ext = file.name.split('.').pop();

        if (_.indexOf(this.allowedFileExtensions, ext) === -1) {
            this.errorList.push(
                `${this.translateService.instant('embed-file-transfer.upload.File')}
                 '${file.name}'
                  ${this.translateService.instant('embed-file-transfer.upload.has wrong/unsupported extension. Please try uploading file with different extension instead:')}
                  .${this.allowedExtensionsForErrors}.`
            );
            flag = false;
        }

        if (file && file.size > this.maxSingleFileSize) {
            this.errorList.push(
                `${this.translateService.instant('embed-file-transfer.upload.File')}
                '${file.name}'
                 ${this.translateService.instant('embed-file-transfer.upload.is too big. File should not be more than')}
                 250 MB.`
            );
            flag = false;
        }

        return flag;
    }

    private checkReportForFileDuplicates(file): boolean {
        if (this.fileList.length === 0) {
            return true;
        } else {
            let flag = true;

            for (const key in this.fileList) {
                if (file.name === this.fileList[key].name
                    && file.size === this.fileList[key].size) {

                    flag = false;
                }
            }

            return flag;
        }
    }

    private checkForTheFileLimit(): boolean {
        return this.fileList.length < this.maxFilesNumber;
    }
}
