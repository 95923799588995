/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LoginService} from '../../services/login.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: 'reset-password.component.html'
})
export class ResetPasswordComponent implements OnInit {
    showStep = 0;
    resetSuccessfully = false;
    error = '';
    accessToken = '';

    constructor(
        public loginService: LoginService,
        private route: ActivatedRoute,
        private router: Router,
    ) {}

    ngOnInit() {
        this.route.paramMap.subscribe(params => {
            this.accessToken = params.get('accessToken');

            if (!this.accessToken.length) {
                this.router.navigate(['/404']);
            } else {
                this.checkChangePasswordHash();
            }
        }, err => {
            console.error(err);
        });
    }

    checkChangePasswordHash() {
        this.loginService.checkAccessToken(this.accessToken).subscribe(() => {
            this.showStep = 1;
        }, () => {
            this.router.navigate(['/404']);
        });
    }

    onPasswordReset() {
        this.resetSuccessfully = true;
        this.showStep = 2;
    }

    onInvalidHash() {
        this.resetSuccessfully = false;
        this.showStep = 2;
    }
}
