/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {PAGINATOR_PAGE_SIZE} from '../constants';
import {IPageInput} from '@interfaces/page-input.interface';
import {IItemList} from '@interfaces/item-list.interface';
import {IMessage, IMessageEditInput} from '@interfaces/message.interface';

@Injectable({
    providedIn: 'root',
})

export class MessageService {
    constructor(private httpClient: HttpClient) {}

    getItem(id: number): Observable<IMessage> {
        return this.httpClient.get<IMessage>(
            `/admin/message/${id}`
        );
    }

    getList(input: IPageInput): Observable<IItemList<IMessage>> {
        const offset = input.page * PAGINATOR_PAGE_SIZE;

        return this.httpClient.get<IItemList<IMessage>>(
            `/admin/message/?sort=${input.sort}&order=${input.order}&limit=${PAGINATOR_PAGE_SIZE}&offset=${offset}&filter=${input.filter ? JSON.stringify(input.filter) : ''}`
        );
    }

    storeItem(input: IMessageEditInput): Observable<IMessage> {
        const formData = new FormData();

        if (null !== input.image) {
            formData.append('image', input.image, input.image.name);
        }
        if (null !== input.users) {
            formData.append('users', input.users, input.users.name);
        }

        formData.append('data', JSON.stringify(input.data));

        if (input.data.id) {
            return this.httpClient.put<IMessage>(
                `/admin/message/${input.data.id}`,
                formData,
            );
        }

        return this.httpClient.post<IMessage>(
            '/admin/message',
            formData,
        );
    }

    deleteItem(item: IMessage): Observable<IMessage> {
        return this.httpClient.delete<IMessage>(
            `/admin/message/${item.id}`
        );
    }
}
