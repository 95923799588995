import {AbstractControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {
    ITEM_STATUS_ID_OBJ_LIST,
    ITEM_STATUS_OBJ_LIST,
    USER_STATUS_ID_OBJ_LIST,
    USER_STATUS_OBJ_LIST
} from '../constants';
import {IPageInput} from '@interfaces/page-input.interface';
import {IItemExportService, IItemService} from '@interfaces/item-service.interface';

/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

export class Helper {
    static getErrorMessage(control: AbstractControl, translate: TranslateService): string {
        if (control.hasError('required')) {
            return translate.instant('validators.required');
        }

        if (control.hasError('email')) {
            return translate.instant('validators.email');
        }

        if (control.hasError('minlength')) {
            return translate.instant('validators.minlength', {value: control.errors.minlength.requiredLength});
        }

        if (control.hasError('integer')) {
            return translate.instant('validators.integer');
        }

        if (control.hasError('api_invalid')) {
            return control.errors['api_invalid'];
        }

        if (control.hasError('patternWithFormat')) {
            return translate.instant('validators.patternWithFormat', {value: control.errors.patternWithFormat.format});
        }

        if (control.hasError('acceptedFiles')) {
            return translate.instant('validators.acceptedFiles', {value: control.errors.acceptedFiles.acceptedFiles});
        }

        if (control.hasError('maxFileSize')) {
            return translate.instant('validators.maxFileSize', {value: control.errors.maxFileSize.maxFileSize});
        }

        if (control.hasError('minImageWidth')) {
            return translate.instant('validators.minImageWidth', {value: control.errors.minImageWidth.minImageWidth});
        }

        if (control.hasError('minImageHeight')) {
            return translate.instant('validators.minImageHeight', {value: control.errors.minImageHeight.minImageHeight});
        }

        if (control.hasError('maxImageWidth')) {
            return translate.instant('validators.maxImageWidth', {value: control.errors.maxImageWidth.maxImageWidth});
        }

        if (control.hasError('maxImageHeight')) {
            return translate.instant('validators.maxImageHeight', {value: control.errors.maxImageHeight.maxImageHeight});
        }

        return  '';
    }

    static getItemStatusClass(statusId: number): string {
        switch (statusId) {
            case ITEM_STATUS_ID_OBJ_LIST.DISABLED:
            case ITEM_STATUS_ID_OBJ_LIST.PAUSED:
            case ITEM_STATUS_ID_OBJ_LIST.HIDDEN:
                return 'yellow';
            case ITEM_STATUS_ID_OBJ_LIST.MODERATED:
                return 'blue';
            case ITEM_STATUS_ID_OBJ_LIST.DELETED:
                return 'red';
            default: // ITEM_STATUS_ID_OBJ_LIST.ACTIVE
                return 'green';
        }
    }

    static getItemStatusName(statusId: number): string {
        switch (statusId) {
            case ITEM_STATUS_ID_OBJ_LIST.DISABLED:
                return ITEM_STATUS_OBJ_LIST.DISABLED;
            case ITEM_STATUS_ID_OBJ_LIST.DELETED:
                return ITEM_STATUS_OBJ_LIST.DELETED;
            case ITEM_STATUS_ID_OBJ_LIST.PAUSED:
                return ITEM_STATUS_OBJ_LIST.PAUSED;
            case ITEM_STATUS_ID_OBJ_LIST.HIDDEN:
                return ITEM_STATUS_OBJ_LIST.HIDDEN;
            case ITEM_STATUS_ID_OBJ_LIST.MODERATED:
                return ITEM_STATUS_OBJ_LIST.MODERATED;
            default: // ITEM_STATUS_ID_OBJ_LIST.ACTIVE
                return ITEM_STATUS_OBJ_LIST.ACTIVE;
        }
    }

    static getUserStatusClass(statusId: number): string {
        switch (statusId) {
            case USER_STATUS_ID_OBJ_LIST.DISABLED:
            case USER_STATUS_ID_OBJ_LIST.PAUSED:
            case USER_STATUS_ID_OBJ_LIST.HIDDEN:
                return 'yellow';
            case USER_STATUS_ID_OBJ_LIST.MODERATED:
                return 'blue';
            case USER_STATUS_ID_OBJ_LIST.DELETED:
                return 'red';
            default: // USER_STATUS_ID_OBJ_LIST.ACTIVE
                return 'green';
        }
    }

    static getUserStatusName(statusId: number): string {
        switch (statusId) {
            case USER_STATUS_ID_OBJ_LIST.DISABLED:
                return USER_STATUS_OBJ_LIST.DISABLED;
            case USER_STATUS_ID_OBJ_LIST.DELETED:
                return USER_STATUS_OBJ_LIST.DELETED;
            case USER_STATUS_ID_OBJ_LIST.PAUSED:
                return USER_STATUS_OBJ_LIST.PAUSED;
            case USER_STATUS_ID_OBJ_LIST.HIDDEN:
                return USER_STATUS_OBJ_LIST.HIDDEN;
            case USER_STATUS_ID_OBJ_LIST.MODERATED:
                return USER_STATUS_OBJ_LIST.MODERATED;
            default: // USER_STATUS_ID_OBJ_LIST.ACTIVE
                return USER_STATUS_OBJ_LIST.ACTIVE;
        }
    }

    static exportToCsv(input: IPageInput, itemService: IItemExportService, fileName: string): void {
        const exportInput: IPageInput = {
            filter: input.filter,
            order: input.order,
            sort: input.sort,
            limit: 0,
            page: 0
        }

        itemService.exportToCsv(exportInput).subscribe((data: Blob) => {
            const blob = new Blob([data], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.download = fileName;
            anchor.href = url;
            anchor.click();
            URL.revokeObjectURL(url);
        }, () => console.log('Error downloading the file.'));
    }
}
