/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {
    DATE_TIME_LONG_FORMAT,
    FILTER_BLOCK_TYPE_DATE_TIME_RANGE,
    FILTER_BLOCK_TYPE_INPUT,
    FILTER_BLOCK_TYPE_SELECT,
    ITEM_STATUS_ID_OBJ_LIST,
    STATUS_OPTION_LIST,
    USER_POLICY_OBJ_LIST
} from '@app/constants';
import {getCurrentTimeZone} from '@app/functions';
import {Router} from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTable} from '@angular/material/table';
import {LoginService} from '@services/login.service';
import {BaseIndexComponent} from '@classes/base-index';
import {UrlService} from '@services/url.service';
import {IFilterConfig, IFilterValue, ISelectOption} from '@interfaces/filter.interface';
import {Helper} from '@classes/helper';
import {FlyerService} from '@services/flyer.service';
import {ISurvey, ISurveyEditInput} from '@interfaces/survey.interface';
import {SurveyService} from '@services/survey.service';
import {StoreService} from '@services/store.service';
import {IColumn} from '@interfaces/column-filter.interface';

@Component({
    selector: 'app-survey-index',
    templateUrl: 'index.component.html'
})
export class SurveyIndexComponent extends BaseIndexComponent<ISurvey, ISurveyEditInput> implements OnInit, AfterViewInit {
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatTable, {static: true}) table: MatTable<any>;

    tableName = 'surveyList';

    tableColumns: Array<IColumn> = [
        {
            name: 'id',
            title: 'item.fields.id'
        },
        {
            name: 'status_id',
            title: 'survey.fields.status_id'
        },
        {
            name: 'flyer_id',
            title: 'survey.fields.flyer_id'
        },
        {
            name: 'description',
            title: 'survey.fields.description'
        },
        {
            name: 'start',
            title: 'survey.fields.start'
        },
        {
            name: 'end',
            title: 'survey.fields.end'
        },
        {
            name: 'created',
            title: 'survey.fields.created'
        },
        {
            name: 'updated',
            title: 'survey.fields.updated'
        },
        {
            name: 'action',
            title: 'item.fields.action'
        }
    ];

    itemList: Array<ISurvey> = [];
    dateTimeLongFormat = DATE_TIME_LONG_FORMAT;
    timeZone = getCurrentTimeZone();
    statusDeleted = ITEM_STATUS_ID_OBJ_LIST.DELETED;

    baseRoute = '/survey';
    itemHistoryKey = 'survey';

    filterConfig: IFilterConfig;

    constructor(
        protected itemService: SurveyService,
        protected deleteDialog: MatDialog,
        protected router: Router,
        protected urlService: UrlService,
        private loginService: LoginService,
        private flyerService: FlyerService,
        protected storeServ: StoreService
    ) {
        super(itemService, deleteDialog, router, urlService, storeServ);

        this.flyerService.getList({
            sort: 'title',
            order: 'asc',
            page: 0,
            filter: {}
        }).subscribe((list) => {
            const itemList: Array<ISelectOption> = list.rows.map((flyer) => {
                return {
                    id: flyer.id.toString(),
                    name: flyer.title
                }
            });

            this.filterConfig = {
                title: 'common.Filter',
                blocks: [
                    {
                        title: 'item.fields.id',
                        type: FILTER_BLOCK_TYPE_INPUT,
                        propertyName: 'id'
                    },
                    {
                        title: 'survey.fields.status_id',
                        type: FILTER_BLOCK_TYPE_SELECT,
                        propertyName: 'status_id',
                        items: STATUS_OPTION_LIST
                    },
                    {
                        title: 'survey.fields.flyer_id',
                        type: FILTER_BLOCK_TYPE_SELECT,
                        propertyName: 'flyer_id',
                        items: itemList
                    },
                    {
                        placeholderFrom: 'survey.fields.startFrom',
                        placeholderTo: 'survey.fields.startTo',
                        type: FILTER_BLOCK_TYPE_DATE_TIME_RANGE,
                        propertyName: 'start'
                    },
                    {
                        placeholderFrom: 'survey.fields.endFrom',
                        placeholderTo: 'survey.fields.endTo',
                        type: FILTER_BLOCK_TYPE_DATE_TIME_RANGE,
                        propertyName: 'end'
                    },
                    {
                        placeholderFrom: 'survey.fields.createdFrom',
                        placeholderTo: 'survey.fields.createdTo',
                        type: FILTER_BLOCK_TYPE_DATE_TIME_RANGE,
                        propertyName: 'created'
                    },
                    {
                        placeholderFrom: 'survey.fields.updatedFrom',
                        placeholderTo: 'survey.fields.updatedTo',
                        type: FILTER_BLOCK_TYPE_DATE_TIME_RANGE,
                        propertyName: 'updated'
                    }
                ]
            };
        })
    }

    ngOnInit() {
        this.handleTableColumns();
    }

    ngAfterViewInit() {
        this.handleUrlQuery();
    }

    canDelete(item: ISurvey): boolean {
        return this.loginService.isGranted(USER_POLICY_OBJ_LIST.POLICY_ADMIN_SURVEY_EDIT) && item.status_id != ITEM_STATUS_ID_OBJ_LIST.DELETED;
    }

    toggleFilter(): void {
        this.displayFilter = !this.displayFilter;
    }

    onFilterChanged(filter: IFilterValue): void {
        this.input.filter = filter;

        this.filterChanged.emit(filter);
    }

    exportToCsv(): void {
        Helper.exportToCsv(this.input, this.itemService, 'survey_export.csv');
    }
}
