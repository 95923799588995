<div class="extra-item index-container">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{'extra-item.index.Title'|translate}}</mat-card-title>
            <button mat-mini-fab (click)="exportToCsv()" title="{{'common.Export'|translate}}">
                <mat-icon>cloud_download</mat-icon>
            </button>
            <button mat-mini-fab (click)="toggleColumnFilter()" title="{{'common.Column filter'|translate}}">
                <mat-icon>swap_horiz</mat-icon>
            </button>
            <button mat-mini-fab (click)="toggleFilter()" title="{{'common.Filter'|translate}}">
                <mat-icon>filter_alt</mat-icon>
            </button>
        </mat-card-header>

        <mat-card-content>
            <div class="row">
                <app-table-filter *ngIf="displayFilter"
                    [config]="filterConfig"
                    [filter]="input.filter"
                    (filterChanged)="onFilterChanged($event)"></app-table-filter>

                <app-table-column-filter *ngIf="displayColumnFilter"
                    [available]="availableColumns"
                    [active]="activeColumns"
                    (changed)="onColumnFilterChanged($event)"></app-table-column-filter>
            </div>

            <div class="table-container">
                <mat-table
                    [dataSource]="itemList"
                    matSort
                    matSortActive="id"
                    matSortDisableClear
                    matSortDirection="desc"
                    class="v-scrollable">

                    <ng-container matColumnDef="id">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>item.fields.id</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.id}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="advertiser_id">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>extra-item.fields.advertiser_id</span></mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            {{row.Advertiser ? row.Advertiser.company : ''}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="status_id">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>extra-item.fields.status_id</span></mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <span class="status" [ngClass]="getStatusClass(row.status_id)" translate>{{'status.' + getStatusTranslationKey(row.status_id)}}</span>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="title">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>extra-item.fields.title</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.title}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="description_short">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>extra-item.fields.description_short</span></mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.description_short}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="preselect">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>extra-item.fields.preselect</span></mat-header-cell>
                        <mat-cell *matCellDef="let row">{{(row.preselect ? 'common.Yes' : 'common.No')|translate}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="step">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>extra-item.fields.step</span></mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.step}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="opt_in">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>extra-item.fields.opt_in</span></mat-header-cell>
                        <mat-cell *matCellDef="let row">{{(row.opt_in ? 'common.Yes' : 'common.No')|translate}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="frequency">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>extra-item.fields.frequency</span></mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.frequency}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="code">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>extra-item.fields.code</span></mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.code}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="created">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>extra-item.fields.created</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.created | date : dateTimeLongFormat : timeZone}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="updated">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>extra-item.fields.updated</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.updated | date : dateTimeLongFormat : timeZone}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="action">
                        <mat-header-cell *matHeaderCellDef class="action-cell">
                            <a
                                class="c-pointer"
                                title="{{'actions.create'|translate}}"
                                routerLink="/extra-item/create">
                                <mat-icon>add_circle</mat-icon>
                            </a>
                        </mat-header-cell>

                        <mat-cell
                            *matCellDef="let row; let ind = index"
                            class="action-cell">

                            <a
                                class="c-pointer"
                                *ngIf="row.status != statusDeleted"
                                title="{{'actions.view'|translate}}"
                                (click)="viewSelected(row)">
                                <mat-icon>visibility</mat-icon>
                            </a>
                            <a
                                class="c-pointer"
                                *ngIf="row.status != statusDeleted"
                                title="{{'actions.update'|translate}}"
                                (click)="updateSelected(row)">
                                <mat-icon>edit</mat-icon>
                            </a>
                            <a
                                class="c-pointer"
                                *ngIf="canDelete(row)"
                                title="{{'actions.delete'|translate}}"
                                (click)="deleteSelected(row)">
                                <mat-icon>delete</mat-icon>
                            </a>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="c-pointer"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>

                <mat-paginator [length]="resultsLength" [pageSize]="25" [hidePageSize]="true"></mat-paginator>
            </div>
        </mat-card-content>
    </mat-card>
</div>



