<div class="fullscreen-overlay forgot-password-container" fxLayout="row" fxLayoutAlign="center center">
    <mat-card
        class="center-card"
        [hidden]="showStep === 0">

        <mat-error *ngIf="error.length">{{error|translate}}</mat-error>

        <mat-card-header>
            <mat-card-title>{{'reset-password.Reset password'|translate}}</mat-card-title>
        </mat-card-header>

        <mat-card-content fxLayout="row" fxLayoutAlign="space-between start">
            <div class="left-side" fxFlex="45%" fxLayout="column" fxLayoutAlign="center start">
                <img class="logo" src="/assets/images/logo.png" alt="Kiesjefolders"/>
                <p *ngIf="showStep === 1"
                    class="info"
                    translate>reset-password.Please fill form to reset password</p>
            </div>

            <div class="right-side" fxFlex="45%" fxLayout="column" fxLayoutAlign="center center">
                <app-reset-password-form
                    *ngIf="showStep === 1"
                    class="w-100"
                    [accessToken]="accessToken"
                    (passwordChanged)="onPasswordReset()"
                    (invalidHash)="onInvalidHash()"></app-reset-password-form>

                <div *ngIf="showStep === 2">
                    <p *ngIf="resetSuccessfully" translate class="info mt-4">reset-password.Congratulations! Your password has been reset.</p>

                    <p *ngIf="!resetSuccessfully" class="info">
                        <span translate>reset-password.Invalid or expired link. Please go to</span>
                        &nbsp;<a routerLink="/forgot-password" translate>reset-password.Forgot password</a>
                        &nbsp;<span translate>reset-password.and try again</span>
                    </p>

                    <div class="form-row d-flex-justify button-container">
                        <a
                            *ngIf="resetSuccessfully"
                            mat-raised-button
                            color="primary"
                            routerLink="/login"
                            routerLinkActive="active">{{'reset-password.Login'|translate}}</a>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
