<div class="role view-container">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{'role.view.Title'|translate}}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <div class="row">

                <div class="col-4">
                    <h3>{{'item.fields.id'|translate}}</h3>
                    <div>{{item.id}}</div>
                </div>

                <div class="col-4">
                    <h3>{{'role.fields.role'|translate}}</h3>
                    <div>{{item.role}}</div>
                </div>
            </div>
            <hr>

            <div class="row">
                <div class="col-6">
                    <h3>{{'role.fields.policiesList'|translate}}</h3>
                    <ng-container *ngFor="let policy of item.policiesList">
                        {{policy}}<br/>
                    </ng-container>
                </div>
            </div>
            <hr>

            <div class="row">
                <div class="col-6">
                    <h3>{{'role.fields.createdAt'|translate}}</h3>
                    <div>{{item.createdAt | date : dateTimeLongFormat : timeZone}}</div>
                </div>

                <div class="col-6">
                    <h3>{{'role.fields.updatedAt'|translate}}</h3>
                    <div>{{item.updatedAt | date : dateTimeLongFormat : timeZone}}</div>
                </div>
            </div>
            <hr>

            <div class="button-row d-flex justify-content-end mt-3">
                <button *ngIf="canEdit()"
                    mat-raised-button
                    color="primary"
                    (click)="updateSelected(item)">{{'actions.update'|translate}}</button>

                <button *ngIf="canEdit()"
                    mat-raised-button
                    color="warn"
                    (click)="deleteSelected(item)">{{'actions.delete'|translate}}</button>

                <button *ngIf="canIndex()"
                    mat-raised-button
                    (click)="backToIndex()">{{'actions.Back to list'|translate}}</button>
            </div>


        </mat-card-content>
    </mat-card>
</div>



