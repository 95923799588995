import {ROLE_GUEST} from '../constants';

/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

export interface IRole {
    id: number;
    role: string;
    policiesList: Array<string>;
    createdAt: string;
    updatedAt: string;
}

export interface IRoleEditInput {
    id: number;
    role: string;
    policiesList: Array<string>;
}

export const ROLE_WITH_EMPTY_DATA: IRole = {
    id: 0,
    role: ROLE_GUEST,
    policiesList: [ROLE_GUEST],
    createdAt: '',
    updatedAt: ''
}
