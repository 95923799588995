/**
 * 2020 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2020 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Component, OnInit} from '@angular/core';
import {LoginService} from '../../../services/login.service';
import {EventService} from '../../../services/event.service';
import {Router} from '@angular/router';
import * as _ from 'lodash';
import {
    EVENT_SIDEBAR_TOGGLE,
    EVENT_TITLE_UPDATED,
} from '../../../constants';


@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html'
})
export class NavbarComponent implements OnInit {
    toggled: boolean = false;
    navBarTitle: string = '';

    constructor(
        public loginService: LoginService,
        public eventService: EventService,
        public router: Router,
    ) {
        this.router.events.subscribe(s => {
            this.toggled = false;
        }, err => {
            console.error(err);
        });

        this.eventService.event$.subscribe((data) => {
            if (!_.isEmpty(data.event) && EVENT_TITLE_UPDATED === data.event){
                this.navBarTitle = data.title;
            }
        });
    }

    ngOnInit() {
    }

    toggleSidebar(event: Event) {
        this.toggled = !this.toggled;
        this.eventService.event({event: EVENT_SIDEBAR_TOGGLE});
    }
}
