<div class="filter-container">
    <h2>{{config.title|translate}}
        <!--<mat-icon
            (click)="onClose()"
            class="close-sidebar-icon c-pointer"
            title="{{'filter.Close'|translate}}">close</mat-icon>-->
    </h2>

    <div
        *ngFor="let block of config.blocks"
        [class]="getBlockClass(block)"
        [style.grow]="block.grow">

        <mat-form-field  *ngIf="block.type === blockTypeInput">
            <mat-label>{{block.title|translate}}</mat-label>
            <input
                (change)="onFilterChanged(block.propertyName, $event.target.value)"
                [value]="getFilterValue(block.propertyName)"
                [textMask]="getBlockMask(block)"
                matInput
                placeholder="{{block.placeholder|translate}}">
            <span *ngIf="block.valueSuffix"
                matSuffix>&nbsp;{{block.valueSuffix}}</span>
        </mat-form-field>

        <div *ngIf="block.type === blockTypeSelect">
<!--            <mat-label class="custom-mat-label">{{block.title|translate}}</mat-label>-->
            <label class="custom-mat-label" [id]="'label-' + block.propertyName">{{block.title|translate}}</label>
            <ng-select
                (change)="onFilterChanged(block.propertyName, $event ? $event.id : '')"
                (clear)="onFilterCleared(block.propertyName)"
                [items]="block.items"
                [virtualScroll]="true"
                bindLabel="name"
                bindValue="id"
                [labelForId]="'label-' + block.propertyName"
                [(ngModel)]="filter[block.propertyName]">
            </ng-select>
        </div>

        <div *ngIf="block.type === blockTypeSearchSelect">
<!--            <mat-label>{{block.title|translate}}</mat-label>-->
            <label
                class="custom-mat-label"
                [id]="'label-' + block.propertyName">
                {{block.title|translate}}
            </label>
            <ng-select
                (change)="onFilterChanged(block.propertyName, $event ? $event.id : '')"
                (clear)="onFilterCleared(block.propertyName)"
                (input)="updateBlockSearchItemList(block, $event)"
                [(ngModel)]="filter[block.propertyName]"
                [items]="block.items"
                [searchable]="true"
                [virtualScroll]="true"
                [labelForId]="'label-' + block.propertyName"
                bindLabel="name"
                bindValue="id">
                {{filter[block.propertyName]}}
            </ng-select>
        </div>

        <div *ngIf="block.type === blockTypeRange"
             class="range-container">

            <div class="min">
                <mat-form-field>
                    <mat-label>{{block.titleMin|translate}}</mat-label>
                    <input
                        (change)="onFilterChanged(block.propertyName + '.min', $event.target.value)"
                        [value]="getRangeValue(block.propertyName, 'min')"
                        matInput
                        placeholder="{{block.placeholderMin|translate}}">
                    <span *ngIf="block.valueSuffix"
                          matSuffix>&nbsp;{{block.valueSuffix}}</span>
                </mat-form-field>
            </div>
            <div class="max">
                <mat-form-field>
                    <mat-label>{{block.titleMax|translate}}</mat-label>
                    <input
                        (change)="onFilterChanged(block.propertyName + '.max', $event.target.value)"
                        [value]="getRangeValue(block.propertyName, 'max')"
                        matInput
                        placeholder="{{block.placeholderMax|translate}}">
                    <span *ngIf="block.valueSuffix"
                          matSuffix>&nbsp;{{block.valueSuffix}}</span>
                </mat-form-field>
            </div>
        </div>

        <mat-form-field *ngIf="block.type === blockTypeDateTime">
            <mat-label>{{block.title|translate}}</mat-label>
            <input
                (dateChange)="onFilterDateChanged(block.propertyName, $event ? $event.value : '')"
                [matDatepicker]="dateTime"
                [ngModel]="filterDateList[block.propertyName]"
                matInput
                placeholder="{{block.placeholder|translate}}">

            <mat-datepicker-toggle [for]="dateTime" matSuffix>
                <app-icon-date-time></app-icon-date-time>
            </mat-datepicker-toggle>

            <mat-datepicker #dateTime [startAt]="block.startDate"></mat-datepicker>
        </mat-form-field>

        <div *ngIf="block.type === blockTypeDateTimeRange"
            class="range-container">

            <mat-form-field class="date-from">
                <input
                    (dateChange)="onFilterDateRangeChanged(block.propertyName, 'from', $event ? $event.value : '')"
                    [matDatepicker]="dateFrom"
                    [(ngModel)]="filterDateList[block.propertyName]['from']"
                    matInput
                    placeholder="{{block.placeholderFrom|translate}}">
                <mat-datepicker-toggle [for]="dateFrom" matSuffix>
                    <app-icon-date-time></app-icon-date-time>
                </mat-datepicker-toggle>
                <mat-datepicker #dateFrom [startAt]="block.startDateFrom"></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="date-to">
                <input
                    (dateChange)="onFilterDateRangeChanged(block.propertyName, 'to', $event ? $event.value : '')"
                    [matDatepicker]="dateTo"
                    [(ngModel)]="filterDateList[block.propertyName]['to']"
                    matInput
                    placeholder="{{block.placeholderTo|translate}}">
                <mat-datepicker-toggle [for]="dateTo" matSuffix>
                    <app-icon-date-time></app-icon-date-time>
                </mat-datepicker-toggle>
                <mat-datepicker #dateTo [startAt]="block.startDateTo"></mat-datepicker>
            </mat-form-field>
        </div>

    </div>

    <div class="filter-actions">
        <button mat-raised-button color="primary" (click)="onApply()">{{'filter.Apply'|translate}}</button>
        <button mat-raised-button (click)="onClear()">{{'filter.Clear'|translate}}</button>
    </div>

</div>

<hr/>
