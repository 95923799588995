/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ToasterService} from 'angular2-toaster';
import {
    ITEM_STATUS_ID_OBJ_LIST,
    ITEM_STATUS_OBJ_LIST,
    FORM_SCENARIO_CREATE,
    FORM_SCENARIO_EDIT
} from '@app/constants';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseForm} from '@classes/base-form';
import {environment} from '../../../../environments/environment';
import {EDITION_WITH_EMPTY_DATA, IEdition, IEditionEditInput} from '@interfaces/edition.interface';
import {IFlyer} from '@interfaces/flyer.interface';
import {EditionService} from '@services/edition.service';
import {FlyerService} from '@services/flyer.service';


@Component({
    selector: 'app-edition-form',
    templateUrl: 'form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditionFormComponent extends BaseForm<IEdition, IEditionEditInput> implements OnInit {
    @Input() scenario: string;
    @Output() dataSaved: EventEmitter<IEdition> = new EventEmitter();

    item: IEdition = {...EDITION_WITH_EMPTY_DATA};
    backLink = '/edition/index';
    error = '';

    scenarioCreate = FORM_SCENARIO_CREATE; // Need for component HTML usage
    scenarioEdit = FORM_SCENARIO_EDIT; // Need for component HTML usage

    formGroup: FormGroup = null;

    statusList = [
        {id: ITEM_STATUS_ID_OBJ_LIST.DELETED, name: ITEM_STATUS_OBJ_LIST.DELETED},
        {id: ITEM_STATUS_ID_OBJ_LIST.ACTIVE, name: ITEM_STATUS_OBJ_LIST.ACTIVE},
        {id: ITEM_STATUS_ID_OBJ_LIST.PAUSED, name: ITEM_STATUS_OBJ_LIST.PAUSED},
        {id: ITEM_STATUS_ID_OBJ_LIST.DISABLED, name: ITEM_STATUS_OBJ_LIST.DISABLED},
        {id: ITEM_STATUS_ID_OBJ_LIST.HIDDEN, name: ITEM_STATUS_OBJ_LIST.HIDDEN},
        {id: ITEM_STATUS_ID_OBJ_LIST.MODERATED, name: ITEM_STATUS_OBJ_LIST.MODERATED},
    ];

    flyerList: Array<IFlyer> = [];

    startDate = new Date();

    private pdfFile: File = null;
    private pdfFileChanged = false;

    constructor(
        protected translate: TranslateService,
        protected itemService: EditionService,
        protected toasterService: ToasterService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected changeDetectorRef: ChangeDetectorRef,
        private formBuilder: FormBuilder,
        private flyerService: FlyerService
    ) {
        super(translate, toasterService, route, router, changeDetectorRef, itemService);

        this.buildFormGroup();
    }

    ngOnInit() {
        this.handleItemFromRequestOrHistory('edition');
    }

    fillFormData() {
        this.updateFlyerList();

        const formData = {
            status_id: this.item.status_id,
            title: this.item.title,
            size: this.item.size,
            pages: this.item.pages,
            weight: this.item.weight,
            pricing: this.item.pricing,
            flyer_id: this.item.flyer_id,
            date_start: this.item.date_start,
            date_end: this.item.date_end
        };

        this.formGroup.reset(formData);
    }

    getFormErrorMessage(): string {
        return ''; //TODO: add handler
    }

    submitForm(data: any): void {
        this.error = '';

        const input = this.getInputFromFormData(data);

        this.itemService.storeItem(input).subscribe((item) => {
            this.item = item;
            this.onSave();
        }, result => this.handleRequestError(result, 'Edition'), () => {
            this.goBack();
        });
    }

    getInputFromFormData(data: any): IEditionEditInput {
        return {
            id: this.item.id,
            status_id: data.status_id,
            flyer_id: data.flyer_id,
            title: data.title,
            size: data.size,
            pages: data.pages,
            weight: data.weight,
            pricing: data.pricing,
            date_start: data.date_start,
            date_end: data.date_end,
            pdf: 1
        };
    }

    onUploadError(event: any): void {
        this.pdfFile = null;

        if (environment.production) {
            console.warn('Image upload failed!');
        } else {
            console.warn('Image upload failed!', event);
        }
    }

    onUploadSuccess(file: File): void {
        this.pdfFile = file;
        this.pdfFileChanged = true;
    }

    onUploadCancel(): void {
        this.pdfFile = null;
    }

    private buildFormGroup() {
        this.formGroup = this.formBuilder.group({
            status_id: ['', Validators.compose([
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(255)
            ])],
            title: ['', Validators.compose([
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(255)
            ])],
            size: ['', Validators.compose([
                Validators.minLength(1),
                Validators.maxLength(255)
            ])],
            pages: ['', Validators.compose([
                Validators.minLength(1),
                Validators.maxLength(255)
            ])],
            weight: ['', Validators.compose([
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(255)
            ])],
            pricing: ['', Validators.compose([
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(255)
            ])],
            flyer_id: ['', Validators.compose([
                Validators.required,
                Validators.maxLength(255)
            ])],
            date_start: ['', Validators.compose([
                Validators.minLength(1),
                Validators.maxLength(255)
            ])],
            date_end: ['', Validators.compose([
                Validators.minLength(1),
                Validators.maxLength(255)
            ])],
        });
    }

    private updateFlyerList(): void {
        this.flyerService.getList({
            sort: 'title',
            order: 'asc',
            page: 0,
            filter: {}
        }).subscribe((list) => {
            this.flyerList = list.rows;

            this.changeDetectorRef.detectChanges();
        });
    }
}
