/**
 * 2022 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2022 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */
import {IUser} from '@interfaces/user.interface';

export interface IFlyerEmailOptin {
    id: number;
    flyer_id: number;
    user_id: number;
    User?: IUser;
}

export const FLYER_EMAIL_OPTIN_WITH_EMPTY_DATA: IFlyerEmailOptin = {
    id: 0,
    flyer_id: 0,
    user_id: 0
};

export interface IFlyerEmailOptinEditInput {
    id?: number;
    flyer_id: number;
    user_id: number;
}

