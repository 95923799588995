/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {
    ITEM_STATUS_ID_OBJ_LIST,
    ITEM_STATUS_OBJ_LIST,
    USER_POLICY_OBJ_LIST
} from '../../../constants';
import {LoginService} from '../../../services/login.service';
import {TranslateService} from '@ngx-translate/core';
import {FLYER_WITH_EMPTY_DATA, IFlyer, IFlyerEditInput} from '../../../interfaces/flyer.interface';
import {FlyerService} from '../../../services/flyer.service';
import {BaseViewComponent} from '../../../classes/base-view';

@Component({
    selector: 'app-flyer-view',
    templateUrl: 'view.component.html'
})
export class FlyerViewComponent extends BaseViewComponent<IFlyer, IFlyerEditInput> implements OnInit {
    item: IFlyer = {...FLYER_WITH_EMPTY_DATA};
    baseRoute = '/flyer';
    itemHistoryKey = 'flyer';

    constructor(
        protected itemService: FlyerService,
        protected translate: TranslateService,
        protected deleteDialog: MatDialog,
        protected router: Router,
        protected route: ActivatedRoute,
        private loginService: LoginService
    ) {
        super(itemService, translate, deleteDialog, router, route);
    }

    ngOnInit() {
        this.handleItemFromRequestOrHistory();
    }

    canIndex(): boolean {
        return this.loginService.isGranted(USER_POLICY_OBJ_LIST.POLICY_ADMIN_FLYER_EDIT);
    }

    canDelete(): boolean {
        return this.loginService.isGranted(USER_POLICY_OBJ_LIST.POLICY_ADMIN_FLYER_EDIT)
            && this.item.status_id != ITEM_STATUS_ID_OBJ_LIST.DELETED;
    }

    getCategoryList(item: IFlyer): string {
        return item.FlyerCategories.map((category) => category.name).join('<br/>');
    }
}
