/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {
    ITEM_STATUS_ID_OBJ_LIST,
    ITEM_STATUS_OBJ_LIST, SURVEY_QUESTION_TYPE_ID_OBJ_LIST, SURVEY_QUESTION_TYPE_OBJ_LIST,
    USER_POLICY_OBJ_LIST
} from '@app/constants';
import {LoginService} from '@services/login.service';
import {TranslateService} from '@ngx-translate/core';
import {BaseViewComponent} from '@classes/base-view';
import {
    ISurvey,
    ISurveyEditInput,
    ISurveyQuestion,
    ISurveyQuestionEditInput, SURVEY_QUESTION_WITH_EMPTY_DATA,
    SURVEY_WITH_EMPTY_DATA
} from '@interfaces/survey.interface';
import {SurveyService} from '@services/survey.service';
import {SurveyQuestionService} from '@services/survey-question.service';

@Component({
    selector: 'app-survey-question-view',
    templateUrl: 'view.component.html'
})
export class SurveyQuestionViewComponent extends BaseViewComponent<ISurveyQuestion, ISurveyQuestionEditInput> implements OnInit {
    item: ISurveyQuestion = {...SURVEY_QUESTION_WITH_EMPTY_DATA};
    baseRoute = '/survey-question';
    itemHistoryKey = 'surveyQuestion';

    constructor(
        protected itemService: SurveyQuestionService,
        protected translate: TranslateService,
        protected deleteDialog: MatDialog,
        protected router: Router,
        protected route: ActivatedRoute,
        private loginService: LoginService
    ) {
        super(itemService, translate, deleteDialog, router, route);
    }

    ngOnInit() {
        this.handleItemFromRequestOrHistory();
    }

    canIndex(): boolean {
        return this.loginService.isGranted(USER_POLICY_OBJ_LIST.POLICY_ADMIN_SURVEY_EDIT);
    }

    canDelete(): boolean {
        return this.loginService.isGranted(USER_POLICY_OBJ_LIST.POLICY_ADMIN_SURVEY_EDIT);
    }

    getTypeTranslationKey(typeId: number) {
        switch (typeId) {
            case SURVEY_QUESTION_TYPE_ID_OBJ_LIST.OPEN:
                return SURVEY_QUESTION_TYPE_OBJ_LIST.OPEN;
            case SURVEY_QUESTION_TYPE_ID_OBJ_LIST.SINGLE:
                return SURVEY_QUESTION_TYPE_OBJ_LIST.SINGLE;
            default: // SURVEY_QUESTION_TYPE_ID_OBJ_LIST.MULTIPLE
                return SURVEY_QUESTION_TYPE_OBJ_LIST.MULTIPLE;
        }
    }

    backToIndex(): void {
        this.router.navigate([`/survey/${this.item.survey_id}`]);
    }
}
