/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {
    ITEM_STATUS_ID_OBJ_LIST
} from '../constants';
import {IFlyer} from '@interfaces/flyer.interface';

export interface IEdition {
    id: number;
    status_id: number;
    flyer_id: number;
    title: string;
    size: number|null;
    pages: number|null;
    weight: number;
    date_start: string|null;
    date_end: string|null;
    pdf: number|null;
    pricing: number;
    Flyer?: IFlyer;
}

export const EDITION_WITH_EMPTY_DATA: IEdition = {
    id: 0,
    status_id: ITEM_STATUS_ID_OBJ_LIST.DISABLED,
    flyer_id: 0,
    title: '',
    size: null,
    pages: null,
    weight: 0,
    date_start: null,
    date_end: null,
    pdf: null,
    pricing: 0
};

export interface IEditionEditInput {
    id: number;
    status_id: number;
    flyer_id: number;
    title: string;
    size: number;
    pages: number;
    weight: number;
    date_start: string;
    date_end: string;
    pdf: number;
    pricing: number;
}

