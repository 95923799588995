/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Component} from '@angular/core';

@Component({
    selector: 'app-login',
    templateUrl: 'login.component.html',
})

export class LoginComponent {
}
