<div class="image-upload"
    (dragover)="onDragOver($event)"
    (dragleave)="onDragLeave($event)"
    (drop)="ondrop($event)"
>
    <div class="upload-container">
        <input
            type="file"
            (change)="onFileSelected($event)"
            style="display: none"
            [accept]="acceptedFiles"
            #fileInput
        >
        <div
            class=" text-center message"
            (click)="onUploadClick(); fileInput.click();"
        >
            <div class="">
                <mat-icon>cloud_upload</mat-icon>
            </div>
<!--            <div class="text__header" translate>Upload your photo</div>-->
            <div class="text">{{config.uploadText|translate}}</div>
            <mat-error *ngIf="hasControl() && control.invalid">{{getErrorMessage(control)}}</mat-error>
        </div>

    </div>

    <div
        class="preview"
        [hidden]="!hasValidImage"
        (click)="onPreviewClick();"
    >
        <img [src]="previewSrc" #previewImg/>
    </div>

    <mat-icon
        class="clear"
        *ngIf="hasValidImage"
        (click)="onUploadCancel()"
    >cancel
    </mat-icon>
</div>
