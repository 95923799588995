/**
 * 2022 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2022 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ToasterService} from 'angular2-toaster';
import {
    FORM_SCENARIO_CREATE,
    FORM_SCENARIO_EDIT
} from '@app/constants';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseForm} from '@classes/base-form';
import {
    FLYER_EMAIL_OPTIN_WITH_EMPTY_DATA,
    IFlyerEmailOptin,
    IFlyerEmailOptinEditInput
} from '@interfaces/flyer-email-optin.interface';
import {FlyerEmailOptinService} from '@services/extra-email-optin.service';


@Component({
    selector: 'app-flyer-email-optin-form',
    templateUrl: 'form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlyerEmailOptinFormComponent extends BaseForm<IFlyerEmailOptin, IFlyerEmailOptinEditInput> implements OnInit {
    item: IFlyerEmailOptin = {...FLYER_EMAIL_OPTIN_WITH_EMPTY_DATA};
    backLink = '/flyer-email-optin/index';
    error = '';

    scenarioCreate = FORM_SCENARIO_CREATE; // Need for component HTML usage
    scenarioEdit = FORM_SCENARIO_EDIT; // Need for component HTML usage

    formGroup: FormGroup = null;

    constructor(
        protected translate: TranslateService,
        protected itemService: FlyerEmailOptinService,
        protected toasterService: ToasterService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected changeDetectorRef: ChangeDetectorRef,
        private formBuilder: FormBuilder
    ) {
        super(translate, toasterService, route, router, changeDetectorRef, itemService);

        this.buildFormGroup();
    }

    ngOnInit() {
        this.handleItemFromRequestOrHistory('advertiser');
    }

    fillFormData() {
        const formData = {
            user_id: this.item.user_id,
            flyer_id: this.item.flyer_id
        };

        this.formGroup.reset(formData);
    }

    getFormErrorMessage(): string {
        return ''; // TODO: Add handler
    }

    submitForm(data: any): void {
        this.error = '';

        const input = this.getInputFromFormData(data);

        this.itemService.storeItem(input).subscribe((item) => {
            this.item = item;
            this.onSave();
        }, result => this.handleRequestError(result, 'FlyerEmailOptin'), () => {
            this.goBack();
        });
    }

    getInputFromFormData(data: any): IFlyerEmailOptinEditInput {
        return {
            id: this.item.id,
            flyer_id: data.flyer_id,
            user_id: data.user_id
        };
    }

    private buildFormGroup() {
        this.formGroup = this.formBuilder.group({
            flyer_id: ['', Validators.compose([
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(255)
            ])],
            user_id: ['', Validators.compose([
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(255)
            ])]
        });
    }
}
