/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {PAGINATOR_PAGE_SIZE} from '../constants';
import {IPageInput} from '@interfaces/page-input.interface';
import {IItemList} from '@interfaces/item-list.interface';
import {IAdvertiser, IAdvertiserEditInput} from '@interfaces/advertiser.interface';
import {ISelectOption} from '@interfaces/filter.interface';

@Injectable({
    providedIn: 'root',
})

export class AdvertiserService {
    constructor(private httpClient: HttpClient) {}

    getItem(id: number): Observable<IAdvertiser> {
        return this.httpClient.get<IAdvertiser>(
            `/admin/advertiser/${id}`
        );
    }

    getList(input: IPageInput): Observable<IItemList<IAdvertiser>> {
        const offset = input.page * PAGINATOR_PAGE_SIZE;

        return this.httpClient.get<IItemList<IAdvertiser>>(
            `/admin/advertiser/?sort=${input.sort}&order=${input.order}&limit=${PAGINATOR_PAGE_SIZE}&offset=${offset}`
        );
    }

    storeItem(input: IAdvertiserEditInput): Observable<IAdvertiser> {
        if (input.id) {
            return this.httpClient.put<IAdvertiser>(
                `/admin/advertiser/${input.id}`,
                input,
            );
        }

        return this.httpClient.post<IAdvertiser>(
            '/admin/advertiser',
            input,
        );
    }

    deleteItem(item: IAdvertiser): Observable<IAdvertiser> {
        return this.httpClient.delete<IAdvertiser>(
            `/admin/advertiser/${item.id}`
        );
    }

    getListByCompany(company: string): Observable<IItemList<IAdvertiser>> {
        return this.httpClient.get<IItemList<IAdvertiser>>(
            `/admin/advertiser/suggestion?q=${company}`,
        );
    }

    getOptionListByCompany(search: string): Observable<Array<ISelectOption>> {
        return new Observable<Array<ISelectOption>>((observer) => {
            this.getListByCompany(search).subscribe((data) => {
                observer.next(data.rows.map((item) => {
                    return {
                        id: item.id.toString(),
                        name: item.company
                    }
                }));
            }, () => {
                observer.next([]);
            }, () => (observer.complete()));
        });
    }
}
