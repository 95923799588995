/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

export interface IFlyerCategory {
    id: number;
    name: string;
    description: string;
    icon: string;
}

export const FLYER_CATEGORY_WITH_EMPTY_DATA: IFlyerCategory = {
    id: 0,
    name: '',
    description: '',
    icon: '',
};

export interface IFlyerCategoryEditInput {
    id: number;
    name: string;
    description: number;
    icon: string;
}

