<div class="edition update-container">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{'edition.update.Title'|translate}}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <app-edition-form [scenario]="formScenario"></app-edition-form>
        </mat-card-content>
    </mat-card>
</div>
