/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AccessService} from './services/access.service';
import {HomeComponent} from './components/home/home.component';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {LoginComponent} from './components/login/login.component';
import {
    ROLE_GUEST,
    USER_POLICY_OBJ_LIST
} from './constants';
import {DashBoardComponent} from './components/dashboard/dashboard.component';
import {LogoutComponent} from './components/logout/logout.component';
import {ForgotPasswordComponent} from './components/forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './components/reset-password/reset-password.component';
import {UserUpdateComponent} from './components/user/update/update.component';
import {UserCreateComponent} from './components/user/create/create.component';
import {UserIndexComponent} from './components/user/index/index.component';
import {UserViewComponent} from './components/user/view/view.component';
import {RoleUpdateComponent} from './components/role/update/update.component';
import {RoleCreateComponent} from './components/role/create/create.component';
import {RoleIndexComponent} from './components/role/index/index.component';
import {RoleViewComponent} from './components/role/view/view.component';
import {SwaggerComponent} from './components/swagger/swagger.component';
import {FlyerAcceptLinkFormComponent} from './components/flyer/accept-link/form.component';
import {FlyerIndexComponent} from './components/flyer/index/index.component';
import {FlyerViewComponent} from './components/flyer/view/view.component';
import {FlyerCategoryUpdateComponent} from './components/flyer-category/update/update.component';
import {FlyerCategoryCreateComponent} from './components/flyer-category/create/create.component';
import {FlyerCategoryIndexComponent} from './components/flyer-category/index/index.component';
import {FlyerCategoryViewComponent} from './components/flyer-category/view/view.component';
import {FlyerUpdateComponent} from './components/flyer/update/update.component';
import {FlyerCreateComponent} from './components/flyer/create/create.component';
import {ExtraItemUpdateComponent} from './components/extra-item/update/update.component';
import {ExtraItemCreateComponent} from './components/extra-item/create/create.component';
import {ExtraItemIndexComponent} from './components/extra-item/index/index.component';
import {ExtraItemViewComponent} from './components/extra-item/view/view.component';
import {InterestUpdateComponent} from './components/interest/update/update.component';
import {InterestCreateComponent} from './components/interest/create/create.component';
import {InterestIndexComponent} from '@components/interest/index/index.component';
import {InterestViewComponent} from '@components/interest/view/view.component';
import {MessageUpdateComponent} from '@components/message/update/update.component';
import {MessageCreateComponent} from '@components/message/create/create.component';
import {MessageIndexComponent} from '@components/message/index/index.component';
import {MessageViewComponent} from '@components/message/view/view.component';
import {EditionUpdateComponent} from '@components/edition/update/update.component';
import {EditionCreateComponent} from '@components/edition/create/create.component';
import {EditionIndexComponent} from '@components/edition/index/index.component';
import {EditionViewComponent} from '@components/edition/view/view.component';
import {ComplaintUpdateComponent} from '@components/complaint/update/update.component';
import {ComplaintCreateComponent} from '@components/complaint/create/create.component';
import {ComplaintIndexComponent} from '@components/complaint/index/index.component';
import {ComplaintViewComponent} from '@components/complaint/view/view.component';
import {SurveyUpdateComponent} from '@components/survey/update/update.component';
import {SurveyCreateComponent} from '@components/survey/create/create.component';
import {SurveyIndexComponent} from '@components/survey/index/index.component';
import {SurveyViewComponent} from '@components/survey/view/view.component';
import {SurveyQuestionUpdateComponent} from '@components/survey/question/update/update.component';
import {SurveyQuestionCreateComponent} from '@components/survey/question/create/create.component';
import {SurveyQuestionViewComponent} from '@components/survey/question/view/view.component';
import {SurveyAnswerIndexComponent} from '@components/survey/answer/index/index.component';
import {UserImportFormComponent} from '@components/user/import/form.component';
import {AdvertiserUpdateComponent} from '@components/advertiser/update/update.component';
import {AdvertiserCreateComponent} from '@components/advertiser/create/create.component';
import {AdvertiserIndexComponent} from '@components/advertiser/index/index.component';
import {AdvertiserViewComponent} from '@components/advertiser/view/view.component';
import {FlyerEmailOptinUpdateComponent} from '@components/flyer-email-optin/update/update.component';
import {FlyerEmailOptinIndexComponent} from '@components/flyer-email-optin/index/index.component';

const appRoutes: Routes = [
    {
        path: 'flyer/accept-link',
        component: FlyerAcceptLinkFormComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_FLYER_ACCEPT_LINK]
        }
    },
    {
        path: 'role/update/:id',
        component: RoleUpdateComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_ROLE_EDIT]
        }
    },
    {
        path: 'role/create',
        component: RoleCreateComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_ROLE_EDIT]
        }
    },
    {
        path: 'role/index',
        component: RoleIndexComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_ROLE_VIEW]
        }
    },
    {
        path: 'role/:id',
        component: RoleViewComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_ROLE_VIEW]
        }
    },
    {
        path: 'user/import',
        component: UserImportFormComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_IMPORT_USER]
        }
    },
    {
        path: 'user/update/:id',
        component: UserUpdateComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_OWN_EDIT_USER, USER_POLICY_OBJ_LIST.POLICY_EDIT_USER]
        }
    },
    {
        path: 'user/create',
        component: UserCreateComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_CREATE_USER]
        }
    },
    {
        path: 'user/index',
        component: UserIndexComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_INDEX_USER]
        }
    },
    {
        path: 'user/:id',
        component: UserViewComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_VIEW_USER, USER_POLICY_OBJ_LIST.POLICY_OWN_VIEW_USER]
        }
    },
    {
        path: 'interest/update/:id',
        component: InterestUpdateComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_INTEREST_EDIT]
        }
    },
    {
        path: 'interest/create',
        component: InterestCreateComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_INTEREST_EDIT]
        }
    },
    {
        path: 'interest/index',
        component: InterestIndexComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_INTEREST_EDIT]
        }
    },
    {
        path: 'interest/:id',
        component: InterestViewComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_INTEREST_EDIT]
        }
    },
    {
        path: 'extra-item/update/:id',
        component: ExtraItemUpdateComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_EXTRA_ITEM_EDIT]
        }
    },
    {
        path: 'extra-item/create',
        component: ExtraItemCreateComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_EXTRA_ITEM_EDIT]
        }
    },
    {
        path: 'extra-item/index',
        component: ExtraItemIndexComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_EXTRA_ITEM_EDIT]
        }
    },
    {
        path: 'extra-item/:id',
        component: ExtraItemViewComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_EXTRA_ITEM_EDIT]
        }
    },
    {
        path: 'flyer-category/update/:id',
        component: FlyerCategoryUpdateComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_FLYER_CATEGORY_EDIT]
        }
    },
    {
        path: 'flyer-category/create',
        component: FlyerCategoryCreateComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_FLYER_CATEGORY_EDIT]
        }
    },
    {
        path: 'flyer-category/index',
        component: FlyerCategoryIndexComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_FLYER_CATEGORY_EDIT]
        }
    },
    {
        path: 'flyer-category/:id',
        component: FlyerCategoryViewComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_FLYER_CATEGORY_EDIT]
        }
    },
    {
        path: 'flyer/update/:id',
        component: FlyerUpdateComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_FLYER_EDIT]
        }
    },
    {
        path: 'flyer/create',
        component: FlyerCreateComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_FLYER_EDIT]
        }
    },
    {
        path: 'flyer/index',
        component: FlyerIndexComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_FLYER_EDIT]
        }
    },
    {
        path: 'flyer/:id',
        component: FlyerViewComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_FLYER_EDIT]
        }
    },
    {
        path: 'advertiser/update/:id',
        component: AdvertiserUpdateComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_ADVERTISER_EDIT]
        }
    },
    {
        path: 'advertiser/create',
        component: AdvertiserCreateComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_ADVERTISER_EDIT]
        }
    },
    {
        path: 'advertiser/index',
        component: AdvertiserIndexComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_ADVERTISER_VIEW]
        }
    },
    {
        path: 'advertiser/:id',
        component: AdvertiserViewComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_ADVERTISER_VIEW]
        }
    },
    {
        path: 'flyer-email-optin/update/:id',
        component: FlyerEmailOptinUpdateComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_FLYER_EMAIL_OPTIN_EDIT]
        }
    },
    {
        path: 'flyer-email-optin/index',
        component: FlyerEmailOptinIndexComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_FLYER_EMAIL_OPTIN_VIEW]
        }
    },
    {
        path: 'message/update/:id',
        component: MessageUpdateComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_MESSAGE_EDIT]
        }
    },
    {
        path: 'message/create',
        component: MessageCreateComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_MESSAGE_EDIT]
        }
    },
    {
        path: 'message/index',
        component: MessageIndexComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_MESSAGE_EDIT]
        }
    },
    {
        path: 'message/:id',
        component: MessageViewComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_MESSAGE_EDIT]
        }
    },
    {
        path: 'edition/update/:id',
        component: EditionUpdateComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_EDITION_EDIT]
        }
    },
    {
        path: 'edition/create',
        component: EditionCreateComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_EDITION_EDIT]
        }
    },
    {
        path: 'edition/index',
        component: EditionIndexComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_EDITION_EDIT]
        }
    },
    {
        path: 'edition/:id',
        component: EditionViewComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_EDITION_EDIT]
        }
    },
    {
        path: 'complaint/update/:id',
        component: ComplaintUpdateComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_COMPLAINT_EDIT]
        }
    },
    {
        path: 'complaint/create',
        component: ComplaintCreateComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_COMPLAINT_EDIT]
        }
    },
    {
        path: 'complaint/index',
        component: ComplaintIndexComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_COMPLAINT_EDIT]
        }
    },
    {
        path: 'complaint/:id',
        component: ComplaintViewComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_COMPLAINT_EDIT]
        }
    },
    {
        path: 'survey/update/:id',
        component: SurveyUpdateComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_SURVEY_EDIT]
        }
    },
    {
        path: 'survey/create',
        component: SurveyCreateComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_SURVEY_EDIT]
        }
    },
    {
        path: 'survey/index',
        component: SurveyIndexComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_SURVEY_EDIT]
        }
    },
    {
        path: 'survey/:id',
        component: SurveyViewComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_SURVEY_EDIT]
        }
    },
    {
        path: 'survey-question/update/:id',
        component: SurveyQuestionUpdateComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_SURVEY_EDIT]
        }
    },
    {
        path: 'survey-question/create/:id',
        component: SurveyQuestionCreateComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_SURVEY_EDIT]
        }
    },
    {
        path: 'survey-question/:id',
        component: SurveyQuestionViewComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_SURVEY_EDIT]
        }
    },
    {
        path: 'survey-answer',
        component: SurveyAnswerIndexComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_ADMIN_SURVEY_EDIT]
        }
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        canActivate: [AccessService],
        data: {
            policies: [ROLE_GUEST]
        }
    },
    {
        path: 'reset-password/:accessToken',
        component: ResetPasswordComponent,
        canActivate: [AccessService],
        data: {
            policies: [ROLE_GUEST]
        }
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [AccessService],
        data: {
            policies: [ROLE_GUEST]
        }
    },
    {
        path: 'logout',
        component: LogoutComponent,
        canActivate: [AccessService]
    },
    {
        path: 'dashboard',
        component: DashBoardComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_DASHBOARD]
        }
    },
    {
        path: 'swagger',
        component: SwaggerComponent,
        canActivate: [AccessService],
        data: {
            policies: [USER_POLICY_OBJ_LIST.POLICY_SWAGGER]
        }
    },
    {path: '', component: HomeComponent},
    {path: '404', component: PageNotFoundComponent},
    {path: '**', redirectTo: '/404'}
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes)
    ],
    exports: [
        RouterModule
    ]
})

export class AppRoutingModule {
}
