/**
 * 2021 Genstu
 *
 *  @author    Alex Kolesnichenko <kolexk@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Injectable, Injector} from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import {map, catchError, finalize} from 'rxjs/operators';
import {TokenService} from '../services/token.service';
import {SpinnerService} from '../services/spinner.service';
import {StoreService} from '../services/store.service';
import {
    INTERCEPTOR_SKIP_HEADER,
    INTERCEPTOR_SKIP_TRANSLATION_URL, SESSION_LIFETIME
} from '../constants';
import {LoginService} from '../services/login.service';

@Injectable({
    providedIn: 'root',
})
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(private injector: Injector) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const tokenService = this.injector.get(TokenService);
        const spinnerService = this.injector.get(SpinnerService);
        const storeServ = this.injector.get(StoreService);

        const urlBase = tokenService.getBaseUrl();

        if (request.headers.has(INTERCEPTOR_SKIP_HEADER)) {
            const headers = request.headers.delete(INTERCEPTOR_SKIP_HEADER);
            return next.handle(request.clone({ headers }));
        }

        const regexUrl = new RegExp('(geo-json)|(download)');
        const showSpinner = request.url.search(regexUrl) === -1;

        if (showSpinner) {
            spinnerService.start();
        }

        if (request.url.indexOf(INTERCEPTOR_SKIP_TRANSLATION_URL) !== 0) {
            if (!!tokenService.getToken() && !storeServ.checkSessionExpired()) {
                request = request.clone({headers: request.headers.set('Authorization', 'bearer ' + tokenService.getToken())});
            }

            // Set default Accept to application/json if not set already
            if (!request.headers.has('Accept')) {
                request = request.clone({headers: request.headers.set('Accept', 'application/json')});
            }

            request = request.clone({url: urlBase + request.url});

            // TODO set only in debug mode
            const params = request.params.set('XDEBUG_SESSION_START', 'PHPSTORM');

            request = request.clone({
                params: params
            });
        }

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    if (event.status === 200) {
                        storeServ.storeSessionExpired(SESSION_LIFETIME); // 1 week or 30 min session
                    }
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                this.respFail( error);
                return throwError(error);
            }),
            finalize(() => {
                spinnerService.stop();
            }));
    }

    private respFail(res: any) {
        const storeService: StoreService = this.injector.get(StoreService);
        const loginService: LoginService = this.injector.get(LoginService);

        if (res.status === 401) {
            loginService.logout();

            return;
        }

        storeService.storeSessionExpired(SESSION_LIFETIME);

        if ([400].indexOf(res.status) !== -1) {
            console.warn('Bad request', res);
        }

        if ([500].indexOf(res.status) !== -1) {
            try {
                console.error('Response Error', res, JSON.parse(res._body));
            } catch (e) {
                console.error('Response Error', res);
            }

            alert('API Internal error! Please contact to support if error will occur again.');
        }
    }
}
