/**
 * 2020 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2020 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, NavigationEnd} from '@angular/router';
import {LoginService} from '../../services/login.service';

@Component({
    templateUrl: 'home.component.html',
})

export class HomeComponent implements OnInit {
    name = 'Home';

    constructor(
        private loginService: LoginService,
        private route: ActivatedRoute,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.router.events.subscribe(s => {
            if (s instanceof NavigationEnd) {
                const tree = this.router.parseUrl(this.router.url);
                if (tree.fragment) {
                    const element = document.querySelector('#' + tree.fragment);
                    if (element) {
                        element.scrollIntoView(true);
                    }
                }
            }
        }, err => {
            console.error(err);
        });

        this.loginService.cookieLogin().subscribe((logged: boolean) => {
            if (logged) {
                this.loginService.goDashboard();

                return;
            }
            this.loginService.goLogin();
        });
    }
}
