/**
 * 2020 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2020 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    OnChanges,
    ViewChild,
    ElementRef, ChangeDetectorRef
} from '@angular/core';
import {AbstractControl, ValidationErrors} from '@angular/forms';
import * as _ from 'lodash';
import {TranslateService} from '@ngx-translate/core';
import {BaseForm} from '../../classes/base-form';
import {Helper} from '../../classes/helper';

@Component({
    selector: 'app-image-upload',
    templateUrl: './image-upload.component.html'
})
export class ImageUploadComponent implements OnInit, OnChanges {
    @Input() options: any = {};
    @Input() control: AbstractControl = null;
    @Output() public uploadClick: EventEmitter<boolean> = new EventEmitter();
    @Output() public previewClick: EventEmitter<boolean> = new EventEmitter();
    @Output() public uploadSuccess: EventEmitter<File> = new EventEmitter();
    @Output() public uploadError: EventEmitter<ValidationErrors> = new EventEmitter();
    @Output() public uploadCancelled: EventEmitter<boolean> = new EventEmitter();
    @ViewChild('previewImg', { static: true }) previewImg: ElementRef;

    selectedFile: File = null;
    previewSrc = '';
    hasValidImage = false;

    acceptedFiles = '*.*';

    config: any = {
        uploadText: 'Upload image',
        imageRestriction: {},
        acceptedFiles: '*.*',
        previewUrl: ''
    };

    constructor(
        protected translate: TranslateService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
    }

    ngOnChanges() {
        Object.assign(this.config, this.options);

        if (null !== this.config.imageRestriction.acceptedFiles) {
            this.acceptedFiles = this.config.imageRestriction.acceptedFiles.join(',');
        }

        if (!_.isEmpty(this.config.previewUrl)) {
            this.refreshPreviewFromConfig();
        }
    }

    onFileSelected(event) {
        this.hasValidImage = false;
        if (_.isEmpty(event.target.files)) {
            return;
        }

        this.handleFile(<File>event.target.files[0]);
    }

    handleFile(file: File) {
        this.selectedFile = file;
        if (null === this.selectedFile) {
            return;
        }

        if (this.hasControl()) {
            this.control.markAsTouched();
            this.control.setValue(this.selectedFile);
            this.validateFile();
        } else {
            this.refreshPreviewFromFile();
            this.onUploadSuccess();
        }
    }

    onUploadCancel(): void {
        this.selectedFile = null;
        this.hasValidImage = false;
        if (this.hasControl()) {
            this.control.setValue(null);
            this.control.markAsTouched();
        }

        this.uploadCancelled.emit(true);
    }

    refreshPreviewFromFile() {
        const reader = new FileReader();
        reader.onload = (e: any) => {
            this.previewSrc = e.target.result;
            this.changeDetectorRef.detectChanges();
        };

        reader.readAsDataURL(this.selectedFile);

    }

    refreshPreviewFromConfig() {
        this.previewSrc = this.config.previewUrl + '?' + performance.now(); // add time to request to avoid browser cache
        this.hasValidImage = true;
    }

    getErrorMessage(control: AbstractControl): string {
        return Helper.getErrorMessage(control, this.translate);
    }

    async validateFile() {
        if (null === this.selectedFile) {
            return;
        }

        this.hasValidImage = false;

        this.control.setErrors(null);

        if (undefined !== this.config.imageRestriction.acceptedFiles && this.config.imageRestriction.acceptedFiles.indexOf(this.selectedFile.type) === -1) {
            this.onInvalidImage({
                acceptedFiles: {
                    acceptedFiles: this.config.imageRestriction.acceptedFiles
                }
            });

            return;
        }

        if (undefined !== this.config.imageRestriction.maxFileSize && this.config.imageRestriction.maxFileSize < this.selectedFile.size) {
            this.onInvalidImage({
                maxFileSize: {
                    maxFileSize: this.config.imageRestriction.maxFileSize + ' b'
                }
            });

            return;
        }

        const checkMinWidth = _.isInteger(this.config.imageRestriction.minImageWidth) && this.config.imageRestriction.minImageWidth > 0;
        const checkMinHeight = _.isInteger(this.config.imageRestriction.minImageHeight) && this.config.imageRestriction.minImageHeight > 0;
        const checkMaxWidth = _.isInteger(this.config.imageRestriction.maxImageWidth) && this.config.imageRestriction.maxImageWidth > 0;
        const checkMaxHeight = _.isInteger(this.config.imageRestriction.maxImageHeight) && this.config.imageRestriction.maxImageHeight > 0;
        if (!(checkMinWidth || checkMinHeight || checkMaxWidth || checkMaxHeight)) { // No need to check image dimensions
            this.refreshPreviewFromFile();
            this.onUploadSuccess();

            return;
        }

        const img = new Image();
        img.onload = () => {
            const width = img.naturalWidth;
            const height = img.naturalHeight;

            if (checkMinWidth && this.config.imageRestriction.minImageWidth > width) {
                this.onInvalidImage({
                    minImageWidth: {
                        minImageWidth: this.config.imageRestriction.minImageWidth
                    }
                });

                return;
            }

            if (checkMinHeight && this.config.imageRestriction.minImageHeight > height) {
                this.onInvalidImage({
                    minImageHeight: {
                        minImageHeight: this.config.imageRestriction.minImageHeight
                    }
                });

                return;
            }

            if (checkMaxWidth && this.config.imageRestriction.maxImageWidth < width) {
                this.onInvalidImage({
                    maxImageWidth: {
                        maxImageWidth: this.config.imageRestriction.maxImageWidth
                    }
                });

                return ;
            }

            if (checkMaxHeight && this.config.imageRestriction.maxImageHeight < height) {
                this.onInvalidImage({
                    maxImageHeight: {
                        maxImageHeight: this.config.imageRestriction.maxImageHeight
                    }
                });

                return;
            }

            this.refreshPreviewFromFile();
            this.onUploadSuccess();
        };
        img.src = window.URL.createObjectURL(this.selectedFile);
    }

    isValid(): boolean {
        return !this.hasControl() || null === this.control.errors || _.isEmpty(this.control.errors);
    }

    isHiddenInvalidMessages(): boolean {
        if (!this.hasControl()) {
            return true;
        }

        return !this.control.dirty && !this.control.touched;
    }

    onUploadClick() {
        this.selectedFile = null;

        if (this.hasControl()) {
            this.control.setValue(null);
            this.control.markAsTouched();
        }

        this.uploadClick.emit(true);
    }

    onPreviewClick() {
        if (this.hasControl()) {
            this.control.markAsTouched();
        }

        this.previewClick.emit(true);
    }

    onInvalidImage(errors: ValidationErrors) {
        this.control.setErrors(errors);
        this.uploadError.emit(errors);
    }

    onUploadSuccess(): void {
        this.hasValidImage = true;
        this.uploadSuccess.emit(this.selectedFile);
    }

    onDragOver(evt) {
        evt.preventDefault();
        evt.stopPropagation();
    }

    onDragLeave(evt) {
        evt.preventDefault();
        evt.stopPropagation();
    }

    ondrop(evt) {
        evt.preventDefault();
        evt.stopPropagation();

        const files = evt.dataTransfer.files;
        if (files.length > 0) {
            this.handleFile(files[0]);
        }
    }

    hasControl(): boolean {
        return undefined !== this.control && null !== this.control;
    }
}
