/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ToasterService} from 'angular2-toaster';
import {
    FORM_SCENARIO_CREATE,
    FORM_SCENARIO_EDIT,
    COMPLAINT_TYPE_OPTION_LIST
} from '@app/constants';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseForm} from '@classes/base-form';
import {COMPLAINT_WITH_EMPTY_DATA, IComplaint, IComplaintEditInput} from '@interfaces/complaint.interface';
import {ComplaintService} from '@services/complaint.service';


@Component({
    selector: 'app-complaint-form',
    templateUrl: 'form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComplaintFormComponent extends BaseForm<IComplaint, IComplaintEditInput> implements OnInit {
    @Input() scenario: string;
    @Output() dataSaved: EventEmitter<IComplaint> = new EventEmitter();

    item: IComplaint = {...COMPLAINT_WITH_EMPTY_DATA};
    backLink = '/complaint/index';
    error = '';

    scenarioCreate = FORM_SCENARIO_CREATE; // Need for component HTML usage
    scenarioEdit = FORM_SCENARIO_EDIT; // Need for component HTML usage

    formGroup: FormGroup = null;

    typeList = COMPLAINT_TYPE_OPTION_LIST;

    startDate = new Date();

    constructor(
        protected translate: TranslateService,
        protected itemService: ComplaintService,
        protected toasterService: ToasterService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected changeDetectorRef: ChangeDetectorRef,
        private formBuilder: FormBuilder
    ) {
        super(translate, toasterService, route, router, changeDetectorRef, itemService);

        this.buildFormGroup();
    }

    ngOnInit() {
        this.handleItemFromRequestOrHistory('complaint');
    }

    fillFormData() {
        const formData = {
            mail: this.item.mail,
            name: this.item.name,
            zip_code: this.item.zip_code,
            building_number: this.item.building_number,
            type_id: this.item.type_id,
            text: this.item.text
        };

        this.formGroup.reset(formData);
    }

    getFormErrorMessage(): string {
        return ''; //TODO: add handler
    }

    submitForm(data: any): void {
        this.error = '';

        const input = this.getInputFromFormData(data);

        this.itemService.storeItem(input).subscribe((item) => {
            this.item = item;
            this.onSave();
        }, result => this.handleRequestError(result, 'Complaint'), () => {
            this.goBack();
        });
    }

    getInputFromFormData(data: any): IComplaintEditInput {
        return {
            id: this.item.id,
            name: data.name,
            mail: data.mail,
            zip_code: data.zip_code,
            building_number: data.building_number,
            type_id: data.type_id,
            text: data.text
        };
    }

    private buildFormGroup() {
        this.formGroup = this.formBuilder.group({
            type_id: ['', Validators.compose([
                Validators.required,
                Validators.maxLength(255)
            ])],
            name: ['', Validators.compose([
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(255)
            ])],
            mail: ['', Validators.compose([
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(255)
            ])],
            zip_code: ['', Validators.compose([
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(255)
            ])],
            building_number: ['', Validators.compose([
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(255)
            ])],
            text: ['', Validators.compose([
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(2000)
            ])]
        });
    }
}
