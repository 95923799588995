/**
 * 2022 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2022 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {
    FILTER_BLOCK_TYPE_INPUT,
    ITEM_STATUS_ID_OBJ_LIST,
    USER_POLICY_OBJ_LIST
} from '@app/constants';
import {Router} from '@angular/router';
import {LoginService} from '@services/login.service';
import {BaseIndexComponent} from '@classes/base-index';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTable} from '@angular/material/table';
import {IFilterConfig, IFilterValue} from '@interfaces/filter.interface';
import {UrlService} from '@services/url.service';
import {StoreService} from '@services/store.service';
import {IColumn} from '@interfaces/column-filter.interface';
import {IFlyerEmailOptin, IFlyerEmailOptinEditInput} from '@interfaces/flyer-email-optin.interface';
import {FlyerEmailOptinService} from '@services/extra-email-optin.service';

@Component({
    selector: 'app-flyer-email-optin-index',
    templateUrl: 'index.component.html'
})
export class FlyerEmailOptinIndexComponent extends BaseIndexComponent<IFlyerEmailOptin, IFlyerEmailOptinEditInput> implements OnInit, AfterViewInit {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatTable) table: MatTable<any>;

    tableName = 'flyerEmailOptinList';

    tableColumns: Array<IColumn> = [
        {
            name: 'id',
            title: 'item.fields.id'
        },
        {
            name: 'user_id',
            title: 'flyer-email-optin.fields.user_id'
        },
        {
            name: 'flyer_id',
            title: 'flyer-email-optin.fields.flyer_id'
        },
        {
            name: 'email',
            title: 'flyer-email-optin.fields.email'
        },
        {
            name: 'action',
            title: 'item.fields.action'
        }
    ];

    statusDeleted = ITEM_STATUS_ID_OBJ_LIST.DELETED;

    baseRoute = '/flyer-email-optin';
    itemHistoryKey = 'flyerEmailOptin';

    filterConfig: IFilterConfig = {
        title: 'common.Filter',
        blocks: [
            {
                title: 'item.fields.id',
                type: FILTER_BLOCK_TYPE_INPUT,
                propertyName: 'id',
            },
            {
                title: 'flyer-email-optin.fields.user_id',
                type: FILTER_BLOCK_TYPE_INPUT,
                propertyName: 'user_id',
            },
            {
                title: 'flyer-email-optin.fields.flyer_id',
                type: FILTER_BLOCK_TYPE_INPUT,
                propertyName: 'flyer_id'
            }
        ]
    }

    constructor(
        protected itemService: FlyerEmailOptinService,
        protected deleteDialog: MatDialog,
        protected router: Router,
        protected urlService: UrlService,
        private loginService: LoginService,
        protected storeServ: StoreService
    ) {
        super(itemService, deleteDialog, router, urlService, storeServ);
    }

    ngOnInit() {
        this.handleTableColumns();
    }

    ngAfterViewInit() {
        this.handleUrlQuery();
    }

    canDelete(item: IFlyerEmailOptin): boolean {
        return this.loginService.isGranted(USER_POLICY_OBJ_LIST.POLICY_ADMIN_FLYER_EMAIL_OPTIN_VIEW);
    }

    toggleFilter(): void {
        this.displayFilter = !this.displayFilter;
    }

    onFilterChanged(filter: IFilterValue): void {
        this.input.filter = filter;

        this.filterChanged.emit(filter);
    }
}
