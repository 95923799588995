/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ToasterService} from 'angular2-toaster';
import {
    FORM_SCENARIO_CREATE,
    FORM_SCENARIO_EDIT
} from '../../../constants';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseForm} from '../../../classes/base-form';
import {
    FLYER_CATEGORY_WITH_EMPTY_DATA,
    IFlyerCategory,
    IFlyerCategoryEditInput
} from '../../../interfaces/flyer-category.interface';
import {FlyerCategoryService} from '../../../services/flyer-category.service';

@Component({
    selector: 'app-flyer-category-form',
    templateUrl: 'form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlyerCategoryFormComponent extends BaseForm<IFlyerCategory, IFlyerCategoryEditInput> implements OnInit {
    item: IFlyerCategory = {...FLYER_CATEGORY_WITH_EMPTY_DATA};
    backLink = '/flyer-category/index';
    error = '';

    scenarioCreate = FORM_SCENARIO_CREATE; // Need for component HTML usage
    scenarioEdit = FORM_SCENARIO_EDIT; // Need for component HTML usage

    formGroup: FormGroup = null;

    constructor(
        protected translate: TranslateService,
        protected itemService: FlyerCategoryService,
        protected toasterService: ToasterService,
        protected changeDetectorRef: ChangeDetectorRef,
        protected router: Router,
        protected route: ActivatedRoute,
        private formBuilder: FormBuilder
    ) {
        super(translate, toasterService, route, router, changeDetectorRef, itemService);

        this.buildFormGroup();
    }

    ngOnInit() {
        this.handleItemFromRequestOrHistory('flyerCategory');
    }

    fillFormData() {
        const formData = {
            name: this.item.name,
            description: this.item.description,
            icon: this.item.icon
        };

        this.formGroup.reset(formData);
    }

    getFormErrorMessage(): string {
        return ''; //TODO: add handler
    }

    submitForm(data: any): void {
        this.error = '';

        const input = this.getInputFromFormData(data);

        this.itemService.storeItem(input).subscribe((category) => {
            this.item = category;
            this.onSave();
        }, result => this.handleRequestError(result, 'FlyerCategory'), () => {
            this.goBack();
        });
    }

    getInputFromFormData(data: any): IFlyerCategoryEditInput {
        return {
            id: this.item.id,
            name: data.name,
            description: data.description,
            icon: data.icon
        };
    }

    private buildFormGroup() {
        this.formGroup = this.formBuilder.group({
            name: ['', Validators.compose([
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(255)
            ])],
            description: ['', Validators.compose([
                Validators.minLength(1),
                Validators.maxLength(255)
            ])],
            icon: ['', Validators.compose([
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(255)
            ])]
        });
    }
}
