/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {
    FILTER_BLOCK_TYPE_DATE_TIME_RANGE,
    FILTER_BLOCK_TYPE_INPUT,
    FILTER_BLOCK_TYPE_SEARCH_SELECT,
    FILTER_BLOCK_TYPE_SELECT,
    ITEM_FREQUENCY_OPTION_LIST,
    ITEM_STATUS_ID_OBJ_LIST,
    STATUS_OPTION_LIST,
    USER_POLICY_OBJ_LIST
} from '@app/constants';
import {Router} from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTable} from '@angular/material/table';
import {LoginService} from '@services/login.service';
import {IFlyer, IFlyerEditInput} from '@interfaces/flyer.interface';
import {FlyerService} from '@services/flyer.service';
import {BaseIndexComponent} from '@classes/base-index';
import {IFilterConfig, IFilterValue} from '@interfaces/filter.interface';
import {AdvertiserService} from '@services/advertiser.service';
import {FlyerCategoryService} from '@services/flyer-category.service';
import {UrlService} from '@services/url.service';
import {IPageInput} from '@interfaces/page-input.interface';
import {Helper} from '@classes/helper';
import {StoreService} from '@services/store.service';
import {IColumn} from '@interfaces/column-filter.interface';

@Component({
    selector: 'app-flyer-index',
    templateUrl: 'index.component.html'
})
export class FlyerIndexComponent extends BaseIndexComponent<IFlyer, IFlyerEditInput> implements OnInit, AfterViewInit {
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatTable, {static: true}) table: MatTable<any>;

    tableName = 'flyerList';

    tableColumns: Array<IColumn> = [
        {
            name: 'id',
            title: 'item.fields.id'
        },
        {
            name: 'advertiser_id',
            title: 'flyer.fields.advertiser_id'
        },
        {
            name: 'code',
            title: 'flyer.fields.code'
        },
        {
            name: 'title',
            title: 'flyer.fields.title'
        },
        {
            name: 'description_short',
            title: 'flyer.fields.description_short'
        },
        {
            name: 'category',
            title: 'flyer.fields.category'
        },
        {
            name: 'frequency',
            title: 'flyer.fields.frequency'
        },
        {
            name: 'status_id',
            title: 'flyer.fields.status_id'
        },
        {
            name: 'created',
            title: 'flyer.fields.created'
        },
        {
            name: 'updated',
            title: 'flyer.fields.updated'
        },
        {
            name: 'action',
            title: 'item.fields.action'
        }
    ];

    statusDeleted = ITEM_STATUS_ID_OBJ_LIST.DELETED;

    baseRoute = '/flyer';
    itemHistoryKey = 'flyer';

    filterConfig: IFilterConfig;

    constructor(
        protected itemService: FlyerService,
        protected deleteDialog: MatDialog,
        protected router: Router,
        protected urlService: UrlService,
        private loginService: LoginService,
        private advertiserService: AdvertiserService,
        private flyerCategoryService: FlyerCategoryService,
        protected storeServ: StoreService
    ) {
        super(itemService, deleteDialog, router, urlService, storeServ);

        this.flyerCategoryService.getOptionList().subscribe((catList) => {
            this.filterConfig = {
                title: 'common.Filter',
                blocks: [
                    {
                        title: 'flyer.fields.id',
                        type: FILTER_BLOCK_TYPE_INPUT,
                        propertyName: 'id'
                    },
                    {
                        title: 'flyer.fields.advertiser_id',
                        type: FILTER_BLOCK_TYPE_SEARCH_SELECT,
                        propertyName: 'advertiser_id',
                        items: [],
                        getList: (value: string) => {
                            return this.advertiserService.getOptionListByCompany(value);
                        }
                    },
                    {
                        title: 'flyer.fields.code',
                        type: FILTER_BLOCK_TYPE_INPUT,
                        propertyName: 'code'
                    },
                    {
                        title: 'flyer.fields.title',
                        type: FILTER_BLOCK_TYPE_INPUT,
                        propertyName: 'title'
                    },
                    {
                        title: 'flyer.fields.description_short',
                        type: FILTER_BLOCK_TYPE_INPUT,
                        propertyName: 'description_short'
                    },
                    {
                        title: 'flyer.fields.category',
                        type: FILTER_BLOCK_TYPE_SELECT,
                        propertyName: 'category_id',
                        items: catList
                    },
                    {
                        title: 'flyer.fields.frequency',
                        type: FILTER_BLOCK_TYPE_SELECT,
                        propertyName: 'frequency',
                        items: ITEM_FREQUENCY_OPTION_LIST
                    },
                    {
                        title: 'flyer.fields.status_id',
                        type: FILTER_BLOCK_TYPE_SELECT,
                        propertyName: 'status_id',
                        items: STATUS_OPTION_LIST
                    },
                    {
                        placeholderFrom: 'flyer.fields.createdFrom',
                        placeholderTo: 'flyer.fields.createdTo',
                        type: FILTER_BLOCK_TYPE_DATE_TIME_RANGE,
                        propertyName: 'created'
                    },
                    {
                        placeholderFrom: 'flyer.fields.updatedFrom',
                        placeholderTo: 'flyer.fields.updatedTo',
                        type: FILTER_BLOCK_TYPE_DATE_TIME_RANGE,
                        propertyName: 'updated'
                    }
                ]
            };
        });
    }

    ngOnInit() {
        this.handleTableColumns();
    }

    ngAfterViewInit() {
        this.handleUrlQuery();
    }

    canDelete(flyer: IFlyer): boolean {
        return this.loginService.isGranted(USER_POLICY_OBJ_LIST.POLICY_ADMIN_FLYER_EDIT) && flyer.status_id != ITEM_STATUS_ID_OBJ_LIST.DELETED;
    }

    getCategoryList(item: IFlyer): string {
        return item.FlyerCategories.map((category) => category.name).join('<br/>');
    }

    toggleFilter(): void {
        this.displayFilter = !this.displayFilter;
    }

    exportToCsv(): void {
        Helper.exportToCsv(this.input, this.itemService, 'flyer_export.csv');
    }

    onFilterChanged(filter: IFilterValue): void {
        this.input.filter = filter;

        this.filterChanged.emit(filter);
    }
}
