/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {PAGINATOR_PAGE_SIZE} from '../constants';
import {IPageInput} from '@interfaces/page-input.interface';
import {IItemList} from '@interfaces/item-list.interface';
import {ISurvey, ISurveyAnswer, ISurveyAnswerEditInput, ISurveyEditInput} from '@interfaces/survey.interface';

@Injectable({
    providedIn: 'root',
})

export class SurveyAnswerService {
    constructor(private httpClient: HttpClient) {}

    getItem(id: number): Observable<ISurveyAnswer> {
        return this.httpClient.get<ISurveyAnswer>(
            `/admin/survey-answer/${id}`
        );
    }

    getList(input: IPageInput): Observable<IItemList<ISurveyAnswer>> {
        const offset = input.page * PAGINATOR_PAGE_SIZE;

        return this.httpClient.get<IItemList<ISurveyAnswer>>(
            `/admin/survey-answer?sort=${input.sort}&order=${input.order}&limit=${PAGINATOR_PAGE_SIZE}&offset=${offset}&filter=${input.filter ? JSON.stringify(input.filter) : ''}`
        );
    }

    storeItem(input: ISurveyAnswerEditInput): Observable<ISurveyAnswer> {
        if (input.id) {
            return this.httpClient.put<ISurveyAnswer>(
                `/admin/survey-answer/${input.id}`,
                input,
            );
        }

        return this.httpClient.post<ISurveyAnswer>(
            '/admin/survey-answer',
            input,
        );
    }

    deleteItem(item: ISurveyAnswer): Observable<ISurveyAnswer> {
        return this.httpClient.delete<ISurveyAnswer>(
            `/admin/survey-answer/${item.id}`
        );
    }

    exportToCsv(input: IPageInput): Observable<Blob> {
        return this.httpClient.get(
            `/admin/export/csv/survey-answer?sort=${input.sort}&order=${input.order}&filter=${input.filter ? JSON.stringify(input.filter) : ''}`,
            { responseType: 'blob' }
        );
    }
}
