/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Injectable} from '@angular/core';
import {Subject, Observable} from 'rxjs';

@Injectable()

export class EventService {
    event$: Observable<any>;

    private eventSubject = new Subject<any>();

    constructor() {
        this.event$ = this.eventSubject.asObservable();
    }

    event(data) {
        this.eventSubject.next(data);
    }
}
