<div class="main-sidebar-container">
    <ul class="sidebar-list">
        <ng-container *ngFor="let item of itemList">
            <!--    Simple item        -->
            <ng-container *ngIf="!isMenuItem(item)" >
                <li *ngIf="isItemAvailable(item)"
                    class="sidebar-item">
                    <a
                        class="nav-link"
                        [routerLink]="item.route"
                        routerLinkActive="active">
                        <div *ngIf="itemHasIcon(item)" class="sidebar-img-icon">
                            <mat-icon>{{item.icon}}</mat-icon>
                        </div>
                        {{item.title|translate}}
                    </a>
                </li>
            </ng-container>

            <!--    Sub menu        -->
            <ng-container *ngIf="isMenuItem(item)" >
                <li *ngIf="isItemAvailable(item)"
                    ngbDropdown
                    [open]="isSectionOpen(item.sectionName)"
                    [autoClose]="false"
                    class="sidebar-item"
                    #datasetDrop="ngbDropdown">

                    <div
                        ngbDropdownAnchor
                        class="dropdown-toggle"
                        [class.active]="isSectionOpen(item.sectionName)"
                        (click)="$event.stopPropagation(); datasetDrop.toggle();">
                        <div class="left-side">
                            <div *ngIf="itemHasIcon(item)" class="sidebar-img-icon">
                                <mat-icon>{{item.icon}}</mat-icon>
                            </div>
                            {{item.title|translate}}
                        </div>
                    </div>

                    <div
                        ngbDropdownMenu
                        class="dropdown-menu">
                        <a *ngFor="let child of item.children"
                            [routerLink]="child.route"
                            class="nav-link dropdown-item"
                            routerLinkActive="active">
                            {{child.title|translate}}
                        </a>
                    </div>
                </li>
            </ng-container>
        </ng-container>
    </ul>
</div>
