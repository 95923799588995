<form
    [formGroup]="formGroup"
    novalidate autocomplete="off"
    class="reset-password-form">
    <mat-error *ngIf="formGroup.invalid">{{getFormErrorMessage()|translate}}</mat-error>
    <mat-error *ngIf="error.length">{{error|translate}}</mat-error>

    <mat-form-field>
        <mat-label>{{'reset-password.form.password'|translate}}</mat-label>
        <input
            matInput
            [placeholder]="'reset-password.form.password'|translate"
            formControlName="password"
            required
            type="password">
        <mat-error *ngIf="formGroup.controls['password'].invalid">{{getErrorMessage(formGroup.controls['password'])}}</mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{'reset-password.form.password confirm'|translate}}</mat-label>
        <input
            matInput
            [placeholder]="'reset-password.form.password confirm'|translate"
            formControlName="repeatPassword"
            required
            type="password">
        <mat-error *ngIf="formGroup.controls['repeatPassword'].invalid">{{getErrorMessage(formGroup.controls['repeatPassword'])}}</mat-error>
    </mat-form-field>

    <div class="form-row button-row">
        <button
            mat-raised-button
            type="submit"
            color="primary"
            (click)="reset(formGroup.value)"
            [disabled]="formGroup.invalid">{{'reset-password.form.Save'|translate}}</button>
    </div>
</form>
