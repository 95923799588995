<form
    [formGroup]="formGroup"
    novalidate autocomplete="off"
    class="edition form-container"
    (ngSubmit)="submitForm(formGroup.value)">

    <mat-error *ngIf="formGroup.invalid">{{getFormErrorMessage()|translate}}</mat-error>
    <mat-error *ngIf="error.length">{{error|translate}}</mat-error>

    <div *ngIf="scenario === scenarioEdit" class="row mb-4">
        <div class="col-12">
            <div class="item-id">{{'item.fields.id'|translate}}: {{item.id}}</div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-md-6">
            <mat-form-field>
                <mat-label>{{'edition.fields.status_id'|translate}}</mat-label>
                <mat-select
                    formControlName="status_id"
                    required>
                    <mat-option *ngFor="let item of statusList" [value]="item.id">{{item.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="formGroup.controls['status_id'].invalid">
                    {{getErrorMessage(formGroup.controls['status_id'])}}
                </mat-error>
            </mat-form-field>
        </div>

        <div class="col-12 col-md-6">
            <mat-form-field>
                <mat-label>{{'edition.fields.flyer_id'|translate}}</mat-label>
                <mat-select formControlName="flyer_id">
                    <mat-option *ngFor="let item of flyerList" [value]="item.id">{{item.title}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-12 col-md-6">
            <mat-form-field>
                <mat-label>{{'edition.fields.title'|translate}}</mat-label>
                <input
                    matInput
                    [placeholder]="'edition.fields.title'|translate"
                    formControlName="title"
                    required>
                <mat-error *ngIf="formGroup.controls['title'].invalid">
                    {{getErrorMessage(formGroup.controls['title'])}}
                </mat-error>
            </mat-form-field>
        </div>

        <div class="col-12 col-md-6">
            <mat-form-field>
                <mat-label>{{'edition.fields.size'|translate}}</mat-label>
                <input
                    matInput
                    [placeholder]="'edition.fields.size'|translate"
                    formControlName="size"
                    required>
                <mat-error *ngIf="formGroup.controls['size'].invalid">
                    {{getErrorMessage(formGroup.controls['size'])}}
                </mat-error>
            </mat-form-field>
        </div>

        <div class="col-12 col-md-6">
            <mat-form-field>
                <mat-label>{{'edition.fields.pages'|translate}}</mat-label>
                <input
                    matInput
                    [placeholder]="'edition.fields.pages'|translate"
                    formControlName="pages"
                    required>
                <mat-error *ngIf="formGroup.controls['pages'].invalid">
                    {{getErrorMessage(formGroup.controls['pages'])}}
                </mat-error>
            </mat-form-field>
        </div>

        <div class="col-12 col-md-6">
            <mat-form-field>
                <mat-label>{{'edition.fields.weight'|translate}}</mat-label>
                <input
                    matInput
                    [placeholder]="'edition.fields.weight'|translate"
                    formControlName="weight"
                    required>
                <mat-error *ngIf="formGroup.controls['weight'].invalid">
                    {{getErrorMessage(formGroup.controls['weight'])}}
                </mat-error>
            </mat-form-field>
        </div>

        <div class="col-12 col-md-6">
            <mat-form-field>
                <mat-label>{{'edition.fields.pricing'|translate}}</mat-label>
                <input
                    matInput
                    [placeholder]="'edition.fields.pricing'|translate"
                    formControlName="pricing"
                    required>
                <mat-error *ngIf="formGroup.controls['pricing'].invalid">
                    {{getErrorMessage(formGroup.controls['pricing'])}}
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-md-6">
            <mat-form-field>
                <mat-label>{{'edition.fields.date_start'|translate}}</mat-label>
                <input
                    [matDatepicker]="dateStart"
                    matInput
                    formControlName="date_start"
                    placeholder="{{'edition.fields.date_start'|translate}}">

                <mat-datepicker-toggle [for]="dateStart" matSuffix>
                    <app-icon-date-time></app-icon-date-time>
                </mat-datepicker-toggle>

                <mat-datepicker #dateStart [startAt]="startDate"></mat-datepicker>

                <mat-error *ngIf="formGroup.controls['date_start'].invalid">
                    {{getErrorMessage(formGroup.controls['date_start'])}}
                </mat-error>
            </mat-form-field>
        </div>

        <div class="col-12 col-md-6">
            <mat-form-field>
                <mat-label>{{'edition.fields.date_end'|translate}}</mat-label>
                <input
                    [matDatepicker]="dateEnd"
                    matInput
                    formControlName="date_end"
                    placeholder="{{'edition.fields.date_end'|translate}}">

                <mat-datepicker-toggle [for]="dateEnd" matSuffix>
                    <app-icon-date-time></app-icon-date-time>
                </mat-datepicker-toggle>

                <mat-datepicker #dateEnd [startAt]="startDate"></mat-datepicker>

                <mat-error *ngIf="formGroup.controls['date_end'].invalid">
                    {{getErrorMessage(formGroup.controls['date_end'])}}
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="button-row d-flex justify-content-end">
        <button
            mat-raised-button
            color="primary"
            type="submit"
            [disabled]="!formGroup.valid">{{'form.Save'|translate}}</button>

        <button
            mat-raised-button
            (click)="goBack()">{{'form.Cancel'|translate}}</button>
    </div>
</form>
