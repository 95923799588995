import {
    AfterViewInit,
    Component,
    ElementRef,
    HostListener,
    OnInit,
    Renderer2,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {SwaggerUIBundle} from 'swagger-ui-dist';
import {HttpClient} from '@angular/common/http';
import * as _ from 'lodash';
import {SWAGGER_SPEC_FILE_URL_PATH} from '../../constants';
import {environment} from '../../../environments/environment';

export const API_TEST_PARAMETERS_TABLE_CONTAINER_CLASS = 'table-container';
export const API_TEST_PARAMETERS_TABLE_HEADER_CLASS = 'col_header';
export const API_TEST_CARD_CLASS = 'opblock-tag-section';
export const API_TEST_CONTAINER_CLASS = 'api-test-container';
export const API_TEST_CARD_BODY_CLASS = 'opblock-body';

@Component({
    selector: 'app-swagger',
    templateUrl: 'swagger.component.html',
    styleUrls: ['swagger.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SwaggerComponent implements OnInit, AfterViewInit {
    @ViewChild('apiTestContainer') apiTestContainer: ElementRef;

    constructor(
        private httpClient: HttpClient,
        private renderer: Renderer2
    ) {
    }

    ngOnInit() {
        this.initSwagger();
    }

    ngAfterViewInit() {
        window.scrollTo(0, 0);
    }

    @HostListener('click', ['$event'])
    onClick(event) {
        if (event.target.localName === 'h4' || event.target.localName === 'p'
            || (event.target.localName === 'span' && event.target.parentElement.localName === 'a' && !/\//ig.test(event.target.innerText))) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    private initSwagger(): void {
        this.httpClient.get(`${SWAGGER_SPEC_FILE_URL_PATH}`).subscribe((res: any) => {
            this.initSwaggerBundle(res);
            // this.prepareSwaggerResults();
        }, () => {
            console.error('Can\'t get swagger specification');
        });
    }

    private initSwaggerBundle(spec: any): void {
        SwaggerUIBundle({
            spec: spec,
            dom_id: '#swagger-ui',
            deepLinking: false,
            presets: [
                SwaggerUIBundle.presets.apis,
                SwaggerUIBundle.SwaggerUIStandalonePreset
            ],
            presets_config: {
                SwaggerUIStandalonePreset: {
                    TopbarPlugin: false
                }
            },
            plugins: [
                SwaggerUIBundle.plugins.DownloadUrl
            ],
            layout: 'BaseLayout',
            defaultModelsExpandDepth: 0
        });
    }

    private prepareSwaggerResults(): void {
        const schemasBlock = document.getElementsByClassName('models');
        const parentSchema = document.getElementById('schemas-block');
        this.renderer.appendChild(parentSchema, schemasBlock[0]);

        const swaggerAuthorizeButton = document.getElementsByClassName('auth-wrapper');
        const authApiWrapper = document.getElementById('authorize-button-container');
        this.renderer.appendChild(authApiWrapper, swaggerAuthorizeButton[0]);

        const childrenArr = _.values(document.getElementsByClassName('opblock-tag-section'));
        childrenArr.forEach((item, index) => {
            if (index !== 0) {
                this.generateAPIEndpointDOMCard('api-block-' + index, item);
            }
        });
    }

    private generateAPIEndpointDOMCard(id: string, element): void {
        const cardBodyDiv = this.renderer.createElement('div');
        this.renderer.addClass (cardBodyDiv, 'mt-20');
        this.renderer.addClass (cardBodyDiv, 'swagger-endpoint-card');
        this.renderer.addClass (cardBodyDiv, 'card-bottom-shadow');
        this.renderer.setAttribute(cardBodyDiv, 'id', id);

        const cardSwaggerUIDiv = this.renderer.createElement('div');
        this.renderer.addClass (cardSwaggerUIDiv, 'swagger-ui');

        const cardWrapperDiv = this.renderer.createElement('div');
        this.renderer.addClass (cardWrapperDiv, 'wrapper');

        const cardBlockSection = this.renderer.createElement('section');
        this.renderer.addClass (cardBlockSection, 'block');
        this.renderer.addClass (cardBlockSection, 'block-desktop');

        this.renderer.appendChild(cardBlockSection, element);
        this.renderer.appendChild(cardWrapperDiv, cardBlockSection);
        this.renderer.appendChild(cardSwaggerUIDiv, cardWrapperDiv);
        this.renderer.appendChild(cardBodyDiv, cardSwaggerUIDiv);
        this.renderer.appendChild(this.apiTestContainer.nativeElement, cardBodyDiv);
    }
}
