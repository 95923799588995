/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {PAGINATOR_PAGE_SIZE} from '../constants';
import {IPageInput} from '@interfaces/page-input.interface';
import {IItemList} from '@interfaces/item-list.interface';
import {IEdition, IEditionEditInput} from '@interfaces/edition.interface';

@Injectable({
    providedIn: 'root',
})

export class EditionService {
    constructor(private httpClient: HttpClient) {}

    getItem(id: number): Observable<IEdition> {
        return this.httpClient.get<IEdition>(
            `/admin/edition/${id}`
        );
    }

    getList(input: IPageInput): Observable<IItemList<IEdition>> {
        const offset = input.page * PAGINATOR_PAGE_SIZE;

        return this.httpClient.get<IItemList<IEdition>>(
            `/admin/edition?sort=${input.sort}&order=${input.order}&limit=${PAGINATOR_PAGE_SIZE}&offset=${offset}&filter=${input.filter ? JSON.stringify(input.filter) : ''}`
        );
    }

    storeItem(input: IEditionEditInput): Observable<IEdition> {
        if (input.id) {
            return this.httpClient.put<IEdition>(
                `/admin/edition/${input.id}`,
                input,
            );
        }

        return this.httpClient.post<IEdition>(
            '/admin/edition',
            input,
        );
    }

    deleteItem(item: IEdition): Observable<IEdition> {
        return this.httpClient.delete<IEdition>(
            `/admin/edition/${item.id}`
        );
    }

    exportToCsv(input: IPageInput): Observable<Blob> {
        return this.httpClient.get(
            `/admin/export/csv/edition?sort=${input.sort}&order=${input.order}&filter=${input.filter ? JSON.stringify(input.filter) : ''}`,
            { responseType: 'blob' }
        );
    }
}
