/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {AbstractControl, FormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {FormFieldErrorListInterface, RequestErrorInterface} from '../interfaces/error.interface';
import {ChangeDetectorRef, EventEmitter, Input, Output} from '@angular/core';
import {ToasterService} from 'angular2-toaster';
import {IExtraItem, IExtraItemEditInput} from '../interfaces/extra-item.interface';
import {FORM_SCENARIO_CREATE} from '../constants';
import {ActivatedRoute, Router} from '@angular/router';
import {IItemService} from '../interfaces/item-service.interface';
import {Helper} from './helper';

export class BaseForm<TItem, TEditInput> {
    @Input() scenario: string;
    @Output() dataSaved: EventEmitter<any> = new EventEmitter();

    item: TItem = null;
    backLink = '/';
    formGroup: FormGroup = null;

    constructor(
        protected translate: TranslateService,
        protected toasterService: ToasterService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected changeDetectorRef: ChangeDetectorRef,
        protected itemService: IItemService<TItem, TEditInput>
    ){};

    getFormErrorMessage(): string {
        return ''; //TODO: add handler
    }

    getErrorMessage(control: AbstractControl): string {
        return Helper.getErrorMessage(control, this.translate);
    }

    onSave() {
        this.dataSaved.emit(this.item);
        this.popSaveSuccess();
    }

    onFail() {
        this.popSaveFailed();
    }

    goBack(): void {
        this.router.navigate([this.backLink]);
    }

    protected handleItemFromRequestOrHistory(itemKey: string) {
        if (FORM_SCENARIO_CREATE === this.scenario) {
            this.fillFormData();

            return;
        }

        this.route.paramMap.subscribe(params => {
            const id = Number(params.get('id'));

            if (!id) {
                this.router.navigate(['/404']);

                return;
            }

            // Get item from route data history
            if (undefined !== history.state[itemKey] && id === history.state[itemKey].id) {
                this.item = history.state[itemKey];

                if (undefined !== history.state.backLink && history.state.backLink.length) {
                    this.backLink = history.state.backLink;
                }

                this.fillFormData();

                return;
            }

            // Get item data from API
            this.itemService.getItem(id).subscribe((item) => {
                this.item = item;
                this.fillFormData();
            }, () => {
                this.router.navigate(['/404']);
            });
        }, err => {
            console.error(err);
        });
    }

    protected fillFormData() {}

    protected handleRequestError(result: any, translationNode?: string): void {
        if (null === result) {
            console.warn('Error. Can\'t save item');
            this.onFail();
        } else {
            this.applyErrorsFromRequest(result.error, translationNode);
            this.onFail();
        }
    }

    protected applyErrorsFromRequest(requestError: RequestErrorInterface, translationNode?: string) {
        const fields: FormFieldErrorListInterface = !!requestError.fields ? requestError.fields : {};
        const trNode = (translationNode && translationNode.length ? `${translationNode}.` : '') + 'errors.';
        Object.keys(fields).forEach((field: string) => {
            if (this.formGroup.contains(field)) {
                let errors = this.formGroup.controls[field].errors;
                errors = null === errors ? {} : errors;
                errors['invalid'] = this.translate.instant(trNode + fields[field].message);
                this.formGroup.controls[field].setErrors(errors);
                this.changeDetectorRef.detectChanges();
            }
        });
    }

    protected popSaveSuccess(): void {
        this.toasterService.pop(
            'success',
            this.translate.instant('pop.Success'),
            this.translate.instant('pop.Saved successfully')
        );
    }

    protected popSaveFailed(): void {
        this.toasterService.pop(
            'error',
            this.translate.instant('pop.Failed'),
            this.translate.instant('pop.Failed to save')
        );
    }
}
