<div class="role index-container">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{'role.index.Title'|translate}}</mat-card-title>
            <button mat-mini-fab (click)="toggleColumnFilter()" title="{{'common.Column filter'|translate}}">
                <mat-icon>swap_horiz</mat-icon>
            </button>
        </mat-card-header>

        <mat-card-content>
            <div class="row">
                <app-table-column-filter *ngIf="displayColumnFilter"
                    [available]="availableColumns"
                    [active]="activeColumns"
                    (changed)="onColumnFilterChanged($event)"></app-table-column-filter>
            </div>
            <div class="table-container">
                <mat-table
                    [dataSource]="itemList"
                    matSort
                    matSortActive="id"
                    matSortDisableClear
                    matSortDirection="desc"
                    class="v-scrollable">

                    <!-- id Column -->
                    <ng-container matColumnDef="id">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>item.fields.id</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.id}}</mat-cell>
                    </ng-container>

                    <!-- role Column -->
                    <ng-container matColumnDef="role">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>role.fields.role</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.role}}</mat-cell>
                    </ng-container>

                    <!-- policiesList Column -->
                    <ng-container matColumnDef="policiesList">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>role.fields.policiesList</span></mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <ng-container *ngFor="let policy of row.policiesList">
                                {{policy}}<br/>
                            </ng-container>
                        </mat-cell>
                    </ng-container>

                    <!-- createdAt Column -->
                    <ng-container matColumnDef="createdAt">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>role.fields.createdAt</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.createdAt | date : dateTimeLongFormat : timeZone}}</mat-cell>
                    </ng-container>

                    <!-- updatedAt Column -->
                    <ng-container matColumnDef="updatedAt">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>role.fields.updatedAt</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.updatedAt | date : dateTimeLongFormat : timeZone}}</mat-cell>
                    </ng-container>

                    <!-- action Column -->
                    <ng-container matColumnDef="action">
                        <mat-header-cell *matHeaderCellDef class="action-cell">
                            <a class="c-pointer"
                                title="{{'actions.create'|translate}}"
                                routerLink="/role/create">
                                <mat-icon>add_circle</mat-icon>
                            </a>
                        </mat-header-cell>

                        <mat-cell
                            *matCellDef="let row; let ind = index"
                            class="action-cell">

                            <a class="c-pointer"
                                title="{{'actions.view'|translate}}"
                                (click)="viewSelected(row)">
                                <mat-icon>visibility</mat-icon>
                            </a>
                            <a class="c-pointer"
                                title="{{'actions.update'|translate}}"
                                (click)="updateSelected(row)">
                                <mat-icon>edit</mat-icon>
                            </a>
                            <a class="c-pointer"
                                *ngIf="canDelete(row)"
                                title="{{'actions.delete'|translate}}"
                                (click)="deleteSelected(row)">
                                <mat-icon>delete</mat-icon>
                            </a>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="c-pointer"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>

                <mat-paginator [length]="resultsLength" [pageSize]="25" [hidePageSize]="true"></mat-paginator>
            </div>
        </mat-card-content>
    </mat-card>
</div>



