/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {LoginService} from '../../../services/login.service';
import * as _ from 'lodash';
import {TranslateService} from '@ngx-translate/core';
import {NavigationEnd, PRIMARY_OUTLET, Router} from '@angular/router';
import {EventService} from '../../../services/event.service';
import {
    EVENT_SIDEBAR_TOGGLE,
    EVENT_TITLE_UPDATED,
} from '../../../constants';
import {ITEM_LIST} from './item-list';
import {SidebarItemInterface} from '../../../interfaces/sidebar-item.interface';


@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
    toggled = false;
    itemList = ITEM_LIST;

    private baseRouteSegment = '';

    constructor(
        public loginService: LoginService,
        private translate: TranslateService,
        private router: Router,
        private eventService: EventService,
        private location: Location
    ) {
        this.handleInitialLocation();
        this.subscribeRouter();
    }

    setTitle(title: string) {
        this.eventService.event({event: EVENT_TITLE_UPDATED, title: title});
    }

    ngOnInit() {
        this.eventService.event$.subscribe((data) => {
            if (!_.isEmpty(data.event) && EVENT_SIDEBAR_TOGGLE === data.event) {
                this.toggled = !this.toggled;
            }
        });
    }

    isSectionOpen(name: string): boolean {
        return name === this.baseRouteSegment;
    }

    isMenuItem(item: SidebarItemInterface): boolean {
        return !_.isNil(item.type) && 'menu' === item.type;
    }

    isItemAvailable(item: SidebarItemInterface): boolean {
        if (_.isNil(item.availableRule)) {
            return true;
        }

        if (_.isFunction(item.availableRule)) {
            return item.availableRule();
        }

        return this.loginService.isGranted(item.availableRule);
    }

    itemHasIcon(item: SidebarItemInterface): boolean {
        return !_.isNil(item.icon) && item.icon.length > 0;
    }

    private subscribeRouter(): void {
        this.router.events.subscribe((event) => {
            this.toggled = false;

            if (event instanceof NavigationEnd) {
                const tree = this.router.parseUrl(this.router.url);
                if (_.isEmpty(tree.root.children[PRIMARY_OUTLET])) {
                    return;
                }

                const segments = tree.root.children[PRIMARY_OUTLET].segments;
                if (!segments.length) {
                    return;
                }

                const baseSegment = segments[0].path;
                if (baseSegment) {
                    this.setTitle(this.translate.instant('sidebar.Dashboard'));
                }

                this.baseRouteSegment = baseSegment;
            }
        });
    }

    private handleInitialLocation(): void {
        const segments = this.location.path().split('/');
        segments.splice(0, 1);
        if (segments.length) {
            this.baseRouteSegment = segments[0];
        }
    }
}
