<div class="fullscreen-overlay login-container" fxLayout="row" fxLayoutAlign="center center">
    <mat-card class="center-card">
        <mat-card-header>
            <mat-card-title>{{'login.Login'|translate}}</mat-card-title>
        </mat-card-header>

        <mat-card-content fxLayout="row" fxLayoutAlign="space-between start">
            <div class="left-side" fxFlex="45%" fxLayout="column" fxLayoutAlign="center start">
                <img class="logo" src="/assets/images/logo_small.png" alt="Kiesjefolders"/>

                <p class="info mt-4">
                    <a
                        routerLink="/forgot-password"
                        translate>login.Forgot password</a>
                </p>
            </div>
            <div class="right-side" fxFlex="45%" fxLayout="column" fxLayoutAlign="center center">
                <app-login-form class="w-100"></app-login-form>
            </div>
        </mat-card-content>
    </mat-card>
</div>



