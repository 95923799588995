<div class="message index-container">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{'message.index.Title'|translate}}</mat-card-title>
            <button mat-mini-fab (click)="toggleColumnFilter()" title="{{'common.Column filter'|translate}}">
                <mat-icon>swap_horiz</mat-icon>
            </button>
            <button mat-mini-fab (click)="toggleFilter()" title="{{'common.Filter'|translate}}">
                <mat-icon>filter_alt</mat-icon>
            </button>
        </mat-card-header>

        <mat-card-content>
            <div class="row">
                <app-table-filter *ngIf="displayFilter"
                    [config]="filterConfig"
                    [filter]="input.filter"
                    (filterChanged)="onFilterChanged($event)"></app-table-filter>

                <app-table-column-filter *ngIf="displayColumnFilter"
                    [available]="availableColumns"
                    [active]="activeColumns"
                    (changed)="onColumnFilterChanged($event)"></app-table-column-filter>
            </div>

            <div class="table-container">
                <mat-table
                    [dataSource]="itemList"
                    matSort
                    matSortActive="id"
                    matSortDisableClear
                    matSortDirection="desc"
                    class="v-scrollable">

                    <ng-container matColumnDef="id">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>item.fields.id</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.id}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="title">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>message.fields.title</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.title}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="description">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>message.fields.description</span></mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.description}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="flyer_id">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>message.fields.flyer_id</span></mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            {{row.Flyer ? row.Flyer.title : ''}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="image">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>message.fields.image</span></mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.image}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="link_url">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>message.fields.link_url</span></mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.link_url}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="notification_id">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>message.fields.notification_id</span></mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.notification_id}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="send_date">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>message.fields.send_date</span></mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.send_date}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="created">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>message.fields.created</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.created | date : dateTimeLongFormat : timeZone}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="updated">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>message.fields.updated</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.updated | date : dateTimeLongFormat : timeZone}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="action">
                        <mat-header-cell *matHeaderCellDef class="action-cell">
                            <a
                                class="c-pointer"
                                title="{{'actions.create'|translate}}"
                                routerLink="/message/create">
                                <mat-icon>add_circle</mat-icon>
                            </a>
                        </mat-header-cell>

                        <mat-cell *matCellDef="let row; let ind = index" class="action-cell">

                            <a class="c-pointer"
                                title="{{'actions.view'|translate}}"
                                (click)="viewSelected(row)">
                                <mat-icon>visibility</mat-icon>
                            </a>
                            <a class="c-pointer"
                                title="{{'actions.update'|translate}}"
                                (click)="updateSelected(row)">
                                <mat-icon>edit</mat-icon>
                            </a>
                            <a *ngIf="canDelete(row)"
                                class="c-pointer"
                                title="{{'actions.delete'|translate}}"
                                (click)="deleteSelected(row)">
                                <mat-icon>delete</mat-icon>
                            </a>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="c-pointer"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>

                <mat-paginator [length]="resultsLength" [pageSize]="25" [hidePageSize]="true"></mat-paginator>
            </div>
        </mat-card-content>
    </mat-card>
</div>



