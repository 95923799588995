/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {
    DATE_TIME_LONG_FORMAT,
    FILTER_BLOCK_TYPE_DATE_TIME_RANGE,
    FILTER_BLOCK_TYPE_INPUT,
    FILTER_BLOCK_TYPE_SEARCH_SELECT,
    FILTER_BLOCK_TYPE_SELECT,
    ITEM_FREQUENCY_OPTION_LIST,
    ITEM_STATUS_ID_OBJ_LIST,
    STATUS_OPTION_LIST,
    USER_POLICY_OBJ_LIST,
    YES_NO_OPTION_LIST
} from '@app/constants';
import {getCurrentTimeZone} from '@app/functions';
import {Router} from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTable} from '@angular/material/table';
import {LoginService} from '@services/login.service';
import {IExtraItem, IExtraItemEditInput} from '@interfaces/extra-item.interface';
import {ExtraItemService} from '@services/extra-item.service';
import {BaseIndexComponent} from '@classes/base-index';
import {UrlService} from '@services/url.service';
import {IFilterConfig, IFilterValue} from '@interfaces/filter.interface';
import {AdvertiserService} from '@services/advertiser.service';
import {IPageInput} from '@interfaces/page-input.interface';
import {Helper} from '@classes/helper';
import {StoreService} from '@services/store.service';
import {IColumn} from '@interfaces/column-filter.interface';

@Component({
    selector: 'app-extra-item-index',
    templateUrl: 'index.component.html'
})
export class ExtraItemIndexComponent extends BaseIndexComponent<IExtraItem, IExtraItemEditInput> implements OnInit, AfterViewInit {
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatTable, {static: true}) table: MatTable<any>;

    tableName = 'extraItemList';

    tableColumns: Array<IColumn> = [
        {
            name: 'id',
            title: 'item.fields.id'
        },
        {
            name: 'advertiser_id',
            title: 'extra-item.fields.advertiser_id'
        },
        {
            name: 'code',
            title: 'extra-item.fields.code'
        },
        {
            name: 'title',
            title: 'extra-item.fields.title'
        },
        {
            name: 'description_short',
            title: 'extra-item.fields.description_short'
        },
        {
            name: 'frequency',
            title: 'extra-item.fields.frequency'
        },
        {
            name: 'status_id',
            title: 'extra-item.fields.status_id'
        },
        {
            name: 'step',
            title: 'extra-item.fields.step'
        },
        {
            name: 'opt_in',
            title: 'extra-item.fields.opt_in'
        },
        {
            name: 'preselect',
            title: 'extra-item.fields.preselect'
        },
        {
            name: 'created',
            title: 'extra-item.fields.created'
        },
        {
            name: 'updated',
            title: 'extra-item.fields.updated'
        },
        {
            name: 'action',
            title: 'item.fields.action'
        }
    ];

    itemList: Array<IExtraItem> = [];
    dateTimeLongFormat = DATE_TIME_LONG_FORMAT;
    timeZone = getCurrentTimeZone();
    statusDeleted = ITEM_STATUS_ID_OBJ_LIST.DELETED;

    baseRoute = '/extra-item';
    itemHistoryKey = 'extraItem';

    filterConfig: IFilterConfig = {
        title: 'common.Filter',
        blocks: [
            {
                title: 'extra-item.fields.id',
                type: FILTER_BLOCK_TYPE_INPUT,
                propertyName: 'id'
            },
            {
                title: 'extra-item.fields.advertiser_id',
                type: FILTER_BLOCK_TYPE_SEARCH_SELECT,
                propertyName: 'advertiser_id',
                items: [],
                getList: (value: string) => {
                    return this.advertiserService.getOptionListByCompany(value);
                }
            },
            {
                title: 'extra-item.fields.code',
                type: FILTER_BLOCK_TYPE_INPUT,
                propertyName: 'code'
            },
            {
                title: 'extra-item.fields.title',
                type: FILTER_BLOCK_TYPE_INPUT,
                propertyName: 'title'
            },
            {
                title: 'extra-item.fields.description_short',
                type: FILTER_BLOCK_TYPE_INPUT,
                propertyName: 'description_short'
            },
            {
                title: 'extra-item.fields.frequency',
                type: FILTER_BLOCK_TYPE_SELECT,
                propertyName: 'frequency',
                items: ITEM_FREQUENCY_OPTION_LIST
            },
            {
                title: 'extra-item.fields.step',
                type: FILTER_BLOCK_TYPE_SELECT,
                propertyName: 'step',
                items: [
                    {id: '1', name: '1'},
                    {id: '2', name: '2'}
                ]
            },
            {
                title: 'extra-item.fields.opt_in',
                type: FILTER_BLOCK_TYPE_SELECT,
                propertyName: 'opt_in',
                items: YES_NO_OPTION_LIST
            },
            {
                title: 'extra-item.fields.preselect',
                type: FILTER_BLOCK_TYPE_SELECT,
                propertyName: 'preselect',
                items: YES_NO_OPTION_LIST
            },
            {
                title: 'extra-item.fields.status_id',
                type: FILTER_BLOCK_TYPE_SELECT,
                propertyName: 'status_id',
                items: STATUS_OPTION_LIST
            },
            {
                placeholderFrom: 'extra-item.fields.createdFrom',
                placeholderTo: 'extra-item.fields.createdTo',
                type: FILTER_BLOCK_TYPE_DATE_TIME_RANGE,
                propertyName: 'created'
            },
            {
                placeholderFrom: 'extra-item.fields.updatedFrom',
                placeholderTo: 'extra-item.fields.updatedTo',
                type: FILTER_BLOCK_TYPE_DATE_TIME_RANGE,
                propertyName: 'updated'
            }
        ]
    };

    constructor(
        protected itemService: ExtraItemService,
        protected deleteDialog: MatDialog,
        protected router: Router,
        protected urlService: UrlService,
        private loginService: LoginService,
        private advertiserService: AdvertiserService,
        protected storeServ: StoreService
    ) {
        super(itemService, deleteDialog, router, urlService, storeServ);
    }

    ngOnInit() {
        this.handleTableColumns();
    }

    ngAfterViewInit() {
        this.handleUrlQuery();
    }

    canDelete(item: IExtraItem): boolean {
        return this.loginService.isGranted(USER_POLICY_OBJ_LIST.POLICY_ADMIN_EXTRA_ITEM_EDIT) && item.status_id != ITEM_STATUS_ID_OBJ_LIST.DELETED;
    }

    toggleFilter(): void {
        this.displayFilter = !this.displayFilter;
    }

    onFilterChanged(filter: IFilterValue): void {
        this.input.filter = filter;

        this.filterChanged.emit(filter);
    }

    exportToCsv(): void {
        Helper.exportToCsv(this.input, this.itemService, 'extra_item_export.csv');
    }
}
