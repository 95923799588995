<div class="extra-item delete-dialog-container">
    <h1 mat-dialog-title translate>dialogs.delete.Delete item</h1>

    <mat-dialog-content>
        <p class="warning" translate>dialogs.delete.Warning</p>
        <p translate>dialogs.delete.Are you sure you want to delete it from the system?</p>
    </mat-dialog-content>

    <mat-dialog-actions class="d-flex justify-content-start">
        <button mat-raised-button color="warn" [mat-dialog-close]="true">{{'dialogs.delete.Delete'|translate}}</button>
        <button mat-raised-button [mat-dialog-close]="false" cdkFocusInitial>{{'dialogs.delete.Cancel'|translate}}</button>
    </mat-dialog-actions>
</div>
