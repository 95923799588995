/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-forgot-password',
    templateUrl: 'forgot-password.component.html'
})
export class ForgotPasswordComponent implements OnInit {
    showStep = 1;

    ngOnInit() {}

    onDataSent() {
        this.showStep = 2;
    }
}
