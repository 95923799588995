<div class="advertiser index-container">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{'advertiser.index.Title'|translate}}</mat-card-title>
            <button mat-mini-fab (click)="toggleColumnFilter()" title="{{'common.Column filter'|translate}}">
                <mat-icon>swap_horiz</mat-icon>
            </button>
            <button mat-mini-fab (click)="toggleFilter()" title="{{'common.Filter'|translate}}">
                <mat-icon>filter_alt</mat-icon>
            </button>
        </mat-card-header>

        <mat-card-content>
            <div class="row">
                <app-table-filter *ngIf="displayFilter"
                    [config]="filterConfig"
                    [filter]="input.filter"
                    (filterChanged)="onFilterChanged($event)"></app-table-filter>

                <app-table-column-filter *ngIf="displayColumnFilter"
                    [available]="availableColumns"
                    [active]="activeColumns"
                    (changed)="onColumnFilterChanged($event)"></app-table-column-filter>
            </div>

            <div class="table-container">
                <mat-table
                    [dataSource]="itemList"
                    matSort
                    matSortActive="id"
                    matSortDisableClear
                    matSortDirection="desc"
                    class="v-scrollable">

                    <ng-container matColumnDef="id">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>item.fields.id</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.id}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="status_id">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>advertiser.fields.status_id</span></mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <span class="status" [ngClass]="getStatusClass(row.status_id)" translate>{{'status.' + getStatusTranslationKey(row.status_id)}}</span>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="company">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>advertiser.fields.company</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.company}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="street_address">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>advertiser.fields.street_address</span></mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.street_address}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="zip_code">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>advertiser.fields.zip_code</span></mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.zip_code}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="city">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>advertiser.fields.city</span></mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.city}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="country">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>advertiser.fields.country</span></mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.country}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="state">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>advertiser.fields.state</span></mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.state}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="contact_name">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>advertiser.fields.contact_name</span></mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.contact_name}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="action">
                        <mat-header-cell *matHeaderCellDef class="action-cell">
                            <a
                                class="c-pointer"
                                title="{{'actions.create'|translate}}"
                                routerLink="/advertiser/create">
                                <mat-icon>add_circle</mat-icon>
                            </a>
                        </mat-header-cell>

                        <mat-cell
                            *matCellDef="let row; let ind = index"
                            class="action-cell">

                            <a
                                class="c-pointer"
                                *ngIf="row.status != statusDeleted"
                                title="{{'actions.view'|translate}}"
                                (click)="viewSelected(row)">
                                <mat-icon>visibility</mat-icon>
                            </a>
                            <a
                                class="c-pointer"
                                *ngIf="row.status != statusDeleted"
                                title="{{'actions.update'|translate}}"
                                (click)="updateSelected(row)">
                                <mat-icon>edit</mat-icon>
                            </a>
                            <a
                                class="c-pointer"
                                *ngIf="canDelete(row)"
                                title="{{'actions.delete'|translate}}"
                                (click)="deleteSelected(row)">
                                <mat-icon>delete</mat-icon>
                            </a>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="c-pointer"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>

                <mat-paginator [length]="resultsLength" [pageSize]="25" [hidePageSize]="true"></mat-paginator>
            </div>
        </mat-card-content>
    </mat-card>
</div>
