<div class="survey-answer index-container">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{'survey-answer.index.Title'|translate}}</mat-card-title>
            <button mat-mini-fab (click)="toggleColumnFilter()" title="{{'common.Column filter'|translate}}">
                <mat-icon>swap_horiz</mat-icon>
            </button>
            <button mat-mini-fab (click)="toggleFilter()" title="{{'common.Filter'|translate}}">
                <mat-icon>filter_alt</mat-icon>
            </button>
        </mat-card-header>

        <mat-card-content>
            <div class="row">
                <app-table-filter *ngIf="displayFilter"
                    [config]="filterConfig"
                    [filter]="input.filter"
                    (filterChanged)="onFilterChanged($event)"></app-table-filter>

                <app-table-column-filter *ngIf="displayColumnFilter"
                    [available]="availableColumns"
                    [active]="activeColumns"
                    (changed)="onColumnFilterChanged($event)"></app-table-column-filter>
            </div>

            <div class="table-container">
                <mat-table
                    [dataSource]="itemList"
                    matSort
                    matSortActive="id"
                    matSortDisableClear
                    matSortDirection="desc"
                    class="v-scrollable">

                    <ng-container matColumnDef="id">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>item.fields.id</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.id}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="user_id">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>survey-answer.fields.user_id</span></mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            {{row.User ? row.User.mail : ''}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="question_id">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>survey-answer.fields.question_id</span></mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            {{row.SurveyQuestion ? row.SurveyQuestion.question : ''}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="answer">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>survey-answer.fields.answer</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.answer}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="created">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>survey-answer.fields.created</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.created | date : dateTimeLongFormat : timeZone}}</mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="c-pointer"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>

                <mat-paginator [length]="resultsLength" [pageSize]="25" [hidePageSize]="true"></mat-paginator>
            </div>
        </mat-card-content>
    </mat-card>
</div>
