/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ToasterService} from 'angular2-toaster';
import {BaseForm} from '../../../classes/base-form';
import {FormFieldErrorListInterface, RequestErrorInterface} from '../../../interfaces/error.interface';
import {FlyerService} from '../../../services/flyer.service';
import {Helper} from '../../../classes/helper';

@Component({
    selector: 'app-flyer-accept-link-form',
    templateUrl: './form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlyerAcceptLinkFormComponent implements OnInit {
    error = '';
    formGroup: FormGroup = null;
    importFile: File = null;

    constructor(
        protected translate: TranslateService,
        private flyerService: FlyerService,
        private toasterService: ToasterService,
        private formBuilder: FormBuilder,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        this.buildFormGroup();
    }

    ngOnInit() {
    }

    getErrorMessage(control: AbstractControl): string {
        return Helper.getErrorMessage(control, this.translate);
    }

    onFileChange(event) {
        /*let reader = new FileReader();

        if(event.target.files && event.target.files.length) {
            const [file] = event.target.files;
            reader.readAsDataURL(file);

            reader.onload = () => {
                this.formGroup.patchValue({
                    file: reader.result
                });

                // need to run CD since file load runs outside of zone
                this.changeDetectorRef.markForCheck();
            };
        }*/

        if (event.target.files.length > 0) {
            this.importFile = event.target.files[0];
            // const file = event.target.files[0];

            /*this.formGroup.patchValue({
                file: file
            });*/
        }
    }

    getFormErrorMessage(): string {
        return ''; //TODO: add handler
    }

    submitForm(data: any): void {
        this.error = '';

        this.flyerService.generateAcceptLinks(this.importFile).subscribe((data) => {
            const downloadUrl = window.URL.createObjectURL(data);
            const link = document.createElement('a');
            link.setAttribute('target', '_blank');
            link.setAttribute('href', downloadUrl);
            link.setAttribute('download', 'flyer-accept-link.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove()
        }, (result: any) => {
            if (null === result) {
                console.warn('Error. Can\'t process file');
                this.onFail();
            } else {
                this.applyErrorsFromRequest(this.changeDetectorRef, this.formGroup, result.error);
                this.onFail();
            }
        });
    }

    onFail() {
        this.toasterService.pop('error', this.translate.instant('pop.Failed'), this.translate.instant('flyer.accept-link.form.File processing failed'));
    }

    private buildFormGroup() {
        this.formGroup = this.formBuilder.group({
            file: [null, Validators.required]
        });
    }

    protected applyErrorsFromRequest(changeDetectorRef: ChangeDetectorRef, formGroup: FormGroup, requestError: RequestErrorInterface) {
        const fields: FormFieldErrorListInterface = !!requestError.fields ? requestError.fields : {};
        Object.keys(fields).forEach((field: string) => {
            let control: AbstractControl = null;

            if (this.formGroup.contains(field)) {
                control = formGroup.controls[field];
            }

            if (null !== control) {
                let errors = control.errors;
                errors = null === errors ? {} : errors;
                errors['api_invalid'] = this.translate.instant('user.errors.' + fields[field].message);
                control.setErrors(errors);
                changeDetectorRef.detectChanges();
            }
        });
    }
}
