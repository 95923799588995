/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {PAGINATOR_PAGE_SIZE} from '../constants';
import {IPageInput} from '../interfaces/page-input.interface';
import {IItemList} from '../interfaces/item-list.interface';
import {IExtraItem, IExtraItemEditInput} from '../interfaces/extra-item.interface';

@Injectable({
    providedIn: 'root',
})

export class ExtraItemService {
    constructor(private httpClient: HttpClient) {}

    getItem(id: number): Observable<IExtraItem> {
        return this.httpClient.get<IExtraItem>(
            `/extra-item/${id}`
        );
    }

    getList(input: IPageInput): Observable<IItemList<IExtraItem>> {
        const offset = input.page * PAGINATOR_PAGE_SIZE;

        return this.httpClient.get<IItemList<IExtraItem>>(
            `/extra-item/list/?sort=${input.sort}&order=${input.order}&limit=${PAGINATOR_PAGE_SIZE}&offset=${offset}&filter=${input.filter ? JSON.stringify(input.filter) : ''}`
        );
    }

    storeItem(input: IExtraItemEditInput): Observable<IExtraItem> {
        const formData = new FormData();

        if (null !== input.image1) {
            formData.append('image1', input.image1, input.image1.name);
        }
        if (null !== input.image2) {
            formData.append('image2', input.image2, input.image2.name);
        }

        formData.append('data', JSON.stringify(input.data));

        if (input.data.id) {
            return this.httpClient.put<IExtraItem>(
                `/admin/extra-item/${input.data.id}`,
                formData,
            );
        }

        return this.httpClient.post<IExtraItem>(
            '/admin/extra-item',
            formData,
        );
    }

    deleteItem(item: IExtraItem): Observable<IExtraItem> {
        return this.httpClient.delete<IExtraItem>(
            `/admin/extra-item/${item.id}`
        );
    }

    exportToCsv(input: IPageInput): Observable<Blob> {
        return this.httpClient.get(
            `/admin/export/csv/extra-item?sort=${input.sort}&order=${input.order}&filter=${input.filter ? JSON.stringify(input.filter) : ''}`,
            { responseType: 'blob' }
        );
    }
}
