<form
    [formGroup]="formGroup"
    novalidate autocomplete="off"
    class="message form-container"
    (ngSubmit)="submitForm(formGroup.value)">

    <mat-error *ngIf="formGroup.invalid">{{getFormErrorMessage()|translate}}</mat-error>
    <mat-error *ngIf="error.length">{{error|translate}}</mat-error>

    <div *ngIf="scenario === scenarioEdit" class="row mb-4">
        <div class="col-12">
            <div class="item-id">{{'item.fields.id'|translate}}: {{item.id}}</div>
        </div>
    </div>

    <div class="row">
        <div class="col-6">
            <div class="row">
                <div class="col-12">
                    <mat-form-field>
                        <mat-label>{{'message.fields.flyer_id'|translate}}</mat-label>
                        <mat-select formControlName="flyer_id">
                            <mat-option *ngFor="let item of flyerList" [value]="item.id">{{item.title}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="formGroup.controls['flyer_id'].invalid">
                            {{getErrorMessage(formGroup.controls['flyer_id'])}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <mat-form-field>
                        <mat-label>{{'message.fields.title'|translate}}</mat-label>
                        <input
                            matInput
                            [placeholder]="'message.fields.title'|translate"
                            formControlName="title"
                            required>
                        <mat-error *ngIf="formGroup.controls['title'].invalid">
                            {{getErrorMessage(formGroup.controls['title'])}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <mat-form-field>
                        <mat-label>{{'message.fields.description'|translate}}</mat-label>
                        <input
                            matInput
                            [placeholder]="'message.fields.description'|translate"
                            formControlName="description"
                            required>
                        <mat-error *ngIf="formGroup.controls['description'].invalid">
                            {{getErrorMessage(formGroup.controls['description'])}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <mat-form-field>
                        <mat-label>{{'message.fields.link'|translate}}</mat-label>
                        <input
                            matInput
                            [placeholder]="'message.fields.link'|translate"
                            formControlName="link">
                        <mat-error *ngIf="formGroup.controls['link'].invalid">
                            {{getErrorMessage(formGroup.controls['link'])}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <mat-form-field>
                        <mat-label>{{'message.fields.link_url'|translate}}</mat-label>
                        <input
                            matInput
                            [placeholder]="'message.fields.link_url'|translate"
                            formControlName="link_url">
                        <mat-error *ngIf="formGroup.controls['link_url'].invalid">
                            {{getErrorMessage(formGroup.controls['link_url'])}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="col-6">
            <div class="row">
                <div class="col-12">
                    <div class="image-logo-container">
                        <mat-label class="logo-label">{{'message.fields.image'|translate}}</mat-label>
                        <app-image-upload
                            [options]="{
                                uploadText: 'form.Upload your logo',
                                imageRestriction: imageRestriction,
                                previewUrl: getImageLink()
                            }"
                            [control]="formGroup.controls['image1']"
                            (uploadSuccess)="onUploadSuccess($event)"
                            (uploadError)="onUploadError($event)"
                            (uploadCancelled)="onUploadCancel()"></app-image-upload>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="messageIsNew()">
                <div class="col-12">
                    <div class="mat-input">
                        <label>{{'message.form.userFile'|translate}}</label>
                        <input
                            class="w-100"
                            formControlName="userFile"
                            type="file"
                            (change)="onUserFileChange($event)"/>
                        <div class="mat-error" *ngIf="formGroup.controls['userFile'].invalid">
                            {{getErrorMessage(formGroup.controls['userFile'])}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <ng-container *ngIf="canChangeSendDate()">
                    <mat-form-field>
                        <mat-label class="logo-label">{{'message.fields.send_date'|translate}}</mat-label>
                        <input
                            matInput
                            [ngxMatDatetimePicker]="picker"
                            placeholder="Choose a date"
                            formControlName="send_date"
                            [min]="minDate">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <ngx-mat-datetime-picker #picker showSpinners="true">
                            <!-- Custom icon or text of Apply icon -->
                            <ng-template>
                                <!-- <mat-icon>star</mat-icon> -->
                                <span>OK</span>
                            </ng-template>
                        </ngx-mat-datetime-picker>
                    </mat-form-field>
                    </ng-container>

                    <ng-container *ngIf="!canChangeSendDate()">
                        <mat-form-field>
                            <mat-label class="logo-label">{{'message.fields.send_date'|translate}}</mat-label>
                            <input
                                matInput
                                placeholder="Choose a date"
                                formControlName="send_date"
                                readonly="true">
                        </mat-form-field>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <div class="button-row d-flex justify-content-end">
        <button
            mat-raised-button
            color="primary"
            type="submit"
            [disabled]="!formGroup.valid">{{'form.Save'|translate}}</button>

        <button
            mat-raised-button
            (click)="goBack()">{{'form.Cancel'|translate}}</button>
    </div>
</form>
