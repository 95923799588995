<div class="emdeb-file-uploader-container">
    <div class="mt-20 files-uploading-section">

        <section class="drop-area-file-list-section">
            <input
                type="file"
                (change)="onFileSelected($event)"
                style="display: none"
                #fileInput
            >

            <section id="browseButton" class="drop-area-wrapper"
                 (dragover)="onDragOver($event)"
                 (dragleave)="onDragLeave($event)"
                 (drop)="onDrop($event)"
                 (click)="fileInput.click();"
            >
                <section class="label-icon-holder">
                    <figure>
                        <svg viewBox="0 0 27 19" width="28" height="20">
                            <g id="cloud">
                                <rect width="30" height="30" style="fill:none"/>
                            </g>
                            <g id="file_transfer" data-name="file transfer">
                                <path class="cloud-icon" d="M21.7,9.68A4.07,4.07,0,0,0,18.15,6.6a6.91,6.91,0,0,0-10.83-2h0A5.41,5.41,0,0,0,1.91,10v.09a3.43,3.43,0,0,0,1.52,6.51h8v2.66a.59.59,0,0,0,.6.59.58.58,0,0,0,.59-.59V16.62h7.83A3.59,3.59,0,0,0,21.7,9.68Zm-1.29,5.79H12.58V13.66h1.06a.59.59,0,0,0,.55-.36.6.6,0,0,0-.13-.65l-1.53-1.53a.72.72,0,0,0-1,0L10,12.65a.6.6,0,0,0-.13.65.59.59,0,0,0,.55.36h1v1.81h-8A2.28,2.28,0,0,1,2.69,11l.43-.15,0-.45a2.82,2.82,0,0,1,0-.41A4.26,4.26,0,0,1,7.32,5.76h.45L8,5.6A5.68,5.68,0,0,1,12,4a5.76,5.76,0,0,1,5.27,3.44l.15.34h.37a3,3,0,0,1,1.9.72,2.89,2.89,0,0,1,1,1.75l.06.37.36.1a2.44,2.44,0,0,1-.64,4.8Z"/>
                            </g>
                        </svg>
                    </figure>
                    <p>
                        <label class="drop-area-label icon-color">{{"embed-file-uploader.Choose File"|translate}}</label>
                    </p>
                </section>
            </section>

            <section class="mt-10" id="filesContainer">
                <ng-container *ngIf="fileList.length > 0">
                    <ul>
                        <ng-container *ngFor="let file of fileList">
                            <li class="list-item-wrapper">
                                <div class="item-info-line">
                                    <div class="top-line">
                                        <mat-icon
                                            title="{{file.name}}"
                                            class="icon-color">
                                            attach_file
                                        </mat-icon>

                                        <span class="list-item list-file-details">{{file.name}}</span>
                                        <span class="list-file-details">{{getFilesSize(file.size)}}</span>
                                    </div>
                                    <div class="progress-line"></div>
                                </div>
                                <mat-icon
                                    class="remove-file-icon icon-color c-pointer"
                                    title="{{'embed-file-uploader.Remove file'|translate}}"
                                    (click)="removeAttachedFile(file)">close</mat-icon>
                            </li>
                        </ng-container>
                    </ul>
                </ng-container>
            </section>
        </section>
    </div>
</div>
