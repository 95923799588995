<form
    [formGroup]="formGroup"
    novalidate autocomplete="off"
    class="role form-container"
    (ngSubmit)="submitForm(formGroup.value)">

    <mat-error *ngIf="formGroup.invalid">{{getFormErrorMessage()|translate}}</mat-error>
    <mat-error *ngIf="error.length">{{error|translate}}</mat-error>

    <div *ngIf="scenario === scenarioEdit" class="row mb-4">
        <div class="col-12">
            <div class="role-id">{{'item.fields.id'|translate}}: {{item.id}}</div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <mat-form-field>
                <mat-label>{{'role.fields.role'|translate}}</mat-label>
                <input
                    matInput
                    [placeholder]="'role.fields.role'|translate"
                    formControlName="role"
                    required>
                <mat-error *ngIf="formGroup.controls['role'].invalid">
                    {{getErrorMessage(formGroup.controls['role'])}}
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col-12" >
            <div class="mat-form-field mat-form-field-can-float mat-form-field-should-float">
                <div class="mat-form-field-wrapper">
                    <div class="mat-form-field-flex">
                        <div class="mat-form-field-infix">
                            <label id="role-form-policiesList" class="mat-form-field-label">{{'role.fields.policiesList'|translate}}</label>
                            <div class="row role-form-policiesList" aria-labelledby="role-form-policiesList">
                                <div *ngFor="let policy of policiesList" class="col-4">
                                    <mat-checkbox
                                        [value]="policy"
                                        [checked]="selectedPoliciesList.indexOf(policy) !== -1"
                                        (change)="togglePolicy(policy)">{{policy}}</mat-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="button-row d-flex justify-content-end">
        <button
            mat-raised-button
            color="primary"
            type="submit"
            [disabled]="!formGroup.valid">{{'form.Save'|translate}}</button>

        <button
            mat-raised-button
            (click)="goBack()">{{'form.Cancel'|translate}}</button>
    </div>
</form>
