<form
    [formGroup]="formGroup"
    novalidate autocomplete="off"
    class="extra-item form-container"
    (ngSubmit)="submitForm(formGroup.value)">

    <mat-error *ngIf="formGroup.invalid">{{getFormErrorMessage()|translate}}</mat-error>
    <mat-error *ngIf="error.length">{{error|translate}}</mat-error>

    <div *ngIf="scenario === scenarioEdit" class="row mb-4">
        <div class="col-12">
            <div class="item-id">{{'extra-item.fields.id'|translate}}: {{item.id}}</div>
        </div>
    </div>

    <div class="row">
        <div class="col-6">
            <div class="row">
                <div class="col-12">
                    <mat-form-field>
                        <mat-label>{{'extra-item.fields.status_id'|translate}}</mat-label>
                        <mat-select
                            formControlName="status_id"
                            required>
                            <mat-option *ngFor="let item of statusList" [value]="item.id">{{item.name}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="formGroup.controls['status_id'].invalid">
                            {{getErrorMessage(formGroup.controls['status_id'])}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <mat-form-field>
                        <mat-label>{{'extra-item.fields.advertiser_id'|translate}}</mat-label>
                        <mat-select formControlName="advertiser_id">
                            <mat-option *ngFor="let item of advertiserList" [value]="item.id">{{item.company}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <mat-form-field>
                        <mat-label>{{'extra-item.fields.title'|translate}}</mat-label>
                        <input
                            matInput
                            [placeholder]="'extra-item.fields.title'|translate"
                            formControlName="title"
                            required>
                        <mat-error *ngIf="formGroup.controls['title'].invalid">
                            {{getErrorMessage(formGroup.controls['title'])}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <mat-form-field>
                        <mat-label>{{'extra-item.fields.code'|translate}}</mat-label>
                        <input
                            matInput
                            [placeholder]="'extra-item.fields.code'|translate"
                            formControlName="code"
                            required>
                        <mat-error *ngIf="formGroup.controls['code'].invalid">
                            {{getErrorMessage(formGroup.controls['code'])}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <mat-form-field>
                        <mat-label>{{'extra-item.fields.description_short'|translate}}</mat-label>
                        <input
                            matInput
                            [placeholder]="'extra-item.fields.description_short'|translate"
                            formControlName="description_short"
                            required>
                        <mat-error *ngIf="formGroup.controls['description_short'].invalid">
                            {{getErrorMessage(formGroup.controls['description_short'])}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <mat-form-field>
                        <mat-label>{{'extra-item.fields.frequency'|translate}}</mat-label>
                        <input
                            matInput
                            [placeholder]="'extra-item.fields.frequency'|translate"
                            formControlName="frequency"
                            required>
                        <mat-error *ngIf="formGroup.controls['frequency'].invalid">
                            {{getErrorMessage(formGroup.controls['frequency'])}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <mat-checkbox
                        color="primary"
                        formControlName="opt_in">
                        {{'extra-item.fields.opt_in'|translate}}
                    </mat-checkbox>
                </div>
            </div>
        </div>

        <div class="col-6">
            <div class="row">
                <div class="col-12">
                    <div class="image1-logo-container">
                        <mat-label class="logo-label">{{'extra-item.fields.image1'|translate}}</mat-label>
                        <app-image-upload
                            [options]="{
                                uploadText: 'form.Upload your logo',
                                imageRestriction: imageRestriction,
                                previewUrl: getImage1Link()
                            }"
                            [control]="formGroup.controls['image1']"
                            (uploadSuccess)="onUploadSuccess($event, uploadType.image1)"
                            (uploadError)="onUploadError($event, uploadType.image1)"
                            (uploadCancelled)="onUploadCancel(uploadType.image1)"></app-image-upload>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="image2-logo-container">
                        <mat-label class="logo-label">{{'extra-item.fields.image2'|translate}}</mat-label>
                        <app-image-upload
                            [options]="{
                                uploadText: 'form.Upload your logo',
                                imageRestriction: imageRestriction,
                                previewUrl: getImage2Link()
                            }"
                            [control]="formGroup.controls['image2']"
                            (uploadSuccess)="onUploadSuccess($event, uploadType.image2)"
                            (uploadError)="onUploadError($event, uploadType.image2)"
                            (uploadCancelled)="onUploadCancel(uploadType.image2)"></app-image-upload>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <mat-form-field>
                <mat-label>{{'extra-item.fields.description_long'|translate}}</mat-label>
                <textarea
                    matInput
                    [placeholder]="'extra-item.fields.description_long'|translate"
                    formControlName="description_long"
                    required></textarea>
                <mat-error *ngIf="formGroup.controls['description_long'].invalid">
                    {{getErrorMessage(formGroup.controls['description_long'])}}
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="button-row d-flex justify-content-end">
        <button
            mat-raised-button
            color="primary"
            type="submit"
            [disabled]="!formGroup.valid">{{'form.Save'|translate}}</button>

        <button
            mat-raised-button
            (click)="goBack()">{{'form.Cancel'|translate}}</button>
    </div>
</form>
