/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Component, OnInit} from '@angular/core';
import {LoginService} from '../../services/login.service';
import {environment} from '../../../environments/environment';


@Component({
    templateUrl: 'dashboard.component.html'
})

export class DashBoardComponent implements OnInit {
    userName: string;
    userNameHead: string;
    userNameTail: string;

    public constructor(
        public loginService: LoginService
    ) {
    }

    ngOnInit(): void {
        this.init();
    }

    init(): void {
        this.userName = this.loginService.getAccount().first_name;
        this.userNameHead = this.userName[0].toUpperCase();
        this.userNameTail = this.userName.substring(1);
    }

    getHeadBackground(): string {
        return `url(/assets/images/dashboard-top-block-bg.jpeg)`;
    }
}
