<div class="interest view-container">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{'interest.view.Title'|translate}}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <div class="row">
                <div class="col-4">
                    <h3>{{'interest.fields.status_id'|translate}}</h3>
                    <div class="status" [ngClass]="getStatusClass(item.status_id)">{{'status.' + getStatusName(item.status_id)|translate}}</div>
                </div>

                <div class="col-4">
                    <h3>{{'interest.fields.code'|translate}}</h3>
                    <div>{{item.code}}</div>
                </div>

                <div class="col-4">
                    <h3>{{'item.fields.id'|translate}}</h3>
                    <div>{{item.id}}</div>
                </div>
            </div>
            <hr>

            <div class="row">
                <div class="col-6">
                    <h3>{{'interest.fields.image1'|translate}}</h3>
                    <div>
                        <img *ngIf="item.image1 && item.image1.length"
                            [src]="item.image1"
                            class="admin-thumb"/>
                    </div>
                </div>

                <div class="col-6">
                    <h3>{{'interest.fields.image2'|translate}}</h3>
                    <div>
                        <img *ngIf="item.image2 && item.image2.length"
                            [src]="item.image2"
                            class="admin-thumb"/>
                    </div>
                </div>
            </div>
            <hr>

            <div class="row">
                <div class="col-4">
                    <h3>{{'interest.fields.title'|translate}}</h3>
                    <div>{{item.title}}</div>
                </div>

                <div class="col-4">
                    <h3>{{'interest.fields.description_short'|translate}}</h3>
                    <div>{{item.description_short}}</div>
                </div>

                <div class="col-4">
                    <h3>{{'interest.fields.description_long'|translate}}</h3>
                    <div>{{item.description_long}}</div>
                </div>
            </div>
            <hr>

            <div *ngIf="canIndex()" class="row">
                <div class="col-6">
                    <h3>{{'interest.fields.created'|translate}}</h3>
                    <div>{{item.created | date : dateTimeLongFormat : timeZone}}</div>
                </div>

                <div class="col-6">
                    <h3>{{'interest.fields.updated'|translate}}</h3>
                    <div>{{item.updated | date : dateTimeLongFormat : timeZone}}</div>
                </div>
            </div>
            <hr>

            <div class="button-row d-flex justify-content-end mt-3">
                <button
                    mat-raised-button
                    color="primary"
                    (click)="updateSelected(item)">{{'actions.update'|translate}}</button>

                <button *ngIf="canDelete()"
                    mat-raised-button
                    color="warn"
                    (click)="deleteSelected(item)">{{'actions.delete'|translate}}</button>

                <button *ngIf="canIndex()"
                    mat-raised-button
                    (click)="backToIndex()">{{'actions.Back to list'|translate}}</button>
            </div>


        </mat-card-content>
    </mat-card>
</div>



