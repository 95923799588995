/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {TextMaskConfig} from 'angular2-text-mask';
import _ from 'lodash';

@Component({
    selector: 'app-value-dialog',
    templateUrl: 'value.component.html'
})

export class ValueDialog {
    value: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
        this.value = data.value || '';
    }

    getMask(): TextMaskConfig {
        const mask: Array<string | RegExp> |false = _.isNil(this.data.mask) ? false : this.data.mask;

        return {
            mask: mask,
            guide: false
        }
    }
}
