/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {
    USER_POLICY_OBJ_LIST
} from '../../../constants';
import {LoginService} from '../../../services/login.service';
import {TranslateService} from '@ngx-translate/core';
import {ROLE_WITH_EMPTY_DATA, IRole, IRoleEditInput} from '../../../interfaces/role.interface';
import {RoleService} from '../../../services/role.service';
import {BaseViewComponent} from '../../../classes/base-view';

@Component({
    selector: 'app-role-view',
    templateUrl: './view.component.html'
})
export class RoleViewComponent extends BaseViewComponent<IRole, IRoleEditInput> implements OnInit {
    item: IRole = {...ROLE_WITH_EMPTY_DATA};
    baseRoute = '/role';
    itemHistoryKey = 'role';

    constructor(
        protected itemService: RoleService,
        protected translate: TranslateService,
        protected deleteDialog: MatDialog,
        protected router: Router,
        protected route: ActivatedRoute,
        private loginService: LoginService
    ) {
        super(itemService, translate, deleteDialog, router, route);
    }

    ngOnInit() {
        this.handleItemFromRequestOrHistory();
    }

    canIndex(): boolean {
        return this.loginService.isGranted(USER_POLICY_OBJ_LIST.POLICY_ADMIN_ROLE_VIEW);
    }

    canEdit(): boolean {
        return this.loginService.isGranted(USER_POLICY_OBJ_LIST.POLICY_ADMIN_ROLE_EDIT);
    }
}
