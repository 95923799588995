/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ToasterService} from 'angular2-toaster';
import {
    FORM_SCENARIO_CREATE,
    FORM_SCENARIO_EDIT,
    STATUS_OPTION_LIST, SURVEY_QUESTION_TYPE_OPTION_LIST
} from '@app/constants';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseForm} from '@classes/base-form';
import {IFlyer} from '@interfaces/flyer.interface';
import {
    ISurveyQuestion,
    ISurveyQuestionEditInput,
    SURVEY_QUESTION_WITH_EMPTY_DATA
} from '@interfaces/survey.interface';
import {SurveyQuestionService} from '@services/survey-question.service';


@Component({
    selector: 'app-survey-question-form',
    templateUrl: 'form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SurveyQuestionFormComponent extends BaseForm<ISurveyQuestion, ISurveyQuestionEditInput> implements OnInit {
    @Input() scenario: string;
    @Output() dataSaved: EventEmitter<ISurveyQuestion> = new EventEmitter();

    item: ISurveyQuestion = {...SURVEY_QUESTION_WITH_EMPTY_DATA};
    error = '';

    scenarioCreate = FORM_SCENARIO_CREATE; // Need for component HTML usage
    scenarioEdit = FORM_SCENARIO_EDIT; // Need for component HTML usage

    formGroup: FormGroup = null;

    typeList = SURVEY_QUESTION_TYPE_OPTION_LIST;

    surveyId: number = null;

    constructor(
        protected translate: TranslateService,
        protected itemService: SurveyQuestionService,
        protected toasterService: ToasterService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected changeDetectorRef: ChangeDetectorRef,
        private formBuilder: FormBuilder
    ) {
        super(translate, toasterService, route, router, changeDetectorRef, itemService);

        this.buildFormGroup();
    }

    ngOnInit() {
        this.handleItemFromRequestOrHistory('surveyQuestion');
    }

    fillFormData() {
        const formData = {
            type_id: this.item.type_id,
            question: this.item.question,
            answer: this.item.answer
        };

        this.formGroup.reset(formData);
    }

    getFormErrorMessage(): string {
        return ''; //TODO: add handler
    }

    submitForm(data: any): void {
        this.error = '';

        const input = this.getInputFromFormData(data);

        this.itemService.storeItem(input).subscribe((item) => {
            this.item = item;
            this.onSave();
        }, result => this.handleRequestError(result, 'SurveyQuestion'), () => {
            this.goBack();
        });
    }

    getInputFromFormData(data: any): ISurveyQuestionEditInput {
        return {
            id: this.item.id,
            survey_id: this.surveyId,
            type_id: data.type_id,
            question: data.question,
            answer: data.answer
        };
    }

    protected handleItemFromRequestOrHistory(itemKey: string) {
        this.route.paramMap.subscribe(params => {
            const id = Number(params.get('id'));

            if (!id) {
                this.router.navigate(['/404']);

                return;
            }

            if (FORM_SCENARIO_CREATE === this.scenario) {
                this.surveyId = id;
                this.backLink = `/survey/${this.surveyId}`;
                this.fillFormData();

                return;
            }

            // Get item from route data history
            if (undefined !== history.state[itemKey] && id === history.state[itemKey].id) {
                this.item = history.state[itemKey];
                this.surveyId = this.item.survey_id;

                if (undefined !== history.state.backLink && history.state.backLink.length) {
                    this.backLink = history.state.backLink;
                } else {
                    this.backLink = `/survey/${this.surveyId}`;
                }

                this.fillFormData();

                return;
            }

            // Get item data from API
            this.itemService.getItem(id).subscribe((item) => {
                this.item = item;
                this.surveyId = item.survey_id;
                this.backLink = `/survey/${this.surveyId}`;
                this.fillFormData();
            }, () => {
                this.router.navigate(['/404']);
            });
        }, err => {
            console.error(err);
        });
    }

    private buildFormGroup() {
        this.formGroup = this.formBuilder.group({
            type_id: ['', Validators.compose([
                Validators.required,
                Validators.maxLength(255)
            ])],
            question: ['', Validators.compose([
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(255)
            ])],
            answer: ['', Validators.compose([
                Validators.minLength(1),
                Validators.maxLength(255)
            ])]
        });
    }
}
