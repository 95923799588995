<div class="flyer-category view-container">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{'flyer-category.view.Title'|translate}}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <div class="row">
                <div class="col-4">
                    <h3>{{'flyer-category.fields.name'|translate}}</h3>
                    <div>{{item.name}}</div>
                </div>

                <div class="col-4">
                    <h3>{{'flyer-category.fields.description'|translate}}</h3>
                    <div>{{item.description}}</div>
                </div>

                <div class="col-4">
                    <h3>{{'flyer-category.fields.icon'|translate}}</h3>
                    <div><i [class]="'fa fa-' + item.icon"></i>&nbsp;{{item.icon}}</div>
                </div>
            </div>
            <hr>

            <div class="button-row d-flex justify-content-end mt-3">
                <button
                    mat-raised-button
                    color="primary"
                    (click)="updateSelected(item)">{{'actions.update'|translate}}</button>

                <button *ngIf="canDelete()"
                    mat-raised-button
                    color="warn"
                    (click)="deleteSelected(item)">{{'actions.delete'|translate}}</button>

                <button *ngIf="canIndex()"
                    mat-raised-button
                    (click)="backToIndex()">{{'actions.Back to list'|translate}}</button>
            </div>

        </mat-card-content>
    </mat-card>
</div>



