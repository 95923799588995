/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {IFilterValue} from '@interfaces/filter.interface';
import {EMPTY_PAGE_INPUT, IPageInput} from '@interfaces/page-input.interface';

@Injectable({
    providedIn: 'root'
})
export class UrlService {
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {}

    updateUrlQuery(params: {[key: string]: string}): Promise<boolean> {
        return this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: params,
            queryParamsHandling: 'merge', // preserve the existing query params in the route
        });
    }

    updateQueryFilter(filter: IFilterValue): Promise<boolean> {
        const value = typeof filter === 'string' ? filter : JSON.stringify(filter);

        return this.updateUrlQuery({filter: value});
    }

    updateQueryPage(page: number): Promise<boolean> {
        return this.updateUrlQuery({page: page.toString()});
    }

    updateQuerySort(sort: string, order: string): Promise<boolean> {
        return this.updateUrlQuery({
            sort: sort,
            order: order,
        });
    }

    async handleUrlQuery(): Promise<IPageInput> {
        return new Promise<IPageInput>((resolve, reject) => {
            this.activatedRoute.queryParams.subscribe((params) => {
                const tableQuery: IPageInput = {
                    page: +params['page'] || 0,
                    sort: params['sort'] || '',
                    order: params['order'] || '',
                    filter: {}
                };

                try {
                    const filter = params['filter'] || '';
                    if (filter.length) {
                        tableQuery.filter = JSON.parse(filter);
                    }
                } catch (e) {
                    console.warn('Can\'t parse filter query parameter');
                }

                resolve(tableQuery);
            }, () => {
                reject(EMPTY_PAGE_INPUT);
            });
        });
    }
}
