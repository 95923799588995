/**
 * 2020 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2020 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {
    THOUSAND,
    MILLION,
} from './constants';
import {FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {COUNTRY_LIST} from './countries';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '/assets/translations/', '.json');
}

export function downloadFile(fileName: string, fileData: Blob) {
    const downloadURL = window.URL.createObjectURL(fileData);
    const link = document.createElement('a');
    link.setAttribute('style', 'display: none');
    link.href = downloadURL;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(downloadURL);
    link.remove();
}

export function getFilesSize(size: number): string {
    const KILOBYTE = 1024;
    const MEGABYTE = 1048576;
    const GIGABYTE = 1073741824;

    if (size < KILOBYTE) {
        return `${size} B`;
    } else if (size < MEGABYTE) {
        return `${(size / KILOBYTE).toFixed(2)} KB`;
    } else if (size < GIGABYTE) {
        return `${(size / MEGABYTE).toFixed(2)} MB`;
    } else if (GIGABYTE < size) {
        return `${(size / GIGABYTE).toFixed(2)} GB`;
    }
}

export function shortenNumber (value: number): string {

    if (value < THOUSAND) {
        return value.toString();
    } else if (THOUSAND < value && value < MILLION) {
        return `${Math.round(value / THOUSAND)}k`;
    } else if (MILLION <= value) {
        return `${Math.round(value / MILLION)}m`;
    }
}

/**
 * Get current local timezone offset
 */
export function maxTimezoneOffset(): number {
    const today = new Date();
    const jan = new Date(today.getFullYear(), 0, 1);
    const jul = new Date(today.getFullYear(), 6, 1);

    return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
}

/**
 * Check that customer is in DaylightSavingTime zone (Summer time)
 */
export const {isDaylightSavingTime} = new class {
    hasDaylightSavingTime: boolean = null;

    isDaylightSavingTime = () => {
        if (null === this.hasDaylightSavingTime) {
            const today = new Date();
            this.hasDaylightSavingTime = today.getTimezoneOffset() < maxTimezoneOffset();
        }

        return this.hasDaylightSavingTime;
    };
};

/**
 * Get current time zone
 */
export const {getCurrentTimeZone} = new class {
    timezone: string = null;

    getCurrentTimeZone = () => {
        if (null === this.timezone) {
            const today = new Date();
            const offset = Math.floor(today.getTimezoneOffset() / 60);
            const sign = offset < 0 ? '+' : '-';

            this.timezone = sign + Math.abs(offset);
        }

        return this.timezone;
    };
};

export function compareCitiesById(a: any, b: any): number {
    if (a.id < b.id) {
        return -1;
    }

    if (a.id > b.id) {
        return 1;
    }

    return 0;
}

export function matchPasswordValidator(formGroup: FormGroup): ValidationErrors|null {
    const password = formGroup.contains('newPassword') ? formGroup.controls['newPassword'].value : formGroup.controls['password'].value;
    const repeatPassword = formGroup.controls['repeatPassword'].value;

    if (repeatPassword.length <= 0) {
        return null;
    }

    if (repeatPassword !== password) {
        return {
            matchPassword: true
        };
    }

    return null;
}

export function getCountryNameByIso2(iso2: string): string|null {
    for (const country of COUNTRY_LIST) {
        if (country.iso2 === iso2) {
            return country.name;
        }
    }

    console.warn(`Can't find country by iso2 for "${iso2}"`);

    return null;
}
