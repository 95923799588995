/**
 * 2022 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2022 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {PAGINATOR_PAGE_SIZE} from '../constants';
import {IPageInput} from '@interfaces/page-input.interface';
import {IItemList} from '@interfaces/item-list.interface';
import {IFlyerEmailOptin, IFlyerEmailOptinEditInput} from '@interfaces/flyer-email-optin.interface';

@Injectable({
    providedIn: 'root',
})

export class FlyerEmailOptinService {
    constructor(private httpClient: HttpClient) {}

    getItem(id: number): Observable<IFlyerEmailOptin> {
        return this.httpClient.get<IFlyerEmailOptin>(
            `/admin/flyer-email-optin/${id}`
        );
    }

    getList(input: IPageInput): Observable<IItemList<IFlyerEmailOptin>> {
        const offset = input.page * PAGINATOR_PAGE_SIZE;

        return this.httpClient.get<IItemList<IFlyerEmailOptin>>(
            `/admin/flyer-email-optin/?sort=${input.sort}&order=${input.order}&limit=${PAGINATOR_PAGE_SIZE}&offset=${offset}&filter=${input.filter ? JSON.stringify(input.filter) : ''}`
        );
    }

    storeItem(input: IFlyerEmailOptinEditInput): Observable<IFlyerEmailOptin> {
        if (input.id) {
            return this.httpClient.put<IFlyerEmailOptin>(
                `/admin/flyer-email-optin/${input.id}`,
                input,
            );
        }

        return this.httpClient.post<IFlyerEmailOptin>(
            '/admin/flyer-email-optin',
            input,
        );
    }

    deleteItem(item: IFlyerEmailOptin): Observable<IFlyerEmailOptin> {
        return this.httpClient.delete<IFlyerEmailOptin>(
            `/admin/flyer-email-optin/${item.id}`
        );
    }
}
