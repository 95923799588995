<div class="user import-container">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{'user.import.Title'|translate}}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <form
                [formGroup]="formGroup"
                novalidate autocomplete="off"
                class="user-import-form-container"
                (ngSubmit)="submitForm()">

                <mat-error *ngIf="formGroup.invalid">{{getFormErrorMessage()|translate}}</mat-error>
                <mat-error *ngIf="error.length">{{error|translate}}</mat-error>

                <div class="row">
                    <div class="col-12">
                        <p translate>user.import.form.Please upload CSV file with proper columns</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="mat-input">
                            <label>{{'user.import.form.file'|translate}}</label>
                            <input
                                formControlName="file"
                                type="file"
                                (change)="onFileChange($event)"/>
                            <div class="mat-error" *ngIf="formGroup.controls['file'].invalid">
                                {{getErrorMessage(formGroup.controls['file'])}}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="button-row d-flex justify-content-end">
                    <button
                        mat-raised-button
                        color="primary"
                        type="submit"
                        [disabled]="!formGroup.valid">{{'user.import.form.Import'|translate}}</button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>

    <mat-card *ngIf="processLog.length > 0">
        <mat-card-header>
            <mat-card-title>{{'user.import.Process log'|translate}}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <textarea
                class="process-log"
                rows="20"
                readonly>{{processLog}}</textarea>
        </mat-card-content>
    </mat-card>
</div>


