<div class="value-dialog-container">
    <h1 mat-dialog-title translate>{{data.title}}</h1>

    <mat-dialog-content>
        <mat-form-field>
            <input
                matInput
                [(ngModel)]="value"
                [maxlength]="data.maxLength || 255"
                [textMask]="getMask()"
                cdkFocusInitial>
        </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions class="d-flex justify-content-start">
        <button mat-raised-button color="primary" [mat-dialog-close]="value">{{'dialogs.value.Ok'|translate}}</button>
        <button mat-raised-button [mat-dialog-close]="null">{{'dialogs.value.Cancel'|translate}}</button>
    </mat-dialog-actions>
</div>
