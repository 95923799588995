/**
 * 2022 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2022 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {
    FILTER_BLOCK_TYPE_INPUT,
    ITEM_STATUS_ID_OBJ_LIST,
    USER_POLICY_OBJ_LIST
} from '@app/constants';
import {Router} from '@angular/router';
import {LoginService} from '@services/login.service';
import {BaseIndexComponent} from '@classes/base-index';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTable} from '@angular/material/table';
import {IFilterConfig, IFilterValue} from '@interfaces/filter.interface';
import {UrlService} from '@services/url.service';
import {StoreService} from '@services/store.service';
import {IColumn} from '@interfaces/column-filter.interface';
import {IAdvertiser, IAdvertiserEditInput} from '@interfaces/advertiser.interface';
import {AdvertiserService} from '@services/advertiser.service';

@Component({
    selector: 'app-advertiser-index',
    templateUrl: 'index.component.html'
})
export class AdvertiserIndexComponent extends BaseIndexComponent<IAdvertiser, IAdvertiserEditInput> implements OnInit, AfterViewInit {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatTable) table: MatTable<any>;

    tableName = 'advertiserList';

    tableColumns: Array<IColumn> = [
        {
            name: 'id',
            title: 'item.fields.id'
        },
        {
            name: 'status_id',
            title: 'advertiser.fields.status_id'
        },
        {
            name: 'company',
            title: 'advertiser.fields.company'
        },
        {
            name: 'street_address',
            title: 'advertiser.fields.street_address'
        },
        {
            name: 'zip_code',
            title: 'advertiser.fields.zip_code'
        },
        {
            name: 'city',
            title: 'advertiser.fields.city'
        },
        {
            name: 'country',
            title: 'advertiser.fields.country'
        },
        {
            name: 'state',
            title: 'advertiser.fields.state'
        },
        {
            name: 'contact_name',
            title: 'advertiser.fields.contact_name'
        },
        {
            name: 'action',
            title: 'item.fields.action'
        }
    ];

    statusDeleted = ITEM_STATUS_ID_OBJ_LIST.DELETED;

    baseRoute = '/advertiser';
    itemHistoryKey = 'advertiser';

    filterConfig: IFilterConfig = {
        title: 'common.Filter',
        blocks: [
            {
                title: 'item.fields.id',
                type: FILTER_BLOCK_TYPE_INPUT,
                propertyName: 'id',
            },
            {
                title: 'advertiser.fields.company',
                type: FILTER_BLOCK_TYPE_INPUT,
                propertyName: 'company',
            },
            {
                title: 'advertiser.fields.zip_code',
                type: FILTER_BLOCK_TYPE_INPUT,
                propertyName: 'zip_code'
            },
            {
                title: 'advertiser.fields.contact_name',
                type: FILTER_BLOCK_TYPE_INPUT,
                propertyName: 'contact_name'
            }
        ]
    }

    constructor(
        protected itemService: AdvertiserService,
        protected deleteDialog: MatDialog,
        protected router: Router,
        protected urlService: UrlService,
        private loginService: LoginService,
        protected storeServ: StoreService
    ) {
        super(itemService, deleteDialog, router, urlService, storeServ);
    }

    ngOnInit() {
        this.handleTableColumns();
    }

    ngAfterViewInit() {
        this.handleUrlQuery();
    }

    canDelete(item: IAdvertiser): boolean {
        return this.loginService.isGranted(USER_POLICY_OBJ_LIST.POLICY_ADMIN_ADVERTISER_EDIT)
            && item.status_id !== ITEM_STATUS_ID_OBJ_LIST.DELETED;
    }

    toggleFilter(): void {
        this.displayFilter = !this.displayFilter;
    }

    onFilterChanged(filter: IFilterValue): void {
        this.input.filter = filter;

        this.filterChanged.emit(filter);
    }
}
