<form
    [formGroup]="formGroup"
    novalidate autocomplete="off"
    class="survey form-container"
    (ngSubmit)="submitForm(formGroup.value)">

    <mat-error *ngIf="formGroup.invalid">{{getFormErrorMessage()|translate}}</mat-error>
    <mat-error *ngIf="error.length">{{error|translate}}</mat-error>

    <div *ngIf="scenario === scenarioEdit" class="row mb-4">
        <div class="col-12">
            <div class="item-id">{{'item.fields.id'|translate}}: {{item.id}}</div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-md-6">
            <mat-form-field>
                <mat-label>{{'survey-question.fields.type_id'|translate}}</mat-label>
                <mat-select
                    formControlName="type_id"
                    required>
                    <mat-option *ngFor="let item of typeList" [value]="item.id">{{item.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="formGroup.controls['type_id'].invalid">
                    {{getErrorMessage(formGroup.controls['type_id'])}}
                </mat-error>
            </mat-form-field>
        </div>

        <div class="col-12 col-md-6">
            <mat-form-field>
                <mat-label>{{'survey-question.fields.question'|translate}}</mat-label>
                <input
                    matInput
                    [placeholder]="'survey-question.fields.question'|translate"
                    formControlName="question"
                    required>
                <mat-error *ngIf="formGroup.controls['question'].invalid">
                    {{getErrorMessage(formGroup.controls['question'])}}
                </mat-error>
            </mat-form-field>
        </div>

        <div class="col-12 col-md-6">
            <mat-form-field>
                <mat-label>{{'survey-question.fields.answer'|translate}}</mat-label>
                <input
                    matInput
                    [placeholder]="'survey-question.fields.answer'|translate"
                    formControlName="answer">
                <mat-error *ngIf="formGroup.controls['answer'].invalid">
                    {{getErrorMessage(formGroup.controls['answer'])}}
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="button-row d-flex justify-content-end">
        <button
            mat-raised-button
            color="primary"
            type="submit"
            [disabled]="!formGroup.valid">{{'form.Save'|translate}}</button>

        <button
            mat-raised-button
            (click)="goBack()">{{'form.Cancel'|translate}}</button>
    </div>
</form>
