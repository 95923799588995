/**
 * 2020 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2020 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Component} from '@angular/core';

@Component({
  templateUrl: 'page-not-found.component.html'
})

export class PageNotFoundComponent  {
    constructor() {}
}
