<div class="flyer-zip-area index-container">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{'flyer-zip-area.index.Title'|translate}}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <div class="table-container">
                <mat-table
                    [dataSource]="itemList"
                    matSort
                    matSortActive="id"
                    matSortDisableClear
                    matSortDirection="desc"
                    class="v-scrollable">

                    <ng-container matColumnDef="id">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>item.fields.id</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.id}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="flyer_id">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>flyer-zip-area.fields.flyer_id</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.flyer_id}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="zip_area">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>flyer-zip-area.fields.zip_area</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.zip_area}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="zip_code">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>flyer-zip-area.fields.zip_code</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.zip_code}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="action">
                        <mat-header-cell *matHeaderCellDef class="action-cell">
                            <div class="c-pointer"
                                title="{{'actions.create'|translate}}"
                                (click)="create()">
                                <mat-icon>add_circle</mat-icon>
                            </div>
                        </mat-header-cell>

                        <mat-cell
                            *matCellDef="let row; let ind = index"
                            class="action-cell">

                            <div class="c-pointer"
                                *ngIf="canEdit()"
                                title="{{'actions.update'|translate}}"
                                (click)="updateSelected(row)">
                                <mat-icon>edit</mat-icon>
                            </div>
                            <div class="c-pointer"
                                *ngIf="canEdit()"
                                title="{{'actions.delete'|translate}}"
                                (click)="deleteSelected(row)">
                                <mat-icon>delete</mat-icon>
                            </div>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns" class="c-pointer"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>

                <mat-paginator [length]="resultsLength" [pageSize]="25" [hidePageSize]="true"></mat-paginator>
            </div>
        </mat-card-content>
    </mat-card>
</div>



