<div class="user index-container">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{'user.index.Title'|translate}}</mat-card-title>
            <button mat-mini-fab (click)="toggleColumnFilter()" title="{{'common.Column filter'|translate}}">
                <mat-icon>swap_horiz</mat-icon>
            </button>
            <button mat-mini-fab (click)="toggleFilter()" title="{{'common.Filter'|translate}}">
                <mat-icon>filter_alt</mat-icon>
            </button>
        </mat-card-header>

        <mat-card-content>
            <div class="row">
                <app-table-filter *ngIf="displayFilter"
                    [config]="filterConfig"
                    [filter]="input.filter"
                    (filterChanged)="onFilterChanged($event)"></app-table-filter>

                <app-table-column-filter *ngIf="displayColumnFilter"
                    [available]="availableColumns"
                    [active]="activeColumns"
                    (changed)="onColumnFilterChanged($event)"></app-table-column-filter>
            </div>

            <div class="table-container">
                <mat-table
                    [dataSource]="itemList"
                    matSort
                    matSortActive="id"
                    matSortDisableClear
                    matSortDirection="desc"
                    class="v-scrollable">

                    <!-- id Column -->
                    <ng-container matColumnDef="id">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>item.fields.id</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.id}}</mat-cell>
                    </ng-container>

                    <!-- status Column -->
                    <ng-container matColumnDef="status_id">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>user.fields.status_id</span></mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <span class="status" [ngClass]="getStatusClass(row.status_id)" translate>{{'status.' + getStatusTranslationKey(row.status_id)}}</span>
                        </mat-cell>
                    </ng-container>

                    <!-- companyName Column -->
                    <ng-container matColumnDef="first_name">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>user.fields.first_name</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.first_name}}</mat-cell>
                    </ng-container>

                    <!-- address Column -->
                    <ng-container matColumnDef="mail">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>user.fields.mail</span></mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.mail}}</mat-cell>
                    </ng-container>

                    <!-- phoneNumber Column -->
                    <ng-container matColumnDef="phone">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>user.fields.phone</span></mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.phone}}</mat-cell>
                    </ng-container>

                    <!-- Users Column -->
                    <ng-container matColumnDef="role_id">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>user.fields.role_id</span></mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.Role.role}}</mat-cell>
                    </ng-container>

                    <!-- createdAt Column -->
                    <ng-container matColumnDef="created">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>user.fields.created</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.created | date : dateTimeLongFormat : timeZone}}</mat-cell>
                    </ng-container>

                    <!-- updatedAt Column -->
                    <ng-container matColumnDef="updated">
                        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            <span translate>user.fields.updated</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">{{row.updated | date : dateTimeLongFormat : timeZone}}</mat-cell>
                    </ng-container>

                    <!-- action Column -->
                    <ng-container matColumnDef="action">
                        <mat-header-cell *matHeaderCellDef class="action-cell">
                            <a
                                class="c-pointer"
                                title="{{'actions.create'|translate}}"
                                routerLink="/user/create">
                                <mat-icon>add_circle</mat-icon>
                            </a>
                        </mat-header-cell>

                        <mat-cell
                            *matCellDef="let row; let ind = index"
                            class="action-cell">

                            <a
                                class="c-pointer"
                                *ngIf="row.status != statusDeleted"
                                title="{{'actions.view'|translate}}"
                                (click)="viewSelected(row)">
                                <mat-icon>visibility</mat-icon>
                            </a>
                            <a
                                class="c-pointer"
                                *ngIf="row.status != statusDeleted"
                                title="{{'actions.update'|translate}}"
                                (click)="updateSelected(row)">
                                <mat-icon>edit</mat-icon>
                            </a>
                            <a
                                class="c-pointer"
                                *ngIf="canDelete(row)"
                                title="{{'actions.delete'|translate}}"
                                (click)="deleteSelected(row)">
                                <mat-icon>delete</mat-icon>
                            </a>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="c-pointer"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>

                <mat-paginator [length]="resultsLength" [pageSize]="25" [hidePageSize]="true"></mat-paginator>
            </div>
        </mat-card-content>
    </mat-card>
</div>



