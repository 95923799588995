<div class="flyer-email-optin update-container">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{'flyer-email-optin.update.Title'|translate}}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <app-flyer-email-optin-form [scenario]="formScenario"></app-flyer-email-optin-form>
        </mat-card-content>
    </mat-card>
</div>
