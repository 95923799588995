<form
    [formGroup]="formGroup"
    novalidate autocomplete="off"
    class="user form-container"
    (ngSubmit)="submitForm(formGroup.value)">

    <mat-error *ngIf="formGroup.invalid">{{getFormErrorMessage()|translate}}</mat-error>
    <mat-error *ngIf="error.length">{{error|translate}}</mat-error>

    <div *ngIf="scenario === scenarioEdit" class="row mb-4">
        <div class="col-12">
            <div class="user-id">{{'item.fields.id'|translate}}: {{item.id}}</div>
        </div>
    </div>

    <div class="row">
        <div class="col-6">
            <div class="row">
                <div class="col-12">
                    <mat-form-field>
                        <mat-label>{{'user.fields.status_id'|translate}}</mat-label>
                        <mat-select
                            formControlName="status_id"
                            required>
                            <mat-option *ngFor="let item of statusList" [value]="item.id">{{item.name}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="formGroup.controls['status_id'].invalid">
                            {{getErrorMessage(formGroup.controls['status_id'])}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <mat-form-field>
                        <mat-label>{{'user.fields.role_id'|translate}}</mat-label>
                        <mat-select formControlName="role_id" required>
                            <mat-option *ngFor="let item of roleList" [value]="item.id">{{item.role}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="formGroup.controls['role_id'].invalid">
                            {{getErrorMessage(formGroup.controls['role_id'])}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <mat-form-field>
                        <mat-label>{{'user.fields.source_id'|translate}}</mat-label>
                        <mat-select formControlName="source_id" required>
                            <mat-option *ngFor="let item of sourceList" [value]="item.id">{{item.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <mat-form-field>
                        <mat-label>{{'user.fields.advertiser_id'|translate}}</mat-label>
                        <mat-select formControlName="advertiser_id">
                            <mat-option *ngFor="let item of advertiserList" [value]="item.id">{{item.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <mat-form-field>
                        <mat-label>{{'user.fields.admin_note'|translate}}</mat-label>
                        <textarea
                            matInput
                            [placeholder]="'user.fields.admin_note'|translate"
                            formControlName="admin_note"></textarea>
                        <mat-error *ngIf="formGroup.controls['admin_note'].invalid">
                            {{getErrorMessage(formGroup.controls['admin_note'])}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="mat-form-field mat-form-field-can-float mat-form-field-should-float">
                        <div class="mat-form-field-wrapper">
                            <div class="mat-form-field-flex">
                                <div class="mat-form-field-infix">
                                    <label id="user-form-sex-label" class="mat-form-field-label">{{'user.fields.sex'|translate}}</label>
                                    <mat-radio-group
                                        formControlName="sex"
                                        aria-labelledby="user-form-sex-label"
                                        class="">
                                        <mat-radio-button class="" *ngFor="let item of sexList" [value]="item.id">
                                            {{item.name}}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <mat-form-field>
                        <mat-label>{{'user.fields.first_name'|translate}}</mat-label>
                        <input
                            matInput
                            [placeholder]="'user.fields.first_name'|translate"
                            formControlName="first_name"
                            required>
                        <mat-error *ngIf="formGroup.controls['first_name'].invalid">
                            {{getErrorMessage(formGroup.controls['first_name'])}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <mat-form-field>
                        <mat-label>{{'user.fields.middle_name'|translate}}</mat-label>
                        <input
                            matInput
                            [placeholder]="'user.fields.middle_name'|translate"
                            formControlName="middle_name">
                        <mat-error *ngIf="formGroup.controls['middle_name'].invalid">
                            {{getErrorMessage(formGroup.controls['middle_name'])}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <mat-form-field>
                        <mat-label>{{'user.fields.last_name'|translate}}</mat-label>
                        <input
                            matInput
                            [placeholder]="'user.fields.last_name'|translate"
                            formControlName="last_name"
                            required>
                        <mat-error *ngIf="formGroup.controls['last_name'].invalid">
                            {{getErrorMessage(formGroup.controls['last_name'])}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="col-6">
            <div class="row">
                <div class="col-12">
                    <mat-form-field>
                        <mat-label>{{'user.fields.mail'|translate}}</mat-label>
                        <input
                            matInput
                            [placeholder]="'user.fields.mail'|translate"
                            formControlName="mail"
                            required>
                        <mat-error *ngIf="formGroup.controls['mail'].invalid">
                            {{getErrorMessage(formGroup.controls['mail'])}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <mat-form-field>
                        <mat-label>{{'user.fields.street_address'|translate}}</mat-label>
                        <input
                            matInput
                            [placeholder]="'user.fields.street_address'|translate"
                            formControlName="street_address">
                        <mat-error *ngIf="formGroup.controls['street_address'].invalid">
                            {{getErrorMessage(formGroup.controls['street_address'])}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <mat-form-field>
                        <mat-label>{{'user.fields.zip_code'|translate}}</mat-label>
                        <input
                            matInput
                            [placeholder]="'user.fields.zip_code'|translate"
                            formControlName="zip_code"
                            required>
                        <mat-error *ngIf="formGroup.controls['zip_code'].invalid">
                            {{getErrorMessage(formGroup.controls['zip_code'])}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <mat-form-field>
                        <mat-label>{{'user.fields.building_number'|translate}}</mat-label>
                        <input
                            matInput
                            appOnlyNumbers
                            [placeholder]="'user.fields.building_number'|translate"
                            formControlName="building_number"
                            required>
                        <mat-error *ngIf="formGroup.controls['building_number'].invalid">
                            {{getErrorMessage(formGroup.controls['building_number'])}}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-6">
                    <mat-form-field>
                        <mat-label>{{'user.fields.building_number_extra'|translate}}</mat-label>
                        <input
                            matInput
                            [placeholder]="'user.fields.building_number_extra'|translate"
                            formControlName="building_number_extra">
                        <mat-error *ngIf="formGroup.controls['building_number_extra'].invalid">
                            {{getErrorMessage(formGroup.controls['building_number_extra'])}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="mat-form-field mat-form-field-can-float mat-form-field-should-float">
                        <div class="mat-form-field-wrapper">
                            <div class="mat-form-field-flex">
                                <div class="mat-form-field-infix">
                                    <label id="user-form-newsletter" class="mat-form-field-label">{{'user.fields.newsletter'|translate}}</label>
                                    <mat-radio-group
                                        formControlName="newsletter"
                                        aria-labelledby="user-form-newsletter"
                                        required
                                        class="">
                                        <mat-radio-button class="" *ngFor="let item of newsLetterList" [value]="item.id">
                                            {{item.name}}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="mat-form-field mat-form-field-can-float mat-form-field-should-float">
                        <div class="mat-form-field-wrapper">
                            <div class="mat-form-field-flex">
                                <div class="mat-form-field-infix">
                                    <label id="user-form-receives_flyers" class="mat-form-field-label">{{'user.fields.receives_flyers'|translate}}</label>
                                    <mat-radio-group
                                        formControlName="receives_flyers"
                                        aria-labelledby="user-form-receives_flyers"
                                        required
                                        class="">
                                        <mat-radio-button class="w-100" *ngFor="let item of receiveFlyerList" [value]="item.id">
                                            {{item.name}}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="button-row d-flex justify-content-end">
        <button
            mat-raised-button
            color="primary"
            type="submit"
            [disabled]="!formGroup.valid">{{'form.Save'|translate}}</button>

        <button
            mat-raised-button
            (click)="goBack()">{{'form.Cancel'|translate}}</button>
    </div>
</form>
