/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {
    COMPLAINT_TYPE_ID_OBJ_LIST, COMPLAINT_TYPE_OBJ_LIST,
    COMPLAINT_TYPE_OPTION_LIST,
    DATE_TIME_LONG_FORMAT,
    FILTER_BLOCK_TYPE_DATE_TIME_RANGE,
    FILTER_BLOCK_TYPE_INPUT,
    FILTER_BLOCK_TYPE_SELECT, ITEM_STATUS_ID_OBJ_LIST, ITEM_STATUS_OBJ_LIST,
    STATUS_OPTION_LIST,
    USER_POLICY_OBJ_LIST
} from '@app/constants';
import {getCurrentTimeZone} from '@app/functions';
import {Router} from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTable} from '@angular/material/table';
import {LoginService} from '@services/login.service';
import {BaseIndexComponent} from '@classes/base-index';
import {UrlService} from '@services/url.service';
import {IFilterConfig, IFilterValue} from '@interfaces/filter.interface';
import {Helper} from '@classes/helper';
import {IComplaint, IComplaintEditInput} from '@interfaces/complaint.interface';
import {ComplaintService} from '@services/complaint.service';
import {StoreService} from '@services/store.service';
import {IColumn} from '@interfaces/column-filter.interface';

@Component({
    selector: 'app-complaint-index',
    templateUrl: 'index.component.html'
})
export class ComplaintIndexComponent extends BaseIndexComponent<IComplaint, IComplaintEditInput> implements OnInit, AfterViewInit {
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatTable, {static: true}) table: MatTable<any>;

    tableName = 'complaintList';

    tableColumns: Array<IColumn> = [
        {
            name: 'id',
            title: 'complaint.fields.id'
        },
        {
            name: 'mail',
            title: 'complaint.fields.mail'
        },
        {
            name: 'name',
            title: 'complaint.fields.name'
        },
        {
            name: 'zip_code',
            title: 'complaint.fields.zip_code'
        },
        {
            name: 'building_number',
            title: 'complaint.fields.building_number'
        },
        {
            name: 'created',
            title: 'complaint.fields.created'
        },
        {
            name: 'action',
            title: 'item.fields.action'
        }
    ];

    itemList: Array<IComplaint> = [];
    dateTimeLongFormat = DATE_TIME_LONG_FORMAT;
    timeZone = getCurrentTimeZone();

    baseRoute = '/complaint';
    itemHistoryKey = 'complaint';

    filterConfig: IFilterConfig = {
        title: 'common.Filter',
        blocks: [
            {
                title: 'item.fields.id',
                type: FILTER_BLOCK_TYPE_INPUT,
                propertyName: 'id'
            },
            {
                title: 'complaint.fields.mail',
                type: FILTER_BLOCK_TYPE_INPUT,
                propertyName: 'mail'
            },
            {
                title: 'complaint.fields.name',
                type: FILTER_BLOCK_TYPE_INPUT,
                propertyName: 'name'
            },
            {
                title: 'complaint.fields.zip_code',
                type: FILTER_BLOCK_TYPE_INPUT,
                propertyName: 'zip_code'
            },
            {
                title: 'complaint.fields.building_number',
                type: FILTER_BLOCK_TYPE_INPUT,
                propertyName: 'building_number'
            },
            {
                title: 'complaint.fields.type_id',
                type: FILTER_BLOCK_TYPE_SELECT,
                propertyName: 'type_id',
                items: COMPLAINT_TYPE_OPTION_LIST
            },
            {
                placeholderFrom: 'complaint.fields.createdFrom',
                placeholderTo: 'complaint.fields.createdTo',
                type: FILTER_BLOCK_TYPE_DATE_TIME_RANGE,
                propertyName: 'created'
            }
        ]
    };

    constructor(
        protected itemService: ComplaintService,
        protected deleteDialog: MatDialog,
        protected router: Router,
        protected urlService: UrlService,
        private loginService: LoginService,
        protected storeServ: StoreService
    ) {
        super(itemService, deleteDialog, router, urlService, storeServ);
    }

    ngOnInit() {
        this.handleTableColumns();
    }

    ngAfterViewInit() {
        this.handleUrlQuery();
    }

    canDelete(item: IComplaint): boolean {
        return this.loginService.isGranted(USER_POLICY_OBJ_LIST.POLICY_ADMIN_COMPLAINT_EDIT);
    }

    toggleFilter(): void {
        this.displayFilter = !this.displayFilter;
    }

    onFilterChanged(filter: IFilterValue): void {
        this.input.filter = filter;

        this.filterChanged.emit(filter);
    }

    exportToCsv(): void {
        Helper.exportToCsv(this.input, this.itemService, 'complaint_export.csv');
    }

    getTypeTranslationKey(typeId: number): string {
        switch (typeId) {
            case COMPLAINT_TYPE_ID_OBJ_LIST.LATE_DELIVERY:
                return COMPLAINT_TYPE_OBJ_LIST.LATE_DELIVERY;
            case COMPLAINT_TYPE_ID_OBJ_LIST.TYPE_DELIVERED_OLD:
                return COMPLAINT_TYPE_OBJ_LIST.DELIVERED_OLD;
            case COMPLAINT_TYPE_ID_OBJ_LIST.NOT_DELIVERED:
                return COMPLAINT_TYPE_OBJ_LIST.NOT_DELIVERED;
            default: // COMPLAINT_TYPE_ID_OBJ_LIST.WRONG_ADDRESS:
                return COMPLAINT_TYPE_OBJ_LIST.WRONG_ADDRESS;
        }
    }
}
