/**
 * 2022 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2022 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */
import {SidebarItemInterface} from '@interfaces/sidebar-item.interface';
import {USER_POLICY_OBJ_LIST} from '@app/constants';

export const ITEM_LIST: Array<SidebarItemInterface> = [
    {
        title: 'sidebar.dashboard',
        route: '/dashboard',
        icon: 'dashboard'
    },
    {
        type: 'menu',
        sectionName: 'advertiser',
        title: 'sidebar.advertiser.Advertisers',
        icon: 'group',
        availableRule: [
            USER_POLICY_OBJ_LIST.POLICY_ADMIN_ADVERTISER_VIEW,
            USER_POLICY_OBJ_LIST.POLICY_ADMIN_ADVERTISER_EDIT
        ],
        children: [
            {
                title: 'sidebar.advertiser.index',
                route: '/advertiser/index',
            },
            {
                title: 'sidebar.advertiser.create',
                route: '/advertiser/create',
            }
        ]
    },
    {
        type: 'menu',
        sectionName: 'flyer',
        title: 'sidebar.flyer.Flyers',
        icon: 'loyalty',
        availableRule: [
            USER_POLICY_OBJ_LIST.POLICY_ADMIN_FLYER_EDIT,
            USER_POLICY_OBJ_LIST.POLICY_FLYER_ACCEPT_LINK
        ],
        children: [
            {
                title: 'sidebar.flyer.index',
                route: '/flyer/index',
            },
            {
                title: 'sidebar.flyer.create',
                route: '/flyer/create',
            },
            {
                title: 'sidebar.flyer.Accept-link',
                route: '/flyer/accept-link',
                availableRule: [USER_POLICY_OBJ_LIST.POLICY_FLYER_ACCEPT_LINK]
            }
        ]
    },
    {
        type: 'menu',
        sectionName: 'flyer-category',
        title: 'sidebar.flyer-category.Categories',
        icon: 'loyalty',
        availableRule: [
            USER_POLICY_OBJ_LIST.POLICY_ADMIN_FLYER_CATEGORY_EDIT
        ],
        children: [
            {
                title: 'sidebar.flyer-category.index',
                route: '/flyer-category/index',
            },
            {
                title: 'sidebar.flyer-category.create',
                route: '/flyer-category/create',
            }
        ]
    },
    {
        type: 'menu',
        sectionName: 'extra-item',
        title: 'sidebar.extra-item.Extra items',
        icon: 'loyalty',
        availableRule: [
            USER_POLICY_OBJ_LIST.POLICY_ADMIN_EXTRA_ITEM_EDIT
        ],
        children: [
            {
                title: 'sidebar.extra-item.index',
                route: '/extra-item/index',
            },
            {
                title: 'sidebar.extra-item.create',
                route: '/extra-item/create',
            }
        ]
    },
    {
        type: 'menu',
        sectionName: 'interest',
        title: 'sidebar.interest.Interests',
        icon: 'loyalty',
        availableRule: [
            USER_POLICY_OBJ_LIST.POLICY_ADMIN_INTEREST_EDIT
        ],
        children: [
            {
                title: 'sidebar.interest.index',
                route: '/interest/index',
            },
            {
                title: 'sidebar.interest.create',
                route: '/interest/create',
            }
        ]
    },
    {
        title: 'sidebar.flyer-email-optin.Flyer email optins',
        route: '/flyer-email-optin/index',
        icon: 'loyalty',
        availableRule: [
            USER_POLICY_OBJ_LIST.POLICY_ADMIN_FLYER_EMAIL_OPTIN_VIEW,
            USER_POLICY_OBJ_LIST.POLICY_ADMIN_FLYER_EMAIL_OPTIN_EDIT
        ]
    },
    {
        type: 'menu',
        sectionName: 'user',
        title: 'sidebar.user.Users',
        icon: 'group',
        availableRule: [
            USER_POLICY_OBJ_LIST.POLICY_INDEX_USER,
            USER_POLICY_OBJ_LIST.POLICY_EDIT_USER,
            USER_POLICY_OBJ_LIST.POLICY_CREATE_USER
        ],
        children: [
            {
                title: 'sidebar.user.index',
                route: '/user/index',
            },
            {
                title: 'sidebar.user.create',
                route: '/user/create',
            },
            {
                title: 'sidebar.user.import',
                route: '/user/import',
            }
        ]
    },
    {
        type: 'menu',
        sectionName: 'role',
        title: 'sidebar.role.Roles',
        icon: 'group',
        availableRule: [
            USER_POLICY_OBJ_LIST.POLICY_ADMIN_ROLE_VIEW,
            USER_POLICY_OBJ_LIST.POLICY_ADMIN_ROLE_EDIT
        ],
        children: [
            {
                title: 'sidebar.role.index',
                route: '/role/index',
            },
            {
                title: 'sidebar.role.create',
                route: '/role/create',
            }
        ]
    },
    {
        type: 'menu',
        sectionName: 'message',
        title: 'sidebar.message.Messages',
        icon: 'notifications',
        availableRule: [
            USER_POLICY_OBJ_LIST.POLICY_ADMIN_MESSAGE_EDIT
        ],
        children: [
            {
                title: 'sidebar.message.index',
                route: '/message/index',
            },
            {
                title: 'sidebar.message.create',
                route: '/message/create',
            }
        ]
    },
    /*{
        type: 'menu',
        sectionName: 'edition',
        title: 'sidebar.edition.Editions',
        icon: 'loyalty',
        availableRule: [
            USER_POLICY_OBJ_LIST.POLICY_ADMIN_EDITION_EDIT
        ],
        children: [
            {
                title: 'sidebar.edition.index',
                route: '/edition/index',
            },
            {
                title: 'sidebar.edition.create',
                route: '/edition/create',
            }
        ]
    },*/
    /*{
        type: 'menu',
        sectionName: 'complaint',
        title: 'sidebar.complaint.Complaints',
        icon: 'feedback',
        availableRule: [
            USER_POLICY_OBJ_LIST.POLICY_ADMIN_COMPLAINT_EDIT
        ],
        children: [
            {
                title: 'sidebar.complaint.index',
                route: '/complaint/index',
            },
            {
                title: 'sidebar.complaint.create',
                route: '/complaint/create',
            }
        ]
    },*/
    {
        type: 'menu',
        sectionName: 'survey',
        title: 'sidebar.survey.Surveys',
        icon: 'question_answer',
        availableRule: [
            USER_POLICY_OBJ_LIST.POLICY_ADMIN_SURVEY_EDIT
        ],
        children: [
            {
                title: 'sidebar.survey.index',
                route: '/survey/index',
            },
            {
                title: 'sidebar.survey.create',
                route: '/survey/create',
            },
            {
                title: 'sidebar.survey.answer',
                route: '/survey-answer',
            }
        ]
    },
    {
        title: 'sidebar.swagger',
        route: '/swagger',
        icon: 'api',
        availableRule: [USER_POLICY_OBJ_LIST.POLICY_SWAGGER]
    },
];
