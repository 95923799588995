/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {
    ITEM_STATUS_ID_OBJ_LIST,
    ITEM_STATUS_OBJ_LIST,
    USER_POLICY_OBJ_LIST
} from '../../../constants';
import {LoginService} from '../../../services/login.service';
import {TranslateService} from '@ngx-translate/core';
import {FLYER_EXTRA_WITH_EMPTY_DATA, IExtraItem, IExtraItemEditInput} from '../../../interfaces/extra-item.interface';
import {ExtraItemService} from '../../../services/extra-item.service';
import {BaseViewComponent} from '../../../classes/base-view';

@Component({
    selector: 'app-extra-item-view',
    templateUrl: 'view.component.html'
})
export class ExtraItemViewComponent extends BaseViewComponent<IExtraItem, IExtraItemEditInput> implements OnInit {
    item: IExtraItem = {...FLYER_EXTRA_WITH_EMPTY_DATA};
    baseRoute = '/extra-item';
    itemHistoryKey = 'extraItem';

    constructor(
        protected itemService: ExtraItemService,
        protected translate: TranslateService,
        protected deleteDialog: MatDialog,
        protected router: Router,
        protected route: ActivatedRoute,
        private loginService: LoginService
    ) {
        super(itemService, translate, deleteDialog, router, route);
    }

    ngOnInit() {
        this.handleItemFromRequestOrHistory();
    }

    canIndex(): boolean {
        return this.loginService.isGranted(USER_POLICY_OBJ_LIST.POLICY_ADMIN_EXTRA_ITEM_EDIT);
    }

    canDelete(): boolean {
        return this.loginService.isGranted(USER_POLICY_OBJ_LIST.POLICY_ADMIN_EXTRA_ITEM_EDIT)
            && this.item.status_id != ITEM_STATUS_ID_OBJ_LIST.DELETED;
    }

    getStatusClass(status_id: number): string {
        switch (status_id) {
            case ITEM_STATUS_ID_OBJ_LIST.DISABLED:
                return 'yellow';
            case ITEM_STATUS_ID_OBJ_LIST.PAUSED:
                return 'yellow';
            case ITEM_STATUS_ID_OBJ_LIST.DELETED:
                return 'red';
            case ITEM_STATUS_ID_OBJ_LIST.HIDDEN:
                return 'blue';
            case ITEM_STATUS_ID_OBJ_LIST.MODERATED:
                return 'blue';
            default: // ITEM_STATUS_ID_OBJ_LIST.ACTIVE
                return 'green';
        }
    }

    getStatusName(status_id: number): string {
        switch (status_id) {
            case ITEM_STATUS_ID_OBJ_LIST.DISABLED:
                return ITEM_STATUS_OBJ_LIST.DISABLED;
            case ITEM_STATUS_ID_OBJ_LIST.PAUSED:
                return ITEM_STATUS_OBJ_LIST.PAUSED;
            case ITEM_STATUS_ID_OBJ_LIST.DELETED:
                return ITEM_STATUS_OBJ_LIST.DELETED;
            case ITEM_STATUS_ID_OBJ_LIST.HIDDEN:
                return ITEM_STATUS_OBJ_LIST.HIDDEN;
            case ITEM_STATUS_ID_OBJ_LIST.MODERATED:
                return ITEM_STATUS_OBJ_LIST.MODERATED;
            default: // ITEM_STATUS_ID_OBJ_LIST.ACTIVE
                return ITEM_STATUS_OBJ_LIST.ACTIVE;
        }
    }
}
