/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Injectable, EventEmitter} from '@angular/core';
import {Router} from '@angular/router';

import {StoreService, StoreTokenData} from './store.service';
import * as _ from 'lodash';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {TokenService} from './token.service';
import {Observable} from 'rxjs';
import {LoginData} from '../interfaces/login-data.interface';
import {TranslateService} from '@ngx-translate/core';
import {AccountInterface} from '../interfaces/account.interface';
import {
    AVAILABLE_LANGUAGES,
    DEFAULT_LANGUAGE,
    ROLE_GUEST,
    SESSION_LIFETIME,
} from '../constants';
import {USER_WITH_EMPTY_DATA, IUser} from '../interfaces/user.interface';
import {AccessTokenCheckInterface, PasswordChangeHash} from '../interfaces/password-change-hash.interface';
import {environment} from '../../environments/environment';
import {UserService} from './user.service';

@Injectable({
    providedIn: 'root',
})

export class LoginService {
    protected static account: AccountInterface = null;

    public logged: EventEmitter<AccountInterface> = new EventEmitter();

    constructor(
        public routerServ: Router,
        private tokenService: TokenService,
        private storeServ: StoreService,
        private userService: UserService,
        private httpClient: HttpClient,
        private translateService: TranslateService
    ) {
        this.initAccount();
    }

    initAccount() {
        LoginService.account = {...USER_WITH_EMPTY_DATA, ...{token: '', cookieChecked: false}};
    }

    getAccount(): AccountInterface {
        return LoginService.account;
    }

    getInfo(): Observable<IUser> {
        return this.userService.getItem(this.getAccount().id);
    }

    cookieLogin(): Observable<boolean> {
        return new Observable<boolean>((observer) => {
            if (this.storeServ.checkSessionExpired()) {
                this.getAccount().token = '';
                this.getAccount().cookieChecked = true;
            }

            if (this.getAccount().cookieChecked) {
                observer.next(this.getAccount().token.length > 0);
                observer.complete();

                return;
            }

            this.getAccount().cookieChecked = true;
            const data: StoreTokenData = this.storeServ.getInfoFromStore();
            if (!data.id || !data.token.length) {
                this.initAccount();
                this.getAccount().cookieChecked = true;
                observer.next(false);
                observer.complete();

                return;
            }

            this.getAccount().id = data.id;
            this.getAccount().token = data.token;
            this.tokenService.setToken(data.token);

            this.getInfo().subscribe((user) => {
                this.updateAccountData(user);
                // this.refreshLanguage().subscribe(() => {
                    observer.next(true);
                    observer.complete();
                // }, () => {
                //     this.clearLoginData();
                //     this.getAccount().cookieChecked = true;
                //     observer.next(false);
                //     observer.complete();
                // });
            }, () => {
                this.clearLoginData();
                this.getAccount().cookieChecked = true;
                observer.next(false);
                observer.complete();
            });
        });
    }

    cookieLoginSuccess() {
        this.logged.emit(this.getAccount());
    }

    storeAccountInfo(callBack?: Function) {
        this.storeServ.storeValue('uid', this.getAccount().id.toString());
        this.storeServ.storeValue('token', this.getAccount().token);
        this.storeServ.storeSessionExpired(SESSION_LIFETIME);

        if (callBack) {
            callBack();
        }
    }

    clearStoredAccountInfo = (callBack?: Function) => {
        this.storeServ.clearStore();

        if (callBack) {
            callBack();
        }
    };

    login(mail: string, password: string): Observable<AccountInterface|string> {
        return new Observable<AccountInterface|string>((observer) => {
            const data = `grant_type=password&email=${mail}&password=${password}`;
            const opts = {headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'})};

            this.httpClient.post('/auth/basic-with-role', data, opts).subscribe((loginData: LoginData) => {
                this.tokenService.setToken(loginData.authToken.token);
                this.updateAccountData(loginData.user);
                this.getAccount().token = loginData.authToken.token;
                this.storeAccountInfo();

                // this.refreshLanguage().subscribe((language) => {
                // this.getAccount().policies = policies;
                this.logged.emit(this.getAccount());
                observer.next(this.getAccount());
                // });
            },
            (res: HttpErrorResponse) => {
                observer.next(this.getLoginErrorFromResponse(res));
            });
        });
    }

    clearLoginData(callBack?: Function) {
        this.clearStoredAccountInfo();
        this.initAccount();

        if (callBack) {
            callBack();
        }
    }

    logout() {
        this.storeServ.storeValue('logout', '1');
        this.clearLoginData(() => {
            // this.refreshLanguage();
            this.goLogin();
        });
    }

    goDashboard() {
        this.routerServ.navigate(['/dashboard']);
    }

    goLogin() {
        this.routerServ.navigate(['/login']);
    }

    refreshLanguage(): Observable<string> {
        return new Observable<string> ((observer) => {
            const lang = this.getAccount().language;
            this.storeServ.storeValue('lang', lang);

            this.translateService.use(lang).subscribe(() => {
                observer.next(lang);
                observer.complete();
            });
        });
    }

    isGranted(grants: string|Array<string>): boolean {
        const grantList = typeof grants === 'object' ? grants : [grants];

        for (const grant of grantList) {
            if (_.indexOf(this.getAccount().Role.policiesList, grant) > -1) {
                return true;
            }
        }

        return false;
    }

    isLogged(): boolean {
        return this.getAccount().Role.role !== ROLE_GUEST;
    }

    forgotPassword(email: string): Observable<boolean|any> {
        return this.httpClient.post(
            '/auth/basic/reset',
            {
                'email': email
            }
        );
    }

    resetPassword(accessToken: string, password: string): Observable<boolean|any> {
        return this.httpClient.post(
            `/auth/basic/reset-password?accessToken=${accessToken}`,
            {
                'newPassword': password,
                'newPasswordRepeat': password
            }
        );
    }

    checkAccessToken(accessToken: string): Observable<AccessTokenCheckInterface|any> {
        return this.httpClient.get(`/auth/basic/check-access-token?accessToken=${accessToken}`);
    }

    accountHasLogo(): boolean {
        return !!this.getAccount().avatar_img && this.getAccount().avatar_img.length > 0;
    }

    getAccountLogoLink(): string {
        return this.accountHasLogo() ? `${environment.apiUrl}/public/${this.getAccount().avatar_img}` : '';
    }

    updateAccountData(user: IUser): void {
        LoginService.account = {...LoginService.account, ...user};
    }

    private getLoginErrorFromResponse(res: HttpErrorResponse): string {
        let error = '';
        if (!_.isEmpty(res.error.error_description || null)) {
            error = res.error.error_description;
        } else if (!_.isEmpty(res.error.message || null)) {
            error = res.error.message;
        } else {
            error = res.statusText;
        }

        console.warn('Login failed. error:', error);

        return error;
    }
}
