/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {PAGINATOR_PAGE_SIZE} from '../constants';
import {IPageInput} from '@interfaces/page-input.interface';
import {IItemList} from '@interfaces/item-list.interface';
import {IComplaint, IComplaintEditInput} from '@interfaces/complaint.interface';

@Injectable({
    providedIn: 'root',
})

export class ComplaintService {
    constructor(private httpClient: HttpClient) {}

    getItem(id: number): Observable<IComplaint> {
        return this.httpClient.get<IComplaint>(
            `/admin/complaint/${id}`
        );
    }

    getList(input: IPageInput): Observable<IItemList<IComplaint>> {
        const offset = input.page * PAGINATOR_PAGE_SIZE;

        return this.httpClient.get<IItemList<IComplaint>>(
            `/admin/complaint?sort=${input.sort}&order=${input.order}&limit=${PAGINATOR_PAGE_SIZE}&offset=${offset}&filter=${input.filter ? JSON.stringify(input.filter) : ''}`
        );
    }

    storeItem(input: IComplaintEditInput): Observable<IComplaint> {
        if (input.id) {
            return this.httpClient.put<IComplaint>(
                `/admin/complaint/${input.id}`,
                input,
            );
        }

        return this.httpClient.post<IComplaint>(
            '/admin/complaint',
            input,
        );
    }

    deleteItem(item: IComplaint): Observable<IComplaint> {
        return this.httpClient.delete<IComplaint>(
            `/admin/complaint/${item.id}`
        );
    }

    exportToCsv(input: IPageInput): Observable<Blob> {
        return this.httpClient.get(
            `/admin/export/csv/complaint?sort=${input.sort}&order=${input.order}&filter=${input.filter ? JSON.stringify(input.filter) : ''}`,
            { responseType: 'blob' }
        );
    }
}
