<div class="brand-side">
    <a class="home-link" routerLink="/">
        <img src="/assets/images/logo_small.png" alt="Kiesjefolders" class="nav-logo"/>
    </a>
</div>

<div class="right-side">
    <div class="right-menu-toggle" [matMenuTriggerFor]="menu">
        <div
            *ngIf="loginService.accountHasLogo()"
            class="avatar c-pointer">
            <img
                [src]="loginService.getAccountLogoLink()"
                [alt]="loginService.getAccount().first_name"/>
        </div>

        <div class="default-avatar c-pointer" *ngIf="!loginService.accountHasLogo()">
            <div class="square" style="width:32px;height:32px;solid #000;">
                <mat-icon>account_circle</mat-icon>
            </div>
        </div>
    </div>
    <mat-menu #menu="matMenu">
        <a
            mat-menu-item
            [routerLink]="'/user/' + loginService.getAccount().id"
            routerLinkActive="active"
        >
            <mat-icon>person</mat-icon>
            {{ 'navbar.Account' | translate }}
        </a>

        <a
            mat-menu-item
            routerLink="/logout"
        >
            <mat-icon>exit_to_app</mat-icon>
            {{ 'navbar.Sign out' | translate }}
        </a>
    </mat-menu>
</div>
