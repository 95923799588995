/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Injectable} from '@angular/core';

export interface StoreTokenData {
    id: number;
    token: string;
}

@Injectable()

export class StoreService {
    storeValue(key: string, value: string) {
        localStorage.setItem(key, value);
    }

    value(key: string): any {
        return localStorage.getItem(key);
    }

    remove(key: string) {
        return localStorage.removeItem(key);
    }

    storeSessionExpired(sessionPeriod: number) {
        const sesExpDate = new Date();
        sesExpDate.setSeconds(sesExpDate.getSeconds() + sessionPeriod);
        localStorage.setItem('user_login_expired', sesExpDate.toISOString());
    }

    clearStore() {
        localStorage.removeItem('uid');
        localStorage.removeItem('company_id');
        localStorage.removeItem('token');
    }

    getInfoFromStore(): StoreTokenData {
        const id = localStorage.getItem('uid');
        const token = localStorage.getItem('token');

        return {
            id: null === id ? 0 :  Number(id),
            token: null === token ? '' : token
        };
    }

    checkSessionExpired(): boolean {
        let expired = true;
        const sesExp = localStorage.getItem('user_login_expired');
        const sesExpired = null === sesExp ? '' : sesExp;

        if (sesExpired.length) {
            const sesExpDate = new Date(sesExp);

            expired = sesExpDate.getTime() < (new Date()).getTime();
        }

        return expired;
    }
}
