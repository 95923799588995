/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {
    DATE_TIME_LONG_FORMAT,
    FILTER_BLOCK_TYPE_DATE_TIME_RANGE,
    FILTER_BLOCK_TYPE_INPUT, FILTER_BLOCK_TYPE_SEARCH_SELECT,
    FILTER_BLOCK_TYPE_SELECT,
    ITEM_STATUS_ID_OBJ_LIST,
    STATUS_OPTION_LIST,
    USER_POLICY_OBJ_LIST
} from '@app/constants';
import {getCurrentTimeZone} from '@app/functions';
import {Router} from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTable} from '@angular/material/table';
import {LoginService} from '@services/login.service';
import {BaseIndexComponent} from '@classes/base-index';
import {UrlService} from '@services/url.service';
import {IFilterConfig, IFilterValue} from '@interfaces/filter.interface';
import {Helper} from '@classes/helper';
import {
    ISurveyAnswer,
    ISurveyAnswerEditInput,
    ISurveyQuestion
} from '@interfaces/survey.interface';
import {SurveyAnswerService} from '@services/survey-answer.service';
import {UserService} from '@services/user.service';
import {map} from 'rxjs/operators';
import {IItemList} from '@interfaces/item-list.interface';
import {IUser} from '@interfaces/user.interface';
import {SurveyQuestionService} from '@services/survey-question.service';
import {StoreService} from '@services/store.service';
import {IColumn} from '@interfaces/column-filter.interface';

@Component({
    selector: 'app-survey-answer-index',
    templateUrl: 'index.component.html'
})
export class SurveyAnswerIndexComponent extends BaseIndexComponent<ISurveyAnswer, ISurveyAnswerEditInput> implements OnInit, AfterViewInit {
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatTable, {static: true}) table: MatTable<any>;

    tableName = 'surveyAnswerList';

    tableColumns: Array<IColumn> = [
        {
            name: 'id',
            title: 'item.fields.id'
        },
        {
            name: 'user_id',
            title: 'survey-answer.fields.user_id'
        },
        {
            name: 'question_id',
            title: 'survey-answer.fields.question_id'
        },
        {
            name: 'answer',
            title: 'survey-answer.fields.answer'
        }
    ];

    itemList: Array<ISurveyAnswer> = [];

    baseRoute = '/survey-answer';
    itemHistoryKey = 'surveyAnswer';

    filterConfig: IFilterConfig = {
        title: 'common.Filter',
        blocks: [
            {
                title: 'item.fields.id',
                type: FILTER_BLOCK_TYPE_INPUT,
                propertyName: 'id'
            },
            {
                title: 'survey-answer.fields.user_id',
                type: FILTER_BLOCK_TYPE_SEARCH_SELECT,
                propertyName: 'user_id',
                items: [],
                getList: (value: string) => {
                    return this.userService.getList({
                        sort: 'mail',
                        order: 'asc',
                        page: 0,
                        filter: {
                            mail: value
                        }
                    }).pipe(map((data: IItemList<IUser>) => {
                        return data.rows.map((user) => {
                            return {
                                id: user.id,
                                name: user.mail
                            }
                        });
                    }))
                }
            },
            {
                title: 'survey-answer.fields.question_id',
                type: FILTER_BLOCK_TYPE_SEARCH_SELECT,
                propertyName: 'question_id',
                items: [],
                getList: (value: string) => {
                    return this.surveyQuestionService.getList({
                        sort: 'question',
                        order: 'asc',
                        page: 0,
                        filter: {
                            email: value
                        }
                    }).pipe(map((data: IItemList<ISurveyQuestion>) => {
                        return data.rows.map((question) => {
                            return {
                                id: question.id,
                                name: question.question
                            }
                        });
                    }))
                }
            },
            {
                title: 'survey-answer.fields.answer',
                type: FILTER_BLOCK_TYPE_INPUT,
                propertyName: 'answer'
            },
            {
                placeholderFrom: 'survey-answer.fields.createdFrom',
                placeholderTo: 'survey-answer.fields.createdTo',
                type: FILTER_BLOCK_TYPE_DATE_TIME_RANGE,
                propertyName: 'created'
            }
        ]
    };

    constructor(
        protected itemService: SurveyAnswerService,
        protected deleteDialog: MatDialog,
        protected router: Router,
        protected urlService: UrlService,
        private loginService: LoginService,
        private surveyQuestionService: SurveyQuestionService,
        private userService: UserService,
        protected storeServ: StoreService
    ) {
        super(itemService, deleteDialog, router, urlService, storeServ);
    }

    ngOnInit() {
        this.handleTableColumns();
    }

    ngAfterViewInit() {
        this.handleUrlQuery();
    }

    toggleFilter(): void {
        this.displayFilter = !this.displayFilter;
    }

    onFilterChanged(filter: IFilterValue): void {
        this.input.filter = filter;

        this.filterChanged.emit(filter);
    }

    exportToCsv(): void {
        Helper.exportToCsv(this.input, this.itemService, 'survey_answer_export.csv');
    }
}
