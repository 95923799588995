<form class="login-form">
    <mat-error *ngIf="error.length">{{'login.form.errors.' + error|translate}}</mat-error>

    <mat-form-field>
        <mat-label>{{'login.form.email'|translate}}</mat-label>
        <input matInput [placeholder]="'login.form.email'|translate" [formControl]="email" required>
        <mat-error>{{getEmailErrorMessage()|translate}}</mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{'login.form.password'|translate}}</mat-label>
        <input matInput [placeholder]="'login.form.password'|translate" [formControl]="password" required type="password">
        <mat-error>{{getPasswordErrorMessage()|translate}}</mat-error>
    </mat-form-field>

    <div class="form-row button-row">
        <button
            mat-raised-button
            color="primary"
            (click)="login()"
            [disabled]="email.invalid || password.invalid">{{'login.form.Login'|translate}}</button>
    </div>
</form>
