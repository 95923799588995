/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {
    ITEM_FREQUENCY_ID_OBJ_LIST,
    ITEM_STATUS_ID_OBJ_LIST
} from '../constants';
import {IFlyerCategory} from './flyer-category.interface';
import {IAdvertiser} from './advertiser.interface';

export interface IExtraItem {
    id: number;
    status_id: number;
    title: string;
    description_short: string;
    description_long: string;
    image1: string;
    image2?: string;
    frequency: number;
    code: string;
    advertiser_id: number;
    Advertiser?: IAdvertiser;
    opt_in: boolean;
    condition: string;
    step: number;
    preselect: boolean;
    created: string;
    updated: string;
}

export const FLYER_EXTRA_WITH_EMPTY_DATA: IExtraItem = {
    id: 0,
    status_id: ITEM_STATUS_ID_OBJ_LIST.DISABLED,
    title: '',
    description_short: '',
    description_long: '',
    code: '',
    image1: '',
    image2: '',
    frequency: ITEM_FREQUENCY_ID_OBJ_LIST.WEEK,
    advertiser_id: 0,
    created: '',
    updated: '',
    opt_in: false,
    condition: '',
    step: 1,
    preselect: false
};

export interface IExtraItemEditInput {
    data: {
        id: number;
        status_id: number;
        title: string;
        description_short: string;
        description_long: string;
        frequency: number;
        code: string;
        advertiser_id: number;
        opt_in: boolean;
        condition: string;
        step: number;
        preselect: boolean;
    };
    image1: File|null;
    image2: File|null;
}

