/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {PAGINATOR_PAGE_SIZE} from '../constants';
import {IPageInput} from '../interfaces/page-input.interface';
import {IFlyerEditInput, IFlyer} from '../interfaces/flyer.interface';
import {IItemList} from '../interfaces/item-list.interface';

@Injectable({
    providedIn: 'root',
})

export class FlyerService {
    constructor(private httpClient: HttpClient) {}

    generateAcceptLinks(file: File): Observable<any> {
        const formData = new FormData();
        formData.append('file', file, file.name);

        return this.httpClient.post('/admin/flyer/accept-link', formData, {responseType: 'blob'});
    }

    getItem(id: number): Observable<IFlyer> {
        return this.httpClient.get<IFlyer>(
            `/flyer/${id}`
        );
    }

    getList(input: IPageInput): Observable<IItemList<IFlyer>> {
        const offset = input.page * PAGINATOR_PAGE_SIZE;

        return this.httpClient.get<IItemList<IFlyer>>(
            `/flyer/list/?sort=${input.sort}&order=${input.order}&limit=${PAGINATOR_PAGE_SIZE}&offset=${offset}&filter=${input.filter ? JSON.stringify(input.filter) : ''}`
        );
    }

    storeItem(input: IFlyerEditInput): Observable<IFlyer> {
        const formData = new FormData();

        if (null !== input.image1) {
            formData.append('image1', input.image1, input.image1.name);
        }
        if (null !== input.image2) {
            formData.append('image2', input.image2, input.image2.name);
        }

        formData.append('data', JSON.stringify(input.data));

        if (input.data.id) {
            return this.httpClient.put<IFlyer>(
                `/admin/flyer/${input.data.id}`,
                formData,
            );
        }

        return this.httpClient.post<IFlyer>(
            '/admin/flyer',
            formData,
        );
    }

    deleteItem(item: IFlyer): Observable<IFlyer> {
        return this.httpClient.delete<IFlyer>(
            `/admin/flyer/${item.id}`
        );
    }

    exportToCsv(input: IPageInput): Observable<Blob> {
        return this.httpClient.get(
            `/admin/export/csv/flyer?sort=${input.sort}&order=${input.order}&filter=${input.filter ? JSON.stringify(input.filter) : ''}`,
            { responseType: 'blob' }
        );
    }
}
