/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {
    USER_RECEIVE_FLYER_ID_OBJ_LIST,
    YES_NO_ID_OBJ_LIST,
    USER_SEX_ID_OBJ_LIST,
    USER_SOURCE_ID_OBJ_LIST,
    USER_STATUS_ID_OBJ_LIST
} from '../constants';
import {ROLE_WITH_EMPTY_DATA, IRole} from './role.interface';

export interface IUser {
    id: number;
    status_id: number;
    first_name: string;
    middle_name: string;
    last_name: string;
    mail: string;
    password?: string;
    sex: string;
    phone: string;
    avatar_img: string;
    street_address: string;
    zip_code: string;
    zip_area?: number;
    city_id: number;
    building_number?: number;
    building_number_extra?: string;
    receives_flyers?: number;
    newsletter?: number;
    mail_confirmed?: number;
    send_sticker?: number;
    why?: string;
    advertiser_id?: number;
    choice_help?: string;
    delete_reason?: string;
    source_id?: number;
    lat?: number;
    lon?: number;
    authKey?: string;
    accessToken?: string;
    birthday?: string;
    facebook_id?: string;
    google_id?: string;
    apple_id?: string;
    comments: string;
    admin_note: string;
    created: string;
    updated: string;
    role_id: number;
    Role: IRole;
    has_facebook_auth: boolean;
    has_google_auth: boolean;
    has_apple_auth: boolean;
    language: string;
}

export const USER_WITH_EMPTY_DATA: IUser = {
    id: 0,
    status_id: USER_STATUS_ID_OBJ_LIST.DISABLED,
    first_name: '',
    middle_name: '',
    last_name: '',
    mail: '',
    password: '',
    sex: USER_SEX_ID_OBJ_LIST.MALE,
    phone: '',
    avatar_img: '',
    street_address: '',
    zip_code: '',
    zip_area: 0,
    city_id: 0,
    building_number: 0,
    building_number_extra: '',
    receives_flyers: USER_RECEIVE_FLYER_ID_OBJ_LIST.YES,
    newsletter: YES_NO_ID_OBJ_LIST.YES,
    mail_confirmed: 0,
    send_sticker: YES_NO_ID_OBJ_LIST.NO,
    why: '',
    advertiser_id: 0,
    choice_help: '',
    delete_reason: '',
    source_id: USER_SOURCE_ID_OBJ_LIST.WEBSITE,
    lat: 0,
    lon: 0,
    authKey: '',
    accessToken: '',
    birthday: '',
    facebook_id: '',
    google_id: '',
    apple_id: '',
    comments: '',
    admin_note: '',
    created: '',
    updated: '',
    role_id: 0,
    has_facebook_auth: false,
    has_google_auth: false,
    has_apple_auth: false,
    Role: {...ROLE_WITH_EMPTY_DATA},
    language: ''
};

export interface IUserEditInput {
    id: number;
    status_id: string;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    mail: string;
    sex: string;
    // phone: string;
    street_address: string;
    zip_code: string;
    building_number?: number;
    building_number_extra?: string;
    receives_flyers?: number;
    newsletter?: number;
    // send_sticker?: number;
    // why?: string;
    advertiser_id?: number;
    // delete_reason?: string;
    source_id?: number;
    // birthday?: string;
    // comments?: string;
    admin_note?: string;
    role_id: number;
}

