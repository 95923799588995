/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Component} from '@angular/core';
import {
    FORM_SCENARIO_CREATE,
} from '../../../constants';


@Component({
    selector: 'app-user-create',
    templateUrl: './create.component.html'
})
export class UserCreateComponent {
    formScenario = FORM_SCENARIO_CREATE;
}
