/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {IFlyer} from '@interfaces/flyer.interface';

export interface IMessage {
    id: number;
    title: string|null;
    description: string;
    image: string|null;
    flyer_id: number|null;
    Flyer?: IFlyer;
    created: string;
    updated: string|null;
    send_date: string|null;
    notification_id: string|null;
    link: string|null;
    link_url: string|null;
}

export const MESSAGE_WITH_EMPTY_DATA: IMessage = {
    id: 0,
    title: null,
    description: '',
    image: null,
    flyer_id: null,
    created: '',
    updated: null,
    send_date: '',
    notification_id: null,
    link: null,
    link_url: null
};

export interface IMessageEditInput {
    data: {
        id: number|null;
        title: string|null;
        description: string;
        flyer_id: number|null;
        link: string|null;
        link_url: string|null;
        send_date: string;
    };
    image: File|null;
    users: File|null;
}

