<div class="message update-container">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{'message.update.Title'|translate}}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <app-message-form [scenario]="formScenario"></app-message-form>
        </mat-card-content>
    </mat-card>
</div>
