/**
 * 2022 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2022 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ToasterService} from 'angular2-toaster';
import {
    FLYER_LOGO_MAX_FILE_SIZE,
    ITEM_STATUS_ID_OBJ_LIST, ITEM_STATUS_OBJ_LIST,
    FORM_SCENARIO_CREATE,
    FORM_SCENARIO_EDIT
} from '@app/constants';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseForm} from '@classes/base-form';
import {environment} from '../../../../environments/environment';
import {IImageFileRestriction} from '@interfaces/image-file-restriction';
import {IInterest, IInterestEditInput, INTEREST_WITH_EMPTY_DATA} from '@interfaces/interest.interface';
import {InterestService} from '@services/interest.service';
import {ADVERTISER_WITH_EMPTY_DATA, IAdvertiser, IAdvertiserEditInput} from '@interfaces/advertiser.interface';
import {AdvertiserService} from '@services/advertiser.service';


@Component({
    selector: 'app-advertiser-form',
    templateUrl: 'form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdvertiserFormComponent extends BaseForm<IAdvertiser, IAdvertiserEditInput> implements OnInit {
    item: IAdvertiser = {...ADVERTISER_WITH_EMPTY_DATA};
    backLink = '/advertiser/index';
    error = '';

    scenarioCreate = FORM_SCENARIO_CREATE; // Need for component HTML usage
    scenarioEdit = FORM_SCENARIO_EDIT; // Need for component HTML usage

    formGroup: FormGroup = null;

    statusList = [
        {id: ITEM_STATUS_ID_OBJ_LIST.DELETED, name: ITEM_STATUS_OBJ_LIST.DELETED},
        {id: ITEM_STATUS_ID_OBJ_LIST.ACTIVE, name: ITEM_STATUS_OBJ_LIST.ACTIVE},
        {id: ITEM_STATUS_ID_OBJ_LIST.PAUSED, name: ITEM_STATUS_OBJ_LIST.PAUSED},
        {id: ITEM_STATUS_ID_OBJ_LIST.DISABLED, name: ITEM_STATUS_OBJ_LIST.DISABLED},
        {id: ITEM_STATUS_ID_OBJ_LIST.HIDDEN, name: ITEM_STATUS_OBJ_LIST.HIDDEN},
        {id: ITEM_STATUS_ID_OBJ_LIST.MODERATED, name: ITEM_STATUS_OBJ_LIST.MODERATED},
    ];

    constructor(
        protected translate: TranslateService,
        protected itemService: AdvertiserService,
        protected toasterService: ToasterService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected changeDetectorRef: ChangeDetectorRef,
        private formBuilder: FormBuilder
    ) {
        super(translate, toasterService, route, router, changeDetectorRef, itemService);

        this.buildFormGroup();
    }

    ngOnInit() {
        this.handleItemFromRequestOrHistory('advertiser');
    }

    fillFormData() {
        const formData = {
            status_id: this.item.status_id,
            company: this.item.company,
            contact_name: this.item.contact_name,
            zip_code: this.item.zip_code,
            street_address: this.item.street_address,
            city: this.item.city,
            state: this.item.state,
            country: this.item.country
        };

        this.formGroup.reset(formData);
    }

    getFormErrorMessage(): string {
        return ''; // TODO: Add handler
    }

    submitForm(data: any): void {
        this.error = '';

        const input = this.getInputFromFormData(data);

        this.itemService.storeItem(input).subscribe((item) => {
            this.item = item;
            this.onSave();
        }, result => this.handleRequestError(result, 'Advertiser'), () => {
            this.goBack();
        });
    }

    getInputFromFormData(data: any): IAdvertiserEditInput {
        return {
            id: this.item.id,
            status_id: data.status_id,
            zip_code: data.zip_code,
            company: data.company,
            contact_name: data.contact_name,
            street_address: data.street_address,
            city: data.city,
            state: data.state,
            country: data.country
        };
    }

    private buildFormGroup() {
        this.formGroup = this.formBuilder.group({
            status_id: ['', Validators.compose([
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(255)
            ])],
            company: ['', Validators.compose([
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(255)
            ])],
            contact_name: ['', Validators.compose([
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(255)
            ])],
            zip_code: ['', Validators.compose([
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(255)
            ])],
            street_address: ['', Validators.compose([
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(255)
            ])],
            city: ['', Validators.compose([
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(255)
            ])],
            state: ['', Validators.compose([
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(255)
            ])],
            country: ['', Validators.compose([
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(255)
            ])]
        });
    }
}
