/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {
    USER_POLICY_OBJ_LIST
} from '@app/constants';
import {LoginService} from '@services/login.service';
import {TranslateService} from '@ngx-translate/core';
import {BaseViewComponent} from '@classes/base-view';
import {IMessage, IMessageEditInput, MESSAGE_WITH_EMPTY_DATA} from '@interfaces/message.interface';
import {MessageService} from '@services/message.service';

@Component({
    selector: 'app-message-view',
    templateUrl: 'view.component.html'
})
export class MessageViewComponent extends BaseViewComponent<IMessage, IMessageEditInput> implements OnInit {
    item: IMessage = {...MESSAGE_WITH_EMPTY_DATA};
    baseRoute = '/message';
    itemHistoryKey = 'message';

    constructor(
        protected itemService: MessageService,
        protected translate: TranslateService,
        protected deleteDialog: MatDialog,
        protected router: Router,
        protected route: ActivatedRoute,
        private loginService: LoginService
    ) {
        super(itemService, translate, deleteDialog, router, route);
    }

    ngOnInit() {
        this.handleItemFromRequestOrHistory();
    }

    canIndex(): boolean {
        return this.loginService.isGranted(USER_POLICY_OBJ_LIST.POLICY_ADMIN_MESSAGE_EDIT);
    }

    canDelete(): boolean {
        return this.loginService.isGranted(USER_POLICY_OBJ_LIST.POLICY_ADMIN_MESSAGE_EDIT);
    }
}
