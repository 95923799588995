/**
 * 2020 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2020 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {LoginService} from './login.service';
import * as _ from 'lodash';
import {Observable} from 'rxjs';

@Injectable()

export class AccessService implements CanActivate {
    constructor(
        public loginService: LoginService,
        protected router: Router
    ) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return new Observable<boolean>((observer) => {
            this.loginService.cookieLogin().subscribe((logged: boolean) => {
                if (logged && state.url === '/login') {
                    // this.router.navigate(['/']);
                    observer.next(false);
                    observer.complete();

                    return;
                }

                observer.next(this.routeIsGranted(route, '/'));
            });
        });
    }

    private routeIsGranted(route: ActivatedRouteSnapshot, redirect?: string): boolean {
        if (route.data === undefined || route.data.policies === undefined || _.isEmpty(route.data.policies)) {
            return true;
        }

        if (!this.loginService.isGranted(route.data.policies)) {
            if (typeof redirect === 'string' && redirect.length) {
                this.router.navigate([redirect]);
            }

            return false;
        }

        return true;
    }
}
