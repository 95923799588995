/**
 * 2020 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2020 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {ChangeDetectionStrategy, Component, OnInit, Renderer2} from '@angular/core';
import {ToasterConfig} from 'angular2-toaster';
import * as _ from 'lodash';
import {AVAILABLE_LANGUAGES, EVENT_SIDEBAR_TOGGLE, NO_LAYOUT_ROUTE_LIST} from './constants';
import {EventService} from './services/event.service';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
    // changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit{
    title = 'frontend';
    displayLayout = false;
    showMainOverlay = false;

    toasterConfig: ToasterConfig = new ToasterConfig({
        showCloseButton: false,
        tapToDismiss: true,
        timeout: 4000,
        positionClass: 'toast-top-right'
    });

    constructor(
        private eventService: EventService,
        private router: Router,
        private translate: TranslateService,
        private renderer: Renderer2,
        private route: ActivatedRoute,
    ) {}

    ngOnInit() {
        this.eventService.event$.subscribe((data) => {
            if (!_.isEmpty(data.event) && EVENT_SIDEBAR_TOGGLE === data.event) {
                this.showMainOverlay = !this.showMainOverlay;
            }
        });

        this.router.events.subscribe((event) => {
            this.showMainOverlay = false;

            // Check that current URL isn't partner page
            if (event instanceof NavigationEnd) {
                for (const route of NO_LAYOUT_ROUTE_LIST) {
                    if (event.url.indexOf(route) === 0) {
                        this.displayLayout = false;

                        return;
                    }
                }

                this.displayLayout = true;
            }
        });

        this.route.queryParamMap.subscribe(queryParams => {
            const lang = queryParams.get('lang');
            if (AVAILABLE_LANGUAGES.indexOf(lang) > -1 && lang !== this.translate.currentLang) {
                this.translate.use(lang);
            }
        });
    }

    isLogged() {

    }
}
