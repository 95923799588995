/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {
    USER_POLICY_OBJ_LIST,
} from '../../../constants';
import {Router} from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTable} from '@angular/material/table';
import {LoginService} from '../../../services/login.service';
import {IRole, IRoleEditInput} from '../../../interfaces/role.interface';
import {RoleService} from '../../../services/role.service';
import {BaseIndexComponent} from '../../../classes/base-index';
import {UrlService} from '@services/url.service';
import * as url from 'url';
import {StoreService} from '@services/store.service';
import {IColumn} from '@interfaces/column-filter.interface';

@Component({
    selector: 'app-role-index',
    templateUrl: './index.component.html'
})
export class RoleIndexComponent extends BaseIndexComponent<IRole, IRoleEditInput> implements OnInit, AfterViewInit {
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatTable, {static: true}) table: MatTable<any>;

    tableName = 'roleList';

    tableColumns: Array<IColumn> = [
        {
            name: 'id',
            title: 'item.fields.id'
        },
        {
            name: 'role',
            title: 'role.fields.role'
        },
        {
            name: 'policiesList',
            title: 'role.fields.policiesList'
        },
        {
            name: 'createdAt',
            title: 'role.fields.createdAt'
        },
        {
            name: 'updatedAt',
            title: 'role.fields.updatedAt'
        },
        {
            name: 'action',
            title: 'item.fields.action'
        }
    ];

    baseRoute = '/role';
    itemHistoryKey = 'role';

    constructor(
        protected itemService: RoleService,
        protected deleteDialog: MatDialog,
        protected router: Router,
        protected urlService: UrlService,
        private loginService: LoginService,
        protected storeServ: StoreService
    ) {
        super(itemService, deleteDialog, router, urlService, storeServ);
    }

    ngOnInit() {
        this.handleTableColumns();
    }

    ngAfterViewInit() {
        this.handleUrlQuery();
    }

    canDelete(role: IRole): boolean {
        return this.loginService.isGranted(USER_POLICY_OBJ_LIST.POLICY_ADMIN_ROLE_EDIT);
    }
}
