<div class="advertiser view-container">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{'advertiser.view.Title'|translate}}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <div class="row">
                <div class="col-4">
                    <h3>{{'advertiser.fields.status_id'|translate}}</h3>
                    <div class="status" [ngClass]="getStatusClass(item.status_id)">{{'status.' + getStatusName(item.status_id)|translate}}</div>
                </div>

                <div class="col-4">
                    <h3>{{'advertiser.fields.company'|translate}}</h3>
                    <div>{{item.company}}</div>
                </div>

                <div class="col-4">
                    <h3>{{'item.fields.id'|translate}}</h3>
                    <div>{{item.id}}</div>
                </div>
            </div>
            <hr>

            <div class="row">
                <div class="col-4">
                    <h3>{{'advertiser.fields.contact_name'|translate}}</h3>
                    <div>{{item.contact_name}}</div>
                </div>

                <div class="col-4">
                    <h3>{{'advertiser.fields.zip_code'|translate}}</h3>
                    <div>{{item.zip_code}}</div>
                </div>

                <div class="col-4">
                    <h3>{{'advertiser.fields.street_address'|translate}}</h3>
                    <div>{{item.street_address}}</div>
                </div>
            </div>
            <hr>

            <div class="row">
                <div class="col-4">
                    <h3>{{'advertiser.fields.city'|translate}}</h3>
                    <div>{{item.city}}</div>
                </div>

                <div class="col-4">
                    <h3>{{'advertiser.fields.state'|translate}}</h3>
                    <div>{{item.state}}</div>
                </div>

                <div class="col-4">
                    <h3>{{'advertiser.fields.country'|translate}}</h3>
                    <div>{{item.country}}</div>
                </div>
            </div>
            <hr>

            <div class="button-row d-flex justify-content-end mt-3">
                <button
                    mat-raised-button
                    color="primary"
                    (click)="updateSelected(item)">{{'actions.update'|translate}}</button>

                <button *ngIf="canDelete()"
                    mat-raised-button
                    color="warn"
                    (click)="deleteSelected(item)">{{'actions.delete'|translate}}</button>

                <button *ngIf="canIndex()"
                    mat-raised-button
                    (click)="backToIndex()">{{'actions.Back to list'|translate}}</button>
            </div>
        </mat-card-content>
    </mat-card>
</div>



