/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Inject, Injectable} from '@angular/core';
import {WINDOW} from './window-providers';

@Injectable({
    providedIn: 'root'
})
export class DomainService {

    constructor(@Inject(WINDOW) private window: Window) {
    }

    getHostname(): string {
        return this.window.location.hostname;
    }
}
