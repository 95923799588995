/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Injectable} from '@angular/core';

@Injectable()

export class SpinnerService {
    counter = 0;
    spinnerElement: HTMLElement;

    constructor(
    ) {
        this.spinnerElement = document.getElementById('main-spinner-overlay');
        this.spinnerElement.style.visibility = 'visible';
    }

    start(): void {
        ++this.counter;
        this.spinnerElement.hidden = false;
    }

    stop(): void {
        if (this.counter) {
            --this.counter;
        }
        if (!this.counter) {
            setTimeout(() => {
                if (!this.counter) {
                    this.spinnerElement.hidden = true;
                }
            }, 300);
        }
    }
}
