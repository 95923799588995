/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {
    DATE_TIME_LONG_FORMAT,
    USER_POLICY_OBJ_LIST
} from '../../../constants';
import {getCurrentTimeZone} from '../../../functions';
import {LoginService} from '../../../services/login.service';
import {ToasterService} from 'angular2-toaster';
import {TranslateService} from '@ngx-translate/core';
import {FLYER_CATEGORY_WITH_EMPTY_DATA, IFlyerCategory} from '../../../interfaces/flyer-category.interface';
import {FlyerCategoryService} from '../../../services/flyer-category.service';
import {DeleteDialog} from '../../dialogs/delete/delete.component';

@Component({
    selector: 'app-flyer-category-view',
    templateUrl: 'view.component.html'
})
export class FlyerCategoryViewComponent implements OnInit {
    item: IFlyerCategory = {...FLYER_CATEGORY_WITH_EMPTY_DATA};
    dateTimeLongFormat = DATE_TIME_LONG_FORMAT;
    timeZone = getCurrentTimeZone();

    constructor(
        private flyerCategoryService: FlyerCategoryService,
        private loginService: LoginService,
        private toasterService: ToasterService,
        private translate: TranslateService,
        private deleteDialog: MatDialog,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.route.paramMap.subscribe(params => {
            const id = params.get('id');

            if (!id.length) {
                this.router.navigate(['/404']);

                return;
            }

            // Get company from route data history
            if (undefined !== history.state.flyerCategory && id === history.state.flyerCategory.id) {
                this.item = history.state.flyerCategory;

                return;
            }

            // Get company data from API
            this.flyerCategoryService.getItem(Number(id)).subscribe((item) => {
                this.item = item;
            });
        }, err => {
            console.error(err);
        });
    }

    updateSelected(item: IFlyerCategory): void {
        this.router.navigateByUrl(
            `/flyer-category/update/${item.id}`,
            {
                state: {
                    flyerCategory: item,
                    backLink: `/flyer-category/${item.id}`
                }
            }
        );
    }

    deleteSelected(item: IFlyerCategory): void {
        const dialogRef = this.deleteDialog.open(DeleteDialog);

        dialogRef.afterClosed().subscribe(result => {
            if (true === result) {
                this.flyerCategoryService.deleteItem(item).subscribe(() => {
                    this.backToIndex();
                });
            }
        });
    }

    backToIndex(): void {
        this.router.navigate(['/flyer-category/index']);
    }

    canIndex(): boolean {
        return this.loginService.isGranted(USER_POLICY_OBJ_LIST.POLICY_ADMIN_FLYER_CATEGORY_EDIT);
    }

    canDelete(): boolean {
        return this.loginService.isGranted(USER_POLICY_OBJ_LIST.POLICY_ADMIN_FLYER_CATEGORY_EDIT)
    }
}
