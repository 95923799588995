<div class="interest create-container">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{'interest.create.Title'|translate}}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <app-interest-form [scenario]="formScenario"></app-interest-form>
        </mat-card-content>
    </mat-card>
</div>
