/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {
    ITEM_STATUS_ID_OBJ_LIST,
    ITEM_STATUS_OBJ_LIST,
    USER_POLICY_OBJ_LIST
} from '@app/constants';
import {LoginService} from '@services/login.service';
import {TranslateService} from '@ngx-translate/core';
import {BaseViewComponent} from '@classes/base-view';
import {ISurvey, ISurveyEditInput, SURVEY_WITH_EMPTY_DATA} from '@interfaces/survey.interface';
import {SurveyService} from '@services/survey.service';

@Component({
    selector: 'app-survey-view',
    templateUrl: 'view.component.html'
})
export class SurveyViewComponent extends BaseViewComponent<ISurvey, ISurveyEditInput> implements OnInit {
    item: ISurvey = {...SURVEY_WITH_EMPTY_DATA};
    baseRoute = '/survey';
    itemHistoryKey = 'survey';

    constructor(
        protected itemService: SurveyService,
        protected translate: TranslateService,
        protected deleteDialog: MatDialog,
        protected router: Router,
        protected route: ActivatedRoute,
        private loginService: LoginService
    ) {
        super(itemService, translate, deleteDialog, router, route);
    }

    ngOnInit() {
        this.handleItemFromRequestOrHistory();
    }

    canIndex(): boolean {
        return this.loginService.isGranted(USER_POLICY_OBJ_LIST.POLICY_ADMIN_SURVEY_EDIT);
    }

    canDelete(): boolean {
        return this.loginService.isGranted(USER_POLICY_OBJ_LIST.POLICY_ADMIN_SURVEY_EDIT)
            && this.item.status_id != ITEM_STATUS_ID_OBJ_LIST.DELETED;
    }

    getStatusClass(status_id: number): string {
        switch (status_id) {
            case ITEM_STATUS_ID_OBJ_LIST.DISABLED:
                return 'yellow';
            case ITEM_STATUS_ID_OBJ_LIST.PAUSED:
                return 'yellow';
            case ITEM_STATUS_ID_OBJ_LIST.DELETED:
                return 'red';
            case ITEM_STATUS_ID_OBJ_LIST.HIDDEN:
                return 'blue';
            case ITEM_STATUS_ID_OBJ_LIST.MODERATED:
                return 'blue';
            default: // ITEM_STATUS_ID_OBJ_LIST.ACTIVE
                return 'green';
        }
    }

    getStatusName(status_id: number): string {
        switch (status_id) {
            case ITEM_STATUS_ID_OBJ_LIST.DISABLED:
                return ITEM_STATUS_OBJ_LIST.DISABLED;
            case ITEM_STATUS_ID_OBJ_LIST.PAUSED:
                return ITEM_STATUS_OBJ_LIST.PAUSED;
            case ITEM_STATUS_ID_OBJ_LIST.DELETED:
                return ITEM_STATUS_OBJ_LIST.DELETED;
            case ITEM_STATUS_ID_OBJ_LIST.HIDDEN:
                return ITEM_STATUS_OBJ_LIST.HIDDEN;
            case ITEM_STATUS_ID_OBJ_LIST.MODERATED:
                return ITEM_STATUS_OBJ_LIST.MODERATED;
            default: // ITEM_STATUS_ID_OBJ_LIST.ACTIVE
                return ITEM_STATUS_OBJ_LIST.ACTIVE;
        }
    }
}
