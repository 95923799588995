/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {
    FILTER_BLOCK_TYPE_DATE_TIME_RANGE,
    FILTER_BLOCK_TYPE_INPUT,
    ITEM_STATUS_ID_OBJ_LIST,
    USER_POLICY_OBJ_LIST
} from '@app/constants';
import {Router} from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTable} from '@angular/material/table';
import {LoginService} from '@services/login.service';
import {IFlyerCategory, IFlyerCategoryEditInput} from '@interfaces/flyer-category.interface';
import {FlyerCategoryService} from '@services/flyer-category.service';
import {BaseIndexComponent} from '@classes/base-index';
import {UrlService} from '@services/url.service';
import {IFilterConfig, IFilterValue} from '@interfaces/filter.interface';
import {StoreService} from '@services/store.service';
import {IColumn} from '@interfaces/column-filter.interface';

@Component({
    selector: 'app-flyer-category-index',
    templateUrl: 'index.component.html'
})
export class FlyerCategoryIndexComponent extends BaseIndexComponent<IFlyerCategory, IFlyerCategoryEditInput> implements OnInit, AfterViewInit {
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatTable, {static: true}) table: MatTable<any>;

    tableName = 'flyerCategoryList';

    tableColumns: Array<IColumn> = [
        {
            name: 'id',
            title: 'item.fields.id'
        },
        {
            name: 'icon',
            title: 'flyer-category.fields.icon'
        },
        {
            name: 'name',
            title: 'flyer-category.fields.name'
        },
        {
            name: 'description',
            title: 'flyer-category.fields.description'
        },
        {
            name: 'action',
            title: 'item.fields.action'
        }
    ];

    statusDeleted = ITEM_STATUS_ID_OBJ_LIST.DELETED;

    baseRoute = '/flyer-category';
    itemHistoryKey = 'flyerCategory';

    filterConfig: IFilterConfig = {
        title: 'common.Filter',
        blocks: [
            {
                title: 'flyer-category.fields.id',
                type: FILTER_BLOCK_TYPE_INPUT,
                propertyName: 'id',
            },
            {
                title: 'flyer-category.fields.name',
                type: FILTER_BLOCK_TYPE_INPUT,
                propertyName: 'name',
            },
            {
                title: 'flyer-category.fields.description',
                type: FILTER_BLOCK_TYPE_INPUT,
                propertyName: 'description'
            }
        ]
    }

    constructor(
        protected itemService: FlyerCategoryService,
        protected deleteDialog: MatDialog,
        protected router: Router,
        protected urlService: UrlService,
        private loginService: LoginService,
        protected storeServ: StoreService
    ) {
        super(itemService, deleteDialog, router, urlService, storeServ);
    }

    ngOnInit() {
        this.handleTableColumns();
    }

    ngAfterViewInit() {
        this.handleUrlQuery();
    }

    canDelete(category: IFlyerCategory): boolean {
        return this.loginService.isGranted(USER_POLICY_OBJ_LIST.POLICY_ADMIN_FLYER_CATEGORY_EDIT);
    }

    toggleFilter(): void {
        this.displayFilter = !this.displayFilter;
    }

    onFilterChanged(filter: IFilterValue): void {
        this.input.filter = filter;

        this.filterChanged.emit(filter);
    }
}
