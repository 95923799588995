/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {
    DATE_TIME_LONG_FORMAT,
    FILTER_BLOCK_TYPE_DATE_TIME_RANGE,
    FILTER_BLOCK_TYPE_INPUT,
    FILTER_BLOCK_TYPE_SELECT,
    ITEM_STATUS_ID_OBJ_LIST,
    STATUS_OPTION_LIST,
    USER_POLICY_OBJ_LIST
} from '@app/constants';
import {getCurrentTimeZone} from '@app/functions';
import {Router} from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTable} from '@angular/material/table';
import {LoginService} from '@services/login.service';
import {BaseIndexComponent} from '@classes/base-index';
import {UrlService} from '@services/url.service';
import {IFilterConfig, IFilterValue, ISelectOption} from '@interfaces/filter.interface';
import {Helper} from '@classes/helper';
import {IEdition, IEditionEditInput} from '@interfaces/edition.interface';
import {EditionService} from '@services/edition.service';
import {FlyerService} from '@services/flyer.service';
import {StoreService} from '@services/store.service';
import {IColumn} from '@interfaces/column-filter.interface';

@Component({
    selector: 'app-edition-index',
    templateUrl: 'index.component.html'
})
export class EditionIndexComponent extends BaseIndexComponent<IEdition, IEditionEditInput> implements OnInit, AfterViewInit {
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatTable, {static: true}) table: MatTable<any>;

    tableName = 'editionList';

    tableColumns: Array<IColumn> = [
        {
            name: 'id',
            title: 'item.fields.id'
        },
        {
            name: 'status_id',
            title: 'edition.fields.status_id'
        },
        {
            name: 'flyer_id',
            title: 'edition.fields.flyer_id'
        },
        {
            name: 'title',
            title: 'edition.fields.title'
        },
        {
            name: 'size',
            title: 'edition.fields.size'
        },
        {
            name: 'pages',
            title: 'edition.fields.pages'
        },
        {
            name: 'weight',
            title: 'edition.fields.weight'
        },
        {
            name: 'pricing',
            title: 'edition.fields.pricing'
        },
        {
            name: 'date_start',
            title: 'edition.fields.date_start'
        },
        {
            name: 'date_end',
            title: 'edition.fields.date_end'
        },
        {
            name: 'action',
            title: 'item.fields.action'
        }
    ];

    itemList: Array<IEdition> = [];
    dateTimeLongFormat = DATE_TIME_LONG_FORMAT;
    timeZone = getCurrentTimeZone();
    statusDeleted = ITEM_STATUS_ID_OBJ_LIST.DELETED;

    baseRoute = '/edition';
    itemHistoryKey = 'edition';

    filterConfig: IFilterConfig;

    constructor(
        protected itemService: EditionService,
        protected deleteDialog: MatDialog,
        protected router: Router,
        protected urlService: UrlService,
        private loginService: LoginService,
        private flyerService: FlyerService,
        protected storeServ: StoreService
    ) {
        super(itemService, deleteDialog, router, urlService, storeServ);

        this.flyerService.getList({
            sort: 'title',
            order: 'asc',
            page: 0,
            filter: {}
        }).subscribe((list) => {
            const itemList: Array<ISelectOption> = list.rows.map((flyer) => {
                return {
                    id: flyer.id.toString(),
                    name: flyer.title
                }
            });

            this.filterConfig = {
                title: 'common.Filter',
                blocks: [
                    {
                        title: 'item.fields.id',
                        type: FILTER_BLOCK_TYPE_INPUT,
                        propertyName: 'id'
                    },
                    {
                        title: 'edition.fields.status_id',
                        type: FILTER_BLOCK_TYPE_SELECT,
                        propertyName: 'status_id',
                        items: STATUS_OPTION_LIST
                    },
                    {
                        title: 'edition.fields.flyer_id',
                        type: FILTER_BLOCK_TYPE_SELECT,
                        propertyName: 'flyer_id',
                        items: itemList
                    },
                    {
                        title: 'edition.fields.title',
                        type: FILTER_BLOCK_TYPE_INPUT,
                        propertyName: 'title'
                    },
                    {
                        title: 'edition.fields.size',
                        type: FILTER_BLOCK_TYPE_INPUT,
                        propertyName: 'size'
                    },
                    {
                        title: 'edition.fields.pages',
                        type: FILTER_BLOCK_TYPE_INPUT,
                        propertyName: 'pages'
                    },
                    {
                        placeholderFrom: 'edition.fields.dateStartFrom',
                        placeholderTo: 'edition.fields.dateStartTo',
                        type: FILTER_BLOCK_TYPE_DATE_TIME_RANGE,
                        propertyName: 'date_start'
                    },
                    {
                        placeholderFrom: 'edition.fields.dateEndFrom',
                        placeholderTo: 'edition.fields.dateEndTo',
                        type: FILTER_BLOCK_TYPE_DATE_TIME_RANGE,
                        propertyName: 'date_end'
                    }
                ]
            };
        })
    }

    ngOnInit() {
        this.handleTableColumns();
    }

    ngAfterViewInit() {
        this.handleUrlQuery();
    }

    canDelete(item: IEdition): boolean {
        return this.loginService.isGranted(USER_POLICY_OBJ_LIST.POLICY_ADMIN_EDITION_EDIT) && item.status_id != ITEM_STATUS_ID_OBJ_LIST.DELETED;
    }

    toggleFilter(): void {
        this.displayFilter = !this.displayFilter;
    }

    onFilterChanged(filter: IFilterValue): void {
        this.input.filter = filter;

        this.filterChanged.emit(filter);
    }

    exportToCsv(): void {
        Helper.exportToCsv(this.input, this.itemService, 'edition_export.csv');
    }
}
