/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

export interface IComplaint {
    id: number;
    mail: string;
    name: string;
    zip_code: string;
    building_number: number;
    type_id: number;
    text: string;
    created: string;
}

export const COMPLAINT_WITH_EMPTY_DATA: IComplaint = {
    id: 0,
    mail: '',
    name: '',
    zip_code: '',
    building_number: 0,
    type_id: 1,
    text: '',
    created: ''
};

export interface IComplaintEditInput {
    id: number;
    mail: string;
    name: string;
    zip_code: string;
    building_number: number;
    type_id: number;
    text: string;
}

