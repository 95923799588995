/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {PAGINATOR_PAGE_SIZE} from '../constants';
import {IPageInput} from '../interfaces/page-input.interface';
import {IItemList} from '../interfaces/item-list.interface';
import {IFlyerCategory, IFlyerCategoryEditInput} from '../interfaces/flyer-category.interface';
import {IAdvertiser} from '@interfaces/advertiser.interface';
import {ISelectOption} from '@interfaces/filter.interface';
import _ from 'lodash';

@Injectable({
    providedIn: 'root',
})

export class FlyerCategoryService {
    constructor(private httpClient: HttpClient) {}

    getItem(id: number): Observable<IFlyerCategory> {
        return this.httpClient.get<IFlyerCategory>(
            `/admin/flyer-category/${id}`
        );
    }

    getList(input: IPageInput): Observable<IItemList<IFlyerCategory>> {
        const offset = input.page * PAGINATOR_PAGE_SIZE;
        const limit = !_.isNil(input.limit) ? input.limit : PAGINATOR_PAGE_SIZE;

        return this.httpClient.get<IItemList<IFlyerCategory>>(
            `/flyer/categories/?sort=${input.sort}&order=${input.order}&limit=${limit}&offset=${offset}&filter=${input.filter ? JSON.stringify(input.filter) : ''}`
        );
    }

    storeItem(input: IFlyerCategoryEditInput): Observable<IFlyerCategory> {
        // const formData = new FormData();

        // formData.append('data', JSON.stringify(input));

        if (input.id) {
            return this.httpClient.put<IFlyerCategory>(
                `/admin/flyer-category/${input.id}`,
                input,
            );
        }

        return this.httpClient.post<IFlyerCategory>(
            '/admin/flyer-category',
            input,
        );
    }

    deleteItem(flyer: IFlyerCategory): Observable<IFlyerCategory> {
        return this.httpClient.delete<IFlyerCategory>(
            `/admin/flyer-category/${flyer.id}`
        );
    }

    storeCategoryItems(flyerId: number, categoryIdList: Array<number>): Observable<Array<number>> {
        return this.httpClient.post<Array<number>>(
            `/admin/flyer/${flyerId}/category-items`,
            {
                categoryIdList: categoryIdList
            }
        );
    }

    getOptionList(): Observable<Array<ISelectOption>> {
        return new Observable<Array<ISelectOption>>((observer) => {
            this.getList({
                page: 0,
                limit: 0,
                sort: '',
                order:'asc',
                filter: {}
            }).subscribe((data) => {
                observer.next(data.rows.map((item) => {
                    return {
                        id: item.id.toString(),
                        name: item.name
                    }
                }));
            }, () => {
                observer.next([]);
            }, () => (observer.complete()));
        });
    }
}
