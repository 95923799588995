/**
 * 2021 Genstu
 *
 *  @author    Polyakov Pavel <polyakov84@gmail.com>
 *  @copyright 2013-2021 Genstu
 *  @license   GNU General Public License version 2
 *
 * http://genstu.com
 */

import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {
    MASK_POSTAL_CODE,
    USER_POLICY_OBJ_LIST
} from '../../../constants';
import {Router} from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTable} from '@angular/material/table';
import {LoginService} from '../../../services/login.service';
import {IFlyer} from '../../../interfaces/flyer.interface';
import {BaseIndexComponent} from '../../../classes/base-index';
import {IFlyerZipArea, IFlyerZipAreaEditInput} from '../../../interfaces/flyer-zip-area.interface';
import {FlyerZipAreaService} from '../../../services/flyer-zip-area.service';
import {IPageInput} from '../../../interfaces/page-input.interface';
import {ValueDialog} from '../../dialogs/value/value.component';
import _ from 'lodash';
import {UrlService} from '@services/url.service';
import {StoreService} from '@services/store.service';

@Component({
    selector: 'app-flyer-zip-area-index',
    templateUrl: 'index.component.html'
})
export class FlyerZipAreaIndexComponent extends BaseIndexComponent<IFlyerZipArea, IFlyerZipAreaEditInput> implements OnInit, AfterViewInit, OnChanges {
    @Input('item') item: IFlyer;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatTable, {static: true}) table: MatTable<any>;

    displayedColumns: Array<string> = ['id', /*'flyer_id', */'zip_area', 'zip_code', 'action'];

    input: IPageInput = {
        sort: '',
        order: '',
        page: 0,
        filter: {}
    };

    constructor(
        protected itemService: FlyerZipAreaService,
        protected deleteDialog: MatDialog,
        protected valueDialog: MatDialog,
        protected router: Router,
        protected urlService: UrlService,
        private loginService: LoginService,
        protected storeServ: StoreService
    ) {
        super(itemService, deleteDialog, router, urlService, storeServ);
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.subscribeEvents();
    }

    ngOnChanges(changes: SimpleChanges) {
        const item = changes.item.currentValue;
        if (item.id) {
            this.input.filter.flyer_id = this.item.id.toString();
            this.updateList();
        }
    }

    canEdit(): boolean {
        return this.loginService.isGranted(USER_POLICY_OBJ_LIST.POLICY_ADMIN_FLYER_ZIP_AREA_EDIT);
    }

    create(): void {
        this.editItemValue(0, {
            data: {
                title: 'flyer-zip-area.create.Title',
                mask: MASK_POSTAL_CODE
            }
        });
    }

    updateSelected(item: IFlyerZipArea): void {
        this.editItemValue(item.id, {
            data: {
                title: 'flyer-zip-area.update.Title',
                mask: MASK_POSTAL_CODE,
                value: (item.zip_area || item.zip_code)
            }
        });
    }

    private editItemValue(itemId: number, dialogData: any): void {
        const dialogRef = this.valueDialog.open(ValueDialog, dialogData);

        dialogRef.afterClosed().subscribe(area => {
            if (!_.isNil(area)) {
                this.itemService.storeItem({
                    id: itemId,
                    flyer_id: this.item.id,
                    zip_area: (4 === area.length ? area.toLowerCase() : null),
                    zip_code: (6 === area.length ? area.toLowerCase() : null)
                }).subscribe(() => {
                    this.paginator.pageIndex = 0;
                    this.updateList();
                });
            }
        });
    }
}
